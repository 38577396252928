import { gql } from '@apollo/client';

export const GET_NURSERIES = gql`
  query {
    nurseries {
      name
      uuid
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query {
    customers {
      name
      uuid
    }
  }
`;

export const WRITE_LOCATION = gql`
  mutation writeLocation($input: LocationInput!) {
    writeLocation(input: $input) {
      name
      uuid
    }
  }
`;

export const GET_LOCATION_GROUPS_LEAF_NODES = gql`
  query LocationGroupCityCluster {
    locationGroups(filter: { onlyCityClusters: true }) {
      name
      uuid
    }
  }
`;

export const GET_LOCATION = gql`
  query Location($uuid: ID!) {
    location(uuid: $uuid) {
      uuid
      name
      timezone
      category
      locationGroup {
        name
        uuid
      }
      nursery {
        uuid
        name
      }
      customer {
        name
        uuid
      }
      farms: operationalFarms {
        name
        model
        uuid
        serialNumber
        capacityInBenches
        nurseryBenches
        lightGerminationBenches
        darkGerminationBenches
      }
    }
  }
`;
