import { FarmModel, AcreType } from '../../types';
import { SelectItem } from '../../components/Select';
import { ConfirmModal, FarmError } from './types';

export enum ACRE_MAX_FIXED_BENCHES {
  'ACRE_24' = 14,
  'ACRE_32' = 22
}

export const mapFarmType = (farmType: string): string => {
  return farmType === AcreType.ACRE_32 || farmType === AcreType.ACRE_24 ? FarmModel.ACRE : farmType;
};

export const mapBenchesToFarmType = (benches: number | undefined) => {
  if (!benches) {
    return '';
  }
  const farm = farmSelectTypes.find(farm => farm.capacityInBenches === benches);

  return farm ? farm.value : '';
};

export const farmSelectTypes: SelectItem[] = [
  { value: FarmModel.INSTOREV2, text: 'InStoreV2', capacityInBenches: 4 },
  { value: AcreType.ACRE_32, text: 'Acre 32 bench', capacityInBenches: 32 },
  { value: AcreType.ACRE_24, text: 'Acre 24 bench', capacityInBenches: 24 },
  { value: FarmModel.GROWTH_CHAMBER, text: 'Nursery Chamber', capacityInBenches: 36 }
];

export const farmValidationMsg = {
  [FarmError.FARM_NAME]: 'Please select a valid value from the list.',
  [FarmError.FARM_TYPE]: 'Please enter a valid farm name.'
};

export const defaultValues = {
  locationGroupUuid: '',
  category: '',
  name: '',
  customerUuid: '',
  timezone: '',
  farms: [],
  nurseryUuid: ''
};

interface ConfirmModalTextFromConfigProps {
  errorMessage?: string | null | undefined;
  locationName: string;
  isEdit: boolean;
  isCancelModal: boolean;
  farmsExist: boolean;
}

const getModalBody = (errorMessage: string) => {
  try {
    const message = JSON.parse(errorMessage);
    return message;
  } catch (error) {
    return errorMessage;
  }
};

export const getConfirmModalTextFromConfig = (
  args: ConfirmModalTextFromConfigProps
): ConfirmModal => {
  const { errorMessage, isEdit, isCancelModal, farmsExist, locationName } = args;
  if (!!errorMessage && isEdit)
    return {
      modalHeading: 'An error occured while updating your Location or Farm',
      modalBody: getModalBody(errorMessage),
      cancelLabel: 'OK'
    };
  if (!!errorMessage)
    return {
      modalHeading: 'An error occured while creating your Location or Farm',
      modalBody: getModalBody(errorMessage),
      cancelLabel: 'OK'
    };

  if (isCancelModal && isEdit)
    return {
      modalHeading: 'Are you sure you want to cancel?',
      modalBody: 'If you click on cancel, you will loose the changes made to the location.',
      cancelLabel: 'No, continue',
      confirmLabel: 'Yes, cancel'
    };
  if (isEdit)
    return {
      modalHeading: 'You are about to save the changes.',
      modalBody: 'Please make sure all information are correct',
      cancelLabel: 'Cancel',
      confirmLabel: 'Save Changes'
    };
  if (!isCancelModal && !farmsExist)
    return {
      modalHeading: `Do you want to create the location '${locationName}' without a Farm?`,
      modalBody:
        "In order to create a planting schedule a Farm needs to be added to the location. You can add a Farm later in 'Location Settings'.",
      cancelLabel: 'Cancel',
      confirmLabel: 'Yes, create location'
    };
  if (!isCancelModal && farmsExist)
    return {
      modalHeading: `Do you want to create the location ${locationName} now?`,
      modalBody:
        "Please make sure all the information is correct. If you need to update it later, you can do so in 'Location Settings'.",
      cancelLabel: 'Cancel',
      confirmLabel: 'Yes, create location'
    };

  return {
    modalHeading: 'Are you sure you want to cancel?',
    modalBody: 'If you cancel, the location will not be saved.',
    cancelLabel: 'No, continue',
    confirmLabel: 'Yes, cancel'
  };
};
