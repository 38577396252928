import { useState } from 'react';
import Select, { Options } from 'react-select';
import { useParams } from 'react-router';
import UserIcon from '../../../assets/user.svg';
import { useQuery } from '@apollo/client';
import { GET_GROWERS } from '../queries';
import { Grower } from '.';
import {
  GrowerListMenu,
  GrowerOption,
  OptionContainer,
  OptionIcon,
  DetailsContainer,
  GrowerName,
  Span
} from './style';
import { getFormattedGrowers } from './utils';

interface SelectGrowersProps {
  selectedOptions: Options<Grower>;
  setSelectedOptions: (options: Options<Grower>) => void;
}

export const SelectGrowers = ({ selectedOptions, setSelectedOptions }: SelectGrowersProps) => {
  const [growers, setGrowers] = useState<Grower[]>([]);
  const { locationUuid } = useParams();
  const { loading } = useQuery(GET_GROWERS, {
    variables: {
      uuid: locationUuid
    },
    onCompleted: data => {
      if (data?.location) {
        setGrowers(
          getFormattedGrowers(
            data.location.locationGroup.growerSupervisors,
            data.location.locationGroup.growers
          )
        );
      }
    }
  });

  const Menu = (props: any) => {
    return <GrowerListMenu {...props}>{props.children}</GrowerListMenu>;
  };

  const Option = (props: any) => {
    return (
      <GrowerOption {...props}>
        <OptionContainer>
          <OptionIcon src={UserIcon} alt="User Icon"></OptionIcon>
          <DetailsContainer>
            <GrowerName>{props.data.name}</GrowerName>
            <Span>{props.data.role}</Span>
          </DetailsContainer>
        </OptionContainer>
      </GrowerOption>
    );
  };

  return (
    <Select
      onChange={setSelectedOptions}
      options={growers}
      isLoading={loading}
      menuIsOpen
      isSearchable
      isMulti
      components={{ Menu, Option }}
      value={selectedOptions}
      placeholder={'Select Growers'}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.uuid}
      styles={{
        dropdownIndicator: base => ({
          ...base,
          display: 'none'
        }),
        indicatorSeparator: base => ({
          ...base,
          display: 'none'
        })
      }}
    />
  );
};
