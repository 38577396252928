import { DsButton, DsRow } from '@infarm/design-system-react';
import { useState } from 'react';
import {
  Button,
  FormControlLabel,
  makeStyles,
  Modal as MaterialModal,
  Radio,
  RadioGroup
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { OperationalFarm } from '../../../types';
import { DsSelect } from '../../../components/Select';

import {
  ModalWrapper,
  ModalHeading,
  ModalButtonsRow,
  ScheduleListButton,
  ProductionOrderNote,
  ServiceDeskLink
} from './style';
import { PRODUCTION_ORDER_TYPES } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';

interface ModalProps {
  farmListForIndividualOrder: OperationalFarm[];
  totalFarmForGroupOrder: number;
}

const useStyles = makeStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: 'black'
    }
  },
  checked: {}
});

export const ProductionOrderModal = (props: ModalProps) => {
  const classes = useStyles();
  const { farmListForIndividualOrder, totalFarmForGroupOrder } = props;
  const { locationUuid } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setModalVisibility] = useState(false);
  const hasGroupOrderFarm = totalFarmForGroupOrder > 0;
  const [productionOrderType, setProductionOrderType] = useState(
    hasGroupOrderFarm ? PRODUCTION_ORDER_TYPES.GROUP : PRODUCTION_ORDER_TYPES.INDIVIDUAL
  );

  const hasFarmListForIndividualOrder = farmListForIndividualOrder.length;
  const [selectedAcreUuid, setSelectedAcreUuid] = useState(
    hasFarmListForIndividualOrder ? farmListForIndividualOrder[0].uuid : undefined
  );

  const selectItems = farmListForIndividualOrder?.map(({ uuid, name }) => ({
    text: name,
    value: uuid
  }));

  const onModalClosed = () => setModalVisibility(false);

  const onPrimaryBtnClick = () => {
    if (productionOrderType === PRODUCTION_ORDER_TYPES.GROUP) {
      navigate(`/locations/${locationUuid}/hub-demand`);
    } else {
      navigate(`/locations/${locationUuid}/${selectedAcreUuid}/planting-schedules/create`);
    }
  };

  return (
    <>
      <DsButton
        label="Place Production Order"
        data-cy="ProductionOrderModal___placeOrder"
        primary
        onClick={() => setModalVisibility(true)}
      />
      <MaterialModal open={isModalOpen} onClose={onModalClosed}>
        <ModalWrapper>
          <ModalHeading>Which type of Production Order do you wish to create?</ModalHeading>
          <br />
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={productionOrderType}
            onChange={(_, value) => {
              setProductionOrderType(value as unknown as PRODUCTION_ORDER_TYPES);
            }}
          >
            <FormControlLabel
              value={PRODUCTION_ORDER_TYPES.GROUP}
              control={
                <Radio
                  disabled={!hasGroupOrderFarm}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              }
              label={`${PRODUCTION_ORDER_TYPES.GROUP} Production Order`}
              disabled={!hasGroupOrderFarm}
            />
            <FormControlLabel
              value={PRODUCTION_ORDER_TYPES.INDIVIDUAL}
              control={
                <Radio
                  disabled={!hasFarmListForIndividualOrder}
                  classes={{
                    root: classes.root,
                    checked: classes.checked
                  }}
                />
              }
              label={`${PRODUCTION_ORDER_TYPES.INDIVIDUAL} Production Order`}
              disabled={!hasFarmListForIndividualOrder}
            />
          </RadioGroup>
          {productionOrderType === PRODUCTION_ORDER_TYPES.INDIVIDUAL && (
            <DsSelect
              fullWidth
              label="Choose Acre to continue"
              onChange={({ target }: { target: HTMLSelectElement }) => {
                setSelectedAcreUuid(target?.value);
              }}
              items={selectItems}
              value={selectedAcreUuid}
            />
          )}
          {hasGroupOrderFarm && productionOrderType === PRODUCTION_ORDER_TYPES.GROUP && (
            <>
              <br />
              <ProductionOrderNote>
                Currently there are {totalFarmForGroupOrder} Acres available for Group Production
                Order. If you wish to change the Acres assigned to this type of Production order,
                please raise a ticket in the{' '}
                <ServiceDeskLink
                  target="_blank"
                  href="https://infarm.atlassian.net/servicedesk/customer/portal/1/group/14/create/56"
                >
                  Service Desk.
                </ServiceDeskLink>
              </ProductionOrderNote>
            </>
          )}
          <ModalButtonsRow>
            <Button onClick={onModalClosed} variant="outlined" style={{ textTransform: 'none' }}>
              Cancel
            </Button>
            <DsButton label={`Create Order`} primary onClick={onPrimaryBtnClick} />
          </ModalButtonsRow>
          {productionOrderType === PRODUCTION_ORDER_TYPES.INDIVIDUAL && (
            <DsRow>
              <ScheduleListButton
                label="Go to Planting Schedule List"
                data-cy="ProductionOrderModal___goToPSList"
                flat
                onClick={() => {
                  navigate(`/locations/${locationUuid}/${selectedAcreUuid}/planting-schedules`);
                }}
              >
                <div slot="trailingIcon">
                  <ArrowForwardIcon fontSize="small" />
                </div>
              </ScheduleListButton>
            </DsRow>
          )}
        </ModalWrapper>
      </MaterialModal>
    </>
  );
};
