import { FC, Fragment, useEffect, useState } from 'react';
import { FarmContainer } from '../style';
import { HeaderRow } from './HeaderRow';
import { generateDaysOfWeek, toIsoDate } from '../../../../../utils/date-functions';
import { NurseryMode } from '../../../../constants';
import { StageRecipeRow } from './StageRecipeRow';
import { ExpandedRowData } from './types';
import { getTraysByNurseryModes } from '../../utils';
import { LocationWaveByFarm } from '../../types';
import { BenchRow } from './BenchRow';

interface NurserySystemTableProps {
  waveByFarm: LocationWaveByFarm;
  date: Date;
  selectedNurseryModes: NurseryMode[];
}

export const NurserySystemTable: FC<NurserySystemTableProps> = ({
  waveByFarm,
  date,
  selectedNurseryModes
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [expandedRowData, setExpandedRowData] = useState<{ [date: string]: ExpandedRowData }>({});
  const toggleExpand = () => setExpanded(curr => !curr);
  const weekDates = generateDaysOfWeek(date);
  const isFarmActive = waveByFarm.waveGroup.totalNumberOfTrays;

  useEffect(() => {
    const updateValues = () => {
      const tempExpandedRowData: { [date: string]: ExpandedRowData } = {};
      generateDaysOfWeek(date).forEach(weekDate => {
        const isoWeekDate = toIsoDate(weekDate);
        const { waveGroup } =
          waveByFarm.waveGroup.byDate.find(
            waveGroupByDate => waveGroupByDate.date === isoWeekDate
          ) ?? {};

        tempExpandedRowData[isoWeekDate] = {
          totalNumberOfTrays: waveGroup
            ? getTraysByNurseryModes(waveGroup, selectedNurseryModes)
            : 0
        };
      });
      setExpandedRowData(tempExpandedRowData);
    };
    updateValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveByFarm, date]);

  return (
    <FarmContainer isInactive={!isFarmActive}>
      <HeaderRow
        waveByFarm={waveByFarm}
        data={expandedRowData}
        isExpanded={isExpanded}
        toggleExpand={toggleExpand}
        dateHeaders={weekDates}
        selectedNurseryModes={selectedNurseryModes}
      />
      {isExpanded &&
        waveByFarm.waveGroup.byBench?.map(waveGroupsByBench => (
          <Fragment key={waveGroupsByBench.bench.uuid}>
            <BenchRow
              waveGroupsByBench={waveGroupsByBench}
              date={date}
              selectedNurseryModes={selectedNurseryModes}
              traysPerBench={waveByFarm.farm.traysPerBench}
            />
            {waveGroupsByBench.waveGroup.byStageRecipe.map(waveGroupsByStageRecipe => (
              <StageRecipeRow
                waveGroupsByStageRecipe={waveGroupsByStageRecipe}
                date={date}
                key={`${waveGroupsByBench.bench.uuid}-${waveGroupsByStageRecipe.stageRecipe.uuid}`}
                selectedNurseryModes={selectedNurseryModes}
              />
            ))}
          </Fragment>
        ))}
    </FarmContainer>
  );
};
