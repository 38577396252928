import { Breadcrumbs } from '../../components/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { PageSubTitle, PageTitle, SectionSubTitle, SectionWrapper } from './style';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { refreshJwt } from '@infarm/auth';
import { DateConfig, ScheduleDetails, ButtonsSection, Modal } from './components';
import { formSchema } from './validationSchema';
import { FormProps, ProductExcessDemand } from './types';
import { HUB_DEMAND_BY_DATE, HUB_DETAILS, ADD_HUB_DEMAND, SEAL_HUB_DEMAND } from './queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import {
  convertAPIResponseToHubDemandConfig,
  convertConfigToApiInput,
  formatHubInfo,
  generateProductDemandDates
} from './utils';
import { DsPlaceholder } from '@infarm/design-system-react';
import { addDays } from 'date-fns';
import { toIsoDate, offsetDateWithTimezone } from '../../utils/date-functions';
import { StageRecipe } from '../../types';
import { Error } from '../../components/Error';
import { ModalType, validationModalContent } from './constants';
import { ValidationModalText } from './components/ValidationModalText';

export const HubDemand = () => {
  const { locationUuid } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType>(ModalType.ERROR);
  const scheduleDetailsRef = useRef<null | HTMLDivElement>(null);

  const formMethods = useForm<FormProps>({
    defaultValues: {
      doesRepeatWeekly: false,
      configurations: [],
      stageRecipes: [],
      startDate: undefined,
      endDate: undefined
    },
    resolver: yupResolver(formSchema()),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError'
  });
  const { handleSubmit, setValue, control } = formMethods;

  const [startDate, endDate, doesRepeatWeekly, configurations] = useWatch({
    name: ['startDate', 'endDate', 'doesRepeatWeekly', 'configurations'],
    control
  });
  const { data: hubDetails, loading } = useQuery(HUB_DETAILS, {
    variables: {
      uuid: locationUuid
    },
    onCompleted: response => {
      if (response?.location) {
        setValue('hubDetails', response?.location);
      }
      if (response?.stageRecipes) {
        const filteredStageRecipes = [...response?.stageRecipes].filter(
          stageRecipe => !stageRecipe.name.toLowerCase().includes('empty')
        );
        const sortedStageRecipes = filteredStageRecipes.sort((a: StageRecipe, b: StageRecipe) =>
          `${a.internalCode ? a.internalCode + '_' : ''}${a.name}` >
          `${b.internalCode ? b.internalCode + '_' : ''}${b.name}`
            ? 1
            : -1
        );
        setValue('stageRecipes', sortedStageRecipes);
      }
    }
  });
  const [getHubDemand, { data }] = useLazyQuery(HUB_DEMAND_BY_DATE);
  const [addHubDemand, { loading: addHubDemandLoading, data: validationResponse, error, reset }] =
    useMutation(ADD_HUB_DEMAND, {
      onCompleted: ({ writeLocationDemands }: { writeLocationDemands: ProductExcessDemand[] }) => {
        const hasAnyExcessDemands = writeLocationDemands.some(
          ({ excessDemands }) => excessDemands?.length
        );
        if (hasAnyExcessDemands) {
          setIsModalOpen(false);
          executeScroll();
        } else {
          setModalType(ModalType.SUBMIT);
        }
      },
      onError: () => {
        setModalType(ModalType.ERROR);
        setIsModalOpen(true);
      }
    });

  const productExcessDemands = validationResponse?.writeLocationDemands || [];

  useEffect(() => {
    if (!configurations.length) {
      reset();
    }
  }, [configurations, reset]);

  const [sealHubDemand, { loading: sealingLoading }] = useMutation(SEAL_HUB_DEMAND, {
    onCompleted: () => {
      setModalType(ModalType.SUCCESS);
      setIsModalOpen(true);
    },
    onError: () => {
      setModalType(ModalType.SUBMIT_ERROR);
      setIsModalOpen(true);
    }
  });
  const plantingWeekDays = generateProductDemandDates(startDate, endDate, doesRepeatWeekly);

  useEffect(() => {
    setValue('configurations', []);
    if (data?.locationDemands) {
      const configurations = convertAPIResponseToHubDemandConfig(
        data.locationDemands,
        generateProductDemandDates(startDate, endDate, doesRepeatWeekly)
      );
      setValue('configurations', configurations);
    }
  }, [data, startDate, endDate, setValue, doesRepeatWeekly]);

  useEffect(() => {
    if (startDate && endDate)
      getHubDemand({
        variables: {
          locationUuid,
          harvestStartDate: startDate,
          harvestEndDate: toIsoDate(addDays(offsetDateWithTimezone(startDate), 6))
        }
      });
  }, [getHubDemand, locationUuid, startDate, endDate]);

  const onApplySchedule = async ({ configurations, endDate }: FormProps) => {
    if (configurations && endDate) {
      setModalType(ModalType.VALIDATING);
      setIsModalOpen(true);

      // We do polling on the middleware side and location demand takes time.
      // So the token gets expired during polling and results in to the error.
      // To Buy more time, we first refresh the token before making actual call.
      await refreshJwt((globalThis as any).env.KOMPOST_ENDPOINT);
      addHubDemand({
        variables: {
          input: {
            locationUuid,
            locationDemands: convertConfigToApiInput(configurations, endDate)
          }
        }
      });
    }
  };

  const onPrimaryBtnClick = () => {
    if (modalType === ModalType.SUBMIT) {
      sealHubDemand({
        variables: {
          input: productExcessDemands.map(({ locationDemand }) => locationDemand.uuid)
        }
      });
    }
    if (modalType === ModalType.SUCCESS) {
      navigate(`/locations/${locationUuid}`);
    }
    if (modalType === ModalType.ERROR) {
      setModalType(ModalType.VALIDATING);
      addHubDemand({
        variables: {
          input: {
            locationUuid,
            locationDemands: convertConfigToApiInput(configurations, endDate!)
          }
        }
      });
    }

    if (modalType === ModalType.SUBMIT_ERROR) {
      setModalType(ModalType.SUBMIT);
      sealHubDemand({
        variables: {
          input: productExcessDemands.map(({ locationDemand }) => locationDemand.uuid)
        }
      });
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) return <DsPlaceholder />;

  const executeScroll = () => {
    scheduleDetailsRef?.current?.scrollIntoView();
  };

  const hubName = hubDetails?.location?.name;
  const operationalFarms = hubDetails?.location?.operationalFarms || [];

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations',
            link: `/locations`
          },
          {
            text: hubName,
            link: `/locations/${locationUuid}`
          },
          {
            text: `Production Order`
          }
        ]}
      />
      <Modal
        onSecondaryBtnClick={closeModal}
        onPrimaryBtnClick={onPrimaryBtnClick}
        isModalOpen={isModalOpen}
        secondaryLabel={validationModalContent[modalType].secondaryLabel}
        primaryLabel={validationModalContent[modalType].primaryLabel}
        heading={validationModalContent[modalType].heading}
        loading={sealingLoading}
        text={ValidationModalText({ modalType, startDate, locationUuid })}
        onModalClosed={(_, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          setIsModalOpen(false);
        }}
      />
      <PageTitle>Production Order</PageTitle>
      {hubDetails?.location && (
        <SectionSubTitle>{formatHubInfo(hubName, operationalFarms)}</SectionSubTitle>
      )}
      <SectionWrapper>
        <PageSubTitle>Harvest period</PageSubTitle>
        <SectionSubTitle>Select from when you wish to produce the demand.</SectionSubTitle>
      </SectionWrapper>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onApplySchedule)}
          noValidate
          onKeyPress={event => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
        >
          <DateConfig />
          <div ref={scheduleDetailsRef}>
            <ScheduleDetails
              plantingWeekDays={plantingWeekDays}
              excessDemands={productExcessDemands}
            />
          </div>
          <ButtonsSection loading={addHubDemandLoading} />
        </form>
        {error && <Error error={error}></Error>}
      </FormProvider>
    </>
  );
};
