import { FC, useState } from 'react';
import { TaskType } from '../../../../types';
import { UnitFilter } from '../../constants';
import { FarmContainer } from '../../style';
import { LocationTaskGroupByOperationalFarm } from '../types';
import { FarmHeaderRow } from './FarmHeaderRow';
import { VarietyRow } from './VarietyRow';

interface Props {
  taskGroupByOperationalFarm: LocationTaskGroupByOperationalFarm;
  weekDates: string[];
  selectedUnitFilter: UnitFilter;
  selectedTaskType: TaskType;
}

export const ProductionExpandableRowFarm: FC<Props> = ({
  taskGroupByOperationalFarm,
  weekDates,
  selectedUnitFilter,
  selectedTaskType
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const { operationalFarm, taskGroup } = taskGroupByOperationalFarm;

  const toggleExpand = () => setExpanded(curr => !curr);

  return (
    <FarmContainer className={isExpanded ? 'expanded' : ''} key={operationalFarm.uuid}>
      <FarmHeaderRow
        isExpanded={isExpanded}
        toggleExpand={toggleExpand}
        selectedUnitFilter={selectedUnitFilter}
        selectedTaskType={selectedTaskType}
        weekDates={weekDates}
        taskGroupByOperationalFarm={taskGroupByOperationalFarm}
      />
      {isExpanded &&
        taskGroup.byStageRecipe?.map(taskGroupByStageRecipe => (
          <VarietyRow
            key={taskGroupByStageRecipe.stageRecipe.uuid}
            taskGroupByStageRecipe={taskGroupByStageRecipe}
            weekDates={weekDates}
            selectedUnitFilter={selectedUnitFilter}
          />
        ))}
    </FarmContainer>
  );
};
