import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { DsIcon } from '@infarm/design-system-react';
import { FormProps, FormState } from '../types';
import { Section, CommentSectionTitleWrapper, StyledInput, ErrorMessage } from '../style';

export const CommentInput = (props: UseControllerProps<FormProps>) => {
  const { field } = useController(props);
  const { type } = useParams();
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <Section>
      <CommentSectionTitleWrapper>
        Comment <span>optional</span>
      </CommentSectionTitleWrapper>
      <StyledInput
        fullWidth
        inputProps={{ 'data-cy': 'comment' }}
        variant="outlined"
        multiline
        aria-label="commnet"
        minRows={3}
        onInput={field.onChange}
        value={field.value}
        placeholder="Add additional comments to this planting schedule"
        error={!!errors?.comment}
        disabled={type === FormState.VIEW}
      />
      {errors?.comment && (
        <ErrorMessage>
          <DsIcon>error_outline</DsIcon>
          {errors?.comment?.message}
        </ErrorMessage>
      )}
    </Section>
  );
};
