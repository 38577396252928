import { TextField } from '@material-ui/core';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { StyledWarningIcon } from '../../pages/HubDemand/style';
import { IconTooltip } from '../IconTooltip';

export const StyledInput = styled(TextField)<{ padding?: string }>`
  & .MuiOutlinedInput-input {
    padding: ${({ padding }) => (padding ? padding : '')};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: transparent !important;
    }
    &.Mui-error fieldset {
      border-color: #f44336 !important;
    }
  }
`;

interface NumberInputProps extends UseControllerProps {
  disabled?: boolean;
  padding?: string;
  resetOnFocus?: boolean;
  warning?: boolean;
  isPlantingDatePast?: boolean;
}
export const NumberInput = (props: NumberInputProps) => {
  const { disabled, padding, resetOnFocus = false, warning, isPlantingDatePast } = props;
  const { field } = useController(props);
  const { trigger } = useFormContext();
  const tooltipColor = isPlantingDatePast ? '#BDBDBD' : '#FFDA87';

  return (
    <>
      <StyledInput
        padding={padding}
        value={isNaN(field.value) ? '' : field.value}
        type="number"
        variant="outlined"
        inputProps={{
          'data-cy': 'trayNumber',
          min: 0
        }}
        InputProps={{
          endAdornment: warning && (
            <IconTooltip
              icon="warning"
              heading={isPlantingDatePast ? 'Cannot edit' : 'Seeding date in the past'}
              placement="right"
              body={
                isPlantingDatePast
                  ? 'Orders with planting date for today or in the past cannot be edited.'
                  : 'Seeding date in the past. Please align with the Nursery team as it can result in the lack or overproduction of seedlings.'
              }
              styles={{
                backgroundColor: tooltipColor,
                arrowColor: tooltipColor,
                headingColor: 'rgba(0, 0, 0, 0.9);',
                bodyColor: 'rgba(0, 0, 0, 0.6);'
              }}
            >
              <StyledWarningIcon style={{ fontSize: '18px' }} />
            </IconTooltip>
          )
        }}
        required
        error={field.value < 0 || isNaN(field.value)}
        onInput={e => {
          const value = parseInt((e.target as HTMLInputElement).value, 10);
          field.onChange(value);
          trigger();
        }}
        onFocus={() => {
          if (resetOnFocus && !field.value) {
            field.onChange('');
          }
        }}
        onBlur={(e: any) => {
          const value = parseInt((e.target as HTMLInputElement).value, 10);
          if (isNaN(value)) field.onChange(0);
        }}
        disabled={disabled}
      />
    </>
  );
};
