import { TextField } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

interface TrayInputrProps {
  date: string;
  value: number;
  disabled?: boolean;
  onTrayUpdated: (input: [string, number]) => void;
}
export const TrayInput = ({ date, value, disabled, onTrayUpdated }: TrayInputrProps) => {
  const { getValues } = useFormContext();
  const { startDate } = getValues();
  return (
    <TextField
      fullWidth
      value={value || ''}
      error={value < 0 || isNaN(value)}
      type="number"
      variant="outlined"
      inputProps={{ 'data-cy': 'trayNumber' }}
      onInput={e => {
        const value = parseInt((e.target as HTMLInputElement).value, 10);
        onTrayUpdated([date, value]);
      }}
      onBlur={e => {
        const value = parseInt((e.target as HTMLInputElement).value, 10);
        if (isNaN(value)) onTrayUpdated([date, 0]);
      }}
      disabled={disabled || !startDate}
    />
  );
};
