import { Backdrop, CircularProgress } from '@material-ui/core';

interface BackDropProps {
  loading: boolean;
}

export const BackDrop = (props: BackDropProps) => {
  const { loading } = props;

  return (
    <Backdrop style={{ zIndex: 2, color: '#fff' }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
