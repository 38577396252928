import { FC } from 'react';
import { DsButton } from '@infarm/design-system-react';
import { ImageLabel } from '../../../../components/ImageLabel';
import { isToday } from '../../../../utils/date-functions';
import { ButtonContainer, DataRow, FlexStyledSpan, LeftBorderdSpan, Row, Span } from '../../style';
import varietyImage from '../../../../assets/variety.png';
import { UnitFilter } from '../../constants';
import { formatRowData, formatStageRecipeDetails } from '../utils';
import { LocationTaskGroupByStageRecipe } from '../types';

interface Props {
  isExpanded: boolean;
  toggleExpand: () => any;
  taskGroupByStageRecipe: LocationTaskGroupByStageRecipe;
  selectedUnitFilter: UnitFilter;
  weekDates: string[];
}

export const VarietyHeaderRow: FC<Props> = ({
  isExpanded,
  toggleExpand,
  taskGroupByStageRecipe,
  selectedUnitFilter,
  weekDates
}) => {
  const rowData = formatRowData(taskGroupByStageRecipe.taskGroup.byDate);

  const { stageRecipe } = taskGroupByStageRecipe;

  return (
    <Row rounded={!isExpanded} topRounded={isExpanded}>
      <FlexStyledSpan className="span-3">
        <ImageLabel
          image={varietyImage}
          title={stageRecipe.name}
          subTitle={formatStageRecipeDetails(stageRecipe)}
          alt={stageRecipe.name}
        />
      </FlexStyledSpan>
      <DataRow className="span-7">
        {weekDates.map(weekDate => (
          <LeftBorderdSpan key={weekDate}>
            <Span bold={isToday(weekDate)}>{rowData[weekDate]?.[selectedUnitFilter] ?? 0}</Span>
          </LeftBorderdSpan>
        ))}
      </DataRow>
      <ButtonContainer>
        <DsButton
          icon={isExpanded ? 'expand_less' : 'expand_more'}
          flat
          disabled={false}
          onClick={toggleExpand}
        />
      </ButtonContainer>
    </Row>
  );
};
