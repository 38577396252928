import { flatMap, uniqBy } from 'lodash-es';
import { ShrinkageReason, OperationalFarm, ShrinkageReport, Task } from '../../types';
import {
  DISABLED_NON_VARIETIES_CODES,
  DOUBLE_PACKED_SHRINKAGE_DESCRIPTION,
  DOUBLE_PACKED_SHRINKAGE_NAME,
  MISSING_PLANT_SHRINKAGE_NAME
} from './constants';

/**
 * Determine whether a given shrinkage report describes double-packed plants
 *
 * @param {ShrinkageReport} report The shrinkage report to analyse
 *
 * @returns {boolean} `true` if the given report describes double-packing,
 * `false` otherwise
 */

const isDoublePackedShrinkage = (report: ShrinkageReport): boolean =>
  report.reasons?.length === 1 &&
  report.reasons[0].name === DOUBLE_PACKED_SHRINKAGE_NAME &&
  report.description === DOUBLE_PACKED_SHRINKAGE_DESCRIPTION;

const isMissingPlantShrinkage = (report: ShrinkageReport): boolean =>
  report.reasons?.length === 1 && report.reasons[0].name === MISSING_PLANT_SHRINKAGE_NAME;
/**
 * Get number of double-packed plants from a list of shrinkage reports
 *
 * @param {ShrinkageReport[]} reports List of shrinkage reports to analyse
 *
 * @returns {number} The number of double-packed plants across the given
 * shrinkage reports
 */
const getDoublePackedCount = (reports: ShrinkageReport[]): number => {
  const doublePackedReport = reports.find(report => isDoublePackedShrinkage(report));

  return doublePackedReport?.quantity || 0;
};

const getMissingPlantsCount = (reports: ShrinkageReport[]): number => {
  const missingPlantReport = reports.find(report => isMissingPlantShrinkage(report));

  return missingPlantReport?.quantity || 0;
};

/**
 * Get unique selected shrinkage reasons across a list of shrinkage reports,
 * not accounting for the special case of double-packed plants
 *
 * @param {ShrinkageReport[]} reports List of shrinkage reports to analyse
 *
 * @returns {ShrinkageReason[]} All shrinkage reasons unrelated to double-
 * packing that have been set in the given reports
 */
const getSelectedReasons = (reports: ShrinkageReport[]): ShrinkageReason[] =>
  uniqBy(
    flatMap(reports, report =>
      isDoublePackedShrinkage(report) || isMissingPlantShrinkage(report) ? [] : report.reasons || []
    ),
    'uuid'
  );

/**
 * Reduce all shrinkage descriptions across a list of shrinkage reports into
 * one single description, not accounting for double-packed plants
 *
 * @param {ShrinkageReport[]} reports List of shrinkage reports to analyse
 *
 * @returns {string} A comma-separated string of all shrinkage descriptions
 * unrelated to double-packing that have been set in the given reports
 */
const getAllDescriptions = (reports: ShrinkageReport[]): string =>
  reports
    .map(report => (!isDoublePackedShrinkage(report) ? report.description : undefined))
    .filter(s => s)
    .join(', ');

const getColumnValues = (task: Task) => {
  const totalPlants = task.plantsOfStageRecipe || 0;
  const totalShrinkage =
    task.shrinkageReports?.reduce((sum, report) => sum + (report.quantity || 0), 0) || 0;

  const isNonVariety = DISABLED_NON_VARIETIES_CODES.includes(task?.stageRecipe?.internalCode || '');

  const missing = getMissingPlantsCount(task.shrinkageReports || []);
  const double = getDoublePackedCount(task.shrinkageReports || []);
  const shrinkage = totalShrinkage - double - missing;
  const single = totalPlants - totalShrinkage;

  const hpDivider = single + double + shrinkage;

  const hp = hpDivider === 0 || isNonVariety ? -1 : Math.round((single / hpDivider) * 100);

  const selectedReasons = getSelectedReasons(task.shrinkageReports || []);
  const description = getAllDescriptions(task.shrinkageReports || []);

  return {
    totalPlants,
    missing,
    double,
    shrinkage,
    single,
    hp,
    selectedReasons,
    description
  };
};

export const getTotalsRowValues = (farms: OperationalFarm[]) => {
  const values = {
    totalExpected: 0,
    totalSingle: 0,
    totalDouble: 0,
    totalMissing: 0,
    totalShrinkage: 0,
    batchesSubmitted: [] as string[],
    totalBatches: 0,
    totalVarieties: 0,
    totalNonVarieties: 0
  };

  farms?.forEach(farm => {
    values.totalBatches += farm.batches?.length || 0;
    farm?.batches?.forEach(batch => {
      const isEditEnabled = batch.tasks?.every(task => task.completed);

      if (isEditEnabled) {
        values.batchesSubmitted.push(batch.batchId || '');
      }

      batch?.tasks?.forEach(task => {
        const isNonVariety = DISABLED_NON_VARIETIES_CODES.includes(
          task?.stageRecipe?.internalCode || ''
        );

        const { totalPlants, missing, double, shrinkage, single } = getColumnValues(task);

        if (isNonVariety) {
          values.totalNonVarieties += 1;
          values.totalExpected += totalPlants;
        } else {
          values.totalVarieties += 1;
          values.totalExpected += totalPlants;
          values.totalDouble += double;
          values.totalMissing += missing;
          values.totalSingle += single;
          values.totalShrinkage += shrinkage;
        }
      });
    });
  });

  return values;
};
