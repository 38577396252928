import { isPast } from 'date-fns';
import { Link } from 'react-router-dom';
import { PlantingSchedule } from '../../../types';
import { formatFullDate, offsetDateWithTimezone } from '../../../utils/date-functions';
import {
  getFirstHarvestDate,
  getFirstSeedingDate,
  getGrowingCycleFromConfig
} from '../../../utils/planting-schedule';
import { FormState } from '../../PlantingSchedule/types';
import { Tr, Td, ScheduleName, CreationDate, Creator } from '../styles';
import { DotsDropdown } from '../../../components/DotsDropdown/index';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { getStatusDetails, STATUS_NAME } from '../constants';
import { Chip } from '../../../components/Chip';

interface TableRowProps extends PlantingSchedule {
  farmCycle: number;
  isWriteAllowed: boolean;
  isAcreFarm: boolean;
}

export const TableRow = (props: TableRowProps) => {
  const {
    name,
    firstPlantingDate,
    maxPropagationDays,
    createdAt,
    farmCycle,
    createdBy,
    uuid,
    summary,
    isWriteAllowed,
    configuration,
    isAcreFarm
  } = props;

  const seedingDate = formatFullDate(
    getFirstSeedingDate(firstPlantingDate, maxPropagationDays),
    false
  );
  const growingCycle = isAcreFarm ? getGrowingCycleFromConfig(configuration) : farmCycle;
  const harvestDate = formatFullDate(getFirstHarvestDate(firstPlantingDate, growingCycle), false);
  const plantingDate = offsetDateWithTimezone(firstPlantingDate);
  const isPlantingDateInPast = isPast(plantingDate);

  // convert createdAt with UTC timezone to user's locale timezone
  const createdTimeWithLocaleTimezone = new Date(createdAt);

  const viewScheduleLink = `${uuid}/${FormState.VIEW}`;
  const editScheduleLink = `${uuid}/${FormState.EDIT}`;
  const duplicateScheduleLink = `${uuid}/${FormState.DUPLICATE}`;

  const statusDetails = getStatusDetails(summary.status);
  const shouldDisableDate = statusDetails.text === STATUS_NAME.CANCELLED;

  const viewDropdownOption = {
    text: 'View',
    redirectLink: viewScheduleLink,
    icon: VisibilityIcon,
    disabled: statusDetails.diabledMode.includes(FormState.VIEW)
  };
  const editDropdownOption = {
    text: 'Edit',
    redirectLink: !isPlantingDateInPast ? editScheduleLink : '',
    icon: EditIcon,
    disabled: isPlantingDateInPast || statusDetails.diabledMode.includes(FormState.VIEW)
  };
  const duplicateDropdownOption = {
    text: 'Duplicate',
    redirectLink: duplicateScheduleLink,
    icon: FileCopyOutlinedIcon,
    disabled: statusDetails.diabledMode.includes(FormState.VIEW)
  };

  const dropdownOptions = isWriteAllowed
    ? [viewDropdownOption, editDropdownOption, duplicateDropdownOption]
    : [viewDropdownOption];

  const scheduleName = name
    ? isAcreFarm
      ? `${growingCycle / 7} weeks - ${name}`
      : name
    : 'Un-named Schedule';

  return (
    <Tr>
      <Td>
        <Link to={viewScheduleLink}>
          <ScheduleName>{scheduleName}</ScheduleName>
        </Link>
      </Td>
      <Td>
        <Chip label={statusDetails.text} styles={statusDetails.styles} />
      </Td>
      <Td disabled={shouldDisableDate}>{formatFullDate(firstPlantingDate, false)}</Td>
      <Td disabled={shouldDisableDate}>
        {summary.lastIncomingMovementDate &&
          formatFullDate(summary.lastIncomingMovementDate, false)}
      </Td>
      <Td disabled={shouldDisableDate}>{seedingDate}</Td>
      <Td disabled={shouldDisableDate}>{harvestDate}</Td>
      <Td>
        <CreationDate>{formatFullDate(createdTimeWithLocaleTimezone, false)} </CreationDate>
        <Creator> by {createdBy?.name || 'System User'}</Creator>
      </Td>
      <Td>
        <DotsDropdown options={dropdownOptions} />
      </Td>
    </Tr>
  );
};
