import { DsTextfield } from '@infarm/design-system-react';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Roboto;
  padding-top: 20px;
  word-break: break-word;
  table-layout: fixed;
`;

export const THead = styled.thead`
  tr {
    box-shadow: none;
  }

  padding-top: 20px;
`;

export const Tr = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
  }
`;

export const TrHeader = styled.tr`
  position: sticky;
  padding-top: 20px;
  top: 64px;
  padding-bottom: 20px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid transparent;
`;

export const Th = styled.th`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #616161;
  padding: 0 17px;
  min-width: 158px;
`;

export const StyledTh = styled(Th)<{ width?: string }>`
  padding-top: 30px;
  padding-bottom: 16px;
  margin-right: -10px;
  width: ${props => props.width};
`;

export const Td = styled.td<{ bold?: boolean; disabled?: boolean }>`
  font-size: 16px;
  font-style: normal;
  text-align: left;
  color: #25213b;
  padding: 20px 15px;
  vertical-align: top;
  font-weight: ${props => (props.bold ? 500 : 400)};
  ${props => props.disabled && `color: rgba(0,0,0,0.6); text-decoration: line-through;`}
`;

export const Row = styled.div`
  margin: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Error = styled.span`
  color: #e00028;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: 500;
`;

export const Search = styled(DsTextfield)`
  margin-top: 16px;
  width: 300px;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ScheduleName = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: #25213b;
  cursor: pointer;
  word-break: break-all;

  :hover {
    text-decoration: underline;
  }
`;

export const CreationDate = styled.div`
  color: #25213b;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
`;

export const Creator = styled.div`
  font-size: 12.5px;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.6);
`;

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Breadcrumb = styled.div`
  margin-bottom: 16px;
  color: #757575;
  display: flex;
  a {
    color: #757575;
  }
  span {
    margin: 0 6px;
  }
`;

export const SortSelect = styled(Select)`
  width: 215px;
  height: 56px;
`;

export const BoldP = styled.p`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 428px;
`;

export const Container = styled.div`
  margin-left: 40px;
`;

export const DatesSpacer = styled.span`
  width: 8;
  margin: 6px;
`;

export const ActionButton = styled.button<{ disabled?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: #007aff;
  font-size: 16px;
  ${props => props.disabled && `cursor: default; color: #007aff; opacity: 0.4;`}
`;

export const StatusTooltipWrapper = styled.span`
  margin-left: 15px;
  cursor: pointer;
  position: absolute;
`;

export const StatusTooltipBodyWrapper = styled.div`
  padding-right: 10px;
  background-color: white;
  color: black;
`;

export const GreyText = styled.span`
  color: #616161 !important;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25em;
  box-shadow: inset 0 0 0 1px #bdbdbd;
`;
