import gql from 'graphql-tag';

export const BATCH_SHRINKAGE = gql`
  query ShrinkageForLocation($uuid: ID!, $date: Date!, $batchType: TaskType!) {
    location(uuid: $uuid) {
      name
      uuid
      farms: operationalFarms(farmStateNot: NEW, models: [ACRE, INSTOREV2]) {
        uuid
        name
        shortId
        batches(date: $date, taskType: $batchType) {
          batchId
          tasks {
            uuid
            stageRecipe {
              name
              internalCode
              uuid
            }
            completed
            plantsOfStageRecipe
            shrinkageReports {
              quantity
              reasons {
                name
                uuid
              }
              description
            }
          }
        }
      }
    }
    shrinkageReasons {
      name
      uuid
    }
  }
`;
