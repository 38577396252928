import { FC } from 'react';
import { DsButton } from '@infarm/design-system-react';
import {
  DateRow,
  Month,
  DateControls,
  Week,
  ToggleWrapper,
  Toggle,
  ToggleButton,
  ControlsRow,
  ViewByWrapper,
  Options,
  StyledSelect
} from '../style';
import { UnitFilter } from '../constants';
import { NurseryMode } from '../../constants';
import { formatMonth, weekNumber } from '../../../utils/date-functions';

interface NurseryControlsProps {
  date: Date;
  selectedNurseryModes: NurseryMode[];
  onClickToday: () => void;
  onClickWeekBefore: () => void;
  onClickWeekAfter: () => void;
  setSelectedNurseryModes: (modes: NurseryMode[]) => void;
  showViewBy?: boolean;
}

export const NurseryControls: FC<NurseryControlsProps> = ({
  date,
  selectedNurseryModes,
  onClickToday,
  onClickWeekAfter,
  onClickWeekBefore,
  setSelectedNurseryModes,
  showViewBy
}) => (
  <ControlsRow>
    <DateRow>
      <DateControls>
        <DsButton label="Today" onClick={onClickToday} data-cy="NurserySystems___Today" />
        <DsButton
          icon="navigate_before"
          flat
          onClick={onClickWeekBefore}
          data-cy="NurserySystems___WeekBefore"
        />
        <DsButton
          icon="navigate_next"
          flat
          onClick={onClickWeekAfter}
          data-cy="NurserySystems___WeekAfter"
        />
        <Month>{formatMonth(date)}</Month>
        <Week>Week {weekNumber(date)}</Week>
      </DateControls>
    </DateRow>
    <ToggleWrapper>
      <Toggle>
        <ToggleButton
          data-cy="NurserySystems___All"
          primary={
            selectedNurseryModes.includes(NurseryMode.LIGHT) &&
            selectedNurseryModes.includes(NurseryMode.DARK)
          }
          onClick={() => setSelectedNurseryModes([NurseryMode.LIGHT, NurseryMode.DARK])}
        >
          All
        </ToggleButton>
        <ToggleButton
          data-cy="NurserySystems___LG"
          primary={
            selectedNurseryModes.includes(NurseryMode.LIGHT) &&
            !selectedNurseryModes.includes(NurseryMode.DARK)
          }
          onClick={() => setSelectedNurseryModes([NurseryMode.LIGHT])}
        >
          Light Germination
        </ToggleButton>
        <ToggleButton
          data-cy="NurserySystems___DG"
          primary={
            selectedNurseryModes.includes(NurseryMode.DARK) &&
            !selectedNurseryModes.includes(NurseryMode.LIGHT)
          }
          onClick={() => setSelectedNurseryModes([NurseryMode.DARK])}
        >
          Dark Germination
        </ToggleButton>
      </Toggle>
    </ToggleWrapper>
    {showViewBy && (
      <ViewByWrapper>
        <p>View by</p>
        <StyledSelect
          value={UnitFilter.TRAYS}
          variant="outlined"
          margin="dense"
          autoWidth={false}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
          disabled
        >
          <Options value={UnitFilter.TRAYS}>
            <strong> {UnitFilter.TRAYS} </strong>
          </Options>
        </StyledSelect>
      </ViewByWrapper>
    )}
  </ControlsRow>
);
