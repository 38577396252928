import { WeekDays, FarmModel } from '../../types';
import { FormProps } from './types';

/* This weekdays values are used internally and should NEVER be DIRECTLY USED to display to the user.
 * Instead `getLocaleWeekDays` function from the date-functions should be used to display weekdays in human readable format.
 * This is important when language is different than english.
 * */
export const getWeekDays = () => Object.values(WeekDays);

export const selectFarm = {
  [FarmModel.ACRE]: {
    title: 'Choose an Acre',
    subTitle: 'Choose one Acre to create the planting schedule for it.'
  },
  [FarmModel.INSTOREV2]: {
    title: 'Available farms',
    subTitle: 'You can create a planting schedule for these farms'
  }
};

export const farmListEmpty = {
  [FarmModel.ACRE]: {
    message: 'No Farm has yet been configured for this Hub. Start by adding Farms to the Hub.'
  },
  [FarmModel.INSTOREV2]: {
    message:
      'No Farm has yet been configured for this location. Start by adding Farms to the location.'
  }
};

export const modalContent = {
  SUCCESS: {
    heading: 'Planting Schedule Applied!',
    primaryLabel: 'Go to Schedules',
    secondaryLabel: undefined,
    subHeading: undefined,
    text: undefined
  },
  ERROR: {
    heading: 'Error!',
    text: 'Something went wrong that we can’t apply this planting schedule, please try again.',
    primaryLabel: 'Try again',
    secondaryLabel: 'Discard',
    subHeading: undefined
  },
  BUSY: {
    heading: 'Please try again later.',
    text: 'The previous input is still being processed. It should not take longer than a few minutes.',
    primaryLabel: 'Try again',
    secondaryLabel: 'Discard',
    subHeading: undefined
  },
  MAX_TRAYS: {
    heading: 'Maximum 2 Operational Days',
    text: 'You have scheduled more than 2 operations days per week. Please change the schedule and try again.',
    primaryLabel: 'Try again',
    secondaryLabel: 'Discard',
    subHeading: undefined
  },
  DISCARD: {
    heading: 'Are you sure you want to discard this planting schedule?',
    text: undefined,
    primaryLabel: 'Yes',
    secondaryLabel: 'No',
    subHeading: undefined
  },
  DEFICIT: {
    heading: 'Capacity Warning',
    text: 'It seems that on certain days there are not enough free benches to support the scheduled planting. This will cause some benches NOT to be scheduled during the first week of planting.',
    primaryLabel: 'Continue anyway',
    secondaryLabel: 'Cancel',
    subHeading: undefined
  },
  SURPLUS: {
    heading: 'Capacity Warning',
    text: 'It seems the proposed schedule leaves some benches temporarily empty. This will impact the growth cycle of other benches already in the farm and may affect plant quality.',
    primaryLabel: 'Continue anyway',
    secondaryLabel: 'Cancel',
    subHeading: undefined
  },
  CONTINUE: {
    heading: 'Planting schedule applied!',
    subHeading: ' Continue with next Acre?',
    primaryLabel: 'Yes, continue',
    secondaryLabel: 'No, go to schedule list',
    text: undefined
  },
  GENERATE: {
    heading: 'Planting schedule applied!',
    subHeading: ' Continue with next Acre?',
    primaryLabel: 'Yes, continue',
    secondaryLabel: 'I’m done, generate nursery demand ',
    text: undefined
  },
  GENERATE_ERROR: {
    heading: 'Error!',
    text: 'Something went wrong that we can’t generate the nursert demand, please try again.',
    primaryLabel: 'Try again',
    secondaryLabel: 'Go to schedule list',
    subHeading: undefined
  },
  MIXED_PLANT_GROUPS: {
    heading: 'Unable to process schedule',
    text: 'This schedule requires different plant groups to be mixed in one bench which can not be done. Please adjust the input and submit again.',
    primaryLabel: 'Try again',
    secondaryLabel: 'Discard',
    subHeading: undefined
  }
};

export const pasteModalContent = {
  REPLACE: {
    heading: 'A planting schedule already exists, do you want to replace it?',
    text: 'A planting schedule with its content already exists in the table. Replacing it will overwrite its current contents.',
    primaryLabel: 'Replace',
    secondaryLabel: 'Cancel'
  },
  COLUMNS_LENGTH: {
    heading: (rows: number, columns: number): string => {
      return `You tried to paste in ${rows} rows and ${columns} columns`;
    },
    text: 'The planting schedule requires 8 columns, please check if you have copied the correct columns.',
    primaryLabel: 'Try again',
    secondaryLabel: undefined
  },
  NON_NUMERIC: {
    heading: 'You tried to paste text into number fields',
    text: 'The planting schedule quantity fields can only contain numeric values, please check if you have copied the correct data.',
    primaryLabel: 'Try again',
    secondaryLabel: undefined
  },
  INVALID: {
    heading: 'You tried to paste in 1 row and 1 column',
    text: 'The planting schedule requires 8 columns, please check if you have copied the correct columns.',
    primaryLabel: 'Try again',
    secondaryLabel: undefined
  }
};

export const configUpdate = {
  ROW: {
    ADD: 'row.add',
    DELETE: 'row.delete'
  },
  CHANGE: {
    VARIETY: 'change.variety',
    TRAY_TYPE: 'change.tray_type'
  }
};

export const DEFAULT_FORM_VALUES: FormProps = {
  scheduleName: '',
  selectedFarm: {
    uuid: 'uuid',
    name: 'name of farm',
    serialNumber: 'serial number',
    farmCycle: 14,
    model: FarmModel.ACRE,
    assignedUsers: null
  },
  firstPlantingDate: undefined,
  firstSeedingDate: undefined,
  growingCycle: 14,
  configuration: [
    {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      trayType: {
        name: '',
        uuid: '',
        surfaceUnits: 0,
        plantsPerTray: 0
      },
      stageRecipeUuid: ''
    }
  ],
  hasDeficit: false,
  hasSurplus: false
};

export const warningContent = {
  DATE: {
    icon: 'warning',
    iconColor: '#FFC647',
    title: 'First planting date not feasible:',
    text: 'Unless already seeded, the seedlings will not be ready by the desired date. You can',
    scrollText: ' change the first planting date',
    textEnd: 'to avoid the issue.'
  }
};

export enum WarningTypes {
  DATE = 'DATE'
}

export const benchCapacityTooltipsTexts = {
  surplus: {
    heading: 'Unutilised capacity',
    text: 'It seems the proposed schedule leaves some benches temporarily empty. You can change the number of benches per day to avoid the issue.'
  },
  deficit: {
    heading: 'Capacity clashes',
    text: 'It seems that on certain days there are not enough free benches to support the scheduled planting. You can change the first planting day, OR the number of benches per day to avoid the conflict.'
  },
  info: 'Daily bench surplus/deficit indicator shows you the available benches in the Acre for each day'
};

export const styleValues = {
  warning: {
    icon: 'warning',
    color: '#FA6400',
    subColor: '#FFDA87'
  },
  error: {
    icon: 'error',
    color: '#FF3B30',
    subColor: '#FFA594'
  }
};
export const partialBenchErrorMessage = (
  <p>
    <strong>Unutilised capacity</strong>
    <br />
    If needed, you can add extra production volume to this order.
  </p>
);

export const underProvisionedMessage = partialBenchErrorMessage;

export const overProvisionedMessage =
  'The Farm is over-provisioned, please adjust the numbers to meet 100% capacity.';
