import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { Location, PlantingSchedule } from '../types';
import { withAuth } from '@infarm/auth';

/**
 * To merge the exsiting location list with the coming location list
 */
const cache = new InMemoryCache({
  typePolicies: {
    OperationalFarm: {
      keyFields: ['uuid']
    },
    Location: {
      keyFields: ['uuid']
    },
    Query: {
      fields: {
        locations: {
          keyArgs: ['filter'],
          merge(existing, incoming, { args }) {
            let edges: Location[] = [];
            let error;
            if (!existing || !args?.cursor) return incoming;
            if (existing.pageInfo?.endCursor === args.cursor)
              edges = [...existing.edges, ...incoming.edges];
            else error = 'Something went wrong. Please reload the Page';

            return {
              ...incoming,
              edges,
              error
            };
          }
        },
        location: {
          merge: false
        },
        plantingSchedules: {
          keyArgs: ['filter'],
          merge(existing, incoming, { args }) {
            let edges: PlantingSchedule[] = [];
            let error;
            if (!existing || !args?.cursor) return incoming;
            if (existing.pageInfo?.endCursor === args.cursor)
              edges = [...existing.edges, ...incoming.edges];
            else error = 'Something went wrong. Please reload the Page';

            return {
              ...incoming,
              edges,
              error
            };
          }
        },
        plantingSchedule: {
          merge: false
        }
      }
    }
  }
});

const apolloClient = new ApolloClient({
  cache,
  link: new HttpLink({
    uri: (globalThis as any).env.FAAS_MIDDLEWARE,
    fetch: withAuth(fetch)
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
});

export default apolloClient;
