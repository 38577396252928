import styled from 'styled-components';

export const ChartContainer = styled.div`
  width: inherit;
  height: 229px;
`;

export const LegendLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const LegendIcon = styled.span<{
  color?: string;
}>`
  height: 15px;
  width: 15px;
  background-color: ${({ color }) => color || ''};
  border-radius: 50%;
  opacity: 0.25;
  margin-right: 8px;
`;

export const LegendContainer = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: 40px;
`;

export const LegendText = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const LegendDashedLine = styled.span`
  border-top: 1.5px dashed #ff3b30;
  color: #ff3b30;
  width: 15px;
  margin-right: 8px;
`;

export const Tooltip = styled.div`
  width: 146px;
  min-height: 83px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(16, 50, 42, 0.2), 0px 1px 2px rgba(16, 50, 42, 0.15);
  border-radius: 4px;
  padding: 12px;
`;

export const TooltipHead = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 7px;
`;

export const TooltipSubText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
`;

export const Seperator = styled.div`
  margin: 12px 0;
  border-bottom: 2px solid #e0e0e0;
`;

export const TooltipValue = styled.p`
  font-size: 12px;
  line-height: 12px;
`;

export const GerminationWrapper = styled.div``;
