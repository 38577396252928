import { ModalButtonsRow } from '../styles';
import { DsButton, DsModal } from '@infarm/design-system-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getConfirmModalTextFromConfig } from '../constants';
import { ConfirmModal } from '../types';
import { ModalBody } from './ModalBody';
import SubmitButton from '../../../components/SubmitButton';

interface ConfirmModalProps {
  onClose: () => void;
  open: boolean;
  locationName: string;
  farmsExist: boolean;
  errorMessage?: string | null;
  loading?: boolean;
  isCancelModal: boolean;
}

export const ConfirmLocationModal = (props: ConfirmModalProps): JSX.Element => {
  const navigate = useNavigate();
  const { locationUuid } = useParams();
  const { onClose, errorMessage, loading, isCancelModal } = props;
  const isEdit = !!locationUuid;

  const { modalHeading, modalBody, cancelLabel, confirmLabel }: ConfirmModal =
    getConfirmModalTextFromConfig({
      ...props,
      isEdit
    });

  return (
    <DsModal onClosed={onClose} open={props.open} heading={modalHeading} hideCloseButton>
      <>
        <ModalBody modalBody={modalBody} isError={!!errorMessage} />
        <ModalButtonsRow>
          <DsButton secondary onClick={onClose} label={cancelLabel} />
          {!errorMessage && !isCancelModal && (
            <SubmitButton type="submit" loading={loading} label={confirmLabel} />
          )}
          {!errorMessage && isCancelModal && (
            <SubmitButton
              type="button"
              label={confirmLabel}
              onClick={() => navigate(`/locations/${locationUuid || ''}`)}
            />
          )}
        </ModalButtonsRow>
      </>
    </DsModal>
  );
};
