import { DsRow } from '@infarm/design-system-react';
import styled from 'styled-components';

export const PageHeaderRow = styled(DsRow)`
  padding-bottom: 16px;
`;

export const HeaderDate = styled.p<{ today?: boolean }>`
  font-size: 24px;
  font-weight: 400;
  color: #767676;
  ${({ today }) => (today ? 'color: #e00028 !important;' : '')}
`;

export const Section = styled.section`
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 20px;
`;

export const TableCaptionRow = styled(DsRow)`
  margin-inline-end: 17px;
  h4 {
    line-height: 38px;
    grid-column: span 12;
  }
`;
