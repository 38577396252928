import { MenuItem, Select } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { StageRecipe } from '../../../types';
import { HubDemandConfig } from '../types';

interface VarietySelectProps {
  onNewVarietyUpdate: (stageRecipe: StageRecipe) => void;
  newConfig: Partial<HubDemandConfig>;
  availableVarieties: StageRecipe[];
}
export const VarietySelect = (props: VarietySelectProps) => {
  const { onNewVarietyUpdate, newConfig, availableVarieties } = props;
  const { getValues } = useFormContext();
  const { startDate } = getValues();
  return (
    <Select
      required
      variant="outlined"
      fullWidth
      disabled={!startDate}
      onChange={({ target }) => {
        const selectedVariety = availableVarieties.find(
          stageRecipe => stageRecipe.uuid === target.value
        );
        onNewVarietyUpdate(selectedVariety!);
      }}
      value={newConfig.stageRecipe?.uuid || 'placeholder'}
    >
      <MenuItem value="placeholder" disabled>
        Select Variety
      </MenuItem>
      {availableVarieties.map(stageRecipe => (
        <MenuItem value={stageRecipe.uuid} key={stageRecipe.uuid}>
          {`${stageRecipe.internalCode ? stageRecipe.internalCode + '_' : ''}${stageRecipe.name}`}
        </MenuItem>
      ))}
    </Select>
  );
};
