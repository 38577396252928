import { OperationalFarm, PlantingScheduleWeeklyConfiguration } from '../../types';
import { DateLike } from '../../utils/date-functions';

export interface FormProps {
  selectedFarm: OperationalFarm;
  scheduleName: string;
  firstPlantingDate: DateLike | undefined;
  firstSeedingDate: Date | undefined;
  configuration: PlantingScheduleWeeklyConfiguration[];
  comment?: string;
  hasDeficit: boolean;
  hasSurplus: boolean;
  growingCycle: number;
}

export enum ModalTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  BUSY = 'BUSY',
  MAX_TRAYS = 'MAX_TRAYS',
  DISCARD = 'DISCARD',
  DEFICIT = 'DEFICIT',
  SURPLUS = 'SURPLUS',
  CONTINUE = 'CONTINUE',
  GENERATE = 'GENERATE',
  GENERATE_ERROR = 'GENERATE_ERROR',
  MIXED_PLANT_GROUPS = 'MIXED_PLANT_GROUPS'
}

export enum PasteModalTypes {
  REPLACE = 'REPLACE',
  COLUMNS_LENGTH = 'COLUMNS_LENGTH',
  NON_NUMERIC = 'NON_NUMERIC',
  INVALID = 'INVALID'
}

export enum FormState {
  EDIT = 'edit',
  VIEW = 'view',
  DUPLICATE = 'duplicate'
}

export enum XArrowHeads {
  LEFT_ARROW_START = 'LEFT_ARROW_START',
  LEFT_ARROW_END = 'LEFT_ARROW_END',
  RIGHT_ARROW_END = 'RIGHT_ARROW_END',
  RIGHT_ARROW_START = 'RIGHT_ARROW_START',
  DASH_ARROW_START = 'DASH_ARROW_START',
  DASH_ARROW_END = 'DASH_ARROW_END'
}
