import { IconTooltip } from '../../../components/IconTooltip';
import { STATUS_TOOLTIP_HEADING, STATUSES_ARRAY } from '../constants';
import { StatusTooltipBodyWrapper, StatusTooltipWrapper, GreyText } from '../styles';

export const StatusTooltipBody = () => {
  return (
    <StatusTooltipBodyWrapper>
      <ul>
        {STATUSES_ARRAY.map(status => {
          return (
            <li key={status.explanation}>
              {status.name}: <GreyText>{status.explanation}</GreyText>
            </li>
          );
        })}
      </ul>
    </StatusTooltipBodyWrapper>
  );
};

export const StatusTooltip = () => {
  const styles = {
    border: '1px solid #E6E8ED',
    borderRadius: '0.4em',
    maxWidth: 800,
    fontWeight: 'normal',
    backgroundColor: 'white',
    headingColor: 'black'
  };

  return (
    <StatusTooltipWrapper>
      <IconTooltip body={<StatusTooltipBody />} heading={STATUS_TOOLTIP_HEADING} styles={styles} />
    </StatusTooltipWrapper>
  );
};
