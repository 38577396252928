import { useParams } from 'react-router-dom';
import { getWeekDays, pasteModalContent } from '../constants';
import {
  FarmModel,
  PlantingScheduleWeeklyConfiguration,
  StageRecipe,
  WeekDayFormat
} from '../../../types';
import { getLocaleWeekDays, getLocaleWeekDay } from '../../../utils/date-functions';
import {
  InputTable,
  Section,
  SectionSubTitle,
  SectionTitle,
  SectionTitleWrapper,
  SectionNote,
  Row,
  HeaderCell,
  Overlay,
  TableWrapper,
  OverlayText,
  PasteButton,
  PasteIcon,
  Container,
  DayIndicatorWrapper,
  PlantingDateIndicator,
  PlatingDateText,
  PlatingDateWrapper
} from '../style';
import { ActionRow, TableTotals, VarietyTrayRow, Modal } from '.';
import { UseControllerProps, useFieldArray, useFormContext } from 'react-hook-form';
import { FormProps, FormState, PasteModalTypes } from '../types';
import { parseClipboardData } from '../../../utils';
import { useState, FocusEvent } from 'react';
import { Snackbar } from '@material-ui/core';
import { DateIndicatorTableHeader } from './DateIndicatorTableHeader/index';
import DayIndicator from '../../../../src/assets/DayIndicator.svg';
import { getPlantingDaysWeek } from '../../../utils/planting-schedule';

interface FarmConfigTableProps extends UseControllerProps<FormProps> {
  availableVarieties: StageRecipe[];
}

export const FarmConfigTable = (props: FarmConfigTableProps) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<PasteModalTypes>(PasteModalTypes.REPLACE);
  const [copiedRows, setCopiedRows] = useState<string[][]>([]);
  const weekDays = getWeekDays();
  const { type } = useParams();
  const localeWeekDays: string[] = getLocaleWeekDays();
  const { availableVarieties, control } = props;
  const { getValues } = useFormContext<FormProps>();
  const { selectedFarm, firstPlantingDate } = getValues();
  const {
    remove,
    fields: configuration,
    append,
    update,
    replace
  } = useFieldArray({
    control,
    name: 'configuration',
    keyName: 'key'
  });

  const isAcreFarm = selectedFarm!.model === FarmModel.ACRE;
  const farmType = isAcreFarm ? 'Acre' : 'Farm';

  const onPasteFromClipboard = (event: React.ClipboardEvent) => {
    if (!isAcreFarm || type === FormState.VIEW) return;

    const copiedData = parseClipboardData(event.clipboardData.getData('text'));
    setCopiedRows(copiedData);

    const hasAnyNaN = copiedData.some(data => {
      //ignore the first element
      const [, ...trays] = data;
      return trays.some(tray => isNaN(+tray));
    });

    if (hasAnyNaN) {
      setModalType(PasteModalTypes.NON_NUMERIC);
      setIsModalOpen(true);
      return;
    }

    const hasInvalidColumnsLength = copiedData.some(data => {
      const requiredColumnsLength = 8;
      return data.length !== requiredColumnsLength;
    });

    if (hasInvalidColumnsLength) {
      setModalType(PasteModalTypes.COLUMNS_LENGTH);
      setIsModalOpen(true);
      return;
    }

    const hasAlreadyValue =
      configuration.length > 1 ||
      configuration[0].stageRecipeUuid ||
      weekDays.some(day => {
        return configuration[0][day] !== 0;
      });

    if (hasAlreadyValue) {
      setModalType(PasteModalTypes.REPLACE);
      setIsModalOpen(true);
      return;
    }

    pasteDataToTable(copiedData);
  };

  const pasteDataToTable = (copiedData: string[][]) => {
    const newConfigs: PlantingScheduleWeeklyConfiguration[] = [];
    copiedData.forEach((row: string[]) => {
      const [variety, ...trays] = row;

      const foundVariety = availableVarieties.find(({ internalCode, name }) => {
        return internalCode?.concat(' ', name) === variety;
      });

      const trayType = foundVariety?.trayType;
      const stageRecipeUuid = foundVariety?.uuid;
      const stageRecipeDensity = foundVariety?.density;
      const copiedDailyTrays = trays.slice(0, 7);

      const newConfig = weekDays.reduce(
        (previous, day, index) => ({
          ...previous,
          [day]: Number(copiedDailyTrays[index])
        }),
        {
          trayType,
          stageRecipeUuid,
          stageRecipeDensity
        }
      ) as PlantingScheduleWeeklyConfiguration;
      newConfigs.push(newConfig);
    });
    replace(newConfigs);
    setShowOverlay(false);
    setShowSnackbar(true);
  };

  const blurEvent = (e: FocusEvent<HTMLInputElement>) => {
    const targetId = e.relatedTarget?.id as string;

    if (showOverlay && targetId !== 'configTable') {
      setShowOverlay(false);
    }
  };

  const onModalClosed = () => {
    setIsModalOpen(false);
  };

  const modalPrimaryAction = () => {
    if (modalType === PasteModalTypes.REPLACE) {
      pasteDataToTable(copiedRows);
    }
    onModalClosed();
  };

  const modalHeading =
    modalType === PasteModalTypes.COLUMNS_LENGTH
      ? pasteModalContent[PasteModalTypes.COLUMNS_LENGTH].heading(
          copiedRows.length,
          copiedRows[0]?.length
        )
      : pasteModalContent[modalType].heading;

  const firstPlantingWeekDay = !firstPlantingDate
    ? null
    : getLocaleWeekDay(firstPlantingDate, WeekDayFormat.SHORT);

  const plantingWeekDays = getPlantingDaysWeek(firstPlantingDate);
  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        onModalClosed={onModalClosed}
        modalText={pasteModalContent[modalType].text || ''}
        heading={modalHeading}
        modalSecondaryAction={onModalClosed}
        modalPrimaryAction={modalPrimaryAction}
        primaryLabel={pasteModalContent[modalType].primaryLabel}
        secondaryLabel={pasteModalContent[modalType].secondaryLabel}
      />
      <Section>
        <SectionTitleWrapper>
          <SectionTitle>Schedule Detail*</SectionTitle>
          <SectionSubTitle>
            {`Please input how many trays you want to harvest per variety at each operation day. The maximum amount of trays is defined by the ${farmType} type.`}
          </SectionSubTitle>
          {!isAcreFarm && (
            <SectionNote>
              Note: Up to 2 operations days per week are allowed with equal tray distribution. (e.g.
              8 trays for each 2 days or 16 trays for 1 day).
            </SectionNote>
          )}
        </SectionTitleWrapper>
        <Container onPaste={onPasteFromClipboard} tabIndex={0} onBlur={blurEvent}>
          {isAcreFarm && type !== FormState.VIEW && (
            <PasteButton
              onClick={() => {
                setShowOverlay(true);
              }}
            >
              <PasteIcon>content_paste</PasteIcon>
              Paste table data
            </PasteButton>
          )}
          <TableWrapper>
            <InputTable id="configTable" data-cy="configTable">
              <thead>
                {process.env.JEST_WORKER_ID === undefined && (
                  <DateIndicatorTableHeader dashness={true} firstPlantingDate={firstPlantingDate} /> // XArrow library fails with jest: https://github.com/Eliav2/react-xarrows/issues/110
                )}
                <Row>
                  <HeaderCell className="span-2 bold" colSpan={2}>
                    Variety
                  </HeaderCell>
                  {localeWeekDays.map(weekDay => {
                    if (weekDay === firstPlantingWeekDay) {
                      return (
                        <HeaderCell className="span-1" key={weekDay}>
                          <DayIndicatorWrapper>
                            <img src={DayIndicator} alt="DayIndicator" />
                          </DayIndicatorWrapper>

                          <PlantingDateIndicator>
                            <PlatingDateWrapper>
                              <div> {weekDay} </div>
                              <PlatingDateText>{plantingWeekDays?.[weekDay]}</PlatingDateText>
                            </PlatingDateWrapper>
                          </PlantingDateIndicator>
                        </HeaderCell>
                      );
                    }
                    return (
                      <HeaderCell className="span-1" key={weekDay}>
                        <PlantingDateIndicator>
                          <PlatingDateWrapper>
                            <div> {weekDay} </div>
                            <PlatingDateText>{plantingWeekDays?.[weekDay]}</PlatingDateText>
                          </PlatingDateWrapper>
                        </PlantingDateIndicator>
                      </HeaderCell>
                    );
                  })}
                </Row>
              </thead>
              <tbody>
                <VarietyTrayRow
                  configuration={configuration}
                  update={update}
                  remove={remove}
                  availableVarieties={availableVarieties}
                />
                {type !== FormState.VIEW && <ActionRow append={append} />}
                <TableTotals />
              </tbody>
            </InputTable>
            {showOverlay && (
              <Overlay>
                <OverlayText>You can press Ctrl + V to paste your data</OverlayText>
              </Overlay>
            )}
          </TableWrapper>
        </Container>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
          message={'Data successfully pasted from clipboard '}
        />
      </Section>
    </>
  );
};
