import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import '@infarm/design-system/dist/scss/global.scss';
import '@infarm/design-system/dist/scss/typography.scss';
import { App } from './App';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'in-fsd-inhub-issues': any;
      'track-visualization': any;
      'performance-report': any;
      'inhub-workstations-tab': any;
      'inhub-kanban-board': any;
      'inhub-batch-label-printer': any;
      'inhub-shrinkage-table': any;
      'inhub-shrinkage': any;
    }
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err, info, props) {
    console.log(err, info, props);
    return <div>Error</div>;
  }
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
