import { SectionTitle, Section } from '../style';
import { Divider } from '@material-ui/core';
import { NurseryInAcreTasksForDate } from '../types';
import { FarmContainer } from './FarmContainer';
import { SummaryWrapper } from './style';

export const OperationSummary = ({ sortedFarms }: { sortedFarms: NurseryInAcreTasksForDate[] }) => {
  return (
    <SummaryWrapper>
      <Section>
        <SectionTitle>Operation Summary</SectionTitle>
        <Divider />
      </Section>
      {sortedFarms.map(({ operationalFarm, taskGroup }) => {
        return (
          <div key={operationalFarm.uuid}>
            <FarmContainer operationalFarm={operationalFarm} taskGroup={taskGroup} />
            <Divider />
          </div>
        );
      })}
    </SummaryWrapper>
  );
};
