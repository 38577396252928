import {
  SliderCounter,
  SlashCounter,
  SliderPhotosText,
  SliderPhotoDetails,
  SliderPhotoNumber,
  SliderPhotoDateUpload,
  SliderPhotoUploadedBy,
  SliderPhotoHourUpload,
  SliderPhotoUploadedText,
  CarouselWrapper,
  CarouselContainer,
  LeftSlide,
  RightSlide,
  SlideImage
} from '../style';
import Slider, { LazyLoadTypes } from 'react-slick';
import { formatFullDate } from '../../../utils/date-functions';
import { PhotosTableRow } from '../../../types';

interface PhotoCarouselProps {
  sliderPhotos: PhotosTableRow | undefined;
  sliderCurrentPhotoIndex: number;
  setSliderCurrentPhotoIndex: (photoIndex: number) => void;
  updateRef: (updatedRef: any) => void;
  swipeRight: () => void;
  swipeLeft: () => void;
}

export const PhotoCarousel = (props: PhotoCarouselProps) => {
  const {
    sliderPhotos,
    setSliderCurrentPhotoIndex,
    updateRef,
    sliderCurrentPhotoIndex,
    swipeRight,
    swipeLeft
  } = props;

  const settings = {
    lazyLoad: 'progressive' as LazyLoadTypes,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: sliderCurrentPhotoIndex,
    beforeChange: (active: number, next: any) => {
      setSliderCurrentPhotoIndex(next);
    }
  };

  const activePhoto = sliderPhotos?.photosDetails[sliderCurrentPhotoIndex];

  const sliderPhotosCount = sliderPhotos?.photosDetails.length;
  const sliderPhotoHourUpload =
    activePhoto &&
    new Date(activePhoto.createdAt).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  return (
    <CarouselWrapper>
      <CarouselContainer>
        <Slider {...settings} ref={c => updateRef(c)}>
          {sliderPhotos?.photosDetails.map((elelemt, index) => {
            return (
              <SlideImage
                src={elelemt.photoUrl}
                alt={`Row ${index}`}
                key={sliderPhotos.uuid + elelemt.photoUrl + index}
              />
            );
          })}
        </Slider>
      </CarouselContainer>
      <SliderCounter>
        {sliderPhotosCount && sliderPhotosCount > 1 && (
          <>
            <LeftSlide onClick={swipeLeft}> {'<'} </LeftSlide>
            <RightSlide onClick={swipeRight}> {'>'} </RightSlide>
          </>
        )}

        <SlashCounter>
          {sliderCurrentPhotoIndex + 1}/{sliderPhotosCount}
        </SlashCounter>
        <SliderPhotosText>photos</SliderPhotosText>
      </SliderCounter>
      <SliderPhotoDetails>
        <SliderPhotoNumber> Photo {sliderCurrentPhotoIndex + 1} </SliderPhotoNumber>
        <SliderPhotoUploadedText> Uploaded on </SliderPhotoUploadedText>
        <SliderPhotoDateUpload>
          {activePhoto && formatFullDate(new Date(activePhoto?.createdAt), false)}
        </SliderPhotoDateUpload>
        <SliderPhotoHourUpload>{sliderPhotoHourUpload}</SliderPhotoHourUpload>
        <SliderPhotoUploadedBy> by {sliderPhotos?.uploadedBy?.name}</SliderPhotoUploadedBy>
      </SliderPhotoDetails>
    </CarouselWrapper>
  );
};
