import { StageRecipe } from '../../../types';
import { VarietySelect } from '.';
import { ActionIcon, Row, Cell } from '../style';
import { getWeekDays } from '../constants';
import { FieldArrayWithId, UseFieldArrayUpdate } from 'react-hook-form';
import { FormProps, FormState } from '../types';
import { useParams } from 'react-router-dom';
import { FindByValues } from '../__tests/mock';
import { NumberInput } from '../../../components/Textfield/NumberInput';

interface VarietyTrayRowProps {
  availableVarieties: StageRecipe[];
  configuration: FieldArrayWithId<FormProps, 'configuration', 'key'>[];
  update: UseFieldArrayUpdate<FormProps, 'configuration'>;
  remove: (value: number) => void;
}
export const VarietyTrayRow = (props: VarietyTrayRowProps) => {
  const { availableVarieties, remove, update, configuration } = props;
  const weekDays = getWeekDays();
  const { type } = useParams();
  const isViewMode = type === FormState.VIEW;

  return (
    <>
      {configuration.map((config, productIndex: number) => {
        const varietyOptions = availableVarieties.filter(variety => {
          const isVarietyFound = configuration.find(
            config => config.stageRecipeUuid === variety.uuid
          );
          if (isVarietyFound && variety.uuid === config.stageRecipeUuid) return true;
          return !isVarietyFound;
        });

        const selectedVariety = varietyOptions.find(
          variety => variety.uuid === config.stageRecipeUuid
        );
        const isTrayChangeDisabled = !selectedVariety;
        const isVarietyInvalid =
          config.stageRecipeUuid === undefined ||
          (!isViewMode && config.stageRecipeUuid && !selectedVariety);
        return (
          <Row key={config.key} className={isVarietyInvalid ? 'empty' : ''}>
            {!isViewMode && (
              <Cell className="closeIcon">
                {configuration.length > 1 && (
                  <ActionIcon
                    role={FindByValues.REMOVE_ROW}
                    onClick={() => {
                      remove(productIndex);
                    }}
                  >
                    close
                  </ActionIcon>
                )}
              </Cell>
            )}
            <Cell colSpan={type === FormState.VIEW ? 2 : 1}>
              <VarietySelect
                varietyOptions={varietyOptions}
                update={update}
                configuration={configuration}
                productIndex={productIndex}
                availableVarieties={availableVarieties}
                name={`configuration.${productIndex}.stageRecipeUuid`}
              />
            </Cell>
            {weekDays.map(day => (
              <Cell className="span-1" key={day}>
                <NumberInput
                  name={`configuration.${productIndex}.${day}`}
                  disabled={isTrayChangeDisabled || type === FormState.VIEW}
                />
              </Cell>
            ))}
          </Row>
        );
      })}
    </>
  );
};
