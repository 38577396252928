import { Section } from '../../PlantingSchedule/style';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DsPlaceholder } from '@infarm/design-system-react';
import { toIsoDate, getMonday, generateDaysOfWeekISO } from '../../../utils/date-functions';
import { TaskType, FarmState, OperationalFarm } from '../../../types';
import {
  defaultUnitFilterValue,
  defaultVarietyFilterValue,
  UnitFilter,
  VarietyFilter
} from '../constants';
import { useLazyQuery } from '@apollo/client';
import { NoFarms } from './NoFarms';
import { TotalOutput } from './TotalOutput';
import { ProductionTableControls } from './ProductionTableControls';
import { ProductionTableDetails } from './ProductionTableDetails';
import { ProductionTableDaysRow } from './ProductionTableDaysRow';
import { addDays } from 'date-fns';
import { PLANT_PRODUCTION } from './queries';
import { LocationWithTaskGroup } from './types';
import { filterArchivedFarms } from './utils';
import { sortFarms } from '../../../utils/farm';

interface Props {
  farms: OperationalFarm[];
  isActive: boolean;
  isInhubLocation: boolean;
}

export const ProductionTabDetail: FC<Props> = ({ farms, isInhubLocation, isActive }) => {
  const { locationUuid } = useParams();
  const [date, setDate] = useState(() => new Date());
  const [selectedTaskType, setSelectedTaskType] = useState(TaskType.PLANTING);
  const [selectedUnitFilter, setSelectedUnitFilter] = useState(defaultUnitFilterValue);
  const [selectedVarietyFilter, setSelectedVarietyFilter] = useState(defaultVarietyFilterValue);

  const [getPlantProductionData, { data, error, loading }] = useLazyQuery(PLANT_PRODUCTION);

  useEffect(() => {
    if (isActive)
      getPlantProductionData({
        variables: {
          locationUuid,
          fromDate: toIsoDate(getMonday(date)),
          toDate: toIsoDate(addDays(getMonday(date), 6)),
          taskType: selectedTaskType,
          farmStateNot: FarmState.NEW
        }
      });
  }, [selectedTaskType, getPlantProductionData, locationUuid, date, isActive]);

  const changeSelectedVarietyFilter = (newSelectedVarietyFilter: VarietyFilter): void => {
    if (newSelectedVarietyFilter !== VarietyFilter.FARM && selectedUnitFilter === UnitFilter.BENCH)
      setSelectedUnitFilter(UnitFilter.TRAYS);
    setSelectedVarietyFilter(newSelectedVarietyFilter);
  };

  const location: LocationWithTaskGroup = data?.location;
  const filteredSortedFarms = sortFarms(
    filterArchivedFarms(farms, location as LocationWithTaskGroup)
  );

  if (!filteredSortedFarms?.length) return <NoFarms />;
  if (error) return <h1>Something went wrong. Please try again</h1>;

  const weekDates: string[] = generateDaysOfWeekISO(date);

  return (
    <Section>
      <TotalOutput
        selectedUnitFilter={selectedUnitFilter}
        location={location}
        loading={loading}
        isInhubLocation={isInhubLocation}
        farms={filteredSortedFarms}
      />
      <ProductionTableControls
        isInhubLocation={isInhubLocation}
        setDate={setDate}
        date={date}
        setSelectedUnitFilter={setSelectedUnitFilter}
        selectedUnitFilter={selectedUnitFilter}
        selectedTaskType={selectedTaskType}
        setSelectedTaskType={setSelectedTaskType}
        selectedVarietyFilter={selectedVarietyFilter}
        changeSelectedVarietyFilter={changeSelectedVarietyFilter}
      />
      {loading || !location ? (
        <DsPlaceholder />
      ) : (
        <>
          <ProductionTableDaysRow weekDates={weekDates} />
          <ProductionTableDetails
            isInhubLocation={isInhubLocation}
            location={location}
            farms={filteredSortedFarms}
            weekDates={weekDates}
            selectedUnitFilter={selectedUnitFilter}
            selectedVarietyFilter={selectedVarietyFilter}
            selectedTaskType={selectedTaskType}
          />
        </>
      )}
    </Section>
  );
};
