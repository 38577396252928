import { ExcessDemand } from '../../types';
import { sortCallback } from '../../utils';
import { RowData } from './OverviewTable';
import { NurseryInAcreTasks, TaskGroupByStageRecipe, TaskGroupByTrayType } from './types';

export const getStageRecipeTableData = (
  byStageRecipes: TaskGroupByStageRecipe[],
  excessDemands: ExcessDemand[]
): [RowData[], RowData[]] => {
  const scheduledRows =
    [...byStageRecipes]
      .sort((a, b) => sortCallback(a.stageRecipe.name, b.stageRecipe.name))
      .map(group => ({
        totalNumberOfTrays: group.taskGroup.totalNumberOfTrays,
        rowData: {
          uuid: group.stageRecipe.uuid,
          name: group.stageRecipe.name,
          statuses: group.taskGroup.statuses,
          taskUuids: group.taskGroup.tasks?.map(task => task.uuid)
        }
      })) || [];
  const groupedExcessDemands = excessDemands.reduce<Record<string, RowData>>(
    (aggregator, demand) => {
      if (!demand.stageRecipe?.uuid) return aggregator;
      const currentEntry = aggregator[demand.stageRecipe.uuid];
      if (currentEntry) {
        return {
          ...aggregator,
          [demand.stageRecipe.uuid]: {
            ...currentEntry,
            totalNumberOfTrays: currentEntry.totalNumberOfTrays + (demand.totalNumberOfTrays || 0)
          }
        };
      } else {
        return {
          ...aggregator,
          [demand.stageRecipe.uuid]: {
            rowData: { uuid: demand.stageRecipe.uuid, name: demand.stageRecipe.name },
            totalNumberOfTrays: demand.totalNumberOfTrays || 0
          }
        };
      }
    },
    {}
  );
  const excessRows = Object.values(groupedExcessDemands).sort((a, b) =>
    sortCallback(a.rowData.name, b.rowData.name)
  );
  return [scheduledRows, excessRows];
};
export const getTrayTableData = (
  byTrayTypes: TaskGroupByTrayType[],
  excessDemands: ExcessDemand[]
): RowData[] => {
  const trayTableDataObject: { [uuid: string]: RowData } = {};
  byTrayTypes?.forEach(group => {
    trayTableDataObject[group.trayType.uuid] = {
      rowData: group.trayType,
      totalNumberOfTrays: group.taskGroup.totalNumberOfTrays
    };
  });

  excessDemands?.forEach(demand => {
    if (trayTableDataObject[demand.stageRecipe!.trayType!.uuid]) {
      trayTableDataObject[demand.stageRecipe!.trayType!.uuid].totalNumberOfTrays +=
        demand.totalNumberOfTrays!;
    } else {
      trayTableDataObject[demand.stageRecipe!.trayType!.uuid] = {
        rowData: demand.stageRecipe!.trayType!,
        totalNumberOfTrays: demand.totalNumberOfTrays!
      };
    }
  });

  return Object.values(trayTableDataObject).sort((a, b) =>
    sortCallback(a.rowData.name, b.rowData.name)
  );
};

const isIncreasing = (plots: string[]) => {
  let currentNumber = 0;
  let prevNumber = 0;
  for (let index = 0; index < plots.length; index++) {
    currentNumber = +plots[index];

    if (index && currentNumber !== prevNumber + 1) return false;
    prevNumber = currentNumber;
  }

  return true;
};

export const formatPlots = (
  plots: {
    uuid: string;
    name: string;
  }[],
  position: 'F' | 'B'
) => {
  const filteredPlots = plots
    .filter(({ name }) => name.includes(position))
    .sort((plotA, plotB) => sortCallback(plotA.name, plotB.name));

  const numbersOnly = filteredPlots.map(({ name }) => {
    const [, plotNumber] = name.split(position);

    return plotNumber;
  });

  const plotFirstNumber = numbersOnly[0];
  const plotLastNumber = numbersOnly[numbersOnly.length - 1];

  //when there is no plot at all
  if (!numbersOnly.length) {
    return '';
  }

  // when there is only one plot
  if (numbersOnly.length === 1) {
    return `${position}${plotFirstNumber}`;
  }

  // when there is multiple increasing numbers
  if (isIncreasing(numbersOnly)) {
    return `${position}${plotFirstNumber}-${plotLastNumber}`;
  } else {
    return filteredPlots.map(({ name }) => name).join(', ');
  }
};
export const sumTrays = (tasks: NurseryInAcreTasks[]) => {
  return tasks.reduce((trays, tasks) => trays + tasks.traysPerStageRecipe, 0);
};
