import { DsButton } from '@infarm/design-system-react';
import { FC } from 'react';
import {
  DateRow,
  DateControls,
  Month,
  Week,
  Toggle,
  ToggleButton,
  StyledSelect,
  Options,
  ToggleWrapper,
  ControlsRow,
  ViewByWrapper
} from '../style';
import { formatMonth, weekNumber } from '../../../utils/date-functions';
import {
  unitFilterOptions,
  UnitFilter,
  VarietyFilter,
  varietyFilterOptions,
  unitFilterOptionsWithBench
} from '../constants';
import { TaskType } from '../../../types';
import { addDays, subDays } from 'date-fns';

interface Props {
  isInhubLocation: boolean;
  setDate: (date: Date) => void;
  date: Date;
  setSelectedUnitFilter: (unitFilter: UnitFilter) => void;
  selectedUnitFilter: UnitFilter;
  setSelectedTaskType: (taskType: TaskType) => void;
  selectedTaskType: TaskType;
  changeSelectedVarietyFilter: (varietyFilter: VarietyFilter) => void;
  selectedVarietyFilter: VarietyFilter;
}

export const ProductionTableControls: FC<Props> = ({
  isInhubLocation,
  date,
  setDate,
  selectedUnitFilter,
  setSelectedUnitFilter,
  selectedTaskType,
  setSelectedTaskType,
  selectedVarietyFilter,
  changeSelectedVarietyFilter
}) => {
  const clickToday = () => {
    setDate(new Date());
  };

  const setWeekBefore = () => setDate(subDays(date, 7));
  const setWeekAfter = () => setDate(addDays(date, 7));

  const shouldShowBenchesUnitFilter =
    isInhubLocation && selectedVarietyFilter === VarietyFilter.FARM;

  const finalUnitFilterOptions = shouldShowBenchesUnitFilter
    ? unitFilterOptionsWithBench
    : unitFilterOptions;

  return (
    <ControlsRow>
      <DateRow>
        <DateControls>
          <DsButton label="Today" onClick={clickToday} />
          <DsButton
            data-cy="PlantProduction___navigateBefore"
            icon="navigate_before"
            flat
            onClick={setWeekBefore}
          />
          <DsButton
            data-cy="PlantProduction___navigateNext"
            icon="navigate_next"
            flat
            onClick={setWeekAfter}
          />
          <Month>{formatMonth(date)}</Month>
          <Week>Week {weekNumber(date)}</Week>
        </DateControls>
      </DateRow>
      <ToggleWrapper>
        <Toggle>
          <ToggleButton
            data-cy="PlantProduction___Planting"
            primary={selectedTaskType === TaskType.PLANTING}
            onClick={() => setSelectedTaskType(TaskType.PLANTING)}
          >
            Planting
          </ToggleButton>
          <ToggleButton
            data-cy="PlantProduction___Harvesting"
            primary={selectedTaskType === TaskType.HARVEST}
            onClick={() => setSelectedTaskType(TaskType.HARVEST)}
          >
            Harvesting
          </ToggleButton>
        </Toggle>
      </ToggleWrapper>

      <ViewByWrapper>
        <p>View by</p>
        <StyledSelect
          value={selectedVarietyFilter}
          onChange={e => changeSelectedVarietyFilter(e.target.value as VarietyFilter)}
          variant="outlined"
          margin="dense"
          autoWidth={false}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {varietyFilterOptions.map(option => (
            <Options key={option} value={option}>
              <strong> {option} </strong>
            </Options>
          ))}
        </StyledSelect>

        <StyledSelect
          value={selectedUnitFilter}
          onChange={e => setSelectedUnitFilter(e.target.value as UnitFilter)}
          variant="outlined"
          margin="dense"
          autoWidth={false}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {finalUnitFilterOptions.map(option => (
            <Options key={option} value={option}>
              <strong> {option} </strong>
            </Options>
          ))}
        </StyledSelect>
      </ViewByWrapper>
    </ControlsRow>
  );
};
