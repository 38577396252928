import { FC } from 'react';
import { DsSpacer } from '@infarm/design-system-react';
import { isToday } from '../../../utils/date-functions';
import { TaskGroupByDate } from './types';
import { UnitFilter } from '../constants';
import { DataRow, Span, StyledSpan, TotalPlantsRow, TotalPlantsRowTitle } from '../style';
import { formatRowData } from './utils';

interface Props {
  taskGroupsByDate: TaskGroupByDate[];
  selectedUnitFilter: UnitFilter;
  weekDates: string[];
}

export const ProductionTableLocationTotals: FC<Props> = ({
  taskGroupsByDate,
  selectedUnitFilter,
  weekDates
}) => {
  const rowData = formatRowData(taskGroupsByDate);

  return (
    <TotalPlantsRow>
      <TotalPlantsRowTitle>Total Output ({selectedUnitFilter})</TotalPlantsRowTitle>
      <DsSpacer size={3} />
      <DataRow className="span-7">
        {weekDates.map(weekDate => (
          <StyledSpan key={weekDate}>
            <Span bold={isToday(weekDate)}>{rowData[weekDate]?.[selectedUnitFilter] ?? 0}</Span>
          </StyledSpan>
        ))}
      </DataRow>
    </TotalPlantsRow>
  );
};
