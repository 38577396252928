import { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { StyledLink, IconWrapper } from './styles';
import { SvgIconComponent } from '@material-ui/icons';

const ITEM_HEIGHT = 48;

interface Option {
  text: string;
  redirectLink: string;
  icon: SvgIconComponent;
  disabled?: boolean;
}

interface DotsDropdownProps {
  options: Option[];
}

export const DotsDropdown = (props: DotsDropdownProps) => {
  const { options } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleDotsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleDotsClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropdownClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options.map(option => {
          const Icon = option.icon;

          return (
            <MenuItem
              component={StyledLink}
              to={option.redirectLink}
              key={option.text}
              onClick={handleDropdownClose}
              disabled={option.disabled}
            >
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {option.text}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
