import { DividerWithContent } from '../../../components/DividerWithContent';
import { OperationalFarm, TaskType } from '../../../types';
import { UnitFilter } from '../constants';
import { ProductionExpandableRowFarm } from './ProductionExpandableRowFarm';
import { LocationWithTaskGroup } from './types';
import { mergeFarmAndTaskGroup } from './utils';
import GroupProductionIconUrl from '../../../assets/group-production-icon.svg';
import IndividualProductionIconUrl from '../../../assets/individual-production-icon.svg';
import { DividerTitle, DividerIcon, RowsWrapper } from './style';

interface ProductionTablesByFarmProps {
  individualFarms: OperationalFarm[];
  groupFarms: OperationalFarm[];
  location: LocationWithTaskGroup;
  weekDates: string[];
  selectedUnitFilter: UnitFilter;
  selectedTaskType: TaskType;
  isInhubLocation: boolean;
}
export const ProductionTablesByFarm = ({
  individualFarms,
  groupFarms,
  location,
  weekDates,
  selectedUnitFilter,
  selectedTaskType,
  isInhubLocation
}: ProductionTablesByFarmProps) => {
  return (
    <>
      {!!groupFarms.length && (
        <>
          <DividerWithContent>
            <DividerTitle>
              <DividerIcon src={GroupProductionIconUrl} alt="Group production icon" /> Grouped
              Production Orders
            </DividerTitle>
          </DividerWithContent>
          <RowsWrapper>
            {groupFarms.map(farm => (
              <ProductionExpandableRowFarm
                taskGroupByOperationalFarm={mergeFarmAndTaskGroup(
                  farm,
                  location.tasksForDate.byOperationalFarm
                )}
                key={farm.uuid}
                weekDates={weekDates}
                selectedUnitFilter={selectedUnitFilter}
                selectedTaskType={selectedTaskType}
              />
            ))}
          </RowsWrapper>
        </>
      )}
      {isInhubLocation && (
        <DividerWithContent>
          <DividerTitle>
            <DividerIcon src={IndividualProductionIconUrl} alt="Individual production icon" />{' '}
            Individual Production Orders
          </DividerTitle>
        </DividerWithContent>
      )}
      <RowsWrapper>
        {individualFarms.map(farm => (
          <ProductionExpandableRowFarm
            taskGroupByOperationalFarm={mergeFarmAndTaskGroup(
              farm,
              location.tasksForDate.byOperationalFarm
            )}
            key={farm.uuid}
            weekDates={weekDates}
            selectedUnitFilter={selectedUnitFilter}
            selectedTaskType={selectedTaskType}
          />
        ))}
      </RowsWrapper>
    </>
  );
};
