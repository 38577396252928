import { SelectItem } from '../components/Select';

export enum inStoreCategory {
  STORE = 'STORE',
  SHOWROOM = 'SHOWROOM',
  RESTAURANT = 'RESTAURANT',
  OTHER = 'OTHER'
}

export enum inHubCategory {
  PRODUCTION = 'PRODUCTION'
}

export enum NurseryMode {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export const inStoreCategoryValues = Object.values(inStoreCategory);
export const inHubCategoryValues = Object.values(inHubCategory);
export const locationDropdownOptions = [
  inStoreCategory.STORE,
  inStoreCategory.SHOWROOM,
  inStoreCategory.RESTAURANT,
  inHubCategory.PRODUCTION,
  inStoreCategory.OTHER
];
export const allCategoryValues = [...inStoreCategoryValues, ...inHubCategoryValues];

export const writeLocationAllowedRoles = ['super_admin', 'operations_manager', 'grower_supervisor'];

export const instoreV2ScheduleAllowedRoles = [
  'super_admin',
  'grower_supervisor',
  'operations_manager',
  'farm_planner'
];
export const acreScheduleAllowedRoles = ['super_admin', 'farm_planner'];

const getFiltersByCategory = (category: string) => {
  const inHubV1Filter = 'InHub';
  const inHubV2Filter = 'IGC';
  const inStoreV1Filter = 'InStore';
  const inStoreV2Filter = '2.0';
  const posFilter = 'PoS';
  const othersFilter = ['Other', 'Others'];

  switch (category) {
    case inHubCategory.PRODUCTION:
      return [inHubV1Filter, inHubV2Filter];
    case inStoreCategory.STORE:
      return [inStoreV1Filter, inStoreV2Filter, posFilter];
    case inStoreCategory.SHOWROOM:
    case inStoreCategory.RESTAURANT:
    case inStoreCategory.OTHER:
      return [inStoreV1Filter, inStoreV2Filter, posFilter, ...othersFilter];
    default:
      return [];
  }
};

export const getLocationsByCategory = (locationGroupSelect: SelectItem[], category: string) => {
  const filters = getFiltersByCategory(category);

  const locations = locationGroupSelect.filter((locationGroup: SelectItem) =>
    filters.some(filter => locationGroup.text.includes(filter))
  );

  return locations;
};
