import * as yup from 'yup';
import { FarmItem } from './types';

const message = 'Please select a valid value from the list.';
export const schema = yup.object().shape({
  locationGroupUuid: yup.string().required(message),
  category: yup.string().required(message),
  name: yup
    .string()
    .required('Please enter a valid location name.')
    .matches(/^[a-zA-Z0-9]/, 'name cannot start with a special character'),
  customerUuid: yup.string().required(message),
  timezone: yup.string().required(message),
  farms: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      model: yup.string(),
      uuid: yup.string(),
      capacityInBenches: yup.number(),
      nurseryBenches: yup.number(),
      isFullDark: yup.boolean()
    })
  ),
  nurseryUuid: yup.string().when('farms', {
    is: (farms: FarmItem[]) => !!farms.length,
    then: yup.string().required('You must select a nursery to be able to add farms.')
  })
});
