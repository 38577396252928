import { useState } from 'react';
import { NurseryMode } from '../../../../constants';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  BarChart,
  Cell,
  Text
} from 'recharts';
import { ChartContainer } from '../style';
import { formatDay, isToday } from '../../../../../utils/date-functions';
import { chartStaticsData } from '../constants';
import { NurseryChartLegend } from './NurseryChartLegend';
import { CustomTooltip } from './NurseryChartTooltip';
import { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';
import { ChartData } from '../types';

interface NurseryChartProps {
  selectedNurseryModes: NurseryMode[];
  nurseryMaxCapacity: { [key in NurseryMode]: number };
  chartData: ChartData[];
}

export const NurseryChart = ({
  chartData,
  selectedNurseryModes,
  nurseryMaxCapacity
}: NurseryChartProps) => {
  const [focusedBarIndex, setFocusedBarIndex] = useState<number | undefined>(undefined);

  const formatXAxisLabel = (props: any) => {
    const date = props.payload.value;

    if (isToday(date)) {
      props['fontWeight'] = 700;
      props['fill'] = '#000000';
    }
    return <Text {...props}>{isToday(date) ? 'Today' : formatDay(date)}</Text>;
  };
  const handleMouseMove = (state: CategoricalChartState) => {
    if (state.isTooltipActive) {
      setFocusedBarIndex(state.activeTooltipIndex);
    } else {
      setFocusedBarIndex(undefined);
    }
  };

  return (
    <ChartContainer>
      <ResponsiveContainer>
        <BarChart data={chartData} margin={{ top: 20, bottom: 5 }} onMouseMove={handleMouseMove}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="date" stroke="rgba(0, 0, 0, 0.5)" tick={formatXAxisLabel} />
          <YAxis axisLine={false} stroke="rgba(0, 0, 0, 0.5)" />
          <Tooltip cursor={false} content={<CustomTooltip />} />
          {chartStaticsData.map(({ dataKey, stackId, fill, nurseryMode }) => {
            const isSelectedNurseryModeBar = selectedNurseryModes.includes(nurseryMode);
            return (
              <Bar
                key={dataKey}
                name={dataKey}
                dataKey={dataKey}
                stackId={stackId}
                fill={fill}
                hide={!isSelectedNurseryModeBar}
                barSize={30}
              >
                {chartData.map((_, index) => (
                  <Cell opacity={focusedBarIndex !== index ? 1 : 0.5} key={`cell-${index}`} />
                ))}
              </Bar>
            );
          })}
          {selectedNurseryModes.includes(NurseryMode.LIGHT) && (
            <ReferenceLine
              y={nurseryMaxCapacity[NurseryMode.LIGHT]}
              label={{
                value: `Light (${nurseryMaxCapacity[NurseryMode.LIGHT]})`,
                fill: '#FF3B30',
                fontWeight: 700,
                fontSize: 12,
                position: 'insideTopLeft'
              }}
              stroke="#FF3B30"
              strokeDasharray="3 3"
            />
          )}
          {selectedNurseryModes.includes(NurseryMode.DARK) && (
            <ReferenceLine
              y={nurseryMaxCapacity[NurseryMode.DARK]}
              label={{
                value: `Dark (${nurseryMaxCapacity[NurseryMode.DARK]})`,
                fill: '#FF3B30',
                fontWeight: 700,
                fontSize: 12,
                position: 'insideTopLeft'
              }}
              stroke="#FF3B30"
              strokeDasharray="3 3"
            />
          )}
        </BarChart>
      </ResponsiveContainer>
      <NurseryChartLegend />
    </ChartContainer>
  );
};
