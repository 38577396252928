import { gql } from '@apollo/client';

export const FARM_HARVESTED_PRODUCTS_TOTALS = gql`
  query getStageRecipeTotals($uuid: ID!, $date: Date!) {
    operationalFarm(uuid: $uuid) {
      uuid
      occupiedStandardBenches(date: $date)
      schedulableBenches
      stageRecipeTotals(date: $date) {
        date
        benches
      }
    }
  }
`;

export const LOCATION_FARMS = gql`
  query getLocationFarms($uuid: ID!, $farmUuid: ID!) {
    location(uuid: $uuid) {
      uuid
      name
      category
      isSelfNursery
    }
    operationalFarm(uuid: $farmUuid) {
      name
      uuid
      schedulableBenches
      capacityUnitsPerBench
      model
      traysPerBench
      farmCycle
      serialNumber
    }
    stageRecipes(
      farmUuid: $farmUuid
      locationUuid: $uuid
      operationalRecipeStatus: ["production", "research"]
      operationalRecipeTypes: ["production"]
    ) {
      uuid
      duration
      density
      trayType {
        name
        uuid
        surfaceUnits
        plantsPerTray
      }
      name
      nurseryMaxPropagationTime
      internalCode
    }
  }
`;

export const CREATE_PLANTING_SCHEDULE = gql`
  mutation CreatePlantingSchedule($input: CreatePlantingScheduleInput!) {
    createPlantingSchedule(input: $input) {
      name
      state
    }
  }
`;

export const STAGE_RECIPE_FIELDS = gql`
  fragment StageRecipeFields on StageRecipe {
    uuid
    name
    duration
    density
    isCurrentVersion
    trayType {
      name
      uuid
      plantsPerTray
      surfaceUnits
    }
  }
`;

export const GET_SCHEDULE_BY_UUID = gql`
  ${STAGE_RECIPE_FIELDS}
  query getPlantingSchedule($uuid: ID!) {
    plantingSchedule(uuid: $uuid) {
      uuid
      name
      firstPlantingDate
      comment
      maxPropagationDays
      configuration {
        monday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
        tuesday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
        wednesday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
        thursday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
        friday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
        saturday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
        sunday {
          stageRecipe {
            ...StageRecipeFields
            currentStageRecipe {
              ...StageRecipeFields
            }
          }
          numberOfTrays
        }
      }
    }
  }
`;

export const GET_PREV_SCHEDULE = gql`
  query getPrevSchedule($farmUuidAsString: String) {
    plantingSchedules(limit: 1, filter: { farmUuid: $farmUuidAsString, stateIn: ACTIVE }) {
      edges {
        uuid
        configuration {
          monday {
            stageRecipe {
              uuid
              duration
            }
          }
          tuesday {
            stageRecipe {
              uuid
              duration
            }
          }
          wednesday {
            stageRecipe {
              uuid
              duration
            }
          }
          thursday {
            stageRecipe {
              uuid
              duration
            }
          }
          friday {
            stageRecipe {
              uuid
              duration
            }
          }
          saturday {
            stageRecipe {
              uuid
              duration
            }
          }
          sunday {
            stageRecipe {
              uuid
              duration
            }
          }
        }
      }
    }
  }
`;

export const GET_FARMS_LIST = gql`
  query getFarmsList($locationUuid: ID!) {
    location(uuid: $locationUuid) {
      uuid
      operationalFarms(farmModel: [ACRE]) {
        uuid
        name
      }
    }
  }
`;

export const TRIGGER_NURSERY_DEMAND_GENERATION = gql`
  mutation triggerNurseryDemand($locationUuid: String!) {
    triggerNurseryDemand(locationUuid: $locationUuid) {
      isTriggeredSuccesfully
    }
  }
`;
