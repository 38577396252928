import { DsButton } from '@infarm/design-system-react';
import React, { useState } from 'react';
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext
} from 'react-hook-form';
import { GroupNameRow, GroupNameCell, VarietyRow, RemoveIcon, Chevron, GroupCell } from '../style';
import { FormProps, HubDemandConfig, PlantingWeekDays } from '../types';
import { VarietyEditSelect } from './VarietyEditSelect';
import { StageRecipe } from '../../../types';
import { TableTotals } from './TableTotals';
import { NumberInput } from '../../../components/Textfield/NumberInput';
import { offsetDateWithTimezone } from '../../../utils/date-functions';
import { isPast, isToday, subDays } from 'date-fns';
import { IconTooltip } from '../../../components/IconTooltip';
interface VarietyGroupProps {
  dateColumns: string[];
  tableConfigs: HubDemandConfig[];
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<FieldValues, 'configurations'>;
  availableVarieties: StageRecipe[];
  plantingWeekDays: PlantingWeekDays;
}

export const VarietyGroup = ({
  dateColumns,
  tableConfigs,
  remove,
  update,
  availableVarieties,
  plantingWeekDays
}: VarietyGroupProps) => {
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpand = () => setExpanded(curr => !curr);
  const [{ stageRecipe }] = tableConfigs;
  const { duration, plantGroup } = stageRecipe;
  const { getValues } = useFormContext<FormProps>();
  const { configurations } = getValues();

  return (
    <>
      <GroupNameRow>
        <Chevron>
          <DsButton icon={isExpanded ? 'expand_less' : 'expand_more'} flat onClick={toggleExpand} />
        </Chevron>
        <GroupNameCell colSpan={dateColumns.length + 1}>
          {`${plantGroup} (${Math.ceil(duration / 7)} weeks)`}
        </GroupNameCell>
      </GroupNameRow>
      {isExpanded &&
        tableConfigs.map(config => {
          const indexInConfigurations = configurations.findIndex(
            ({ stageRecipe }) => stageRecipe.uuid === config.stageRecipe.uuid
          );
          return (
            <VarietyRow key={config.stageRecipe.uuid}>
              <GroupCell className="closeIcon">
                <RemoveIcon
                  onClick={() => {
                    remove(indexInConfigurations);
                  }}
                >
                  close
                </RemoveIcon>
              </GroupCell>
              <GroupCell>
                <VarietyEditSelect
                  availableVarieties={availableVarieties}
                  indexInConfigurations={indexInConfigurations}
                  selectedVariety={config.stageRecipe}
                  update={update}
                />
              </GroupCell>
              {dateColumns.map(weekday => {
                const { duration, nurseryMaxPropagationTime } =
                  configurations[indexInConfigurations]?.stageRecipe || {};
                const harvestDate = offsetDateWithTimezone(plantingWeekDays[weekday]);
                const plantingDate = subDays(harvestDate, duration);
                const seedingDate = subDays(plantingDate, nurseryMaxPropagationTime);

                const isPlantingDatePast = isPast(plantingDate) || isToday(plantingDate);
                const isSeedingDatePast = isPast(seedingDate) || isToday(seedingDate);
                const tooltipColor = isPlantingDatePast ? '#BDBDBD' : '#FFDA87';
                const numberOfTray =
                  configurations[indexInConfigurations]?.dailyConfig[plantingWeekDays[weekday]] ||
                  0;
                const hasWarning = !isPlantingDatePast && isSeedingDatePast && !!numberOfTray;

                return (
                  <GroupCell key={`${config.stageRecipe.uuid} + ${weekday}`} warning={hasWarning}>
                    {isPlantingDatePast ? (
                      <IconTooltip
                        heading="Cannot edit"
                        placement="right"
                        body="Orders with planting date for today or in the past cannot be edited."
                        styles={{
                          backgroundColor: tooltipColor,
                          arrowColor: tooltipColor,
                          headingColor: 'rgba(0, 0, 0, 0.9);',
                          bodyColor: 'rgba(0, 0, 0, 0.6);'
                        }}
                      >
                        <div>
                          <NumberInput
                            warning={hasWarning}
                            resetOnFocus={false}
                            padding="0"
                            disabled={isPlantingDatePast}
                            name={`configurations.${indexInConfigurations}.dailyConfig.${plantingWeekDays[weekday]}`}
                          />
                        </div>
                      </IconTooltip>
                    ) : (
                      <NumberInput
                        isPlantingDatePast={isPlantingDatePast}
                        padding="0"
                        warning={hasWarning}
                        resetOnFocus={false}
                        disabled={isPlantingDatePast}
                        name={`configurations.${indexInConfigurations}.dailyConfig.${plantingWeekDays[weekday]}`}
                      />
                    )}
                  </GroupCell>
                );
              })}
            </VarietyRow>
          );
        })}
      {isExpanded && (
        <TableTotals
          tableConfigs={tableConfigs}
          plantingWeekDays={plantingWeekDays}
          dateColumns={dateColumns}
        />
      )}
    </>
  );
};
