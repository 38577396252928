import { DsIcon } from '@infarm/design-system-react';
import { add, addDays, isWithinInterval } from 'date-fns';
import { useState } from 'react';
import { useController, useWatch, useFormContext } from 'react-hook-form';
import { offsetDateWithTimezone, toIsoDate } from '../../../utils/date-functions';
import { modalContent } from '../constants';
import {
  DateConfigWrapper,
  DateInputWrapper,
  ErrorMessage,
  Label,
  SectionDates,
  StyledDateInput
} from '../style';
import { Modal } from './Modal';

export const DateConfig = () => {
  const {
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { field: startDateField } = useController({ name: 'startDate' });
  const { field: endDateField } = useController({ name: 'endDate' });
  const { configurations } = getValues();
  const [doesRepeatWeekly] = useWatch({
    name: ['doesRepeatWeekly']
  });
  const { value: endDate } = endDateField;
  const { value: startDate } = startDateField;
  const scheduleDateIsoMin = doesRepeatWeekly
    ? toIsoDate(addDays(offsetDateWithTimezone(startDate), 6))
    : startDate || toIsoDate(new Date());
  const scheduleDateIsoMax = doesRepeatWeekly
    ? toIsoDate(add(offsetDateWithTimezone(startDate), { months: 3 }))
    : toIsoDate(addDays(offsetDateWithTimezone(startDate), 6));

  const onPrimaryBtnClick = () => {
    setValue('configurations', []);
    closeModal();
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleStartDateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value;
    if (!newStartDate) return;

    if (!doesRepeatWeekly) {
      const isValidEndDate =
        endDate &&
        isWithinInterval(new Date(endDate), {
          start: new Date(newStartDate),
          end: addDays(new Date(newStartDate), 6)
        });
      if (!isValidEndDate) {
        setValue('endDate', toIsoDate(addDays(offsetDateWithTimezone(newStartDate), 6)));
      }
    } else {
      setValue('endDate', toIsoDate(add(offsetDateWithTimezone(newStartDate), { months: 1 })));
    }
    startDateField.onChange(e);
  };

  return (
    <SectionDates>
      <Modal
        onSecondaryBtnClick={closeModal}
        onPrimaryBtnClick={onPrimaryBtnClick}
        isModalOpen={isModalOpen}
        secondaryLabel="No"
        primaryLabel="Yes"
        heading={modalContent.reset.heading}
        text={modalContent.reset.text}
        onModalClosed={() => {
          setIsModalOpen(false);
        }}
      />
      <DateConfigWrapper>
        <DateInputWrapper>
          <Label>Start date (1st harvest date)</Label>
          <StyledDateInput
            type="date"
            variant="outlined"
            value={startDateField.value || ''}
            onClick={e => {
              if (configurations.length) {
                setIsModalOpen(true);
                e.preventDefault();
              }
            }}
            onInput={handleStartDateInput}
          />
          {errors?.startDate && (
            <ErrorMessage>
              <DsIcon>error_outline</DsIcon> {errors.startDate?.message}
            </ErrorMessage>
          )}
        </DateInputWrapper>
        <DateInputWrapper>
          <Label>End date</Label>
          <StyledDateInput
            type="date"
            variant="outlined"
            onClick={e => {
              if (configurations.length) {
                setIsModalOpen(true);
                e.preventDefault();
              }
            }}
            value={endDate || ''}
            InputProps={{
              inputProps: {
                min: scheduleDateIsoMin,
                max: scheduleDateIsoMax
              }
            }}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              const newEndDate = e.target.value;
              if (!newEndDate) return;
              endDateField.onChange(e);
            }}
          />
          {errors?.endDate && (
            <ErrorMessage>
              <DsIcon>error_outline</DsIcon> {errors.endDate?.message}
            </ErrorMessage>
          )}
        </DateInputWrapper>
      </DateConfigWrapper>
      {/* {!errors?.startDate && !errors.endDate && (
        <SectionSubTitle>
          You can repeat the order with max. 3 months period. Default period has been set to 1
          month.
        </SectionSubTitle>
      )} */}
    </SectionDates>
  );
};
