import React from 'react';
import styled from 'styled-components';

const ChipWrapper = styled.div<{ backgroundColor?: string }>`
  background-color: ${props => props.backgroundColor || '#f5f5f5'};
  border-radius: 1em;
  display: inline-flex;
  margin: 2px;
  box-sizing: border-box;
  margin-right: 10px;
  padding-left: 3px;
  padding-right: 3px;
`;

const Text = styled.span`
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  overflow: hidden;
  padding: 3px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  font-weight: normal;
  align-self: center;
`;

const IconWrapper = styled.div`
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
`;

interface ChipProps {
  label: string;
  icon?: any;
  styles?: any;
}

export const Chip = ({ label, icon, styles }: ChipProps) => {
  return (
    <ChipWrapper backgroundColor={styles?.backgroundColor}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <Text color={styles?.labelColor}>{label}</Text>
    </ChipWrapper>
  );
};
