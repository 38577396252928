import { add, addDays } from 'date-fns';
import * as yup from 'yup';
import { HubDemandConfig } from './types';

export const formSchema = () =>
  yup.object().shape({
    stageRecipes: yup.array(),
    hubDetails: yup.object({
      name: yup.string(),
      uuid: yup.string(),
      operationalFarms: yup.array().of(
        yup.object({
          name: yup.string(),
          traysPerBench: yup.number(),
          uuid: yup.string()
        })
      )
    }),
    doesRepeatWeekly: yup.boolean().required(),
    startDate: yup
      .date()
      .required('Start date is required.')
      .when('configurations', (configurations: HubDemandConfig[], schema) => {
        const errorMsg =
          'Planting date should be in the future. Please adjust the first harvest date.';
        if (configurations.length) {
          const longestGrowingCycle = Math.max(
            ...configurations.map(conf => conf.stageRecipe.duration)
          );
          return schema.min(addDays(new Date(), longestGrowingCycle), errorMsg);
        } else {
          return schema.min(
            addDays(new Date(), 7),
            'Planting date should be in the future. Please adjust the first harvest date.'
          );
        }
      }),
    endDate: yup
      .date()
      .required('End date is required.')
      // @ts-expect-error
      .when(['startDate', 'doesRepeatWeekly'], (startDate, doesRepeatWeekly, schema) => {
        if (startDate) {
          if (!doesRepeatWeekly) {
            const maxEndDate = addDays(startDate, 6);
            return schema
              .min(startDate, 'The end date should be later than the start date.')
              .max(maxEndDate, 'The end date must be within one week of the start date.');
          } else {
            const minEndDate = addDays(startDate, 6);
            const maxEndDate = add(startDate, { months: 3 });
            return schema
              .min(
                minEndDate,
                'The end date should be at least one week later than the start date.'
              )
              .max(maxEndDate, 'The end date must be within 3 months of the start date.');
          }
        }
      }),
    configurations: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            dailyConfig: yup.lazy(value => {
              const newEntries = Object.keys(value).reduce(
                (acc, val) => ({
                  ...acc,
                  [val]: yup.number().min(0)
                }),
                {}
              );
              return yup.object().shape(newEntries);
            }),
            stageRecipe: yup.object({
              uuid: yup.string().required(),
              name: yup.string(),
              duration: yup.number(),
              internalCode: yup.string(),
              plantGroup: yup.string(),
              trayType: yup.object({
                name: yup.string(),
                plantsPerTray: yup.number(),
                surfaceUnits: yup.number(),
                uuid: yup.string()
              })
            })
          })
          .required()
      )
      .min(1)
      .test({
        name: 'noTrayInput',
        test: configurations => {
          return !!configurations?.every((config: HubDemandConfig) => {
            return Object.values(config?.dailyConfig).reduce((a, b) => a + b) > 0;
          });
        }
      })
  });
