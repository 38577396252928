import { NurseryInAcreTaskGroup, TaskGroup } from '../types';
import {
  ChevronWrapper,
  ChipChevron,
  Plots,
  RecipeName,
  RecipeRow,
  Span,
  StyledIcon,
  Task,
  TaskParent,
  TaskTrayInfo,
  TaskWrapper,
  TraysPerTask
} from './style';
import Loading from '../../../assets/loading.png';
import Unloading from '../../../assets/unloading.png';
import Spraying from '../../../assets/spraying.png';
import { DsButton } from '@infarm/design-system-react';
import { useState } from 'react';
import { formatPlots } from '../utils';
import { StatusChip } from '../../../components/StatusChip';
import { Status } from '../../../types';

export const TaskContainer = ({
  taskGroup,
  task
}: {
  taskGroup: {
    [key in TaskGroup]: NurseryInAcreTaskGroup;
  };
  task: TaskGroup;
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(curr => !curr);
  const getTaskIcon = () => {
    if (task === TaskGroup.LOADING) return Loading;
    if (task === TaskGroup.UNLOADING) return Unloading;
    if (task === TaskGroup.SPRAYING) return Spraying;
  };

  const tasksAllCompleted = taskGroup[task].tasks.every(task => task.status === 'COMPLETED');

  return (
    <TaskParent done={tasksAllCompleted}>
      <TaskWrapper>
        <TaskTrayInfo>
          <StyledIcon src={getTaskIcon()} alt="Bench Icon" />
          <Task>{task === TaskGroup.SPRAYING ? 'Variety specific task' : task}</Task>
          <TraysPerTask>
            {taskGroup[task]?.tasks?.reduce((acc, task) => (acc += task.traysPerStageRecipe), 0)}{' '}
            Tray(s)
          </TraysPerTask>
        </TaskTrayInfo>
        <ChipChevron>
          <StatusChip
            status={tasksAllCompleted ? Status.SUCCESS : Status.DEFAULT}
            label={tasksAllCompleted ? 'DONE' : 'TO DO'}
          />
          <ChevronWrapper>
            <DsButton
              icon={isExpanded ? 'expand_less' : 'expand_more'}
              flat
              onClick={toggleExpand}
            />
          </ChevronWrapper>
        </ChipChevron>
      </TaskWrapper>
      {isExpanded &&
        taskGroup[task].tasks.map(({ uuid, stageRecipe, traysPerStageRecipe, plots }) => {
          const frontPlots = formatPlots(plots, 'F');
          const backPlots = formatPlots(plots, 'B');
          const plotsLocation = frontPlots && backPlots ? `${frontPlots}, ` : frontPlots;
          return (
            <RecipeRow key={uuid}>
              <RecipeName>
                <Span>{stageRecipe.name}</Span>
              </RecipeName>
              <Plots>
                <Span>{traysPerStageRecipe} Tray(s)</Span>
                <Span>{`${plotsLocation}${backPlots}`}</Span>
              </Plots>
            </RecipeRow>
          );
        })}
    </TaskParent>
  );
};
