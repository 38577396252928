import { useFieldArray, useWatch } from 'react-hook-form';
import { formatDateWithMonth } from '../../../utils/date-functions';
import { DateIndicatorTableHeader } from '../../PlantingSchedule/components/DateIndicatorTableHeader';
import {
  TableWrapper,
  DemandTable,
  HeaderCell,
  HeaderRow,
  DateLabel,
  Overlay,
  OverlayText,
  DayIndicatorWrapper
} from '../style';
import { HubDemandConfig, PlantingWeekDays } from '../types';
import { groupBy } from 'lodash-es';
import { StageRecipe } from '../../../types';
import { TableTotals, ConfigRow, VarietyGroup } from '../components';
import DayIndicator from '../../../../src/assets/DayIndicator.svg';

export const ConfigTable = ({
  showOverlay,
  dateColumns,
  plantingWeekDays
}: {
  showOverlay: boolean;
  dateColumns: string[];
  plantingWeekDays: PlantingWeekDays;
}) => {
  const [startDate, doesRepeatWeekly, configurations, stageRecipes] = useWatch({
    name: ['startDate', 'doesRepeatWeekly', 'configurations', 'stageRecipes']
  });
  const { remove, append, update } = useFieldArray({
    name: 'configurations'
  });

  const availableVarieties = stageRecipes.filter(
    (variety: StageRecipe) =>
      !configurations.some(({ stageRecipe }: HubDemandConfig) => stageRecipe.uuid === variety.uuid)
  );

  const groupedConfigurations = groupBy(configurations, 'stageRecipe.plantGroup');

  return (
    <TableWrapper>
      {showOverlay && (
        <Overlay>
          <OverlayText>You can press Ctrl + V to paste your data</OverlayText>
        </Overlay>
      )}
      <DemandTable>
        <thead>
          {doesRepeatWeekly && (
            <DateIndicatorTableHeader
              isHubDemandForm={true}
              dashness={false}
              firstPlantingDate={startDate}
            />
          )}
          <HeaderRow>
            <HeaderCell />
            <HeaderCell>Variety</HeaderCell>
            {dateColumns.map(weekday => {
              return (
                <HeaderCell key={weekday}>
                  {doesRepeatWeekly && startDate && startDate === plantingWeekDays[weekday] && (
                    <DayIndicatorWrapper>
                      <img src={DayIndicator} alt="DayIndicator" />
                    </DayIndicatorWrapper>
                  )}
                  {weekday}
                  {plantingWeekDays?.[weekday] && (
                    <DateLabel>{formatDateWithMonth(plantingWeekDays?.[weekday])}</DateLabel>
                  )}
                </HeaderCell>
              );
            })}
          </HeaderRow>
        </thead>
        <tbody>
          <ConfigRow
            availableVarieties={availableVarieties}
            plantingWeekDays={plantingWeekDays}
            dateColumns={dateColumns}
            append={append}
          />
          {Object.keys(groupedConfigurations).map(group => (
            <VarietyGroup
              key={group}
              availableVarieties={availableVarieties}
              update={update}
              plantingWeekDays={plantingWeekDays}
              remove={remove}
              tableConfigs={groupedConfigurations[group]}
              dateColumns={dateColumns}
            />
          ))}
          {!!configurations.length && (
            <TableTotals
              isGrandTotal={true}
              groupedConfigurations={groupedConfigurations}
              plantingWeekDays={plantingWeekDays}
              dateColumns={dateColumns}
            />
          )}
        </tbody>
      </DemandTable>
    </TableWrapper>
  );
};
