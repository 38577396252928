import { FunctionComponent } from 'react';
import { HybridCaption, ButtonWithIcon, NurseryBenchesConfig } from './styles';
import { Icon, TextField } from '@material-ui/core';
import { AcreType } from '../../types';
import { ACRE_MAX_FIXED_BENCHES } from './constants';

interface Props {
  increaseNurseryBenches: () => void;
  decreaseNurseryBenches: () => void;
  nurseryBenches: number;
  farmType: string;
}

export const FarmDetailModalHybridSection: FunctionComponent<Props> = ({
  increaseNurseryBenches,
  decreaseNurseryBenches,
  nurseryBenches,
  farmType
}): JSX.Element => {
  const maxNurseryBenchesAllowed: number = ACRE_MAX_FIXED_BENCHES[farmType as AcreType] ?? 0;

  return (
    <>
      <HybridCaption>Select the number of nursery benches in this Acre.</HybridCaption>
      <NurseryBenchesConfig>
        <TextField
          inputProps={{ style: { textAlign: 'center' } }}
          variant="outlined"
          value={nurseryBenches}
          InputProps={{
            readOnly: true
          }}
        />
        <>
          <ButtonWithIcon
            disabled={nurseryBenches === 0}
            variant="outlined"
            onClick={decreaseNurseryBenches}
            startIcon={<Icon>remove</Icon>}
            data-cy="FarmDetailModalHybridSection_decreaseNurseryBenches"
          />
          <ButtonWithIcon
            disabled={nurseryBenches >= maxNurseryBenchesAllowed}
            variant="outlined"
            onClick={increaseNurseryBenches}
            startIcon={<Icon>add</Icon>}
            data-cy="FarmDetailModalHybridSection_increaseNurseryBenches"
          />
        </>
      </NurseryBenchesConfig>
    </>
  );
};
