import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BreadcrumbsElementType } from './types';

const BreadcrumbsElementStyled = styled.div`
  display: flex;
  flex-direction: row;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

const BreadcrumbsLink = styled(Link)`
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
  &:hover {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 500;
  }
  /*
   * The reason for the ::after is to prevent flicking when turning a text bolder, consequantly taking more width,
   * and pushing other content, causing unpleasent user experience on hover.
   */
  &::after {
    display: block;
    content: attr(data-bold-placeholder);
    font-weight: 500;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

const BreadcrumbsLastElement = styled.span`
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  text-transform: capitalize;
`;

const BreadcrumbsSeperator = styled.span`
  margin-left: 6px;
  margin-right: 6px;
`;

interface BreadcrumbsElementProps {
  element: BreadcrumbsElementType;
  isLastElement: boolean;
}

export const BreadcrumbsElement = ({ element, isLastElement }: BreadcrumbsElementProps) => {
  return (
    <BreadcrumbsElementStyled>
      {isLastElement ? (
        <BreadcrumbsLastElement>{element.text}</BreadcrumbsLastElement>
      ) : (
        <>
          <BreadcrumbsLink
            to={element.link ?? '#'}
            data-cy={element.dataCy}
            data-bold-placeholder={element.text}
          >
            {element.text}
          </BreadcrumbsLink>
          <BreadcrumbsSeperator>/</BreadcrumbsSeperator>
        </>
      )}
    </BreadcrumbsElementStyled>
  );
};
