import { NurseryMode } from '../../../constants';

export enum ChartDataKeys {
  DARK_UTILISED = 'DARK.utilised',
  DARK_UNUTILISED = 'DARK.unutilised',
  LIGHT_UTILISED = 'LIGHT.utilised',
  LIGHT_UNUTILISED = 'LIGHT.unutilised'
}

export type ChartData = {
  date: string;
  [NurseryMode.DARK]: {
    utilised: number;
    unutilised: number;
  };
  [NurseryMode.LIGHT]: {
    utilised: number;
    unutilised: number;
  };
};
