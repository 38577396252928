import { ModalSubtitle, AnchorLink, Note } from '../styles';
interface ModalBodyProps {
  modalBody: string | string[];
  isError: boolean;
}

export const ModalBody = ({ modalBody, isError }: ModalBodyProps) => {
  return (
    <ModalSubtitle>
      {Array.isArray(modalBody) ? (
        <ul>
          {modalBody.map(message => (
            <li>{message}</li>
          ))}
        </ul>
      ) : (
        modalBody
      )}
      {isError ? (
        <>
          <p>
            Please raise a ticket at the{' '}
            <AnchorLink
              href="https://infarm.atlassian.net/servicedesk/customer/portal/1/group/4"
              rel="noreferrer"
              target="_blank"
            >
              Farmboard service desk
            </AnchorLink>{' '}
            and the software support team will assist you
          </p>
          <Note>
            Please be sure to include a screenshot of this error message in your service desk
            ticket.
          </Note>
        </>
      ) : null}
    </ModalSubtitle>
  );
};
