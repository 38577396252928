import { useFormContext } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { Section, CancelButton } from '../style';
import SubmitButton from '../../../components/SubmitButton';
import { FormState } from '../types';
import { hasPermission } from '../../../utils/user';
import { FarmModel } from '../../../types';
import { acreScheduleAllowedRoles, instoreV2ScheduleAllowedRoles } from '../../constants';
import { offsetDateWithTimezone } from '../../../utils/date-functions';
import { isPast } from 'date-fns';

export const SubmitButtonWithValidation = () => {
  const navigate = useNavigate();
  const { locationUuid, farmUuid, type, plantingScheduleUuid } = useParams();
  const {
    formState: { isValid, isDirty },
    getValues
  } = useFormContext();
  const { selectedFarm, firstPlantingDate } = getValues();

  const hasError = !isValid || !isDirty;
  const farmModel = selectedFarm.model;
  const allowedRoles =
    farmModel === FarmModel.ACRE ? acreScheduleAllowedRoles : instoreV2ScheduleAllowedRoles;

  const plantingDate = offsetDateWithTimezone(firstPlantingDate);
  const isPlantingDateInPast = isPast(plantingDate);
  const submitButtonPrefix = type === FormState.EDIT ? 'Save' : 'Apply';

  return type === FormState.VIEW ? (
    <Section marginBottom>
      <CancelButton
        label={'Back'}
        onClick={() => navigate(`/locations/${locationUuid}/${farmUuid}/planting-schedules`)}
      />
      {hasPermission(allowedRoles) && (
        <>
          <SubmitButton
            type="button"
            label="Edit Schedule"
            disabled={isPlantingDateInPast}
            onClick={() =>
              navigate(
                `/locations/${locationUuid}/${farmUuid}/planting-schedules/${plantingScheduleUuid}/${FormState.EDIT}`
              )
            }
          />
          <SubmitButton
            type="button"
            label="Duplicate Schedule"
            onClick={() =>
              navigate(
                `/locations/${locationUuid}/${farmUuid}/planting-schedules/${plantingScheduleUuid}/${FormState.DUPLICATE}`
              )
            }
          />
        </>
      )}
    </Section>
  ) : (
    <Section marginBottom>
      <CancelButton
        label={'Cancel'}
        data-cy="SubmitButtonWithValidation___cancelButton"
        onClick={() => navigate(`/locations/${locationUuid}/${farmUuid}/planting-schedules`)}
      />
      <SubmitButton
        type="submit"
        data-cy={`SubmitButtonWithValidation___${submitButtonPrefix}Button`}
        label={`${submitButtonPrefix} Schedule`}
        disabled={hasError}
      />
    </Section>
  );
};
