import React, { FunctionComponent } from 'react';
import {
  InfarmLabel,
  InputWrapper,
  Title,
  SmallCaption,
  DesignTextField,
  ValidationError
} from './styles';

interface DsTextFieldProps {
  value?: any;
  label?: string;
  caption?: string;
  prefix?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  validationMessage?: string | null;
  defaultValue?: string;
}

export const DsTextField: FunctionComponent<DsTextFieldProps> = ({
  label,
  caption,
  type = 'text',
  placeholder,
  validationMessage = null,
  onChange,
  ...props
}: DsTextFieldProps) => {
  const inputTitle = label ? <Title>{label}</Title> : null;
  const inputCaption = caption ? <SmallCaption>{caption}</SmallCaption> : null;

  const inputPlaceholder = placeholder ? placeholder : label;

  const handleChange = (event: React.FormEvent<HTMLDsTextfieldElement>) => {
    event.preventDefault();
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <InfarmLabel>
      {inputTitle}
      {inputCaption}
      <InputWrapper>
        <DesignTextField
          {...props}
          type={type}
          placeholder={inputPlaceholder}
          warning={!!validationMessage}
          onInput={event => handleChange(event)}
        />
      </InputWrapper>
      {validationMessage && <ValidationError>{validationMessage}</ValidationError>}
    </InfarmLabel>
  );
};
