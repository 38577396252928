import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 68px;
`;

export const FarmName = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
`;

export const BenchQuantity = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 8px;
`;

export const ChevronWrapper = styled.div`
  display: inline;
`;

export const FarmWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BenchSummary = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const BenchNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  justify-content: space-between;
  padding: 0px 19px 0px 16px;
  border-radius: 4px;
  background: #f5f5f5;
`;

export const BenchName = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-left: 14px;
`;

export const Task = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const TraysPerTask = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 8px;
`;

export const TaskWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 19px 0px 16px;
  margin: 12px 0;
`;

export const StyledIcon = styled.img`
  margin-right: 11px;
`;

export const TaskTrayInfo = styled.div`
  align-items: center;
  display: flex;
`;

export const RecipeRow = styled.div`
  margin: 0px 24px 0px 16px;
  border-bottom: 1px solid #e0e0e0;
  height: 48px;
  display: flex;
  align-items: center;
`;

export const Span = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const Plots = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const RecipeName = styled.div`
  width: 500px;
`;

export const SummaryWrapper = styled.div`
  margin-bottom: 56px;
`;

export const TaskParent = styled.div<{ done?: boolean }>`
  ${({ done }) => (done ? 'opacity: 0.5;' : '')}
`;

export const CompletedTasks = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;

export const CompletedTasksWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const ChipChevron = styled.div`
  display: flex;
  align-items: center;
`;
