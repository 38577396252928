import { Cell, ActionIcon, ActionCell, ConfigRowWrapper, VarietyCell } from '../style';
import { TrayInput } from './TrayInput';
import { VarietySelect } from '../components/VarietySelect';
import { StageRecipe } from '../../../types';
import { UseFieldArrayAppend, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { FormProps, HubDemandConfig, PlantingWeekDays } from '../types';
import { generateDefaultHubConfig } from '../utils';
import { isPast, isToday, subDays } from 'date-fns';
import { IconTooltip } from '../../../components/IconTooltip';
import { offsetDateWithTimezone } from '../../../utils/date-functions';

interface ConfigRowProps {
  plantingWeekDays: PlantingWeekDays;
  dateColumns: string[];
  append: UseFieldArrayAppend<FormProps, 'configurations'>;
  availableVarieties: StageRecipe[];
}
export const ConfigRow = ({
  plantingWeekDays,
  dateColumns,
  append,
  availableVarieties
}: ConfigRowProps) => {
  const [newConfig, setNewConfig] = useState<Partial<HubDemandConfig>>({
    stageRecipe: undefined,
    dailyConfig: undefined
  });
  const [startDate] = useWatch({
    name: ['startDate']
  });
  const [isPlantingDatePast, setIsPlantingDatePast] = useState(false);

  useEffect(() => {
    setNewConfig(generateDefaultHubConfig(plantingWeekDays));
  }, [plantingWeekDays]);

  useEffect(() => {
    if (newConfig.stageRecipe) {
      const { duration } = newConfig.stageRecipe;
      const startDateObject = offsetDateWithTimezone(startDate);
      const plantingDate = subDays(startDateObject, duration);
      const isPlantingDatePast = isPast(plantingDate) || isToday(plantingDate);

      setIsPlantingDatePast(isPlantingDatePast);
    }
  }, [newConfig.stageRecipe, startDate]);

  const addNewConfig = () => {
    if (!newConfig.stageRecipe) return;
    append(newConfig);
    setNewConfig(generateDefaultHubConfig(plantingWeekDays));
  };

  const onTrayUpdated = ([date, value]: [string, number]) => {
    setNewConfig({
      ...newConfig,
      dailyConfig: {
        ...newConfig.dailyConfig,
        [date]: value
      }
    });
  };
  const onNewVarietyUpdate = (stageRecipe: StageRecipe) => {
    setNewConfig({
      ...newConfig,
      stageRecipe
    });
  };

  const hasNoTrayInput =
    newConfig?.dailyConfig && Object.values(newConfig.dailyConfig).every(tray => !tray);

  return (
    <ConfigRowWrapper
      onKeyPress={event => {
        if (event.key === 'Enter' && !isPlantingDatePast && !hasNoTrayInput) {
          addNewConfig();
        }
      }}
    >
      <ActionCell>
        {isPlantingDatePast ? (
          <IconTooltip
            styles={{
              backgroundColor: 'rgba(0, 0, 0, 0.7);'
            }}
            placement="top"
            body="Cannot add variety because planting date is in the past."
          />
        ) : (
          <ActionIcon
            disabled={!startDate || !newConfig.stageRecipe || hasNoTrayInput}
            onClick={() => addNewConfig()}
          >
            add_circle
          </ActionIcon>
        )}
      </ActionCell>
      <VarietyCell>
        <VarietySelect
          availableVarieties={availableVarieties}
          onNewVarietyUpdate={onNewVarietyUpdate}
          newConfig={newConfig}
        />
      </VarietyCell>
      {dateColumns.map(date => {
        const iosDate = plantingWeekDays[date];
        return (
          <Cell key={date}>
            <TrayInput
              onTrayUpdated={onTrayUpdated}
              date={iosDate}
              value={newConfig?.dailyConfig?.[iosDate] || 0}
            />
          </Cell>
        );
      })}
    </ConfigRowWrapper>
  );
};
