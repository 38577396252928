import { DsButton, DsCard } from '@infarm/design-system-react';
import styled from 'styled-components';

export const TodayDate = styled.p`
  color: #e00028 !important;

  &::before {
    content: '● ';
  }
`;

export const DateButtons = styled.div`
  white-space: nowrap;
  text-align: end;
`;

export const TodayButton = styled(DsButton)`
  margin: 0 16px;
`;

export const Section = styled.section`
  margin-top: 24px;
  padding-top: 24px;
  margin-bottom: 24px;
`;

export const SectionTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Card = styled(DsCard)`
  height: 150px;
`;

export const CardText = styled.p`
  display: block;
  margin-top: 1.83em;
`;

export const WorkstationsCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

export const CardName = styled.h2`
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1em;
`;
