import { FarmModel, OperationalFarm } from '../../../../types';
import { generateDaysOfWeekISO } from '../../../../utils/date-functions';
import { NurseryMode } from '../../../constants';
import { LocationWaveByDate, NurseryChartGroupByDate } from '../types';

export const getNurseryMaxCapacity = (operationalFarms: OperationalFarm[]) => {
  const nurseryMaxCapacity = { [NurseryMode.LIGHT]: 0, [NurseryMode.DARK]: 0 };
  if (!operationalFarms.length) {
    return undefined;
  }
  operationalFarms?.forEach((farm: OperationalFarm) => {
    if (farm.model === FarmModel.ACRE) {
      if (farm.nurseryBenches && farm.traysPerBench) {
        nurseryMaxCapacity[NurseryMode.LIGHT] += farm.nurseryBenches * farm.traysPerBench;
      }
    }

    if (farm.model === FarmModel.GROWTH_CHAMBER) {
      if (farm.lightGerminationBenches && farm.traysPerBench) {
        nurseryMaxCapacity[NurseryMode.LIGHT] += farm.lightGerminationBenches * farm.traysPerBench;
      }
      if (farm.darkGerminationBenches && farm?.traysPerBench) {
        nurseryMaxCapacity[NurseryMode.DARK] += farm.darkGerminationBenches * farm.traysPerBench;
      }
    }
  });

  return nurseryMaxCapacity;
};

export const formatNurseryChartData = (
  data: LocationWaveByDate[],
  nurseryMaxCapacity: { [key in NurseryMode]: number },
  selectedDay: Date
) => {
  const wavesByDate = data.reduce((waveByDate, wave) => {
    waveByDate[wave.date] = wave.waveGroup;
    return waveByDate;
  }, {} as NurseryChartGroupByDate);

  const selectedDays = generateDaysOfWeekISO(selectedDay);

  const chartData = selectedDays.map(date => {
    const utilisedDark = wavesByDate[date]?.[NurseryMode.DARK].totalNumberOfTrays || 0;
    const unutilisedDark = nurseryMaxCapacity[NurseryMode.DARK] - utilisedDark;

    const utilisedLight = wavesByDate[date]?.[NurseryMode.LIGHT].totalNumberOfTrays || 0;
    const unutilisedLight = nurseryMaxCapacity[NurseryMode.LIGHT] - utilisedLight;

    return {
      date,
      [NurseryMode.DARK]: {
        utilised: utilisedDark,
        unutilised: unutilisedDark
      },
      [NurseryMode.LIGHT]: {
        utilised: utilisedLight,
        unutilised: unutilisedLight
      }
    };
  });

  return chartData;
};
