import React, { useState } from 'react';
import {
  DateControls,
  Month,
  ToggleButton,
  ToggleWrapper,
  Week
} from '../../pages/LocationDetail/style';
import {
  TrackVisualizationButton,
  TrackVisualizationLinkWrapper,
  TrackVisualizationModalBody,
  TrackVisualizationModalContainer,
  TrackVisualizationModalDateControls,
  FarmName,
  FarmShortId,
  ModelSubtitle,
  Row,
  TrackVisualizationProductionToggle
} from './styles';
import { DsButton, DsRow } from '@infarm/design-system-react';
import { toIsoDate, formatFullDate } from '../../utils/date-functions';
import { OperationalFarm } from '../../types';

interface TrackVisualizationModalParams {
  farm: OperationalFarm;
  dateInterval: number;
}
export const TrackVisualizationModal = (params: TrackVisualizationModalParams) => {
  const [date, setDate] = useState(() => new Date());
  const [isModalOpen, setModalVisibility] = useState(false);
  const [displayNurseryTracks, toggleNurseryTracks] = useState(false);

  const getToDate = (): Date => {
    const currentDate = new Date(date);
    return new Date(currentDate.setDate(currentDate.getDate() + 30));
  };

  const clickToday = () => {
    setDate(new Date());
  };

  const setNDaysBefore = () => {
    const currentDate = new Date(date);
    setDate(new Date(currentDate.setDate(currentDate.getDate() - params.dateInterval)));
  };

  const setNDaysAfter = () => {
    const currentDate = new Date(date);
    setDate(new Date(currentDate.setDate(currentDate.getDate() + params.dateInterval)));
  };
  const openModal = () => {
    setModalVisibility(true);
  };
  const closeModal = () => {
    setModalVisibility(false);
  };
  return (
    <TrackVisualizationLinkWrapper>
      <TrackVisualizationButton icon={'stacked_bar_chart'} onClick={() => openModal()} />
      <TrackVisualizationModalContainer
        open={isModalOpen}
        hideCloseButton={false}
        onClosed={() => closeModal()}
      >
        <TrackVisualizationModalBody>
          <Row>
            <FarmName>{params.farm.name}</FarmName>
            <FarmShortId>{params.farm.shortId}</FarmShortId>
          </Row>
          <DsRow>
            <ModelSubtitle>Track visualization</ModelSubtitle>
          </DsRow>

          <TrackVisualizationModalDateControls>
            <DateControls>
              <DsButton label="Today" onClick={clickToday} />
              <DsButton icon="navigate_before" flat onClick={setNDaysBefore} />
              <DsButton icon="navigate_next" flat onClick={setNDaysAfter} />
              <Month>From {formatFullDate(date)}</Month>
              <Week>To {formatFullDate(getToDate())}</Week>
            </DateControls>
            {!!params.farm.nurseryBenches && (
              <ToggleWrapper>
                <TrackVisualizationProductionToggle>
                  <ToggleButton
                    primary={displayNurseryTracks}
                    onClick={() => toggleNurseryTracks(true)}
                  >
                    Nursery
                  </ToggleButton>
                  <ToggleButton
                    primary={!displayNurseryTracks}
                    onClick={() => toggleNurseryTracks(false)}
                  >
                    Production
                  </ToggleButton>
                </TrackVisualizationProductionToggle>
              </ToggleWrapper>
            )}
          </TrackVisualizationModalDateControls>
          <track-visualization
            farm-uuid={params.farm.uuid}
            activated={isModalOpen}
            from-date={toIsoDate(date)}
            day-interval={30}
            nursery-tracks={displayNurseryTracks}
          />
        </TrackVisualizationModalBody>
      </TrackVisualizationModalContainer>
    </TrackVisualizationLinkWrapper>
  );
};
