import {
  isToday,
  formatFullDate,
  toIsoDate,
  offsetDateWithTimezone,
  addDays
} from '../../utils/date-functions';
import { SEEDING_OVERVIEW } from './queries';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { PageHeaderRow, HeaderDate, SectionTitle, Section, TableCaptionRow } from './style';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { isValid } from 'date-fns';
import { NurseryTaskType } from '../InhubWorkstation/constants';
import { displayNurseryTaskType } from '../InhubWorkstation/utils';
import { Divider } from '@material-ui/core';
import { TasksForDate } from './types';
import { Error } from '../../components/Error';
import { OverviewTable } from './OverviewTable';
import { DownloadNiASeedingPlan } from './DownloadNiASeedingPlan';
import { DsButton, DsPlaceholder, DsRow } from '@infarm/design-system-react';
import { ExcessDemand } from '../../types';
import { getStageRecipeTableData, getTrayTableData } from './utils';
import { DateButtons, TodayButton } from '../InhubWorkstation/style';
import { useNavigate } from 'react-router-dom';
import { OperationOverview } from './NiAOperationOverview';
import { SeedingPlanStatus } from '../../components/SeedingPlanStatus';
import { CommitButton } from './CommitButton';
import { hasPermission } from '../../utils/user';

export const InhubNurseryTasks = () => {
  const { locationUuid, date, nurseryTaskType } = useParams();
  const batchType = nurseryTaskType! as NurseryTaskType;
  const [getOverview, { data, error, loading }] = useLazyQuery(SEEDING_OVERVIEW);
  const [currentDate, setCurrentDate] = useState(offsetDateWithTimezone(date || new Date()));

  useEffect(() => {
    const currDate = offsetDateWithTimezone(date || '');
    if (!locationUuid || !isValid(currDate) || !nurseryTaskType) return;
    const variables = {
      uuid: locationUuid,
      date: toIsoDate(currDate),
      batchType: nurseryTaskType.toUpperCase(),
      skipRest: nurseryTaskType !== NurseryTaskType.SEEDING
    };
    getOverview({ variables });
  }, [date, locationUuid, nurseryTaskType, getOverview]);

  const canCommit = useMemo(() => {
    const latestDate = addDays(toIsoDate(new Date()), 2);
    if (latestDate < currentDate) return false;
    return hasPermission(['nursery_grower', 'nursery_supervisor', 'super_admin']);
  }, [currentDate]);

  const fullDate = formatFullDate(currentDate);
  const urlDate = toIsoDate(currentDate);
  const tasksForDate = data?.location?.tasksForDate as TasksForDate;
  const allTaskUuids = tasksForDate?.tasks?.map(task => task.uuid);
  const excessDemands = data?.excessDemands as ExcessDemand[];

  const [varieties, excessVarieties] = getStageRecipeTableData(
    tasksForDate?.byStageRecipe ?? [],
    excessDemands ?? []
  );
  const trayTableData = getTrayTableData(tasksForDate?.byTrayType, excessDemands);

  const totalNumberOfTrays =
    tasksForDate?.totalNumberOfTrays +
    excessDemands?.reduce((sum, { totalNumberOfTrays }) => sum + (totalNumberOfTrays || 0), 0);

  const navigate = useNavigate();

  const setDate = (date: Date) => {
    setCurrentDate(date);
    const newDate = toIsoDate(date);
    navigate(`/locations/${locationUuid}/daily/${newDate}/workstations/nursery/${batchType}`, {
      replace: true
    });
  };

  const selectToday = () => {
    setDate(new Date());
  };

  const selectPreviousDay = () => {
    setDate(addDays(currentDate, -1));
  };

  const selectNextDay = () => {
    setDate(addDays(currentDate, 1));
  };

  const getSeedingContent = () => {
    if (loading) return <DsPlaceholder />;
    if (error) return <Error title="" error={error} />;
    return (
      <>
        <Section>
          <SectionTitle>Overview</SectionTitle>
          <Divider />
        </Section>
        <DsRow>
          <div>
            <TableCaptionRow>
              <h4>Varieties to be seeded</h4>
              {canCommit && (
                <CommitButton
                  statuses={tasksForDate?.statuses}
                  taskUuids={allTaskUuids}
                  commitAll
                ></CommitButton>
              )}
            </TableCaptionRow>
            <OverviewTable
              rows={varieties}
              excessRows={excessVarieties}
              totalNumberOfTrays={totalNumberOfTrays}
              thead={['Variety', 'Amount of Trays']}
              showCommitButton={canCommit}
              stretch
            />
          </div>
          <div>
            <TableCaptionRow>
              <h4>Trays req. for seeding</h4>
            </TableCaptionRow>
            <OverviewTable
              rows={trayTableData}
              totalNumberOfTrays={totalNumberOfTrays}
              thead={['Tray Type', 'Amount of Trays']}
            />
          </div>
        </DsRow>
      </>
    );
  };

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations',
            link: `/locations`,
            dataCy: 'InhubWorkstation__goBackThreeLevels'
          },
          {
            text: data?.location?.name,
            link: `/locations/${locationUuid}/`,
            dataCy: 'InhubWorkstation__goBackTwoLevel'
          },
          {
            text: 'Workstations',
            link: `/locations/${locationUuid}/daily/${urlDate}`,
            dataCy: 'InhubWorkstation__goBackOneLevel'
          },
          {
            text: displayNurseryTaskType(batchType)
          }
        ]}
      />
      <PageHeaderRow>
        <div className="span-8">
          <h1>
            {displayNurseryTaskType(batchType)}{' '}
            <SeedingPlanStatus statuses={tasksForDate?.statuses} />
          </h1>
          <HeaderDate today={isToday(currentDate)}>{fullDate}</HeaderDate>
        </div>
        {batchType === NurseryTaskType.SEEDING && <DownloadNiASeedingPlan />}
        {batchType === NurseryTaskType.ACRE && (
          <DateButtons className="span-2">
            <TodayButton label="Today" onClick={selectToday}></TodayButton>
            <DsButton icon="navigate_before" flat onClick={selectPreviousDay}></DsButton>
            <DsButton icon="navigate_next" flat onClick={selectNextDay}></DsButton>
          </DateButtons>
        )}
      </PageHeaderRow>
      {batchType === NurseryTaskType.SEEDING && getSeedingContent()}
      {batchType === NurseryTaskType.ACRE && <OperationOverview />}
    </>
  );
};
