import { DsButton } from '@infarm/design-system-react';
import { useState } from 'react';
import { NurserySystemsButton } from '../../style';
import { NurserySystemsDaysRow } from './NurserySystemsDaysRow';
import { NurserySystemTable } from './NurserySystemTable';
import { EmptyList } from '../style';
import { NurseryMode } from '../../../constants';
import { LocationWaveByFarm } from '../types';
import { hasWaveNurseryMode } from '../utils';

interface NurserySystemsDetailsProps {
  selectedNurseryModes: NurseryMode[];
  waveByFarms: LocationWaveByFarm[];
  date: Date;
}

export const NurserySystemsDetails = (props: NurserySystemsDetailsProps) => {
  const { waveByFarms, date, selectedNurseryModes } = props;
  const [shouldDisplayInactiveSystems, setInactiveSystemsVisibility] = useState(false);
  const toggleInactiveSystemsVisibility = () => {
    setInactiveSystemsVisibility(curr => !curr);
  };

  const activeWaveByFarms = waveByFarms.filter(waveByFarm =>
    hasWaveNurseryMode(waveByFarm.waveGroup, selectedNurseryModes)
  );
  const inactiveWaveByFarms = waveByFarms.filter(
    waveByFarm => !hasWaveNurseryMode(waveByFarm.waveGroup, selectedNurseryModes)
  );

  const numberOfInactiveFarms = waveByFarms.length - activeWaveByFarms.length;
  const toggleInactiveFarmsButtonLabel = shouldDisplayInactiveSystems
    ? `Hide Unutilised Systems (${numberOfInactiveFarms})`
    : `Show Unutilised Systems (${numberOfInactiveFarms})`;

  return (
    <>
      <NurserySystemsDaysRow date={date} />
      {activeWaveByFarms.map(waveByFarm => (
        <NurserySystemTable
          waveByFarm={waveByFarm}
          date={date}
          key={waveByFarm.farm.uuid}
          selectedNurseryModes={selectedNurseryModes}
        />
      ))}
      {shouldDisplayInactiveSystems &&
        inactiveWaveByFarms.map(waveByFarm => {
          return (
            <NurserySystemTable
              waveByFarm={waveByFarm}
              date={date}
              key={waveByFarm.farm.uuid}
              selectedNurseryModes={selectedNurseryModes}
            />
          );
        })}
      {!activeWaveByFarms.length && !shouldDisplayInactiveSystems && (
        <EmptyList lowOpacity rounded>
          There is no active nursery system
        </EmptyList>
      )}
      {!!inactiveWaveByFarms.length && (
        <NurserySystemsButton>
          <DsButton
            onClick={toggleInactiveSystemsVisibility}
            label={toggleInactiveFarmsButtonLabel}
            secondary
          />
        </NurserySystemsButton>
      )}
    </>
  );
};
