import { PlantingScheduleTableProps } from '.';
import { TableRow } from './TableRow';
import { PlantingSchedule } from '../../../types';
import { Tr, Td, Tbody } from '../styles';

interface TablebbodyProps extends PlantingScheduleTableProps {
  loading: boolean;
  plantingSchedules: PlantingSchedule[];
  isAcreFarm: boolean;
}

export const TableBody = (props: TablebbodyProps) => {
  const { loading, plantingSchedules, farmCycle, isWriteAllowed, isAcreFarm } = props;

  if (loading)
    return (
      <tbody>
        <Tr>
          <Td>Loading....</Td>
        </Tr>
      </tbody>
    );
  if (!plantingSchedules?.length)
    return (
      <tbody>
        <Tr>
          <Td>No planting schedules are available at the moment</Td>
        </Tr>
      </tbody>
    );
  return (
    <Tbody data-cy="PlantingScheduleTable___tableBody">
      {plantingSchedules.map((plantingSchedule: PlantingSchedule) => (
        <TableRow
          key={plantingSchedule.uuid}
          {...plantingSchedule}
          isWriteAllowed={isWriteAllowed}
          farmCycle={farmCycle}
          isAcreFarm={isAcreFarm}
        />
      ))}
    </Tbody>
  );
};
