import { TooltipProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { formatDayWithYearMonth } from '../../../../../utils/date-functions';
import {
  GerminationWrapper,
  Seperator,
  Tooltip,
  TooltipHead,
  TooltipSubText,
  TooltipValue
} from '../style';
import { ChartDataKeys } from '../types';

type FoundUtilisationKeys = {
  [key in ChartDataKeys]: number;
};

const findUtilisation = (payloads: Payload<number, ChartDataKeys>[]) => {
  return payloads.reduce((foundUtilisation, { name, value }) => {
    if (name) {
      foundUtilisation[name] = value || 0;
    }
    return foundUtilisation;
  }, {} as FoundUtilisationKeys);
};

export const CustomTooltip = ({ active, payload, label }: TooltipProps<number, ChartDataKeys>) => {
  if (active && payload?.length) {
    const foundUtilisation = findUtilisation(payload);

    const lightDemand = foundUtilisation[ChartDataKeys.LIGHT_UTILISED];
    const lightSurplus = foundUtilisation[ChartDataKeys.LIGHT_UNUTILISED];

    const darkDemand = foundUtilisation[ChartDataKeys.DARK_UTILISED];
    const darkSurplus = foundUtilisation[ChartDataKeys.DARK_UNUTILISED];

    return (
      <Tooltip>
        <TooltipHead>{formatDayWithYearMonth(label)}</TooltipHead>
        {!!lightSurplus && (
          <GerminationWrapper>
            <TooltipSubText>Light Germination</TooltipSubText>
            <TooltipValue>• Utilised: {lightDemand}</TooltipValue>
            <TooltipValue>• Unutilised: {lightSurplus}</TooltipValue>
          </GerminationWrapper>
        )}
        {!!lightSurplus && !!darkSurplus && <Seperator />}
        {!!darkSurplus && (
          <GerminationWrapper>
            <TooltipSubText>Dark Germination</TooltipSubText>
            <TooltipValue>• Utilised: {darkDemand}</TooltipValue>
            <TooltipValue>• Unutilised: {darkSurplus}</TooltipValue>
          </GerminationWrapper>
        )}
      </Tooltip>
    );
  }

  return null;
};
