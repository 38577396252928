import { DsButton } from '@infarm/design-system-react';
import { useState } from 'react';
import { sortCallback } from '../../../utils';
import { NurseryInAcreTasksForDate } from '../types';
import { BenchContainer } from './BenchContainer';
import { Container, FarmName, BenchQuantity, ChevronWrapper, FarmWrapper } from './style';

export const FarmContainer = ({ operationalFarm, taskGroup }: NurseryInAcreTasksForDate) => {
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpand = () => setExpanded(curr => !curr);

  return (
    <FarmWrapper>
      <Container key={operationalFarm.uuid}>
        <FarmName>{operationalFarm.name}</FarmName>
        <BenchQuantity>{taskGroup.byBench.length} Bench(es)</BenchQuantity>
        <ChevronWrapper>
          <DsButton icon={isExpanded ? 'expand_less' : 'expand_more'} flat onClick={toggleExpand} />
        </ChevronWrapper>
      </Container>
      {isExpanded && (
        <div>
          {[...taskGroup.byBench]
            .sort(({ bench }, { bench: benchB }) => sortCallback(bench.name!, benchB.name!))
            .map(({ bench, taskGroup }) => {
              return <BenchContainer bench={bench} taskGroup={taskGroup} key={bench.uuid} />;
            })}
        </div>
      )}
    </FarmWrapper>
  );
};
