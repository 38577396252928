import styled from 'styled-components';
import { ApolloError } from '@apollo/client';
import { getErrorMessage } from '../../utils/errors';

export const ErrorTitle = styled.span`
  color: #e00028;
  font-weight: 500;
  font-size: 1.2rem;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
`;

export const ErrorMessage = styled.span`
  font-weight: 400;
  color: #808080;
`;

interface ErrorProps {
  error: ApolloError;
  title?: string;
}

export const Error = ({ error, title }: ErrorProps) => {
  const errorTitle = title || 'Error';
  return (
    <ErrorWrapper>
      <ErrorTitle>{errorTitle}</ErrorTitle>
      <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>
    </ErrorWrapper>
  );
};
