import { DsButton } from '@infarm/design-system-react';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_FARMS_LIST } from '../queries';
import { OperationalFarm } from '../../../types';
import { DsSelect } from '../../../components/Select';
import { ModalTypes } from '../types';
import { capitalize } from 'lodash';
import { sortFarms } from '../../../utils/farm';
import { Modal as MaterialModal } from '@material-ui/core';
import { ModalHeading, ModalText, ModalButtonsRow } from '../style';
import { FarmDetailModalWrapper } from '../../LocationForm/styles';

interface ModalProps {
  isModalOpen: boolean;
  onModalClosed: () => void;
  modalText: string;
  modalSecondaryAction: () => void;
  modalPrimaryAction: (nextAcreUuid?: string | undefined) => void;
  heading: string;
  secondaryLabel: string | undefined;
  primaryLabel: string;
  modalType?: ModalTypes;
  subHeading?: string;
}

export const Modal = (props: ModalProps) => {
  const isContinueModal =
    props.modalType === ModalTypes.CONTINUE || props.modalType === ModalTypes.GENERATE;
  const [nextAcreUuid, setNextAcreUuid] = useState('');
  const { locationUuid } = useParams();
  const { data, loading } = useQuery(GET_FARMS_LIST, {
    variables: {
      locationUuid
    },
    skip: !isContinueModal
  });

  const farms: OperationalFarm[] = data?.location?.operationalFarms || [];
  const {
    isModalOpen,
    onModalClosed,
    modalText,
    heading,
    secondaryLabel,
    primaryLabel,
    subHeading,
    modalSecondaryAction,
    modalPrimaryAction,
    modalType
  } = props;

  const selectItems = sortFarms(farms)?.map(({ uuid, name }) => ({ text: name, value: uuid }));

  const onPrimaryBtnClick = () => {
    if (isContinueModal) {
      modalPrimaryAction(nextAcreUuid);
    } else {
      modalPrimaryAction();
    }
  };

  return (
    <MaterialModal open={isModalOpen} onClose={onModalClosed}>
      <FarmDetailModalWrapper>
        <ModalHeading>{heading}</ModalHeading>
        <ModalHeading>{subHeading || ''}</ModalHeading>
        <ModalText>{modalText || ''}</ModalText>
        <div>
          {isContinueModal && (
            <DsSelect
              fullWidth
              data-cy="PlantingSchedule___NextAcreSelect"
              label="Create new schedule for"
              loading={loading}
              onChange={({ target }: { target: HTMLSelectElement }) => {
                setNextAcreUuid(target?.value);
              }}
              items={selectItems}
              value={nextAcreUuid}
            />
          )}
        </div>
        <ModalButtonsRow>
          {secondaryLabel && (
            <DsButton
              data-cy={`PlantingSchedule___${capitalize(modalType)}ModalSecondaryBtn`}
              label={secondaryLabel}
              onClick={modalSecondaryAction}
            />
          )}
          <DsButton
            data-cy={`PlantingSchedule___${capitalize(modalType)}ModalPrimaryBtn`}
            label={primaryLabel}
            primary
            onClick={onPrimaryBtnClick}
            disabled={isContinueModal && !nextAcreUuid}
          />
        </ModalButtonsRow>
      </FarmDetailModalWrapper>
    </MaterialModal>
  );
};
