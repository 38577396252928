import { FarmError, FarmItem } from './types';
import ct from 'countries-and-timezones';
import { farmValidationMsg } from './constants';
import { sortCallback } from '../../utils';

export const getValidationError = (type: FarmError, errorList: FarmError[]): string => {
  return errorList.includes(type) ? farmValidationMsg[type] : '';
};

export const timezones = Object.values(ct.getAllTimezones())
  .filter(tz => tz.countries.length)
  .sort((first, second) => first.utcOffset - second.utcOffset);

export const sortFarmItems = (farmItems: FarmItem[]): FarmItem[] =>
  farmItems.sort((a, b) => sortCallback(a.name, b.name));
