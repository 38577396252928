import React from 'react';
import { Controller } from 'react-hook-form';
import { DsSelect, DsSelectProps } from '.';

interface FormInputProps extends DsSelectProps {
  name: string;
  control: any;
}

export const FormInputDropdown: React.FC<FormInputProps> = ({ name, control, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <DsSelect
          validationMessage={error?.message}
          onBlur={field.onBlur}
          onChange={field.onChange}
          value={field.value}
          {...props}
        />
      )}
    />
  );
};
