import React from 'react';
import Xarrow from 'react-xarrows';
import Leaf from '../../../../../src/assets/Leaf.svg';
import line from '../../../../../src/assets/line.svg';
import {
  BlackRow,
  MondayRightArrowEnd,
  MondayLeaf,
  EndOfColumn,
  MondayRightArrow,
  MondayDashArrowStart,
  HeaderCell,
  MondayHeaderCell
} from '../../style';
import { getWeekDays } from '../../constants';
import { WeekDays } from '../../../../types';
import { XArrowHeads } from '../../types';

export const MondayHeader = ({
  dashness,
  isHubDemandForm
}: {
  dashness: boolean;
  isHubDemandForm?: boolean;
}) => {
  const weekDays = getWeekDays();

  return (
    <BlackRow key="mondayHeader">
      <MondayHeaderCell className="span-3 bold" colSpan={2} />

      {weekDays.map(weekDay => {
        const isLastColumn = weekDay === WeekDays.sunday;
        const shouldRenderDashedArrowBeggining = weekDay === WeekDays.monday;
        const shouldRenderRightArrowEnding = isLastColumn;

        if (shouldRenderRightArrowEnding) {
          return (
            <HeaderCell className="span-1" key={weekDay}>
              <EndOfColumn>
                <MondayRightArrowEnd id={XArrowHeads.RIGHT_ARROW_END} />
              </EndOfColumn>

              <Xarrow
                start={XArrowHeads.RIGHT_ARROW_START}
                end={XArrowHeads.RIGHT_ARROW_END}
                color={'white'}
                strokeWidth={2}
              />
            </HeaderCell>
          );
        } else {
          if (shouldRenderDashedArrowBeggining) {
            return (
              <HeaderCell className="span-1" key={weekDay}>
                <MondayLeaf isHubDemandForm={isHubDemandForm}>
                  <img src={isHubDemandForm ? line : Leaf} alt="Leaf" />
                </MondayLeaf>
                <MondayDashArrowStart
                  isHubDemandForm={isHubDemandForm}
                  id={XArrowHeads.DASH_ARROW_START}
                />

                <EndOfColumn>
                  <MondayRightArrow id={XArrowHeads.RIGHT_ARROW_START} />
                </EndOfColumn>
                <Xarrow
                  start={XArrowHeads.DASH_ARROW_START}
                  end={XArrowHeads.RIGHT_ARROW_START}
                  color={'white'}
                  strokeWidth={2}
                  dashness={dashness}
                  showHead={false}
                />
              </HeaderCell>
            );
          }
          return <HeaderCell className="span-1" key={weekDay} />;
        }
      })}
    </BlackRow>
  );
};
