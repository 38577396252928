import styled from 'styled-components';
import { Row } from '../style';

export const SpacerVertical = styled.div`
  width: 0;
  height: 18px;
`;

export const EmptyList = styled(Row)`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 16px;
`;
