import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const IconContainer = styled.div`
  height: 48px;
  width: 48px;
  margin-inline-end: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Image = styled.img`
  max-height: 48px;
  max-width: 48px;
  height: auto;
  width: auto;
`;

const Name = styled.span`
  color: #000000;
  font-weight: 400;
`;

const Subtitle = styled(Name)`
  font-size: 12px;
  color: #767676;
`;

const LabelsRow = styled.div`
  display: flex;
`;

interface ImageLabelProps {
  image: any;
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  alt: string;
}

export const ImageLabel = (props: ImageLabelProps) => (
  <Wrapper>
    <IconContainer>
      <Image src={props.image} alt={props.alt} />
    </IconContainer>
    <LabelContainer>
      <Name>{props.title}</Name>
      <LabelsRow>
        {typeof props.subTitle === 'string' ? (
          <Subtitle>{props.subTitle}</Subtitle>
        ) : (
          props.subTitle
        )}
      </LabelsRow>
    </LabelContainer>
  </Wrapper>
);
