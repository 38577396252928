import { NurseryMode } from '../../../constants';
import { ChartDataKeys } from './types';

export const chartStaticsData = [
  {
    dataKey: ChartDataKeys.DARK_UTILISED,
    stackId: 'a',
    fill: '#80bd46',
    nurseryMode: NurseryMode.DARK
  },
  {
    dataKey: ChartDataKeys.DARK_UNUTILISED,
    stackId: 'a',
    fill: '#009dff',
    nurseryMode: NurseryMode.DARK
  },

  {
    dataKey: ChartDataKeys.LIGHT_UTILISED,
    stackId: 'b',
    fill: '#34C759',
    nurseryMode: NurseryMode.LIGHT
  },
  {
    dataKey: ChartDataKeys.LIGHT_UNUTILISED,
    stackId: 'b',
    fill: '#007AFF',
    nurseryMode: NurseryMode.LIGHT
  }
];

export const legendData = [
  {
    name: 'Utilised',
    fill: '#80bd46'
  },
  {
    name: 'Unutilised',
    fill: '#009dff'
  },
  // {
  //   name: 'Excess Demand',
  //   fill: '#FF3B30'
  // },
  {
    name: 'Max. Nursery Capacity',
    fill: '#FF3B30',
    line: true
  }
];
