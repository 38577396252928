import { formatDate } from '../../../utils/date-functions';
import { ModalType } from '../constants';
import { AnchorLink, StyledLink } from '../style';

export const ValidationModalText = ({
  modalType,
  startDate,
  locationUuid
}: {
  modalType: ModalType;
  startDate?: string;
  locationUuid?: string;
}) => {
  if (modalType === ModalType.VALIDATING) {
    return "Please don't close this screen as we might need you if there's a capacity clash. It can take up to 30 minutes.";
  }

  if (modalType === ModalType.SUBMIT) {
    return `The new entered demand will replace the old demand automatically on the scheduled start date.
      ${formatDate(startDate!)}  Do you want to submit this new demand now?`;
  }

  if (modalType.includes('error')) {
    return (
      <>
        Please try again or raise a{' '}
        <AnchorLink
          href="https://infarm.atlassian.net/servicedesk/customer/portal/1/group/4"
          rel="noreferrer"
          target="_blank"
        >
          support desk ticket
        </AnchorLink>
        .
      </>
    );
  }

  if (modalType === ModalType.SUCCESS) {
    return (
      <>
        {`Hub demand starts at ${formatDate(
          startDate!
        )} has been successfully created and submitted. `}
        You can check the plants allocation later in{' '}
        <StyledLink state={{ tabIndex: 1 }} to={`/locations/${locationUuid}`}>
          nursery overview
        </StyledLink>{' '}
        and <StyledLink to={`/locations/${locationUuid}`}>plant production</StyledLink>.
      </>
    );
  }

  return '';
};
