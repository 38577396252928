import React from 'react';
import Xarrow from 'react-xarrows';
import Leaf from '../../../../../src/assets/Leaf.svg';
import line from '../../../../../src/assets/line.svg';
import {
  BlackRow,
  StyledSpan,
  HeaderCell,
  SundayFirstArrowEnd,
  SundayDashed,
  SundayLeaf,
  EndOfColumn,
  SundayDashArrowStart
} from '../../style';
import { getWeekDays } from '../../constants';
import { WeekDays } from '../../../../types';
import { XArrowHeads } from '../../types';

export const SundayHeader = ({
  dashness,
  isHubDemandForm
}: {
  dashness: boolean;
  isHubDemandForm?: boolean;
}) => {
  const weekDays = getWeekDays();

  return (
    <BlackRow key="SundayHeader">
      <HeaderCell className="span-3 bold" colSpan={2}>
        <StyledSpan id={XArrowHeads.LEFT_ARROW_START} />
      </HeaderCell>

      {weekDays.map(weekDay => {
        const shouldRenderLeftArrowEnding = weekDay === WeekDays.saturday;
        const shouldRenderSunday = weekDay === WeekDays.sunday;

        if (shouldRenderLeftArrowEnding) {
          return (
            <HeaderCell className="span-1" key={weekDay}>
              <EndOfColumn>
                <SundayLeaf isHubDemandForm={isHubDemandForm}>
                  <img src={isHubDemandForm ? line : Leaf} alt="Leaf" />
                </SundayLeaf>
              </EndOfColumn>

              <EndOfColumn>
                <SundayDashArrowStart
                  isHubDemandForm={isHubDemandForm}
                  id={XArrowHeads.DASH_ARROW_START}
                />
              </EndOfColumn>

              <SundayFirstArrowEnd id={XArrowHeads.LEFT_ARROW_END} />
              <Xarrow
                start={XArrowHeads.LEFT_ARROW_START}
                end={XArrowHeads.LEFT_ARROW_END}
                color={'white'}
                strokeWidth={2}
              />
            </HeaderCell>
          );
        } else {
          if (shouldRenderSunday) {
            return (
              <HeaderCell className="span-1" key={weekDay}>
                <EndOfColumn>
                  <SundayDashed id={XArrowHeads.DASH_ARROW_END} />
                </EndOfColumn>

                <Xarrow
                  start={XArrowHeads.DASH_ARROW_START}
                  end={XArrowHeads.DASH_ARROW_END}
                  color={'white'}
                  strokeWidth={2}
                  dashness={dashness}
                  showHead={false}
                />
              </HeaderCell>
            );
          }
          return <HeaderCell className="span-1" key={weekDay} />;
        }
      })}
    </BlackRow>
  );
};
