import {
  CellSpan,
  Image,
  InfoWrapper,
  StageRecipeDetail,
  StageRecipeInfo,
  StageRecipeName
} from '../style';
import varietyImage from '../../../../../assets/variety.png';
import { DataRow, LeftBorderdSpan, Row } from '../../../style';
import { generateDaysOfWeek, toIsoDate } from '../../../../../utils/date-functions';
import { FC, useEffect, useState } from 'react';
import { isToday } from 'date-fns';
import { WaveGroupByStageRecipe } from '../../types';
import { NurseryMode } from '../../../../constants';
import { getTraysByNurseryModes } from '../../utils';

interface StageRecipeRowProps {
  waveGroupsByStageRecipe: WaveGroupByStageRecipe;
  date: Date;
  selectedNurseryModes: NurseryMode[];
}

export const StageRecipeRow: FC<StageRecipeRowProps> = ({
  waveGroupsByStageRecipe,
  date,
  selectedNurseryModes
}) => {
  const [valuesWithWeekDate, setValuesWithWeekDate] = useState<{ value: number; weekDate: Date }[]>(
    []
  );

  useEffect(() => {
    const updateValuesWithWeekDate = () => {
      const weekDates = generateDaysOfWeek(date);
      const newValuesWithWeekDateForWeek = weekDates.map(weekDate => {
        const { waveGroup } =
          waveGroupsByStageRecipe.waveGroup.byDate?.find(
            waveGroupByDate => waveGroupByDate.date === toIsoDate(weekDate)
          ) ?? {};
        const totalNumberOfTrays = waveGroup
          ? getTraysByNurseryModes(waveGroup, selectedNurseryModes)
          : 0;

        return { value: totalNumberOfTrays, weekDate };
      });
      setValuesWithWeekDate(newValuesWithWeekDateForWeek);
    };
    updateValuesWithWeekDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveGroupsByStageRecipe, date]);

  return (
    <Row noTopBorder>
      <StageRecipeInfo className="span-3">
        <Image src={varietyImage} />
        <InfoWrapper>
          <StageRecipeName>{waveGroupsByStageRecipe.stageRecipe.name}</StageRecipeName>
          <StageRecipeDetail>
            {waveGroupsByStageRecipe.stageRecipe.internalCode || '(internal code missing)'}
          </StageRecipeDetail>
        </InfoWrapper>
      </StageRecipeInfo>
      <DataRow className="span-7">
        {valuesWithWeekDate.map(({ value, weekDate }, idx) => (
          <LeftBorderdSpan key={idx}>
            <CellSpan isBolder={isToday(weekDate)} isDarkBlack={isToday(weekDate)}>
              {value}
            </CellSpan>
          </LeftBorderdSpan>
        ))}
      </DataRow>
    </Row>
  );
};
