import { useState, useEffect, useMemo } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DsButton, DsPlaceholder } from '@infarm/design-system-react';
import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { SelectItem } from '../../components/Select';
import { AddAFarmSection } from './AddAFarmSection';
import {
  GET_LOCATION_GROUPS_LEAF_NODES,
  GET_CUSTOMERS,
  GET_NURSERIES,
  WRITE_LOCATION,
  GET_LOCATION
} from './queries';
import {
  StyledLocationForm,
  FormSectionHead,
  FormFooter,
  ThinLine,
  ModalButtonsRow
} from './styles';
import { FarmItem, LocationResponse, IFormInputs, LocationInput, Location } from './types';
import { ConfirmLocationModal } from './ConfirmLocationModal';
import { defaultValues } from './constants';
import { locationDropdownOptions, getLocationsByCategory, inHubCategory } from '../constants';
import { schema } from './validationSchema';
import { FormInputText } from '../../components/Textfield/FormInputText';
import { FormInputDropdown } from '../../components/Select/FormInputSelect';
import { getSelectData } from '../../utils';
import { FormInputTimezoneAutocomplete } from './TimeZoneAutocomplete/FormInputTimezoneAutocomplete';
import { LOCATION_DETAIL } from '../LocationDetail/queries';

export const LocationForm = () => {
  const { locationUuid } = useParams();
  const navigate = useNavigate();
  const [cancelFormConfirmation, setCancelFormConfirmation] = useState<boolean>(false);
  const [confirmationError, setConfirmationError] = useState<string>('');
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [locationGroup, setLocationGroup] = useState<SelectItem[]>([]);
  const [productionLocationData, setProductionLocationData] = useState<Location>();

  const { data: customerRawData, loading: isCustomerLoading } = useQuery(GET_CUSTOMERS);
  const { data: nurseryRawData, loading: isNurseryLoading } = useQuery(GET_NURSERIES);

  const {
    handleSubmit,
    control,
    formState: { isValid },
    getValues,
    setValue,
    trigger
  } = useForm<IFormInputs>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError'
  });

  const setValuesFromAPI = (data: { location: LocationResponse }) => {
    if (data?.location) {
      const { location } = data;
      const values: IFormInputs = {
        locationGroupUuid: location.locationGroup.uuid,
        category: location.category,
        name: location.name,
        customerUuid: location.customer.uuid,
        timezone: location.timezone,
        nurseryUuid: location.nursery?.uuid ?? '',
        farms: location.farms.map(
          ({ __typename, ...farm }: FarmItem & { __typename: string }) => farm
        )
      };
      if (location.category === inHubCategory.PRODUCTION) {
        setProductionLocationData({ name: location.name, uuid: location.uuid });
      }
      Object.keys(values).forEach((key: string) => {
        const value: string | FarmItem[] = values[key as keyof IFormInputs];
        setValue(key as keyof IFormInputs, value);
      });
    }
  };
  const [getLocationData, { loading: isLoadingLocationData }] = useLazyQuery(GET_LOCATION, {
    onCompleted: data => setValuesFromAPI(data)
  });
  const [addOrEditLocation, { loading }] = useMutation(WRITE_LOCATION, {
    refetchQueries: [{ query: LOCATION_DETAIL }, 'Location'],
    onCompleted: data => navigate(`/locations/${data.writeLocation.uuid || ''}`),
    onError: error => {
      setConfirmationError(error.message);
    }
  });
  const { loading: isLocationGroupLoading } = useQuery(GET_LOCATION_GROUPS_LEAF_NODES, {
    onCompleted: data => {
      if (data?.locationGroups.length) {
        const locationGroupSelectData = getSelectData(
          data.locationGroups,
          'uuid',
          'name',
          true,
          false
        );

        setLocationGroup(locationGroupSelectData);
      }
    }
  });

  useEffect(() => {
    if (locationUuid) {
      getLocationData({
        variables: {
          uuid: locationUuid
        }
      });
    }
  }, [locationUuid, getLocationData]);

  const customerSelectData = getSelectData(customerRawData?.customers, 'uuid', 'name', true, false);
  const customerInfarm = customerSelectData?.find((customer: any) => customer.text === 'infarm');
  const selectableNurseries =
    !!productionLocationData && !!nurseryRawData?.nurseries
      ? [...nurseryRawData?.nurseries, productionLocationData]
      : nurseryRawData?.nurseries;
  const nurserySelectData = getSelectData(selectableNurseries, 'uuid', 'name', true, false);

  const saveLocation = async (input: IFormInputs) => {
    const farms = input.farms.map(
      ({
        serialNumber,
        capacityInBenches,
        lightGerminationBenches,
        darkGerminationBenches,
        nurseryBenches,
        ...farm
      }) => {
        return {
          ...farm,
          benches: capacityInBenches,
          schedulableBenches: capacityInBenches - nurseryBenches
        };
      }
    );

    const variables: { input: LocationInput } = {
      input: { ...input, farms }
    };

    if (locationUuid) variables.input.locationUuid = locationUuid;

    addOrEditLocation({
      variables
    });
  };

  const category: string | undefined = useWatch({
    name: 'category',
    control
  });

  const locationsByCategory = useMemo(
    () => getLocationsByCategory(locationGroup, category),
    [category, locationGroup]
  );

  useEffect(() => {
    if (locationsByCategory.length === 1) {
      setValue('locationGroupUuid', locationsByCategory[0].value);
    }
  }, [locationsByCategory, setValue]);

  const onCategoryChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const categoryValue = e.target.value as string;
    const shouldUpdateCustomer = categoryValue === inHubCategory.PRODUCTION && customerInfarm;

    if (shouldUpdateCustomer) {
      setValue('customerUuid', customerInfarm?.value);
    } else {
      const isCategoryNotProduction = categoryValue && categoryValue !== inHubCategory.PRODUCTION;

      if (isCategoryNotProduction) {
        setValue('customerUuid', '');
      }
    }
    setValue('category', categoryValue);
    setValue('locationGroupUuid', '');
  };

  if (isLoadingLocationData) return <DsPlaceholder />;

  return (
    <section>
      <StyledLocationForm onSubmit={handleSubmit(saveLocation)}>
        <h1>{locationUuid ? 'Location Settings' : 'Create New Location'}</h1>
        <FormInputDropdown
          name="category"
          label="Location Type"
          items={getSelectData(locationDropdownOptions, undefined, undefined, false, true)}
          control={control}
          onChange={onCategoryChange}
          data-cy="LocationForm___locationType"
        />
        <FormInputDropdown
          name="locationGroupUuid"
          label="City Cluster"
          loading={isLocationGroupLoading}
          disabled={!category}
          items={locationsByCategory}
          control={control}
          data-cy="LocationForm___cityCluster"
        />

        <FormSectionHead>Location Information</FormSectionHead>
        <FormInputText
          name="name"
          label="Location Name"
          caption="Enter a name of the location. This name will be surfaced in the Grower App."
          control={control}
          data-cy="LocationForm___locationName"
        />
        <FormInputDropdown
          name="customerUuid"
          label="Customer"
          caption="Select the customer who owns this location."
          loading={isCustomerLoading}
          items={customerSelectData}
          control={control}
          data-cy="LocationForm___customer"
        />
        <FormInputTimezoneAutocomplete
          name="timezone"
          label="Time Zone"
          control={control}
          data-cy="LocationForm___timeZone"
        />
        <FormInputDropdown
          name="nurseryUuid"
          label="Nursery"
          caption="Define from which Hub or Nursery the location will get seedlings from."
          loading={isNurseryLoading}
          items={nurserySelectData}
          control={control}
          disabled={!nurserySelectData.length}
          warningMessage={
            !isNurseryLoading && !nurserySelectData.length
              ? 'No nurseries available. Please ask your manager to give you access to the right nursery'
              : ''
          }
          data-cy="LocationForm___nursery"
        />
        <FormSectionHead>Linked Farms</FormSectionHead>
        <Controller
          control={control}
          name="farms"
          render={({ field }) => (
            <AddAFarmSection
              farms={getValues('farms')}
              onUpdate={(values: FarmItem[]) => {
                field.onChange(values);
                trigger('nurseryUuid');
              }}
            />
          )}
        />
        <ThinLine />
        <FormFooter>
          <ModalButtonsRow>
            <DsButton
              label="Cancel"
              secondary
              onClick={() => {
                setCancelFormConfirmation(true);
                setConfirmModalOpen(true);
              }}
            />
            <DsButton
              label={`${locationUuid ? 'Save Settings' : 'Create Location'}`}
              disabled={!isValid}
              onClick={() => setConfirmModalOpen(true)}
              data-cy="LocationForm___createLocation"
              primary
            />
          </ModalButtonsRow>
        </FormFooter>
        <ConfirmLocationModal
          onClose={() => {
            setConfirmModalOpen(false);
            setConfirmationError('');
          }}
          open={confirmModalOpen}
          isCancelModal={cancelFormConfirmation}
          locationName={getValues('name')}
          farmsExist={getValues('farms').length > 0}
          errorMessage={confirmationError}
          loading={loading}
        />
      </StyledLocationForm>
    </section>
  );
};
