import { FormState } from '../PlantingSchedule/types';

export const TABLE_HEADER = [
  'Name',
  'Status',
  'First planting',
  'Last planting',
  'First seeding',
  'First harvesting',
  'Date created',
  'Actions'
];

export enum ORDER_BY {
  CREATED_AT = 'CREATED_AT',
  STARTING_DATE = 'STARTING_DATE'
}

export const STATUS_TOOLTIP_HEADING = 'What is planting schedule status ?';

export enum STATUS_NAME {
  UPCOMING = 'Upcoming',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  PROCESSING = 'Processing'
}

export enum STATUS_EXPLANATION {
  UPCOMING = 'Planting will happen in the future using this schedule.',
  IN_PROGRESS = 'Planting is in progress using this schedule.',
  COMPLETED = 'Planting days for this schedule is in the past. Harvesting might still be in progress.',
  CANCELLED = 'This planting schedule is cancelled and will not be used.'
}

export const STATUSES_ARRAY = [
  {
    name: STATUS_NAME.UPCOMING,
    explanation: STATUS_EXPLANATION.UPCOMING
  },
  {
    name: STATUS_NAME.IN_PROGRESS,
    explanation: STATUS_EXPLANATION.IN_PROGRESS
  },
  {
    name: STATUS_NAME.COMPLETED,
    explanation: STATUS_EXPLANATION.COMPLETED
  },
  {
    name: STATUS_NAME.CANCELLED,
    explanation: STATUS_EXPLANATION.CANCELLED
  }
];

export const getStatusDetails = (status: string) => {
  switch (status) {
    case 'in_progress':
      return {
        text: STATUS_NAME.IN_PROGRESS,
        styles: { backgroundColor: '#A3FCB2' },
        diabledMode: [] as FormState[]
      };
    case 'completed':
      return {
        text: STATUS_NAME.COMPLETED,
        styles: { backgroundColor: '#FFDA87' },
        diabledMode: [] as FormState[]
      };
    case 'cancelled':
      return {
        text: STATUS_NAME.CANCELLED,
        styles: { backgroundColor: '#FFA594' },
        diabledMode: [FormState.EDIT]
      };
    case 'upcoming':
      return {
        text: STATUS_NAME.UPCOMING,
        styles: { backgroundColor: '#9DC6FF' },
        diabledMode: [] as FormState[]
      };
    case 'processing':
      return {
        text: STATUS_NAME.PROCESSING,
        styles: { backgroundColor: '#808080' },
        diabledMode: [FormState.DUPLICATE, FormState.VIEW, FormState.EDIT]
      };
    default:
      return {
        text: '',
        styles: {},
        diabledMode: [] as FormState[]
      };
  }
};

export const getColumnWidth = (column: string) => {
  switch (column) {
    case 'Actions':
      return '90px';
    case 'Name':
      return '230px';
    default:
      return 'auto';
  }
};
