import styled from 'styled-components';
import { ExcessDemand } from '../../../types';
import { formatDayWithYearMonth } from '../../../utils/date-functions';
import { ProductExcessDemand } from '../types';

const Wrapper = styled.div``;
const Container = styled.div`
  border: 1.5px solid #ff3b30;
  border-radius: 4px;
  padding: 20px 24px;
  background: rgba(255, 165, 148, 0.1);
`;

const Heading = styled.h2`
  color: #ff3b30;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 16px 0;
`;

const DateCell = styled.td`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

const Cell = styled.td`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const HeaderCell = styled.th`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
`;

const HeaderRow = styled.tr`
  height: 20px;
`;
const Row = styled.tr`
  height: 50px;
`;

export const ExcessDemands = ({ excessDemands }: { excessDemands: ProductExcessDemand[] }) => {
  const sortedDemandsByDate = excessDemands.sort(
    (demandA, demandB) =>
      new Date(demandA.locationDemand.harvestDate).valueOf() -
      new Date(demandB.locationDemand.harvestDate).valueOf()
  );
  return (
    <Wrapper>
      <Heading>The following is a list of trays that can't find space. Please adjust.</Heading>
      <Container>
        <Table>
          <tbody>
            <HeaderRow>
              <HeaderCell>Harvest date</HeaderCell>
              <HeaderCell>Exceeded Plant Group</HeaderCell>
              <HeaderCell>Exceeded tray qty.</HeaderCell>
            </HeaderRow>
            {sortedDemandsByDate.map(({ locationDemand, excessDemands }) => {
              const excessDemandDict: { [plotgroupUuid: string]: ExcessDemand } = {};
              excessDemands.forEach(excessDemand => {
                if (excessDemandDict[excessDemand!.stageRecipe!.plantGroup!]) {
                  excessDemandDict[excessDemand!.stageRecipe!.plantGroup!].totalNumberOfTrays! +=
                    excessDemand!.totalNumberOfTrays || 0;
                } else {
                  excessDemandDict[excessDemand!.stageRecipe!.plantGroup!] = {
                    ...excessDemand
                  };
                }
              });
              const sortedExcessDemands = Object.values(excessDemandDict).sort(
                (demandA, demandB) =>
                  demandA!.stageRecipe!.plantGroup!.localeCompare(
                    demandB!.stageRecipe!.plantGroup!
                  ) || demandB.quantity - demandA.quantity
              );
              return sortedExcessDemands.map(excessDemand => {
                const numberOfTrays = excessDemand.totalNumberOfTrays;
                const plantGroup = `${excessDemand!.stageRecipe!.plantGroup} (${Math.ceil(
                  excessDemand!.stageRecipe!.duration / 7
                )} weeks)`;
                return (
                  <Row key={excessDemand.uuid}>
                    <DateCell>{formatDayWithYearMonth(locationDemand.harvestDate)}</DateCell>
                    <Cell>{plantGroup}</Cell>
                    <Cell>{numberOfTrays}</Cell>
                  </Row>
                );
              });
            })}
          </tbody>
        </Table>
      </Container>
    </Wrapper>
  );
};
