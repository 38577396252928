import { DsButton, DsRow } from '@infarm/design-system-react';
import { Modal as MaterialModal } from '@material-ui/core';
import styled from 'styled-components';
import SubmitButton from '../../../components/SubmitButton';
export const FarmDetailModalWrapper = styled.div`
  max-height: calc(100% - 32px);
  border-radius: 4px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 28px;
`;
export const ModalHeading = styled.div`
  min-width: 453px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const ModalText = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-top: 4px;
`;

export const ModalWrapper = styled.div`
  max-height: calc(100% - 32px);
  border-radius: 4px;
  background-color: white;
  position: absolute;
  width: 501px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 28px;
`;

export const ModalButtonsRow = styled(DsRow)`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-bottom: 4px;
`;
type ModalProps = {
  isModalOpen: boolean;
  onModalClosed: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  heading: string;
  text: string | JSX.Element;
  secondaryLabel?: string;
  primaryLabel?: string;
  onPrimaryBtnClick: () => void;
  onSecondaryBtnClick: () => void;
  loading?: boolean;
};
export const Modal = (props: ModalProps) => {
  const {
    isModalOpen,
    onModalClosed,
    heading,
    text,
    primaryLabel,
    secondaryLabel,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
    loading
  } = props;
  return (
    <MaterialModal open={isModalOpen} onClose={onModalClosed}>
      <ModalWrapper>
        <ModalHeading> {heading}</ModalHeading>
        <ModalText> {text}</ModalText>
        <ModalButtonsRow>
          {secondaryLabel && (
            <DsButton label={secondaryLabel} secondary onClick={onSecondaryBtnClick} />
          )}
          {primaryLabel && (
            <SubmitButton label={primaryLabel} onClick={onPrimaryBtnClick} loading={loading} />
          )}
        </ModalButtonsRow>
      </ModalWrapper>
    </MaterialModal>
  );
};
