import { FormHelperText, MenuItem, Select } from '@material-ui/core';
import { FieldValues, UseFieldArrayUpdate, useFormContext } from 'react-hook-form';
import { StageRecipe } from '../../../types';
import { StyledFormControl } from '../style';

interface VarietyEditSelectProps {
  update: UseFieldArrayUpdate<FieldValues, 'configurations'>;
  selectedVariety: StageRecipe;
  indexInConfigurations: number;
  availableVarieties: StageRecipe[];
}

export const VarietyEditSelect = (props: VarietyEditSelectProps) => {
  const { availableVarieties, selectedVariety, update, indexInConfigurations } = props;
  const { getValues } = useFormContext();
  const { configurations } = getValues();

  const stageRecipeDensity = selectedVariety.density || 100;
  const plantsPerTray = selectedVariety.trayType?.plantsPerTray || 0;
  const actualPlantsPerTray = Math.ceil((stageRecipeDensity / 100) * plantsPerTray);
  return (
    <StyledFormControl>
      <Select
        required
        fullWidth
        disableUnderline={true}
        value={selectedVariety.uuid}
        onChange={({ target }) => {
          const selectedVariety = availableVarieties.find(
            stageRecipe => stageRecipe.uuid === target.value
          );
          update(indexInConfigurations, {
            ...configurations[indexInConfigurations],
            stageRecipe: selectedVariety
          });
        }}
      >
        {[...availableVarieties, selectedVariety].map(stageRecipe => (
          <MenuItem value={stageRecipe.uuid} key={stageRecipe.uuid}>
            {`${stageRecipe.internalCode ? stageRecipe.internalCode + '_' : ''}${stageRecipe.name}`}
          </MenuItem>
        ))}
      </Select>
      {
        <FormHelperText>
          {selectedVariety.trayType?.name}
          {!!actualPlantsPerTray && <span> {` ∙ ${actualPlantsPerTray} plants per tray`}</span>}
        </FormHelperText>
      }
    </StyledFormControl>
  );
};
