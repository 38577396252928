import styled from 'styled-components';
import { Status } from '../../types';

const StatusColorLookup: Record<Status, string> = {
  [Status.DEFAULT]: '#e0e0e0',
  [Status.SUCCESS]: '#a3fcb2',
  [Status.ERROR]: '#ffa594',
  [Status.WARNING]: '#ffda87',
  [Status.INFO]: '#9dc6ff'
};

const Container = styled.div<{ status?: Status }>`
  padding: 4px 8px;
  height: 24px;
  background: ${({ status }) => StatusColorLookup[status || Status.DEFAULT]};
  border-radius: 999px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.9);
  display: inline-block;
  margin-inline: 0.5em;
  vertical-align: middle;
`;
export const StatusChip = ({ status, label }: { status: Status; label: string }) => {
  return <Container status={status}>{label}</Container>;
};
