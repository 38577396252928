import { Table, THead, TrHeader, Error, Row, StyledTh } from '../styles';
import { useParams } from 'react-router';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { getErrorMessage } from '../../../utils/errors';
import { TABLE_HEADER, getColumnWidth } from '../constants';
import { TableBody } from './TableBody';
import { useEffect } from 'react';
import { GET_PLANTING_SCHEDULES } from '../queries';
import { DsButton } from '@infarm/design-system-react';
import { toIsoDate } from '../../../utils/date-functions';
import { PlantingScheduleState } from '../../../types';
import { StatusTooltip } from './StatusTooltip';

export interface PlantingScheduleTableProps {
  orderBy: string;
  farmCycle: number;
  startDate: Date | null;
  endDate: Date | null;
  isWriteAllowed: boolean;
  isAcreFarm: boolean;
}

interface Filter {
  farmUuid: string;
  stateIn: PlantingScheduleState[];
  startDate?: string;
  endDate?: string;
}

export const PlantingScheduleTable = (props: PlantingScheduleTableProps) => {
  const { farmUuid } = useParams();
  const { orderBy, startDate, endDate, isAcreFarm } = props;
  const [getPlantingSchedules, { data, error, fetchMore, networkStatus, loading: psLoading }] =
    useLazyQuery(GET_PLANTING_SCHEDULES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    });
  useEffect(() => {
    if (farmUuid) {
      const filter: Filter = {
        farmUuid,
        stateIn: [PlantingScheduleState.ACTIVE, PlantingScheduleState.ARCHIVED]
      };
      if (startDate) filter.startDate = toIsoDate(startDate);
      if (endDate) filter.endDate = toIsoDate(endDate);
      getPlantingSchedules({
        variables: {
          filter,
          orderBy,
          isAcreFarm
        }
      });
    }
  }, [farmUuid, getPlantingSchedules, orderBy, endDate, startDate, isAcreFarm]);

  const loading = psLoading && networkStatus !== NetworkStatus.fetchMore;
  const plantingSchedules = data?.plantingSchedules?.edges;
  const pageInfo = data?.plantingSchedules?.pageInfo;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const fetchMoreError = data?.plantingSchedules?.error;

  const fetchMoreSchedules = () => {
    if (fetchMore)
      fetchMore({
        variables: {
          cursor: data?.plantingSchedules?.pageInfo?.endCursor
        }
      });
  };

  if (error) return <Error>{getErrorMessage(error)}</Error>;
  return (
    <>
      <Table>
        <THead>
          <TrHeader>
            {TABLE_HEADER.map(name => {
              const width = getColumnWidth(name);

              return (
                <StyledTh key={name} width={width}>
                  {name}
                  {name === 'Status' && <StatusTooltip />}
                </StyledTh>
              );
            })}
          </TrHeader>
        </THead>
        <TableBody {...props} loading={loading} plantingSchedules={plantingSchedules} />
      </Table>
      {fetchMoreError && <Error>{fetchMoreError}</Error>}
      {!!plantingSchedules?.length && (
        <Row>
          <DsButton
            label="Show More"
            secondary
            disabled={!pageInfo?.hasNextPage || isFetchingMore}
            onClick={fetchMoreSchedules}
          />
        </Row>
      )}
    </>
  );
};
