import gql from 'graphql-tag';

export const SEEDING_OVERVIEW = gql`
  query SeedingOverviewForHub($uuid: ID!, $date: Date!, $skipRest: Boolean!) {
    location(uuid: $uuid) {
      name
      uuid
      tasksForDate(date: $date, filter: { taskType: [PLANTING], sourceType: SEEDING_STATION })
        @skip(if: $skipRest) {
        totalNumberOfTrays
        statuses
        byStageRecipe {
          stageRecipe {
            uuid
            name
          }
          taskGroup {
            totalNumberOfTrays
            statuses
            tasks {
              uuid
            }
          }
        }
        byTrayType {
          trayType {
            uuid
            name
          }
          taskGroup {
            totalNumberOfTrays
          }
        }
        tasks {
          uuid
        }
      }
    }
    excessDemands(filter: { locationUuid: $uuid, seededAt: $date }) @skip(if: $skipRest) {
      uuid
      totalNumberOfTrays
      date
      seededAt
      stageRecipe {
        uuid
        name
        trayType {
          uuid
          name
        }
      }
    }
  }
`;

export const NURSERY_DEMAND_STATUES = gql`
  query NurseryDemandStatues($locationUuid: ID!) {
    location(uuid: $locationUuid) {
      uuid
      isGeneratingNurseryDemand
    }
  }
`;

export const DOWNLOAD_NIA_SEEDING_PLAN = gql`
  query getInHubSeedingData($startSeedingDate: Date!, $nurseryUuid: ID!, $endSeedingDate: Date) {
    location(uuid: $nurseryUuid) {
      uuid
      name
      seedingPlan(startDate: $startSeedingDate, endDate: $endSeedingDate) {
        nia {
          file
        }
      }
    }
  }
`;

export const WORKSTATION_NURSERY_IN_ACRE = gql`
  query getWorkstationNurseryInAcre($locationUuid: ID!, $date: Date!) {
    location(uuid: $locationUuid) {
      uuid
      tasksForDate(
        date: $date
        filter: {
          isNursery: true
          farmModel: [ACRE]
          farmStateNot: NEW
          taskType: [PLANTING, HARVEST, SPRAYING]
        }
      ) {
        byOperationalFarm {
          operationalFarm {
            name
            uuid
          }
          taskGroup {
            byBench {
              bench {
                name
                uuid
                capacityUnits
                benchType
                operationalBenchId
              }
              taskGroup {
                loading: filter(by: { taskType: PLANTING }) {
                  tasks {
                    uuid
                    date
                    status
                    traysPerStageRecipe
                    sourceType
                    plots {
                      name
                      uuid
                    }
                    stageRecipe {
                      uuid
                      name
                    }
                  }
                }
                unloading: filter(by: { taskType: HARVEST }) {
                  tasks {
                    uuid
                    date
                    status
                    uuid
                    traysPerStageRecipe
                    plots {
                      name
                      uuid
                    }
                    stageRecipe {
                      uuid
                      name
                    }
                  }
                }
                spraying: filter(by: { taskType: SPRAYING }) {
                  tasks {
                    uuid
                    date
                    status
                    traysPerStageRecipe
                    plots {
                      name
                      uuid
                    }
                    stageRecipe {
                      uuid
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const COMMIT_SEEDING = gql`
  mutation commitSeeding($taskUuids: [String!]!) {
    commitTasks(input: { taskUuids: $taskUuids }) {
      uuid
      status
    }
  }
`;
