import { Tr, Td } from './style';
import { RowData } from '.';
import { CommitButton } from '../CommitButton';

export const TableRow = (props: RowData) => {
  const { rowData, totalNumberOfTrays, showCommitButton } = props;

  return (
    <Tr data-cy={`table-row${rowData.uuid}`}>
      <Td bold>{rowData.name}</Td>
      <Td>{totalNumberOfTrays}</Td>
      {showCommitButton && (
        <Td compact>
          <CommitButton statuses={rowData.statuses} taskUuids={rowData.taskUuids} />
        </Td>
      )}
    </Tr>
  );
};
