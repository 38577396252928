import { FC } from 'react';
import { DsButton } from '@infarm/design-system-react';
import { Link } from 'react-router-dom';
import { ImageLabel } from '../../../../components/ImageLabel';
import { isToday } from '../../../../utils/date-functions';
import {
  AnchorTagUnderline,
  ControlsRow,
  DataRow,
  FlexStyledSpan,
  GreyText,
  LeftBorderdSpan,
  RedText,
  Row,
  Span
} from '../../style';
import { FarmModel, FarmState, OperationalFarm } from '../../../../types';
import InstoreImage from '../../../../assets/instore-v2_farm_front_icon.png';
import AcreImage from '../../../../assets/acre.svg';
import { UnitFilter } from '../../constants';
import { formatRowData } from '../utils';
import { TaskGroupByOperationalFarm } from '../types';

const FarmDetail: FC<{ farm: OperationalFarm }> = ({ farm }) => {
  const { model, serialNumber } = farm;
  let greyText = model === FarmModel.INSTOREV2 ? 'INSF v2.0 · ' : model + ' · ';
  if (serialNumber) greyText = greyText + `ID ${serialNumber}`;

  return (
    <div>
      <GreyText>{greyText}</GreyText>
      {!serialNumber && <RedText>Farm ID missing</RedText>}
    </div>
  );
};

const FarmName: FC<{ farm: OperationalFarm }> = ({ farm }) => {
  if (!farm.serialNumber) return <span>{farm.name}</span>;
  return <AnchorTagUnderline to={`/farms/${farm.serialNumber}`}>{farm.name}</AnchorTagUnderline>;
};

interface Props {
  isExpanded: boolean;
  selectedUnitFilter: UnitFilter;
  weekDates: string[];
  taskGroupByFarm: TaskGroupByOperationalFarm;
}

export const FarmRow: FC<Props> = ({
  isExpanded,
  selectedUnitFilter,
  weekDates,
  taskGroupByFarm
}) => {
  const rowData = formatRowData(taskGroupByFarm.taskGroup.byDate);

  const { operationalFarm } = taskGroupByFarm;
  const shouldDisplayPlantingSchedule =
    operationalFarm.state !== FarmState.ARCHIVED && operationalFarm.model === FarmModel.INSTOREV2;

  return (
    <Row rounded={!isExpanded} topRounded={isExpanded}>
      <FlexStyledSpan className="span-3">
        <ImageLabel
          image={operationalFarm.model === FarmModel.ACRE ? AcreImage : InstoreImage}
          title={<FarmName farm={operationalFarm} />}
          subTitle={<FarmDetail farm={operationalFarm} />}
          alt={operationalFarm.name}
        />
        {shouldDisplayPlantingSchedule && (
          <ControlsRow>
            <Link to={`${operationalFarm.uuid}/planting-schedules`}>
              <DsButton
                icon="date_range"
                data-cy={`FarmPlantingSchedule___${operationalFarm.uuid}`}
              />
            </Link>
          </ControlsRow>
        )}
      </FlexStyledSpan>
      <DataRow className="span-7">
        {weekDates.map(weekDate => (
          <LeftBorderdSpan key={weekDate}>
            <Span bold={isToday(weekDate)}>{rowData[weekDate]?.[selectedUnitFilter] ?? 0}</Span>
          </LeftBorderdSpan>
        ))}
      </DataRow>
    </Row>
  );
};
