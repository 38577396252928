import { DsRow, DsSpacer } from '@infarm/design-system-react';
import { FC } from 'react';
import { isToday, formatDay } from '../../../utils/date-functions';
import { DaysRow, WeekDate } from './style';

interface Props {
  weekDates: string[];
}

export const ProductionTableDaysRow: FC<Props> = ({ weekDates }) => {
  return (
    <DsRow>
      <DsSpacer size={3} />
      <DaysRow className="span-7">
        {weekDates.map(weekDate => (
          <WeekDate isToday={isToday(weekDate)} key={weekDate.toString()}>
            {isToday(weekDate) ? 'Today' : formatDay(weekDate)}
          </WeekDate>
        ))}
      </DaysRow>
    </DsRow>
  );
};
