import styled from 'styled-components';

export const Table = styled.table<{ stretch?: boolean }>`
  border-collapse: collapse;
  font-family: Roboto;
  margin-block: 20px;
  box-shadow: inset 1px 1px 1px #e0e0e0, inset -1px 0px 0px #e0e0e0;
  ${props => props.stretch && 'width: 100%;'}
`;

export const THead = styled.thead``;

export const Tr = styled.tr``;

export const Th = styled.th`
  height: 32px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  &:not(:last-child) {
    box-shadow: inset 0px 1px 0px #e0e0e0, inset 1px 0px 0px #e0e0e0, inset 0px -1px 0px #e0e0e0;
  }
  box-shadow: inset 0px 1px 0px #e0e0e0, inset -1px 0px 0px #e0e0e0, inset 1px 0px 0px #e0e0e0,
    inset 0px -1px 0px #e0e0e0;
`;

export const Tbody = styled.tbody``;

export const Td = styled.td<{ bold?: boolean; colSpan?: number; compact?: boolean }>`
  height: 56px;
  padding: 8px 16px;
  font-weight: ${props => (props.bold ? 700 : 400)};
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.9);
  box-shadow: inset 1px 0px 0px #e0e0e0, inset 0px -1px 0px #e0e0e0;
  ${props => props.compact && 'width: 0.1%;'}
`;

export const TotalCell = styled(Td)`
  height: 32px;
`;

export const TotalRow = styled(Tr)`
  border-top: 2px solid #bdbdbd;
`;
