import { gql } from '@apollo/client';

export const GET_NURSERY_DATA = gql`
  query nurseryData($locationUuid: ID!, $fromDate: Date!, $toDate: Date!) {
    location(uuid: $locationUuid) {
      uuid
      lastNurseryDemandUpdatedAt
      wavesForDate(
        fromDate: $fromDate
        toDate: $toDate
        filter: { benchStationType: [DARK, LIGHT, ACRE], isNursery: true }
      ) {
        byDate {
          date: dateAtWhichWaveIsPresentInFarm
          waveGroup {
            totalNumberOfTrays
            DARK: filter(by: { benchStationType: [DARK] }) {
              totalNumberOfTrays
            }
            LIGHT: filter(by: { benchStationType: [LIGHT, ACRE] }) {
              totalNumberOfTrays
            }
          }
        }
        byOperationalFarm {
          farm: operationalFarm {
            uuid
          }
          waveGroup {
            totalNumberOfTrays
            DARK: filter(by: { benchStationType: [DARK] }) {
              totalNumberOfTrays
            }
            LIGHT: filter(by: { benchStationType: [LIGHT, ACRE] }) {
              totalNumberOfTrays
            }
            byDate {
              date: dateAtWhichWaveIsPresentInFarm
              waveGroup {
                totalNumberOfTrays
                DARK: filter(by: { benchStationType: [DARK] }) {
                  totalNumberOfTrays
                }
                LIGHT: filter(by: { benchStationType: [LIGHT, ACRE] }) {
                  totalNumberOfTrays
                }
              }
            }
            byBench {
              bench {
                uuid
                name
                operationalBenchId
              }
              waveGroup {
                totalNumberOfTrays
                byDate {
                  date: dateAtWhichWaveIsPresentInFarm
                  waveGroup {
                    totalNumberOfTrays
                    DARK: filter(by: { benchStationType: [DARK] }) {
                      totalNumberOfTrays
                    }
                    LIGHT: filter(by: { benchStationType: [LIGHT, ACRE] }) {
                      totalNumberOfTrays
                    }
                  }
                }
                byStageRecipe {
                  stageRecipe {
                    uuid
                    name
                    internalCode
                  }
                  waveGroup {
                    totalNumberOfTrays
                    byDate {
                      date: dateAtWhichWaveIsPresentInFarm
                      waveGroup {
                        totalNumberOfTrays
                        DARK: filter(by: { benchStationType: [DARK] }) {
                          totalNumberOfTrays
                        }
                        LIGHT: filter(by: { benchStationType: [LIGHT, ACRE] }) {
                          totalNumberOfTrays
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const NURSERY_DEMAND_STATUES = gql`
  query NurseryDemandStatues($locationUuid: ID!) {
    location(uuid: $locationUuid) {
      uuid
      isGeneratingNurseryDemand
    }
  }
`;
