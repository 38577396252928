export const DOUBLE_PACKED_SHRINKAGE_NAME = 'Small Plant';
export const DOUBLE_PACKED_SHRINKAGE_DESCRIPTION = 'Double packed';
export const MISSING_PLANT_SHRINKAGE_NAME = 'Missing Plant';
export const HARVEST_DASHBOARD_LINK =
  'https://infarm.cloud.looker.com/dashboards/125?Farm+Type=acre&Harvest+Week=10+week&Crops+Table+Date+Filter=1+week+ago+for+1+week';
export const DISABLED_NON_VARIETIES_CODES = ['E', 'E2', 'E3', 'X', 'NiA1', 'NiA2'];

/**
 * Column IDs used for addressing shrinkage table columns
 */
export enum ColumnId {
  FARM = 'farm',
  BATCH = 'batchid',
  VARIETY = 'variety',
  EXPECTED = 'expected',
  SINGLE = 'single',
  DOUBLE = 'double',
  MISSING = 'missing',
  SHRINKAGE = 'shrinkage',
  SHRINKAGEVALUE = 'shrinkageValue',
  HP = 'hp',
  ACTIONS = 'actions'
}
