import { gql } from '@apollo/client';

export const GET_PLANTING_SCHEDULES = gql`
  query getPlantingSchedule(
    $cursor: String
    $filter: PlantingScheduleFilter
    $orderBy: [PlantingScheduleOrderBy]
    $isAcreFarm: Boolean!
  ) {
    plantingSchedules(cursor: $cursor, filter: $filter, orderBy: $orderBy) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        uuid
        name
        createdAt
        firstPlantingDate
        maxPropagationDays
        createdBy {
          name
        }
        summary {
          status
          firstIncomingMovementDate
          lastIncomingMovementDate
          firstOutgoingMovementDate
          lastOutgoingMovementDate
        }
        configuration @include(if: $isAcreFarm) {
          monday {
            stageRecipe {
              uuid
              duration
            }
          }
          tuesday {
            stageRecipe {
              uuid
              duration
            }
          }
          wednesday {
            stageRecipe {
              uuid
              duration
            }
          }
          thursday {
            stageRecipe {
              uuid
              duration
            }
          }
          friday {
            stageRecipe {
              uuid
              duration
            }
          }
          saturday {
            stageRecipe {
              uuid
              duration
            }
          }
          sunday {
            stageRecipe {
              uuid
              duration
            }
          }
        }
      }
    }
  }
`;

export const GET_LOCATION_AND_FARM = gql`
  query getOperationalFarm($farmUuid: ID, $locationUuid: ID!) {
    location(uuid: $locationUuid) {
      name
      uuid
    }
    operationalFarm(uuid: $farmUuid) {
      uuid
      farmCycle
      model
      name
    }
  }
`;
