import { Photo, PhotosTableRow } from '../../types';

export const groupPhotosByDate = (photos: Photo[]) => {
  const groupByPhotos = photos.reduce(
    (
      accum: { [key: string]: PhotosTableRow },
      { photoUrl, createdAt, ...currentDateRow }: Photo
    ) => {
      const currentPhotoDetails = {
        photoUrl: photoUrl,
        createdAt: createdAt
      };
      accum[currentDateRow.date] = {
        ...currentDateRow,
        photosDetails: [...(accum[currentDateRow.date]?.photosDetails || []), currentPhotoDetails]
      };

      return accum;
    },
    {}
  );

  const sortByDateDesc = Object.values(groupByPhotos).sort((a, b) => {
    const da = new Date(a.date).valueOf();
    const db = new Date(b.date).valueOf();

    if (da < db) {
      return 1;
    }
    if (da > db) {
      return -1;
    }
    return 0;
  });

  return sortByDateDesc;
};
