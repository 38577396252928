import { FC } from 'react';
import { ImageLabel } from '../../../../components/ImageLabel';
import { isToday } from '../../../../utils/date-functions';
import { DataRow, FlexStyledSpan, LeftBorderdSpan, Row } from '../../style';
import varietyImage from '../../../../assets/variety.png';
import { UnitFilter } from '../../constants';
import { CellSpan } from '../style';
import { formatRowData, formatStageRecipeDetails } from '../utils';
import { TaskGroupByStageRecipe } from '../types';

interface Props {
  taskGroupByStageRecipe: TaskGroupByStageRecipe;
  weekDates: string[];
  selectedUnitFilter: UnitFilter;
}

export const VarietyRow: FC<Props> = ({
  taskGroupByStageRecipe,
  weekDates,
  selectedUnitFilter
}) => {
  const rowData = formatRowData(taskGroupByStageRecipe.taskGroup.byDate);

  const { stageRecipe } = taskGroupByStageRecipe;

  return (
    <Row noTopBorder key={stageRecipe.uuid}>
      <FlexStyledSpan className="span-3">
        <ImageLabel
          image={varietyImage}
          title={stageRecipe.name}
          subTitle={formatStageRecipeDetails(stageRecipe)}
          alt={stageRecipe.name}
        />
      </FlexStyledSpan>

      <DataRow className="span-7">
        {weekDates.map(weekDate => (
          <LeftBorderdSpan key={weekDate}>
            <CellSpan isBolder={isToday(weekDate)} isDarkBlack={isToday(weekDate)}>
              {rowData[weekDate]?.[selectedUnitFilter] ?? 0}
            </CellSpan>
          </LeftBorderdSpan>
        ))}
      </DataRow>
    </Row>
  );
};
