import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const IconWrapper = styled.div`
  padding-right: 8px;
  height: 27px;
`;
