import { useState, FunctionComponent, useEffect } from 'react';
import {
  ValidationError,
  ModalButtonsRow,
  SwitchLabel,
  FarmDetailModalSwitch,
  StyledSwitch,
  FarmDetailModalWrapper,
  HybridCaption,
  HybridConfig,
  HybridSwitch
} from './styles';
import { DsButton } from '@infarm/design-system-react';
import { DsTextField } from '../../components/Textfield';
import { DsSelect } from '../../components/Select';
import { FarmError, FarmItem } from './types';
import { getValidationError } from './utils';
import {
  farmSelectTypes,
  mapFarmType,
  mapBenchesToFarmType,
  ACRE_MAX_FIXED_BENCHES
} from './constants';
import { AcreType, FarmModel } from '../../types';
import { hasPermission } from '../../utils/user';
import { FarmDetailModalHybridSection } from './FarmDetailModalHybridSection';
import { Modal } from '@material-ui/core';

export const FarmDetailModal: FunctionComponent<{
  onClose: Function;
  addOrUpdateFarm: Function;
  isOpen: boolean;
  duplicateError?: boolean;
  farm?: FarmItem;
}> = ({ onClose, addOrUpdateFarm, isOpen, duplicateError = false, farm }): JSX.Element => {
  const [farmName, setFarmName] = useState<string>('');
  const [farmType, setFarmType] = useState<string>('');
  const [farmBenches, setFarmBenches] = useState<number>();
  const [nurseryBenches, setNurseryBenches] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<FarmError[]>([]);
  const [isHybrid, setIsHybrid] = useState(false);
  const [isFullDark, setFullDark] = useState(false);

  useEffect(() => {
    if (isOpen && farm) {
      setFarmName(farm.name);
      setFarmType(mapBenchesToFarmType(farm.capacityInBenches));
      setFarmBenches(farm.capacityInBenches);
      setNurseryBenches(farm.nurseryBenches);
      setIsHybrid(!!farm.nurseryBenches);
    }
  }, [farm, isOpen]);

  useEffect(() => {
    if (!isHybrid) {
      setNurseryBenches(0);
    }
  }, [isHybrid]);

  const onFarmTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const typeOfFarm = event.target.value as string;
    const dropdownOption = farmSelectTypes.find(farmType => farmType.value === typeOfFarm);
    const benches = dropdownOption?.capacityInBenches;
    const isCurrentNurseryBenchesMoreThanAllowedAcre24 =
      typeOfFarm === AcreType.ACRE_24 && nurseryBenches > ACRE_MAX_FIXED_BENCHES.ACRE_24;

    setFarmBenches(benches);
    setFarmType(typeOfFarm);

    if (typeOfFarm === FarmModel.INSTOREV2) {
      setNurseryBenches(0);
      setIsHybrid(false);
      setFullDark(false);
    }

    if (isCurrentNurseryBenchesMoreThanAllowedAcre24) {
      setNurseryBenches(ACRE_MAX_FIXED_BENCHES.ACRE_24);
    }
  };

  const resetModal = () => {
    setFarmName('');
    setNurseryBenches(0);
    setIsHybrid(false);
    setFullDark(false);
    setFarmType(farmSelectTypes.length === 1 ? farmSelectTypes[0].value : '');
    setValidationErrors([]);
  };

  const handleClose = () => {
    resetModal();
    onClose();
  };
  const mappedFarmType = mapFarmType(farmType);

  const handleOk = () => {
    const formErrors: FarmError[] = [];
    const isSelectedTypeValid = farmSelectTypes.some(item => item.value === farmType);

    if (!isSelectedTypeValid) {
      formErrors.push(FarmError.FARM_TYPE);
    }

    if (!farmName.trim()) {
      formErrors.push(FarmError.FARM_NAME);
    }

    setValidationErrors(formErrors);

    if (!formErrors.length) {
      addOrUpdateFarm({
        ...farm,
        name: farmName,
        model: mappedFarmType,
        capacityInBenches: farmBenches,
        nurseryBenches,
        isFullDark,
        lightGerminationBenches: isFullDark ? 0 : 24,
        darkGerminationBenches: isFullDark ? 36 : 12
      });
      resetModal();
    }
  };

  const increaseNurseryBenches = (): void => {
    setNurseryBenches(currentNurseryBenches => currentNurseryBenches + 2);
  };

  const decreaseNurseryBenches = (): void => {
    setNurseryBenches(currentNurseryBenches => currentNurseryBenches - 2);
  };

  const isEditForm = !!farm;
  const showDarkOnlySwitch = !isEditForm && mappedFarmType === FarmModel.GROWTH_CHAMBER;
  const isAcre = mappedFarmType === FarmModel.ACRE;

  return (
    <Modal onClose={handleClose} open={isOpen}>
      <FarmDetailModalWrapper>
        <h2>{!!farm?.uuid ? 'Edit Farm' : 'Add Farm'}</h2>
        <div>
          <DsTextField
            onChange={setFarmName}
            value={farmName}
            label="Farm Name"
            caption="Farm names should be 'Farm 1', 'Farm 2'; 'Acre 1'; 'NuC 1' and so on for each new farm."
            validationMessage={getValidationError(FarmError.FARM_NAME, validationErrors)}
            data-cy="FarmDetailModal___farmName"
          />
          <DsSelect
            required={isOpen && !farm}
            onChange={onFarmTypeChange}
            items={farmSelectTypes}
            value={farmType}
            label="Farm Type"
            data-cy="FarmDetailModal___farmType"
            caption="Select the type that describes the Farm you want to add."
            disabled={isEditForm}
            validationMessage={getValidationError(FarmError.FARM_TYPE, validationErrors)}
            onBlur={(): any => {}}
          />
          {duplicateError && (
            <ValidationError>
              A farm with that name has already been created for this location.
              <br />
              Farm names within a single location must be unique.
            </ValidationError>
          )}
          {showDarkOnlySwitch && (
            <FarmDetailModalSwitch>
              <SwitchLabel>Use for dark germination only</SwitchLabel>
              <StyledSwitch
                checked={isFullDark}
                data-cy="FarmDetailModal___setFullDark"
                onChange={() => setFullDark(!isFullDark)}
              />
            </FarmDetailModalSwitch>
          )}
          {isAcre && hasPermission(['super_admin']) && (
            <HybridConfig>
              <HybridSwitch>
                <SwitchLabel>Use as Hybrid Acre </SwitchLabel>
                <StyledSwitch checked={isHybrid} onChange={() => setIsHybrid(!isHybrid)} />
              </HybridSwitch>
            </HybridConfig>
          )}
          {isHybrid && hasPermission(['super_admin']) && (
            <FarmDetailModalHybridSection
              increaseNurseryBenches={increaseNurseryBenches}
              decreaseNurseryBenches={decreaseNurseryBenches}
              nurseryBenches={nurseryBenches}
              farmType={farmType}
            />
          )}
          {isEditForm && isAcre && (
            <HybridCaption>
              Note: Changing Acre settings will delete all batches that are not yet planted.
              <br />
              You can still harvest batches inside Acres.
            </HybridCaption>
          )}
        </div>
        <ModalButtonsRow>
          <DsButton secondary onClick={handleClose} label="Cancel" />
          <DsButton
            primary
            onClick={handleOk}
            label={!!farm?.uuid ? 'Save' : 'Add'}
            data-cy="FarmDetailModal___addOrSave"
          />
        </ModalButtonsRow>
      </FarmDetailModalWrapper>
    </Modal>
  );
};
