import { FarmModel, FarmState, OperationalFarm } from '../../../types';
import { sortCallback } from '../../../utils';
import { NurseryMode } from '../../constants';
import { LocationWaveByFarm, NurserySystemsGroup, WaveGroupEndNode } from './types';

export const filterFarmsWithNurseryCapacity = (farms: OperationalFarm[]) =>
  farms.filter(
    farm =>
      farm.state !== FarmState.ARCHIVED &&
      (farm.model === FarmModel.GROWTH_CHAMBER || farm.nurseryBenches)
  );

export const getLocationWaveByFarms = (
  farms: OperationalFarm[],
  locationWavesByFarm: LocationWaveByFarm[]
): LocationWaveByFarm[] =>
  farms.map(farm => ({
    farm,
    waveGroup: locationWavesByFarm.find(wave => wave.farm.uuid === farm.uuid)?.waveGroup ?? {
      totalNumberOfTrays: 0,
      [NurseryMode.DARK]: {
        totalNumberOfTrays: 0
      },
      [NurseryMode.LIGHT]: {
        totalNumberOfTrays: 0
      },
      byDate: [],
      byStageRecipe: [],
      byBench: []
    }
  }));

export const getTraysByNurseryModes = (
  waveGroup: WaveGroupEndNode,
  nurseryModes: NurseryMode[]
) => {
  if (nurseryModes.includes(NurseryMode.DARK) && nurseryModes.includes(NurseryMode.LIGHT))
    return waveGroup.totalNumberOfTrays;
  return waveGroup[nurseryModes[0]]?.totalNumberOfTrays ?? 0;
};

export const sortLocationWaveByFarms = (locationWaveByFarms: LocationWaveByFarm[]) =>
  locationWaveByFarms
    .slice()
    .sort((a: LocationWaveByFarm, b: LocationWaveByFarm) => sortCallback(a.farm.name, b.farm.name));

export const hasWaveNurseryMode = (waveGroup: NurserySystemsGroup, nurseryModes: NurseryMode[]) =>
  Object.values(nurseryModes).some(mode => waveGroup[mode].totalNumberOfTrays);
