import {
  DsButton,
  DsPlaceholder,
  DsRow,
  DsCard,
  DsSpacer,
  DsTab,
  DsTabContainer,
  DsColumn
} from '@infarm/design-system-react';
import { useLocation, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useDebounce } from 'usehooks-ts';
import { LOCATION_DETAIL } from './queries';
import { FarmModel, FarmState, OperationalFarm } from '../../types';
import { formatFullDate, toIsoDate } from '../../utils/date-functions';
import {
  Span,
  CardContainer,
  FullDate,
  Card,
  CardLink,
  PageHeader,
  UpperButtonContainer
} from './style';
import { AssignGrower } from './AssignGrower';
import { hasPermission } from '../../utils/user';
import { inHubCategory, inHubCategoryValues, writeLocationAllowedRoles } from '../constants';
import { PhotosTabDetail } from './PhotosTabDetail';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { NurseryTabDetail } from './NurseryTabDetail';
import { ProductionTabDetail } from './ProductionTabDetail';

export const LocationDetail = () => {
  const { state } = useLocation() as { state: { tabIndex: number } };
  const { locationUuid } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(state?.tabIndex || 0);
  const [getLocationData, { data, error, loading }] = useLazyQuery(LOCATION_DETAIL);
  const debouncedSelectedTabIndex = useDebounce<number>(selectedTabIndex, 100);
  useEffect(() => {
    getLocationData({
      variables: {
        uuid: locationUuid,
        farmModel: [FarmModel.ACRE, FarmModel.INSTOREV2, FarmModel.GROWTH_CHAMBER],
        farmStateNot: FarmState.NEW
      }
    });
  }, [locationUuid, getLocationData]);

  if (!data || loading) return <DsPlaceholder />;
  if (error) return <h1>Something went wrong. Please try again</h1>;

  const upcomingDays = [1, 2, 3].map((offset): Date => {
    const date = new Date();
    return new Date(date.setDate(date.getDate() + offset));
  });

  const DateCard = ({ date }: { date: Date }) => {
    return (
      <CardLink to={`daily/${toIsoDate(date)}`}>
        <Card class="date-card" clickable>
          <span>{date.toLocaleString('en-us', { weekday: 'short' })}</span>
          <h2>{date.getDate()}</h2>
        </Card>
      </CardLink>
    );
  };

  const farms = [
    ...(data.location.farms as OperationalFarm[])?.map(farm => ({
      ...farm,
      isEligibleForAutomation: false
    })),
    ...(data.location.automationFarms as OperationalFarm[])?.map(farm => ({
      ...farm,
      isEligibleForAutomation: true
    }))
  ];
  const hasFarms = !!farms.length;
  const isInhubLocation = inHubCategoryValues.includes(data.location.category);

  const canUpdateLocation = hasPermission(writeLocationAllowedRoles);
  const showNurseryOverviewTab =
    (hasPermission(['super_admin']) && data.location.category === inHubCategory.PRODUCTION) ||
    data.location.isSelfNursery;

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations',
            link: '/locations',
            dataCy: 'LocationDetail___goBackOneLevel'
          },
          {
            text: data.location.name
          }
        ]}
      />
      <PageHeader>
        <h2>{data.location.name}</h2>
        {canUpdateLocation && (
          <UpperButtonContainer>
            <Link to="edit">
              <DsButton label="Location Settings"></DsButton>
            </Link>
          </UpperButtonContainer>
        )}
      </PageHeader>
      <Span>{data.location.locationGroup.name}</Span>
      {!isInhubLocation && (
        <AssignGrower assignedUsers={data.location.assignedUsers} hasFarms={hasFarms} />
      )}
      {isInhubLocation && (
        <CardContainer>
          <DsCard>
            <h2>Today</h2>
            <FullDate>{formatFullDate(new Date())}</FullDate>
            <DsSpacer />
            <DsRow>
              <Link to={`daily/${toIsoDate(new Date())}`}>
                <DsButton label="View Workstations" stretch primary style={{ flex: 2 }}></DsButton>
              </Link>
              <DsSpacer />
            </DsRow>
          </DsCard>
          <DsCard>
            <DsColumn>
              <h2>Upcoming</h2>
              <DsSpacer />
              <DsRow>
                {upcomingDays.map((date: Date) => (
                  <DateCard date={date} key={date.toDateString()} />
                ))}
              </DsRow>
              <DsRow>
                <DsButton label="View Schedule" stretch primary disabled style={{ flex: 2 }} />
                <DsSpacer />
              </DsRow>
            </DsColumn>
          </DsCard>
          <DsSpacer />
        </CardContainer>
      )}
      <DsTabContainer
        selected={selectedTabIndex}
        onChangeTab={event => {
          event.preventDefault();
          setSelectedTabIndex(event.detail.index);
        }}
      >
        <DsTab label="Plant Production">
          <ProductionTabDetail
            farms={farms}
            isActive={debouncedSelectedTabIndex === 0}
            isInhubLocation={isInhubLocation}
          />
        </DsTab>
        {showNurseryOverviewTab && (
          <DsTab label="Nursery Overview">
            <NurseryTabDetail isActive={debouncedSelectedTabIndex === 1} farms={farms} />
          </DsTab>
        )}
        {/* https://infarm.atlassian.net/browse/SWPD-256 */}
        {/* <DsTab label="Harvest Performance" data-cy="LocationDetail___HarvestPerformanceTab"> */}
        {/*   <performance-report location-uuid={locationUuid}></performance-report> */}
        {/* </DsTab> */}
        {!isInhubLocation && (
          <DsTab label={'Visit Photos'}>
            <PhotosTabDetail
              isActive={debouncedSelectedTabIndex === (showNurseryOverviewTab ? 3 : 2)}
            />
          </DsTab>
        )}
      </DsTabContainer>
    </>
  );
};
