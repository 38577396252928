import { Table, Th, THead, Tr } from './style';
import { TableBody } from './TableBody';

export interface RowData {
  rowData: {
    name: string;
    uuid: string;
    taskUuids?: string[];
    statuses?: string[];
  };
  totalNumberOfTrays: number;
  showCommitButton?: boolean;
}

export interface TableBodyProps {
  rows: RowData[];
  excessRows?: RowData[];
  totalNumberOfTrays: number;
  showCommitButton?: boolean;
}

export interface TableProps extends TableBodyProps {
  thead: string[];
  stretch?: boolean;
}

export const OverviewTable = (props: TableProps) => {
  const { thead, stretch, ...tableBodyProps } = props;
  return (
    <Table stretch={stretch}>
      <THead>
        <Tr>
          {thead.map(header => (
            <Th key={header}>{header}</Th>
          ))}
          {tableBodyProps.showCommitButton && <Th key={'commit-header'}></Th>}
        </Tr>
      </THead>
      <TableBody {...tableBodyProps} />
    </Table>
  );
};
