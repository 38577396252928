import { Chip } from '@material-ui/core';
import { PhotosTableRow, WeekDayFormat } from '../../../types';
import { formatFullDate, getLocaleWeekDay } from '../../../utils/date-functions';
import {
  Tr,
  Td,
  PhotoThumbnail,
  PhotoThumbnailsWrapper,
  PhotoWithHiddenCount,
  PicturesHidedCount
} from '../style';
import UserIcon from '../../../assets/user.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PHOTOS_PER_ROW = 4;

interface TableRowProps {
  photo: PhotosTableRow;
  onThumbnailClick: (photoIndex: number, date: string) => void;
}

export const TableRow = (props: TableRowProps) => {
  const { photo, onThumbnailClick } = props;

  const date = photo.date;
  const photoDate =
    formatFullDate(date, false) + ` (${getLocaleWeekDay(date, WeekDayFormat.SHORT)})`;
  const photosUrls = photo.photosDetails.slice(0, PHOTOS_PER_ROW);
  const picturesHiddenCount = photo.photosDetails.length - PHOTOS_PER_ROW;

  return (
    <Tr key={photo.uuid}>
      <Td> {photoDate} </Td>
      <Td>
        <Chip
          key={photo.uuid + photo.date}
          label={photo.uploadedBy.name}
          icon={<img src={UserIcon} alt="User Icon" />}
        />
      </Td>
      <Td>
        <PhotoThumbnailsWrapper>
          {photosUrls.map((element, index) => {
            const isLastPictureAndHasCounter =
              index === PHOTOS_PER_ROW - 1 && picturesHiddenCount > 0;
            if (isLastPictureAndHasCounter) {
              return (
                <PhotoWithHiddenCount
                  key={photo.uuid + index}
                  onClick={() => onThumbnailClick(index, photo.date)}
                >
                  <PicturesHidedCount> +{picturesHiddenCount} </PicturesHidedCount>
                  <PhotoThumbnail src={element.photoUrl} applyOpacity />
                </PhotoWithHiddenCount>
              );
            }
            return (
              <div key={photo.uuid + index}>
                <PhotoThumbnail
                  src={element.photoUrl}
                  onClick={() => onThumbnailClick(index, photo.date)}
                />
              </div>
            );
          })}
        </PhotoThumbnailsWrapper>
      </Td>
      <Td>{photo.description}</Td>
    </Tr>
  );
};
