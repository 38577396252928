import gql from 'graphql-tag';

export const HUB_NAME = gql`
  query NameForHub($uuid: ID!) {
    location(uuid: $uuid) {
      name
      uuid
      category
      isSelfNursery
    }
  }
`;

export const SEEDING_STATE = gql`
  query SeedingState($uuid: ID!, $date: Date!) {
    location(uuid: $uuid) {
      uuid
      tasksForDate(date: $date, filter: { sourceType: SEEDING_STATION, taskType: PLANTING }) {
        statuses
      }
    }
  }
`;
