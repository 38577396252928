import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Icon, InputAdornment, OutlinedInput } from '@material-ui/core';
import { BoldP, DatesWrapper, DateSelectionContainer, Delimiter, TimeLimitError } from './style';
import { PhotosTable } from './PhotosTable';
import { differenceInDays, addDays } from 'date-fns';

export const PhotosTabDetail = ({ isActive }: { isActive: boolean }) => {
  const today = new Date();
  const threeWeeksBefore = addDays(today, -23);
  const oneWeekAfter = addDays(today, 7);

  const [startDate, setStartDate] = useState<Date>(threeWeeksBefore);
  const [endDate, setEndDate] = useState<Date>(oneWeekAfter);
  const [shouldDisplayTimeLimitError, setShouldDisplayTimeLimitError] = useState<boolean>(false);
  const maxEndDate = addDays(startDate, 30);

  const onStartDateClick = (date: Date) => {
    const endDateInOneMonth = addDays(date, 30);
    const diffDays = differenceInDays(endDate, date);

    setStartDate(date);

    if (diffDays > 30 || diffDays < 0) {
      setEndDate(endDateInOneMonth);
      setShouldDisplayTimeLimitError(true);
    } else {
      setShouldDisplayTimeLimitError(false);
    }
  };

  const onEndDateClick = (date: Date) => {
    setEndDate(date);
    setShouldDisplayTimeLimitError(false);
  };

  return (
    <>
      <DateSelectionContainer>
        <BoldP>Timeframe </BoldP>
        <DatesWrapper>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Date"
            selected={startDate}
            onChange={onStartDateClick}
            startDate={startDate}
            customInput={
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <Icon>event</Icon>
                  </InputAdornment>
                }
              />
            }
          />
          <Delimiter>-</Delimiter>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Date"
            selected={endDate}
            onChange={onEndDateClick}
            minDate={startDate}
            maxDate={maxEndDate}
            customInput={
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <Icon>event</Icon>
                  </InputAdornment>
                }
              />
            }
          />
          {shouldDisplayTimeLimitError && (
            <TimeLimitError> You can only select a maximum of 1 month timeframe</TimeLimitError>
          )}
        </DatesWrapper>
      </DateSelectionContainer>
      <PhotosTable startDate={startDate} endDate={endDate} isActive={isActive} />
    </>
  );
};
