import { FC } from 'react';
import { DsRow, DsSpacer } from '@infarm/design-system-react';
import { formatDay, generateDaysOfWeek } from '../../../../utils/date-functions';
import { WeekDate, DaysRow } from './style';
import { isToday } from 'date-fns';

interface NurserySystemsDaysRowProps {
  date: Date;
}

export const NurserySystemsDaysRow: FC<NurserySystemsDaysRowProps> = ({ date }) => {
  const weekDates = generateDaysOfWeek(date);

  return (
    <DsRow>
      <DsSpacer size={3} />
      <DaysRow className="span-7">
        {weekDates.map(weekDate => (
          <WeekDate isToday={isToday(weekDate)} key={weekDate.toString()}>
            {isToday(weekDate) ? 'Today' : formatDay(weekDate)}
          </WeekDate>
        ))}
      </DaysRow>
    </DsRow>
  );
};
