import { DsButton, DsRow } from '@infarm/design-system-react';
import styled, { css } from 'styled-components';

export const DaysRow = styled(DsRow)`
  display: grid;
  grid-template-columns: repeat(7, 3fr) 1fr;
  justify-self: stretch;
  vertical-align: middle;
`;

export const WeekDate = styled.span<{ isToday?: boolean }>`
  padding: 8px 24px 8px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  ${({ isToday }) =>
    isToday
      ? css`
          font-weight: 700;
          color: rgb(0, 0, 0);
        `
      : ''}
`;

export const CellSpan = styled.span<{
  isSmall?: boolean;
  isDarkBlack?: boolean;
  isBolder?: boolean;
}>`
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  ${({ isSmall }) => (isSmall ? 'font-size: 12px;' : '')}
  ${({ isDarkBlack }) => (isDarkBlack ? 'color: rgba(0, 0, 0, .9);' : '')}
  ${({ isBolder }) => (isBolder ? 'font-weight: 700;' : '')}
`;

export const ModalWrapper = styled.div`
  max-width: 450px;
  border-radius: 4px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 28px;
`;

export const ModalHeading = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const ModalButtonsRow = styled(DsRow)`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-bottom: 12px;
`;

export const ScheduleListButton = styled(DsButton)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductionOrderNote = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
`;

export const ServiceDeskLink = styled.a`
  color: #007aff;
  text-decoration: underline;
`;

export const DividerTitle = styled.p`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DividerIcon = styled.img`
  margin: 0 8px;
`;

export const RowsWrapper = styled.div`
  margin-top: 16px;
`;
