import React, { useRef, useState, useLayoutEffect } from 'react';
import Xarrow from 'react-xarrows';
import Leaf from '../../../../../src/assets/Leaf.svg';
import line from '../../../../../src/assets/line.svg';
import {
  BlackRow,
  StyledSpan,
  HeaderCell,
  LeftArrowEnd,
  DashArrowEnd,
  RightArrowEnd,
  TueToSatLeaf,
  EndOfColumn,
  TueToSatPlantDay,
  LastArrowStartTueToSat
} from '../../style';
import { getWeekDays } from '../../constants';
import { WeekDays } from '../../../../types';
import { XArrowHeads } from '../../types';

interface HeaderProps {
  firstPlantingWeekDay: string;
  dashness: boolean;
  isHubDemandForm?: boolean;
}

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const getLastArrowMargin = (dayOfTheWeek: number, headerCellRef: any) => {
  if (headerCellRef === null) {
    return 0;
  }

  return (5 - dayOfTheWeek) * headerCellRef?.clientWidth + 20;
};

export const TueToSatHeader = (props: HeaderProps) => {
  const { firstPlantingWeekDay, dashness, isHubDemandForm } = props;
  const weekDays = getWeekDays();
  const firstPlantingWeekDayIndex = Object.keys(WeekDays).indexOf(firstPlantingWeekDay);

  const headerCellRef = useRef<HTMLElement>(null); // we render last arrow based on cell width.
  useWindowSize(); //  if we resize browser, force to check the new width of cell so arrow is also displayed good on browser resize

  return (
    <BlackRow key="TueToSatHeader">
      <HeaderCell className="span-3 bold" colSpan={2}>
        <StyledSpan id={XArrowHeads.LEFT_ARROW_START} />
      </HeaderCell>

      {weekDays.map((weekDay, index) => {
        const isLastColumn = weekDay === WeekDays.sunday;
        const shouldRenderLeftArrowEnding =
          !isLastColumn && weekDays[index + 1] === firstPlantingWeekDay;
        const shouldRenderDashedArrowBeginning = weekDay === firstPlantingWeekDay;

        if (shouldRenderDashedArrowBeginning) {
          return (
            <HeaderCell key={weekDay}>
              <TueToSatLeaf isHubDemandForm={isHubDemandForm}>
                <img src={isHubDemandForm ? line : Leaf} alt="Leaf" />
              </TueToSatLeaf>

              <EndOfColumn>
                <DashArrowEnd id={XArrowHeads.DASH_ARROW_END} />
              </EndOfColumn>

              <Xarrow
                start={XArrowHeads.DASH_ARROW_START}
                end={XArrowHeads.DASH_ARROW_END}
                color={'white'}
                strokeWidth={2}
                dashness={dashness}
                showHead={false}
              />
            </HeaderCell>
          );
        } else {
          if (shouldRenderLeftArrowEnding) {
            return (
              <HeaderCell className="span-1" key={weekDay}>
                <EndOfColumn>
                  <TueToSatPlantDay
                    isHubDemandForm={isHubDemandForm}
                    id={XArrowHeads.DASH_ARROW_START}
                  />
                </EndOfColumn>

                <LeftArrowEnd id={XArrowHeads.LEFT_ARROW_END} />

                <Xarrow
                  start={XArrowHeads.LEFT_ARROW_START}
                  end={XArrowHeads.LEFT_ARROW_END}
                  color={'white'}
                  strokeWidth={2}
                />
              </HeaderCell>
            );
          }

          if (isLastColumn) {
            return (
              <React.Fragment key={weekDay}>
                <HeaderCell
                  className="span-1"
                  ref={headerCellRef as React.RefObject<HTMLTableCellElement>}
                >
                  <EndOfColumn>
                    <RightArrowEnd id={XArrowHeads.RIGHT_ARROW_END} />
                  </EndOfColumn>

                  <LastArrowStartTueToSat
                    id="last"
                    key="last"
                    style={{
                      position: 'absolute',
                      marginLeft: `-${getLastArrowMargin(
                        firstPlantingWeekDayIndex,
                        headerCellRef?.current
                      )}px`,
                      marginTop: '13px'
                    }}
                  />

                  <Xarrow
                    start={'last'}
                    end={XArrowHeads.RIGHT_ARROW_END}
                    color={'white'}
                    strokeWidth={2}
                    showHead={false}
                  />
                </HeaderCell>
              </React.Fragment>
            );
          }

          return <HeaderCell className="span-1" key={weekDay} />;
        }
      })}
    </BlackRow>
  );
};
