import { LocationTableProps } from '.';
import { TableRow } from './TableRow';
import { Location } from '../../../types';
import { Tr, Td, Tbody } from '../styles';

export const TableBody = (props: LocationTableProps) => {
  const { loading, locations, tab } = props;

  if (loading)
    return (
      <tbody>
        <Tr>
          <Td>Loading....</Td>
        </Tr>
      </tbody>
    );
  if (!locations?.length)
    return (
      <tbody>
        <Tr>
          <Td>No locations are available at the moment</Td>
        </Tr>
      </tbody>
    );
  return (
    <Tbody data-cy="LocationTable___tableBody">
      {locations.map((location: Location) => (
        <TableRow key={tab + location.uuid} {...location} />
      ))}
    </Tbody>
  );
};
