import { groupBy } from 'lodash-es';
import { Grower } from '.';
import { sortCallback } from '../../../utils';

export const getFormattedGrowers = (growerSupervisors: Grower[], growers: Grower[]): Grower[] => {
  const mergedGrowers = [
    ...growerSupervisors.map((grower: Grower) => ({
      ...grower,
      role: 'Grower supervisor'
    })),
    ...growers.map((grower: Grower) => ({
      ...grower,
      role: 'Grower'
    }))
  ];

  if (!mergedGrowers.length) return [];

  return Object.entries(groupBy(mergedGrowers, 'uuid')).map(([uuid, grower]) => {
    return {
      uuid,
      name: grower[0].name,
      role: grower.map(grower => grower.role).join(', ')
    };
  });
};

export const sortGrowers = (list: Grower[]): Grower[] => {
  return list?.sort((a, b) => sortCallback(a.name, b.name));
};
