import React, { FunctionComponent, useState } from 'react';
import { DsButton } from '@infarm/design-system-react';
import { FarmDetailModal } from './FarmDetailModal';
import { FarmListItem } from './FarmListItem';
import { FarmItem } from './types';
import { sortFarmItems } from './utils';
import { H4, Small, P, FarmInfo, FarmInfoSpan, FarmList } from './styles';

export const AddAFarmSection: FunctionComponent<{
  farms: FarmItem[];
  onUpdate: Function;
}> = ({ farms, onUpdate }): JSX.Element => {
  const [addFarmModalVisible, setAddFarmModalVisible] = useState<boolean>(false);
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const [currentFarm, setCurrentFarm] = useState<FarmItem>();
  const showAddFarmModal = () => {
    setAddFarmModalVisible(true);
  };
  const closeFarmModal = () => {
    setDuplicateError(false);
    setAddFarmModalVisible(false);
  };
  const addOrUpdateFarm = (farmItem: FarmItem) => {
    const farmNameDuplicated = farms.some(({ name, uuid }) => {
      if (!farmItem.uuid) {
        return name.toLowerCase() === farmItem.name.toLowerCase();
      }
      return uuid !== farmItem?.uuid && name.toLowerCase() === farmItem.name.toLowerCase();
    });

    if (farmNameDuplicated) {
      setDuplicateError(true);
      return;
    }

    if (farmItem.uuid) {
      const index = farms.findIndex(farm => farm.uuid === farmItem.uuid);
      farms[index] = {
        ...farmItem
      };

      onUpdate([...farms]);
      closeFarmModal();
      return;
    }
    const update = [...farms, farmItem];
    onUpdate(update);
    closeFarmModal();
  };

  const removeFarmFromList = ({ name, model }: FarmItem) => {
    onUpdate(farms.filter((farm: FarmItem) => !(farm.name === name && farm.model === model)));
  };

  return (
    <section>
      {farms.length === 0 && (
        <span>
          <H4>
            <strong>Add A Farm</strong> <Small>optional</Small>
          </H4>
          <P>In order to create a planting schedule a Farm needs to be linked to the location.</P>
          <FarmInfo>
            <FarmInfoSpan>
              You can link the missing Farm ID later in the 'Farms &amp; Systems' tab.
            </FarmInfoSpan>
          </FarmInfo>
        </span>
      )}
      <FarmList>
        {sortFarmItems(farms).map((farm: FarmItem) => (
          <FarmListItem
            key={`${farm.name}-${farm.model}`}
            onRemove={() => removeFarmFromList(farm)}
            onEdit={() => {
              setCurrentFarm(farm);
              showAddFarmModal();
            }}
            farm={farm}
          />
        ))}
      </FarmList>
      <DsButton
        primary
        label="Add Farm"
        icon="add"
        onClick={() => {
          setCurrentFarm(undefined);
          showAddFarmModal();
        }}
        data-cy="AddAFarmSection___addButton"
      />
      <FarmDetailModal
        isOpen={addFarmModalVisible}
        onClose={closeFarmModal}
        addOrUpdateFarm={addOrUpdateFarm}
        duplicateError={duplicateError}
        farm={currentFarm}
      />
    </section>
  );
};
