import styled from 'styled-components';
import { DsButton, DsModal } from '@infarm/design-system-react';
import { Toggle } from '../../pages/LocationDetail/style';

export const TrackVisualizationLinkWrapper = styled.div`
  margin-left: 12px;
`;
export const TrackVisualizationModalBody = styled.div`
  height: 70vh;
`;
export const TrackVisualizationModalDateControls = styled.div`
  margin: 10px 0;
  display: flex;
`;
export const TrackVisualizationModalContainer = styled(DsModal)`
  --mdc-dialog-min-width: 90vw !important;
`;
export const TrackVisualizationButton = styled(DsButton)`
  transform: rotate(90deg);
`;

export const Row = styled.div`
  display: inline-flex;
`;

export const FarmName = styled.h2`
  color: black;
`;

export const FarmShortId = styled.span`
  color: grey;
  align-items: center;
  display: flex;
  margin-left: 10px;
`;

export const ModelSubtitle = styled.span``;

export const TrackVisualizationProductionToggle = styled(Toggle)`
  margin-left: 20px;
`;
