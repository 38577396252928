import React, { FunctionComponent } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import styled from 'styled-components';
import { LocationList } from './pages/LocationList';
import { LocationDetail } from './pages/LocationDetail';
import { PlantingSchedule } from './pages/PlantingSchedule';
import apolloClient from './lib/apollo-client';
import { LocationForm } from './pages/LocationForm';
import { hasPermission } from './utils/user';
import { PlantingScheduleList } from './pages/PlantingSchedulesList';
import { writeLocationAllowedRoles } from './pages/constants';
import { InhubWorkstation } from './pages/InhubWorkstation';
import { InhubTasks } from './pages/InhubTasks';
import { InhubShrinkage } from './pages/InhubShrinkage';
import { InhubNurseryTasks } from './pages/InhubNurseryTasks';
import { HubDemand } from './pages/HubDemand';

const PageWrapper = styled.div`
  margin-top: 48px;
`;
const NoMatch = () => {
  return <h3>Invalid URL {window.location.pathname}</h3>;
};

export const App: FunctionComponent = () => {
  document.title = 'Farmboard - Locations (Gen 2)';

  return (
    <ApolloProvider client={apolloClient}>
      <PageWrapper className="ds-layout-column">
        <BrowserRouter basename={(globalThis as any).env.FARMBOARD_ROOT}>
          <Routes>
            <Route path="/locations" element={<LocationList />} />
            {hasPermission(writeLocationAllowedRoles) && (
              <Route path="/locations/create" element={<LocationForm />} />
            )}
            {hasPermission(writeLocationAllowedRoles) && (
              <Route path="/locations/:locationUuid/edit" element={<LocationForm />} />
            )}
            <Route path="/locations/:locationUuid" element={<LocationDetail />} />
            <Route path="/locations/:locationUuid/hub-demand" element={<HubDemand />} />
            <Route
              path="/locations/:locationUuid/:farmUuid/planting-schedules"
              element={<PlantingScheduleList />}
            />
            <Route
              path="/locations/:locationUuid/:farmUuid/planting-schedules/create"
              element={<PlantingSchedule />}
            />
            <Route
              path="/locations/:locationUuid/:farmUuid/planting-schedules/:plantingScheduleUuid/:type"
              element={<PlantingSchedule />}
            />
            <Route path="/locations/:locationUuid/daily/:date" element={<InhubWorkstation />} />
            <Route
              path="/locations/:locationUuid/daily/:date/workstations/nursery/:nurseryTaskType"
              element={<InhubNurseryTasks />}
            />
            <Route
              path="/locations/:locationUuid/daily/:date/workstations/product/:taskType"
              element={<InhubTasks />}
            />
            <Route
              path="/locations/:locationUuid/daily/:date/workstations/product/:taskType/shrinkage"
              element={<InhubShrinkage />}
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </PageWrapper>
    </ApolloProvider>
  );
};
