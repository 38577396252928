import styled from 'styled-components';
import { DsIcon } from '@infarm/design-system-react';
import { Section } from '../style';
import { WarningTypes, warningContent } from '../constants';

const WarningPanelWrapper = styled.div`
  display: flex;
  background-color: #f5f5f5;
  padding: 16px;
  color: rgba(0, 0, 0, 0.9);
  justify-content: space-between;
  span {
    color: #007aff;
    cursor: pointer;
  }
`;

const WarningTextWrapper = styled.div`
  display: flex;

  ds-icon {
    color: #ffc647;
    margin-right: 4px;
  }
`;

const WarningTitle = styled.p`
  font-weight: bold;
  margin-right: 8px;
`;

const DismissButton = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
`;

const WarningIcon = styled(DsIcon)<{ $color: string }>`
  color: ${({ $color }) => ($color ? `${$color}` : '#FFC647')};
  margin-right: 4px;
`;

interface WarningPanelProps {
  onDismiss: (type: WarningTypes) => void;
  scrollToInput: (type: WarningTypes) => void;
  warningType: WarningTypes;
}
export const WarningPanel = (props: WarningPanelProps) => {
  const { onDismiss, scrollToInput, warningType } = props;
  return (
    <Section marginBottom>
      <WarningPanelWrapper>
        <WarningTextWrapper>
          <WarningIcon $color={warningContent[warningType].iconColor}>
            {warningContent[warningType].icon}
          </WarningIcon>
          <WarningTitle>{warningContent[warningType].title} </WarningTitle>
          <p>
            {warningContent[warningType].text}
            <span onClick={() => scrollToInput(warningType)}>
              {warningContent[warningType].scrollText}
            </span>{' '}
            {warningContent[warningType].textEnd}
          </p>
        </WarningTextWrapper>
        <DismissButton onClick={() => onDismiss(warningType)}>Dismiss</DismissButton>
      </WarningPanelWrapper>
    </Section>
  );
};
