import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DsButton, DsPlaceholder } from '@infarm/design-system-react';
import { Icon, InputAdornment, OutlinedInput } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import {
  FilterRow,
  PageHeader,
  SortSelect,
  BoldP,
  DatesWrapper,
  Container,
  DatesSpacer
} from './styles';
import { GET_LOCATION_AND_FARM } from './queries';
import { PlantingScheduleTable } from './PlantingScheduleTable';
import { hasPermission } from '../../utils/user';
import { FarmModel } from '../../types';
import { ORDER_BY } from './constants';
import { acreScheduleAllowedRoles, instoreV2ScheduleAllowedRoles } from '../constants';
import { Options } from '../LocationDetail/style';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import 'react-datepicker/dist/react-datepicker.css';

export const PlantingScheduleList = () => {
  const { farmUuid, locationUuid } = useParams();
  const [orderBy, setOrderBy] = useState(ORDER_BY.CREATED_AT);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { data, loading, error } = useQuery(GET_LOCATION_AND_FARM, {
    variables: {
      farmUuid,
      locationUuid
    }
  });

  if ((!data && !error) || loading) return <DsPlaceholder />;

  const farmCycle = data?.operationalFarm?.farmCycle;
  const farmModel = data?.operationalFarm?.model;
  const farmName = data?.operationalFarm?.name;
  const isAcreFarm = farmModel === FarmModel.ACRE;
  const allowedRoles =
    farmModel === FarmModel.ACRE ? acreScheduleAllowedRoles : instoreV2ScheduleAllowedRoles;
  const isWriteAllowed = hasPermission(allowedRoles);

  const setOrder = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOrderBy(event.target.value as ORDER_BY);
  };

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations',
            link: `/locations`,
            dataCy: 'PlantingSchedulesList__goBackTwoLevels'
          },
          {
            text: data?.location?.name,
            link: `/locations/${locationUuid}`,
            dataCy: 'PlantingSchedulesList__goBackOneLevel'
          },
          {
            text: `${farmName} Planting Schedules`
          }
        ]}
      />
      <PageHeader data-cy="PlantingScheduleList___pageHeader">
        <h2>{farmName} Planting Schedules</h2>
        {isWriteAllowed && (
          <Link to="create">
            <DsButton
              label="Create New Schedule"
              data-cy="PlantingScheduleList___createNewSchedule"
              primary
            />
          </Link>
        )}
      </PageHeader>
      <FilterRow>
        <div>
          <BoldP>Sort by (newest - oldest)</BoldP>
          <SortSelect variant="outlined" onChange={setOrder} value={orderBy} className="span-3">
            <Options value={ORDER_BY.CREATED_AT}>Date created</Options>
            <Options value={ORDER_BY.STARTING_DATE}>1st planting date</Options>
          </SortSelect>
        </div>
        <Container>
          <BoldP>Filter by First planting</BoldP>
          <DatesWrapper>
            <DatePicker
              portalId="planting-schedules-list"
              dateFormat="dd/MM/yyyy"
              placeholderText="Start date"
              selected={startDate}
              onChange={date => setStartDate(date as Date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
              customInput={
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon>event</Icon>
                    </InputAdornment>
                  }
                />
              }
            />
            <DatesSpacer>─</DatesSpacer>
            <DatePicker
              portalId="planting-schedules-list"
              dateFormat="dd/MM/yyyy"
              placeholderText="End date"
              selected={endDate}
              onChange={date => setEndDate(date as Date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={
                <OutlinedInput
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon>event</Icon>
                    </InputAdornment>
                  }
                />
              }
            />
          </DatesWrapper>
        </Container>
      </FilterRow>
      <PlantingScheduleTable
        isAcreFarm={isAcreFarm}
        orderBy={orderBy}
        farmCycle={farmCycle}
        startDate={startDate}
        endDate={endDate}
        isWriteAllowed={isWriteAllowed}
      />
    </>
  );
};
