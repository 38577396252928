import { FC } from 'react';
import { DsButton } from '@infarm/design-system-react';
import AcreImage from '../../../../../assets/acre.svg';
import NurseryChamberImage from '../../../../../assets/nursery_chamber.png';
import { FarmModel, OperationalFarm } from '../../../../../types';
import { isToday, toIsoDate } from '../../../../../utils/date-functions';
import { AnchorTagUnderline, ButtonContainer, DataRow, LeftBorderdSpan, Row } from '../../../style';
import {
  CellSpan,
  FarmDetailStyled,
  FarmInfo,
  FarmNameStyled,
  Image,
  InfoWrapper,
  OccupiedTraysText
} from '../style';
import { ExpandedRowData } from './types';
import { NurseryMode } from '../../../../constants';
import { LocationWaveByFarm } from '../../types';
import { hasWaveNurseryMode } from '../../utils';

const FarmName: FC<{ farm: OperationalFarm }> = ({ farm }) => {
  if (farm.model !== FarmModel.ACRE || !farm.serialNumber)
    return <FarmNameStyled>{farm.name}</FarmNameStyled>;
  return (
    <FarmNameStyled>
      <AnchorTagUnderline to={`/farms/${farm.serialNumber}`}>{farm.name}</AnchorTagUnderline>
    </FarmNameStyled>
  );
};

interface HeaderRowProps {
  waveByFarm: LocationWaveByFarm;
  data: { [date: string]: ExpandedRowData };
  isExpanded: boolean;
  toggleExpand: () => void;
  dateHeaders: Date[];
  selectedNurseryModes: NurseryMode[];
}

export const HeaderRow: FC<HeaderRowProps> = ({
  waveByFarm,
  data,
  isExpanded,
  toggleExpand,
  dateHeaders,
  selectedNurseryModes
}) => {
  const isFarmActive = hasWaveNurseryMode(waveByFarm.waveGroup, selectedNurseryModes);

  const calculateTotalTrays = (): number => {
    if (waveByFarm.farm.model === FarmModel.ACRE) {
      if (
        waveByFarm.farm.nurseryBenches !== undefined &&
        waveByFarm.farm.traysPerBench !== undefined
      ) {
        return waveByFarm.farm.nurseryBenches * waveByFarm.farm.traysPerBench;
      }
      return 0;
    }

    if (waveByFarm.farm.model === FarmModel.GROWTH_CHAMBER) {
      let totalBenches = 0;
      if (selectedNurseryModes.includes(NurseryMode.LIGHT)) {
        totalBenches += waveByFarm.farm.lightGerminationBenches || 0;
      }
      if (selectedNurseryModes.includes(NurseryMode.DARK)) {
        totalBenches += waveByFarm.farm.darkGerminationBenches || 0;
      }
      if (waveByFarm.farm.traysPerBench) {
        return totalBenches * waveByFarm.farm.traysPerBench;
      }
      return 0;
    }

    return 0;
  };

  const generateBenchesText = (): string => {
    if (waveByFarm.farm.model === FarmModel.ACRE) {
      return `${waveByFarm.farm.nurseryBenches} NiA Benches`;
    }

    if (waveByFarm.farm.model === FarmModel.GROWTH_CHAMBER) {
      if (
        selectedNurseryModes.includes(NurseryMode.DARK) &&
        selectedNurseryModes.includes(NurseryMode.LIGHT)
      ) {
        return `${
          (waveByFarm.farm.lightGerminationBenches || 0) +
          (waveByFarm.farm.darkGerminationBenches || 0)
        } Benches`;
      }
      if (selectedNurseryModes.includes(NurseryMode.LIGHT)) {
        return `${waveByFarm.farm.lightGerminationBenches || 0} LG Benches`;
      }
      if (selectedNurseryModes.includes(NurseryMode.DARK)) {
        return `${waveByFarm.farm.darkGerminationBenches || 0} DG Benches`;
      }
      return '0 Benches';
    }

    return '(farm model not found)';
  };

  const totalTrays = calculateTotalTrays();

  const benchesText = generateBenchesText();
  const traysText = `${totalTrays} Trays`;
  const benchesAndTraysText = `${benchesText} · ${traysText}`;

  const farmImageSrc = waveByFarm.farm.model === FarmModel.ACRE ? AcreImage : NurseryChamberImage;

  return (
    <Row rounded={!isExpanded} topRounded={isExpanded} lowOpacity={!isFarmActive}>
      <FarmInfo className="span-3">
        <Image src={farmImageSrc} alt={waveByFarm.farm.model} />
        <InfoWrapper>
          <FarmName farm={waveByFarm.farm} />
          <FarmDetailStyled>{benchesAndTraysText}</FarmDetailStyled>
        </InfoWrapper>
      </FarmInfo>
      <DataRow className="span-7">
        {dateHeaders.map(date => (
          <LeftBorderdSpan key={toIsoDate(date)}>
            <CellSpan isSmall isDarkBlack>
              <OccupiedTraysText isBolder={isToday(date)}>
                {data[toIsoDate(date)]?.totalNumberOfTrays || 0}
              </OccupiedTraysText>{' '}
              / {totalTrays}
            </CellSpan>
          </LeftBorderdSpan>
        ))}
      </DataRow>
      <ButtonContainer>
        <DsButton
          icon={isExpanded ? 'expand_less' : 'expand_more'}
          flat
          disabled={!isFarmActive}
          onClick={toggleExpand}
        />
      </ButtonContainer>
    </Row>
  );
};
