import { eachDayOfInterval, startOfWeek, endOfWeek, format, getISOWeek, isValid } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { WeekDayFormat } from '../types';

export type DateLike = string | Date;

/**
 * Returns the calendar date of `date` formatted as an ISO date
 *
 * @param date A date-like object
 */
export const toIsoDate = (date: DateLike): string => {
  const localTimezoneDate = offsetDateWithTimezone(date);
  //check if the date is valid in case of undefined values
  if (isValid(localTimezoneDate)) {
    return format(offsetDateWithTimezone(date), 'yyyy-MM-dd');
  }
  return '';
};

export const offsetDateWithTimezone = (date: DateLike): Date => {
  // when the arg is a valid date object return it back
  if (isValid(date)) {
    return new Date(date);
  }

  // transform the iso string date into a Date object
  const dateObject = new Date(date);

  if (dateObject.getHours() === 0 && dateObject.getMinutes() === 0 && dateObject.getSeconds() === 0)
    return dateObject;

  // add or remove the difference introduced by local timezones
  const minuteInMillisecs = 60000;
  const dateWithOffset = new Date(
    dateObject.getTime() + dateObject.getTimezoneOffset() * minuteInMillisecs
  );
  return dateWithOffset;
};

export const getLocaleWeekDays = (locale: Locale = enUS, dateFormat: string = 'EEE'): string[] => {
  const now = new Date();
  const weekDates = eachDayOfInterval({
    start: startOfWeek(now, { weekStartsOn: 1 }),
    end: endOfWeek(now, { weekStartsOn: 1 })
  });
  const weekDays = weekDates.map((date: Date) => {
    return format(date, dateFormat, { locale });
  });
  return weekDays;
};

/**
 * Returns the monday of the week which includes `date`
 *
 * @param date A date-like object
 */
export const getMonday = (date: DateLike): Date => {
  const d = offsetDateWithTimezone(date);
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const generateDaysOfWeek = (date: Date): Date[] => {
  const monday = getMonday(date);
  return [...Array(7)].map((_, index) => addDays(monday, index));
};

export const generateDaysOfWeekISO = (date: Date): string[] => {
  const monday = getMonday(date);
  return [...Array(7)].map((_, index) => toIsoDate(addDays(monday, index)));
};

/**
 * Returns the human-readable month name of the week which includes `date`
 *
 * @param date A date-like object
 */
export const formatMonth = (date: DateLike): string => {
  const first = getMonday(date);
  const last = new Date(first);
  last.setDate(first.getDate() + 6);
  if (first.getMonth() === last.getMonth()) {
    return first.toLocaleString('en-us', { year: 'numeric', month: 'long' });
  } else {
    const addYear: { year?: 'numeric' } =
      first.getFullYear() !== last.getFullYear() ? { year: 'numeric' } : {};
    const startMonth = first.toLocaleString('en-us', {
      ...addYear,
      month: 'long'
    });
    const endMonth = last.toLocaleString('en-us', {
      year: 'numeric',
      month: 'long'
    });
    return `${startMonth} / ${endMonth}`;
  }
};

export const weekNumber = (date: Date) => getISOWeek(date);

/**
 * Returns `true` if the date specified in `date` is today
 *
 * @param date A date-like object
 */
export const isToday = (date: DateLike): boolean => {
  return toIsoDate(date) === toIsoDate(new Date());
};

/**
 * Returns a human-readable short date for the day specified in `date`
 *
 * @param date A date-like object
 * @example 20 Thu
 */
export const formatDay = (date: DateLike): string => {
  const day = offsetDateWithTimezone(date);
  return day.toLocaleString('en-us', { weekday: 'short', day: '2-digit' });
};

/**
 * Returns a human-readable long date for the day specified in `date`
 *
 * @param date A date-like object
 */
export const formatFullDate = (date: DateLike, showWeekDay = true): string => {
  const day = offsetDateWithTimezone(date);
  if (showWeekDay)
    return day.toLocaleString('en-gb', {
      weekday: 'short',
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });

  return day.toLocaleString('en-gb', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

export const getLocaleWeekDay = (date: DateLike, format: WeekDayFormat | undefined) => {
  return offsetDateWithTimezone(date).toLocaleString('en-US', {
    weekday: format
  });
};

export const formatDateWithMonth = (date: DateLike) => {
  const day = offsetDateWithTimezone(date);
  return day.toLocaleString('en-gb', { day: '2-digit', month: 'short' }); // 17 Nov for example
};

/**
 * Returns a human-readable weekday for the day specified in `date`
 *
 * @param date A date-like object
 */
export const formatWeekday = (date: Date): string => {
  const day = new Date(date);
  return day.toLocaleString('en-us', { weekday: 'long' });
};

/**
 * Returns a human-readable date (full date without weekday) for the day specified in `date`
 *
 * @param date A date-like object
 */
export const formatDate = (date: DateLike): string => {
  const day = offsetDateWithTimezone(date);
  return day.toLocaleString('en-gb', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

export const addDays = (date: DateLike, days: number) => {
  const result = offsetDateWithTimezone(date);
  result.setDate(result.getDate() + days);
  return result;
};

/**
 * Returns a human-readable date with weekday, month and year for the day specified in `date`
 *
 * @param date A date-like object
 * @example Mon, 16 May 2022
 */
export const formatDayWithYearMonth = (date: DateLike): string => {
  const day = offsetDateWithTimezone(date);
  return day.toLocaleString('en-us', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};
