import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query GetLocations($cursor: String, $filter: LocationFilter) {
    locations(cursor: $cursor, filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
      edges {
        uuid
        name
        category
        locationGroup {
          name
        }
        farms: operationalFarms(farmModel: [ACRE, INSTOREV2]) {
          uuid
          name
          model
          assignedUsers {
            name
          }
        }
      }
    }
  }
`;
