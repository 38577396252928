import { useQuery } from '@apollo/client';
import { DsButton, DsColumn, DsRow, DsTab, DsTabContainer } from '@infarm/design-system-react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import {
  formatDate,
  formatFullDate,
  formatWeekday,
  isToday,
  offsetDateWithTimezone,
  toIsoDate
} from '../../utils/date-functions';
import { HUB_NAME, SEEDING_STATE } from './queries';
import NurseryLeafUrl from '../../assets/nursery-leaf.svg';
import ProductLeafUrl from '../../assets/product-leaf.svg';
import { NurseryTaskType } from './constants';
import { displayNurseryTaskType } from './utils';
import {
  TodayDate,
  DateButtons,
  TodayButton,
  Section,
  SectionTitle,
  WorkstationsCardGrid,
  Card,
  CardText,
  CardName
} from './style';
import { SeedingPlanStatus } from '../../components/SeedingPlanStatus';

export const InhubWorkstation = () => {
  const { locationUuid, date } = useParams();
  const { data } = useQuery(HUB_NAME, {
    variables: { uuid: locationUuid }
  });
  const { data: seedingData } = useQuery(SEEDING_STATE, {
    variables: { uuid: locationUuid, date },
    fetchPolicy: 'cache-and-network'
  });
  const seedingState = seedingData?.location?.tasksForDate?.statuses;
  const [currentDate, setCurrentDate] = useState(offsetDateWithTimezone(date || new Date()));
  const navigate = useNavigate();

  const setDate = (date: Date) => {
    setCurrentDate(date);
    const newDate = toIsoDate(date);
    navigate(`/locations/${locationUuid}/daily/${newDate}/`, { replace: true });
  };

  const selectToday = () => {
    setDate(new Date());
  };

  const selectPreviousDay = () => {
    setDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
  };

  const selectNextDay = () => {
    setDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
  };

  const navigateToNurseryTaskPage = (nursertTaskType: NurseryTaskType) => {
    navigate(
      `/locations/${locationUuid}/daily/${toIsoDate(
        currentDate
      )}/workstations/nursery/${nursertTaskType}`
    );
  };

  const fullDate = formatFullDate(currentDate);
  const shorterDate = formatDate(currentDate);
  const weekday = formatWeekday(currentDate);

  const showNurserySection = data?.location?.isSelfNursery;

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations',
            link: `/locations`,
            dataCy: 'InhubWorkstation__goBackTwoLevels'
          },
          {
            text: data?.location?.name,
            link: `/locations/${locationUuid}/`,
            dataCy: 'InhubWorkstation__goBackOneLevel'
          },
          {
            text: 'Workstations'
          }
        ]}
      />
      <DsRow>
        <div className="span-10">
          {isToday(currentDate) ? (
            <>
              <h1>Today</h1> <TodayDate className="ds-lead">{fullDate}</TodayDate>
            </>
          ) : (
            <>
              <h1>{weekday}</h1> <p className="ds-lead">{shorterDate}</p>
            </>
          )}
        </div>
        <DateButtons className="span-2">
          <TodayButton label="Today" onClick={selectToday}></TodayButton>
          <DsButton icon="navigate_before" flat onClick={selectPreviousDay}></DsButton>
          <DsButton icon="navigate_next" flat onClick={selectNextDay}></DsButton>
        </DateButtons>
      </DsRow>
      <DsTabContainer>
        <DsTab selected label="Workstations">
          {showNurserySection && (
            <Section>
              <SectionTitle>
                <img src={NurseryLeafUrl} alt="Nursery" /> Nursery
              </SectionTitle>
              <WorkstationsCardGrid>
                <Card clickable onClick={() => navigateToNurseryTaskPage(NurseryTaskType.SEEDING)}>
                  <DsColumn>
                    <CardName>
                      {displayNurseryTaskType(NurseryTaskType.SEEDING)}
                      <SeedingPlanStatus statuses={seedingState} />
                    </CardName>
                    {(!seedingState || seedingState.length === 0) && (
                      <CardText>No tasks for today.</CardText>
                    )}
                  </DsColumn>
                </Card>
                <Card clickable onClick={() => navigateToNurseryTaskPage(NurseryTaskType.ACRE)}>
                  <DsColumn>
                    <CardName>{displayNurseryTaskType(NurseryTaskType.ACRE)}</CardName>
                  </DsColumn>
                </Card>
              </WorkstationsCardGrid>
            </Section>
          )}
          <Section>
            <SectionTitle>
              <img src={ProductLeafUrl} alt="Product" /> Plant Production
            </SectionTitle>
            <inhub-workstations-tab
              hub={locationUuid}
              date={toIsoDate(currentDate)}
            ></inhub-workstations-tab>
          </Section>
        </DsTab>
      </DsTabContainer>
    </>
  );
};
