export enum Tab {
  PLANT_PRODUCTION = 'Plant Production',
  ISSUES = 'Issues',
  PHOTOS = 'Photos',
  HARVEST_PERFORMANCE = 'Harvest Performance',
  NURSERY_OVERVIEW = 'Nursery Overview'
}

export enum UnitFilter {
  PLANTS = 'Plants',
  TRAYS = 'Trays',
  BENCH = 'Benches'
}

export enum VarietyFilter {
  FARM = 'Farm',
  VARIETY = 'Variety'
}

export enum PRODUCTION_ORDER_TYPES {
  GROUP = 'Group',
  INDIVIDUAL = 'Individual'
}

export const defaultVarietyFilterValue = VarietyFilter.FARM;
export const defaultUnitFilterValue = UnitFilter.TRAYS;

export const unitFilterOptions = [UnitFilter.PLANTS, UnitFilter.TRAYS];
export const unitFilterOptionsWithBench = [UnitFilter.PLANTS, UnitFilter.TRAYS, UnitFilter.BENCH];

export const varietyFilterOptions = [VarietyFilter.FARM, VarietyFilter.VARIETY];
