import { useController, UseControllerProps } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InputSection, SectionTitleWrapper, SectionTitle, SectionSubTitle } from '../style';
import { FormProps } from '../types';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import styled from 'styled-components';

export const StyledFormControl = styled(FormControl)`
  .MuiInputBase-root {
    width: 288px;

    & fieldset {
      border-color: #757575;
    }
  }
`;

const growingCycles = [
  { week: 2, day: 14 },
  { week: 3, day: 21 },
  { week: 4, day: 28 }
];

export const GrowingCycle = (props: UseControllerProps<FormProps>) => {
  const { field } = useController(props);
  const { type } = useParams();

  return (
    <InputSection>
      <SectionTitleWrapper data-cy="PlantingSchedule___GrowingCycleTitle">
        <SectionTitle>Schedule Growing Cycle</SectionTitle>
        <SectionSubTitle>Choose a growing cycle for your schedule</SectionSubTitle>
      </SectionTitleWrapper>
      <StyledFormControl variant="outlined">
        <Select
          fullWidth
          className="growingCycle"
          type="text"
          onChange={field.onChange}
          value={(field.value as string) || 'placeholder'}
          disabled={!!type}
        >
          <MenuItem value="placeholder" disabled>
            Select Growing Cycle
          </MenuItem>
          {growingCycles.map(({ week, day }) => {
            return (
              <MenuItem key={day} value={day}>
                {`${week} weeks`}
              </MenuItem>
            );
          })}
        </Select>
      </StyledFormControl>
    </InputSection>
  );
};
