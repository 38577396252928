import { Link, useNavigate } from 'react-router-dom';
import { FarmModel, Location } from '../../../types';
import { Tr, Td } from '../styles';

export const TableRow = (location: Location) => {
  const { name, locationGroup, category, farms, uuid } = location;
  const isAcreFarm = farms.length && farms[0].model === FarmModel.ACRE;
  const navigate = useNavigate();

  const onRowClick = (event: React.MouseEvent) => {
    if ((event.target as HTMLTableRowElement).tagName.toLowerCase() !== 'a') navigate(uuid);
  };

  const growers =
    (!isAcreFarm &&
      [
        ...new Set(
          farms
            .map(farm => farm.assignedUsers)
            .flat()
            .map(user => user!.name)
        )
      ].join(', ')) ||
    '-';

  return (
    <Tr onClick={onRowClick} selectable data-cy={`table-row${uuid}`}>
      <Td bold>
        <Link to={uuid}>{name}</Link>
      </Td>
      <Td>{locationGroup?.name || ''}</Td>
      <Td>{category}</Td>
      <Td>{farms.length}</Td>
      <Td>{growers}</Td>
    </Tr>
  );
};
