import { DsRow } from '@infarm/design-system-react';
import styled, { css } from 'styled-components';
import { StyledSpan } from '../../style';

export const Image = styled.img`
  height: 48px;
  width: 48px;
`;

export const BenchIcon = styled.img`
  height: 32px;
  width: 48px;
`;

export const DaysRow = styled(DsRow)`
  display: grid;
  grid-template-columns: repeat(7, 3fr) 1fr;
  justify-self: stretch;
  vertical-align: middle;
`;

export const WeekDate = styled.span<{ isToday?: boolean }>`
  padding: 8px 24px 8px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
  ${({ isToday }) =>
    isToday
      ? css`
          font-weight: 700;
          color: rgb(0, 0, 0);
        `
      : ''}
`;

export const FarmContainer = styled.div<{ isInactive: boolean }>`
  margin-bottom: 16px;
  ${({ isInactive }) => (isInactive ? 'opacity: 1;' : '')}
`;

export const FarmInfo = styled(StyledSpan)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  word-break: break-word;
`;

export const LoadInfo = styled(StyledSpan)`
  padding: 8px 16px;
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  word-break: break-word;
`;

export const StageRecipeInfo = styled(StyledSpan)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  word-break: break-word;
`;

export const FarmNameStyled = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

export const FarmDetailStyled = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

export const StageRecipeName = styled.span`
  font-weight: 400;
  font-size: 16px;
`;

export const StageRecipeDetail = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

export const CellSpan = styled.span<{
  isSmall?: boolean;
  isDarkBlack?: boolean;
  isBolder?: boolean;
}>`
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  ${({ isSmall }) => (isSmall ? 'font-size: 12px;' : '')}
  ${({ isDarkBlack }) => (isDarkBlack ? 'color: rgba(0, 0, 0, .9);' : '')}
  ${({ isBolder }) => (isBolder ? 'font-weight: 700;' : '')}
`;

export const OccupiedTraysText = styled.span<{ isBolder?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  ${({ isBolder }) => (isBolder ? 'font-weight: 700;' : '')}
`;

export const SubTitle = styled.p`
  color: #999999;
  font-weight: 400;
`;

export const Overlay = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const NurseryContainer = styled.div`
  position: relative;
`;
