import { getUser, JwtUser, userHasRole } from '@infarm/auth';

export const hasPermission = (allowedRoles: string[]): boolean => {
  const user: JwtUser | null = getUser();
  const userGlobalRoles: string[] = user?.user_claims?.roles_ || [];
  const userAssignments = user?.user_claims?.assignments_ || {};

  const userAssignmentsRole: string[] =
    Object.keys(userAssignments)
      .map(assignmentId => userAssignments[assignmentId].roles)
      .flat() || [];

  const hasValidGlobalRoles = userGlobalRoles.some(role => allowedRoles.includes(role));
  const hasValidAssignmentsRole = userAssignmentsRole.some(role => allowedRoles.includes(role));

  return hasValidGlobalRoles || hasValidAssignmentsRole;
};

export const userIsAdmin = () => {
  const user: JwtUser | null = getUser();
  return user && userHasRole('super_admin', user);
};
