import { DsIcon } from '@infarm/design-system-react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { FindByValues } from '../../pages/PlantingSchedule/__tests/mock';
import { ReactElement } from 'react';

const Icon = styled(DsIcon)<{ color?: string }>`
  color: ${({ color }) => color || '#000000'};
`;
const TooltipHeading = styled.p<{ headingColor?: string }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${({ headingColor }) => (headingColor ? headingColor : '')};
`;

const TooltipText = styled.span<{ bodyColor?: string }>`
  color: ${({ bodyColor }) => (bodyColor ? bodyColor : '')};
`;
interface IconTooltipProps {
  heading?: string;
  body?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  icon?: string;
  styles?: any;
  children?: ReactElement;
  placement?: any;
}

export const IconTooltip = ({
  heading,
  icon,
  body,
  styles,
  children,
  ...props
}: IconTooltipProps) => {
  const defaultStyles = {
    backgroundColor: '#000000',
    maxWidth: 350,
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '20px',
    padding: '16px'
  };
  const CustomizedTooltip = withStyles(() => ({
    tooltip: {
      ...defaultStyles,
      ...styles,
      color: styles?.headingColor
    },
    arrow: {
      color: styles?.arrowColor || '#000000'
    }
  }))(Tooltip);

  return (
    <CustomizedTooltip
      {...props}
      title={
        <>
          <TooltipHeading headingColor={styles?.headingColor}>{heading}</TooltipHeading>
          <TooltipText bodyColor={styles?.bodyColor}>{body}</TooltipText>
        </>
      }
      arrow
    >
      {children ? (
        children
      ) : (
        <Icon title="" color={styles?.iconColor} role={FindByValues.TOOLTIP_ICON}>
          {icon || 'info'}
        </Icon>
      )}
    </CustomizedTooltip>
  );
};
