import { NurseryContainer, SubTitle, Overlay, OverlayText } from './style';
import { NurserySystemsDetails } from './NurserySystemsDetails';
import { NurseryMode } from '../../../constants';
import { LocationWaveByFarm } from '../types';
import { sortLocationWaveByFarms } from '../utils';
import { SpacerVertical } from '../style';

export const NurserySystems = ({
  date,
  waveByFarms,
  selectedNurseryModes,
  isGeneratingNurseryDemand
}: {
  date: Date;
  waveByFarms: LocationWaveByFarm[];
  selectedNurseryModes: NurseryMode[];
  isGeneratingNurseryDemand: boolean;
}) => (
  <>
    <h3>Nursery Systems</h3>
    <SubTitle>
      Numbers in the Nursery systems table reflect what exists (or will exist) in that farm on a
      selected day.
    </SubTitle>
    <SpacerVertical />
    <NurseryContainer>
      {isGeneratingNurseryDemand && (
        <Overlay>
          <OverlayText>Nursery demand is being generated. Please check it later.</OverlayText>
        </Overlay>
      )}
      <NurserySystemsDetails
        date={date}
        waveByFarms={sortLocationWaveByFarms(waveByFarms ?? [])}
        selectedNurseryModes={selectedNurseryModes}
      />
    </NurseryContainer>
  </>
);
