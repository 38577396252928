import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';

const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: #000000;
    margin-right: 12px;
    &:hover {
      background-color: #000000;
      opacity: 0.9;
    }
  }

  & .MuiButton-label {
    color: #fff;
    text-transform: none;
    font-weight: normal;
  }

  &.Mui-disabled {
    background-color: #ebebeb;

    &.MuiButton-outlined {
      border: #ebebeb;
    }
    & .MuiButton-label {
      color: rgba(0, 0, 0, 0.2);
    }
    & .MuiCircularProgress-root {
      margin-right: 10px;
    }
  }
`;

interface SubmitButtonProps {
  label?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  variant?: string;
}

const SubmitButton = ({ label, disabled, loading, onClick, type, ...props }: SubmitButtonProps) => {
  return (
    <StyledButton
      {...props}
      type={type}
      variant="outlined"
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading && <CircularProgress variant="indeterminate" size={20} color="inherit" />}
      {label || ''}
    </StyledButton>
  );
};

export default SubmitButton;
