import { useCallback, useEffect, useState } from 'react';
import { DsIcon } from '@infarm/design-system-react';
import { useController, UseControllerProps, useWatch, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { max, min } from 'lodash-es';
import { toIsoDate } from '../../../utils/date-functions';
import {
  SectionTitleWrapper,
  SectionTitle,
  SectionSubTitle,
  Section,
  StyledInput,
  DatesWrapper,
  SectionDates,
  ErrorMessage,
  SeedingDateWrapper,
  WarningIcon
} from '../style';
import { FormProps, FormState } from '../types';
import {
  getPropagationTimeBoundary,
  selectConfiguredVarieties,
  getFirstHarvestDate,
  getFirstSeedingDate,
  getStageRecipeForFarmCycle
} from '../../../utils/planting-schedule';
import { PlantingScheduleWeeklyConfiguration, StageRecipe } from '../../../types';
import { isPast } from 'date-fns';

interface DateConfigProps extends UseControllerProps<FormProps> {
  availableStageRecipes: StageRecipe[];
}

export const DateConfig = (props: DateConfigProps) => {
  const { availableStageRecipes } = props;
  const { type } = useParams();
  const [firstHarvestDate, setFirstHarvestDate] = useState<Date | undefined>();
  const { setValue, getValues } = useFormContext<FormProps>();
  const { field } = useController(props);
  const firstSeedingDate: Date = useWatch({
    name: 'firstSeedingDate'
  });
  const configuration: PlantingScheduleWeeklyConfiguration[] = useWatch({
    name: 'configuration'
  });
  const { growingCycle } = getValues();
  const {
    formState: { errors }
  } = useFormContext();

  const calculatePropagationTime = useCallback(() => {
    const selectedFarmVarieties = getStageRecipeForFarmCycle(availableStageRecipes, growingCycle!);
    const selectedConfiguredVarieties = selectConfiguredVarieties(
      selectedFarmVarieties,
      configuration
    );

    const maxSelectedPropagationTime = getPropagationTimeBoundary(selectedConfiguredVarieties, max);
    const minGlobalPropagationTime = getPropagationTimeBoundary(selectedFarmVarieties, min);
    const propagationTime =
      maxSelectedPropagationTime !== 0 ? maxSelectedPropagationTime : minGlobalPropagationTime;

    return propagationTime;
  }, [configuration, growingCycle, availableStageRecipes]);

  useEffect(() => {
    const propagationTime = calculatePropagationTime();
    if (field.value) {
      const plantingDate = field.value as Date;
      setValue('firstSeedingDate', getFirstSeedingDate(plantingDate, propagationTime));
      setFirstHarvestDate(getFirstHarvestDate(plantingDate, growingCycle!));
    } else {
      setValue('firstSeedingDate', undefined);
      setFirstHarvestDate(undefined);
    }
  }, [field.value, calculatePropagationTime, setValue, setFirstHarvestDate, growingCycle]);

  const isSeedingDateInPast = isPast(firstSeedingDate);
  const hasAnyVarietySelected = configuration.some(config => config.stageRecipeUuid !== '');
  const isWarningOn = isSeedingDateInPast && hasAnyVarietySelected;

  const plantingDateIsoMin = toIsoDate(new Date());
  // `plantingDateIsoMax` needs to be refined: https://infarm.atlassian.net/browse/OSDOPM-616
  const plantingDateIsoMax = '9999-12-31';

  return (
    <Section>
      <SectionTitleWrapper>
        <SectionTitle>Schedule Start Date*</SectionTitle>
        <SectionSubTitle>
          Please choose the first desired planting date, the seeding date and harvesting date will
          be calculated accordingly
        </SectionSubTitle>
      </SectionTitleWrapper>
      <SectionDates>
        <SeedingDateWrapper>
          <label>First Planting Date</label>
          <StyledInput
            type="date"
            placeholder="plantingDate"
            variant="outlined"
            InputProps={{
              inputProps: {
                min: plantingDateIsoMin,
                max: plantingDateIsoMax
              }
            }}
            value={toIsoDate(field.value! as Date)}
            onInput={field.onChange}
            error={!!errors?.firstPlantingDate}
            $warning={isWarningOn}
            disabled={type === FormState.VIEW || type === FormState.EDIT}
          />
          {isWarningOn && type !== FormState.VIEW && <WarningIcon>warning</WarningIcon>}
          {errors?.firstPlantingDate && (
            <ErrorMessage>
              <DsIcon>error_outline</DsIcon> {errors.firstPlantingDate?.message}
            </ErrorMessage>
          )}
        </SeedingDateWrapper>
        <DatesWrapper>
          <div>
            <label>First Seeding Date</label>
            <h2>{firstSeedingDate?.toDateString() || '-'}</h2>
          </div>
          <div>
            <label>First Harvest Date</label>
            <h2>{firstHarvestDate?.toDateString() || '-'}</h2>
          </div>
        </DatesWrapper>
      </SectionDates>
    </Section>
  );
};
