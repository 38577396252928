import { FC, useState } from 'react';
import { UnitFilter } from '../../constants';
import { FarmContainer } from '../../style';
import { VarietyHeaderRow } from './VarietyHeaderRow';
import { FarmRow } from './FarmRow';
import { LocationTaskGroupByStageRecipe } from '../types';

interface Props {
  taskGroupByStageRecipe: LocationTaskGroupByStageRecipe;
  weekDates: string[];
  selectedUnitFilter: UnitFilter;
}

export const ProductionExpandableRowVariety: FC<Props> = ({
  taskGroupByStageRecipe,
  weekDates,
  selectedUnitFilter
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(curr => !curr);

  return (
    <FarmContainer className={isExpanded ? 'expanded' : ''}>
      <VarietyHeaderRow
        isExpanded={isExpanded}
        toggleExpand={toggleExpand}
        selectedUnitFilter={selectedUnitFilter}
        weekDates={weekDates}
        taskGroupByStageRecipe={taskGroupByStageRecipe}
      />
      {isExpanded &&
        taskGroupByStageRecipe.taskGroup.byOperationalFarm.map((taskGroupByFarm, idx) => (
          <FarmRow
            key={idx}
            taskGroupByFarm={taskGroupByFarm}
            weekDates={weekDates}
            selectedUnitFilter={selectedUnitFilter}
            isExpanded={isExpanded}
          />
        ))}
    </FarmContainer>
  );
};
