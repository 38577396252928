import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { DsModal } from '@infarm/design-system-react';
import { GET_PHOTOS } from '../queries';
import { Table, THead, Th, TRheader, DateTh, UploadByTh, PhotosTh } from '../style';
import { TableBody } from './TableBody';
import { toIsoDate } from '../../../utils/date-functions';
import { PhotoCarousel } from './PhotoCarousel';
import { groupPhotosByDate } from '../utils';
import { Error } from '../../../components/Error';

interface PhotosTableProps {
  startDate: Date | null;
  endDate: Date | null;
  isActive: boolean;
}
interface inputVariables {
  locationUuid?: string;
  date?: string;
  dateRangeBefore?: string;
  dateRangeAfter?: string;
}

export const PhotosTable = ({ startDate, endDate, isActive }: PhotosTableProps) => {
  const { locationUuid } = useParams();

  const [getAllPhotos, { data, error, loading }] = useLazyQuery(GET_PHOTOS, {
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    const filter: inputVariables = {};
    if (locationUuid) {
      filter.locationUuid = locationUuid;
    }
    if (startDate && endDate) {
      filter.dateRangeAfter = toIsoDate(startDate);
      filter.dateRangeBefore = toIsoDate(endDate);

      if (isActive)
        getAllPhotos({
          variables: {
            filter
          }
        });
    }
  }, [locationUuid, getAllPhotos, startDate, endDate, isActive]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sliderDate, setSliderDate] = useState('');
  const [sliderCurrentPhotoIndex, setSliderCurrentPhotoIndex] = useState(-1);
  const [hasJumpedToPhotoIndex, setHasJumpedToPhotoIndex] = useState(false);

  const photos = data?.allPhotos || [];
  const photosByDate = groupPhotosByDate(photos);
  const sliderPhotos = photosByDate.find(photosByDate => photosByDate.date === sliderDate);

  let sliderRef: any = useRef(null);

  const updateRef = (updatedRef: any) => {
    sliderRef = updatedRef;
  };

  const swipeRight = () => sliderRef.slickNext();
  const swipeLeft = () => sliderRef.slickPrev();

  const onThumbnailClick = (photoIndex: number, date: string) => {
    setIsModalOpen(true);
    setSliderDate(date);
    setSliderCurrentPhotoIndex(photoIndex);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setHasJumpedToPhotoIndex(false);
    setSliderDate('');
    setSliderCurrentPhotoIndex(-1);
  };

  useEffect(() => {
    // initialSlide library prop doesn't do the work alone. Force going to the selected photo thumbnail index
    if (
      isModalOpen &&
      sliderCurrentPhotoIndex !== -1 &&
      !hasJumpedToPhotoIndex &&
      typeof sliderRef.slickGoTo === 'function' // we make sure the carousel ref is initialised
    ) {
      sliderRef.slickGoTo(sliderCurrentPhotoIndex, true);
      setHasJumpedToPhotoIndex(true); // jump only in carousel entrace
    }
  }, [isModalOpen, sliderCurrentPhotoIndex, hasJumpedToPhotoIndex, sliderRef]);

  const photoCarouselProps = {
    sliderPhotos,
    setSliderCurrentPhotoIndex,
    updateRef,
    sliderCurrentPhotoIndex,
    swipeRight,
    swipeLeft
  };

  if (error) return <Error error={error} />;
  return (
    <>
      <Table>
        <THead>
          <TRheader>
            <DateTh>Mission Date</DateTh>
            <UploadByTh>Uploaded by</UploadByTh>
            <PhotosTh>Photos (Farm/POS)</PhotosTh>
            <Th>Note</Th>
          </TRheader>
        </THead>
        <TableBody loading={loading} photos={photosByDate} onThumbnailClick={onThumbnailClick} />
      </Table>
      <DsModal open={isModalOpen} onClosed={onModalClose} width={1100}>
        <PhotoCarousel {...photoCarouselProps} />
      </DsModal>
    </>
  );
};
