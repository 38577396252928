export enum FarmModel {
  ACRE = 'ACRE',
  GROWTH_CHAMBER = 'GROWTH_CHAMBER',
  INSTOREV1 = 'INSTOREV1',
  INSTOREV1_NURSERY = 'INSTOREV1_NURSERY',
  INSTOREV2 = 'INSTOREV2'
}

export enum AcreType {
  ACRE_32 = 'ACRE_32',
  ACRE_24 = 'ACRE_24'
}

export enum TaskType {
  PLANTING = 'PLANTING',
  HARVEST = 'HARVEST',
  PACKING = 'PACKING',
  COMPLETED = 'COMPLETED'
}

export enum Role {
  GROWER = 'grower',
  GROWER_SUPERVISOR = 'grower_supervisor'
}

export enum PlantingScheduleState {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  IN_PROCESSING = 'IN_PROCESSING',
  NEW = 'NEW',
  PROCESSED = 'PROCESSED'
}

export enum FarmState {
  NEW = 'NEW',
  ARCHIVED = 'ARCHIVED',
  ACTIVE = 'ACTIVE'
}

export enum StageRecipeStatus {
  DISCONTINUED = 'DISCONTINUED',
  PRODUCTION = 'PRODUCTION',
  RESEARCH = 'RESEARCH'
}

export interface StageRecipe {
  uuid: string;
  name: string;
  trayType?: TrayType;
  duration: number;
  nurseryMaxPropagationTime: number;
  internalCode: string;
  weight: string;
  density?: number;
  plantGroup?: string;
  isCurrentVersion?: boolean;
  currentStageRecipe?: CurrentStageRecipe;
  operationalRecipeUuid?: string;
  isDarkGermination?: boolean;
}

export type CurrentStageRecipe = Omit<StageRecipe, 'currentStageRecipe'>;

export interface Task {
  uuid: string;
  stageRecipe?: StageRecipe;
  trayType?: string;
  completed?: boolean;
  cancelled?: boolean;
  plantsOfStageRecipe?: number;
  traysPerStageRecipe?: number;
  shrinkageReports?: ShrinkageReport[];
}

export interface ExcessDemand {
  uuid: string;
  quantity: number;
  date?: string;
  seededAt?: string;
  stageRecipeUuid?: string;
  stageRecipe?: StageRecipe;
  totalNumberOfTrays?: number;
}

export interface BatchWithFarm extends Batch {
  farm?: OperationalFarm;
}

export interface Bench {
  uuid: string;
  capacityUnits?: number;
  name?: string;
  operationalBenchId?: number;
}

export interface Batch {
  batchId?: string;
  fullBatchId?: string;
  batchType?: TaskType;
  totalTrays?: number;
  tasks?: Array<Task>;
  date: string;
  cancelled?: boolean;
  plots?: Array<{ name?: string }>;
  bench: Bench;
}

interface LocationGroup {
  name: string;
}

export interface User {
  uuid: string;
  name: string;
  email?: string;
}

export interface StageRecipeQuantity {
  date: string;
  quantity?: number;
  trays?: number;
  benches: number;
}

export interface StageRecipeConfigurationDetails {
  stageRecipe: StageRecipe;
  stageRecipeQuantities: Array<StageRecipeQuantity>;
}

export interface OperationalFarm {
  uuid: string;
  shortId?: string;
  stageRecipe?: StageRecipe;
  name: string;
  serialNumber: string | null;
  stageRecipeTotals?: Array<StageRecipeQuantity>;
  stageRecipes?: Array<StageRecipeConfigurationDetails>;
  batches?: [Batch];
  nurseryBenches?: number;
  capacityInBenches?: number;
  schedulableBenches?: number;
  capacityUnitsPerBench?: number;
  traysPerBench?: number;
  lightGerminationBenches?: number;
  darkGerminationBenches?: number;
  farmCycle: number;
  model: FarmModel;
  assignedUsers: [User] | null;
  state?: FarmState;
  // This property is not returned from the middleware, but is rather added manually in the FB code for the ease.
  // Check out `LocationDetail/index.tsx` page for reference.
  isEligibleForAutomation?: boolean;
}

export interface Location {
  name: string;
  uuid: string;
  category: string;
  farms: [OperationalFarm];
  stageRecipeTotals?: Array<StageRecipeQuantity>;
  locationGroup: LocationGroup;
}

export interface TrayType {
  name: string;
  uuid: string;
  plantsPerTray: number;
  surfaceUnits: number;
}

export enum WeekDays {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday'
}

export enum WeekDayFormat {
  LONG = 'long',
  SHORT = 'short',
  NARROW = 'narrow'
}

type DailyNumberOfTrays = {
  [day in WeekDays]: number;
};

export interface PlantingScheduleWeeklyConfiguration extends DailyNumberOfTrays {
  trayType: TrayType | undefined;
  stageRecipeUuid?: string;
  stageRecipeName?: string;
  stageRecipeDensity?: number;
}
export interface FarmConfigDailyTotal {
  trays: number;
  benches: number;
  units: number;
  harvestedBenches: number;
  surplusOrDeficit: number;
}

export type PerDayConfiguration = {
  [day in WeekDays]: FarmConfigDailyTotal;
};

export interface FarmConfigTotals {
  trays: number;
  benches: number;
  fillingPercentage: number;
  perDay: PerDayConfiguration;
}

export interface VarietyConfigurationInput {
  stageRecipeUuid?: string;
  numberOfTrays: number;
}

export type PlantingScheduleWeeklyConfigurationInput = {
  [day in WeekDays]: VarietyConfigurationInput[];
};

export interface VarietyConfigurationFromServer {
  stageRecipe: StageRecipe;
  numberOfTrays: number;
}

export type WeeklyConfigurationFromServer = {
  [day in WeekDays]: VarietyConfigurationFromServer[];
};

export interface Summary {
  status: string;
  firstIncomingMovementDate: string;
  lastIncomingMovementDate: string;
  firstOutgoingMovementDate: string;
  lastOutgoingMovementDate: string;
}

export interface PlantingSchedule {
  name: string;
  comment: string;
  firstPlantingDate: string;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: User;
  createdBy: User;
  state: PlantingScheduleState;
  operationalFarm: OperationalFarm;
  maxPropagationDays: number;
  configuration: WeeklyConfigurationFromServer;
  summary: Summary;
}

export interface PhotoUploadedBy {
  name: string;
  uuid: string;
}

export interface Photo {
  date: string;
  description: string;
  photoUrl: string;
  uuid: string;
  uploadedBy: PhotoUploadedBy;
  createdAt: string;
}

export interface PhotoDetail {
  photoUrl: string;
  createdAt: string;
}

export type PhotosTableRow = Omit<Photo, 'photoUrl' | 'createdAt'> & {
  photosDetails: PhotoDetail[];
};

export interface ShrinkageReason {
  name?: string;
  uuid?: string;
}

export interface ShrinkageReport {
  quantity?: number;
  reasons?: ShrinkageReason[];
  description?: string;
}

export interface TaskGroup {
  totalNumberOfTrays: number;
  totalNumberOfPlants: number;
  byStageRecipe: TaskGroupByStageRecipe[];
  byDate: TaskGroupByDate[];
  byOperationalFarm: TaskGroupByOperationalFarm[];
}

export interface TaskGroupByStageRecipe {
  stageRecipe: StageRecipe;
  taskGroup: TaskGroup;
}

export interface TaskGroupByDate {
  date: string;
  taskGroup: TaskGroup;
}

export interface TaskGroupByOperationalFarm {
  operationalFarm: OperationalFarm;
  taskGroup: TaskGroup;
}

export const enum Status {
  DEFAULT = 0,
  SUCCESS = 1,
  WARNING = 2,
  ERROR = 3,
  INFO = 4
}

export interface DRFErrorResponse {
  url: string;
  status: number;
  statusText: string;
  body: {
    non_field_errors?: string[];
  };
}
