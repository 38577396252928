export const sortCallback = (a: string, b: string) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};
interface unknownObject {
  [key: string]: string;
}

export const getSelectData = (
  items: unknownObject[] | string[],
  value?: string,
  text?: string,
  shouldSort?: boolean | undefined,
  upperCaseFirstLetter?: boolean
) => {
  if (!items?.length) return [];
  if (value && text) {
    const mappedItems = (items as unknownObject[]).map(item => ({
      value: item[value],
      text: upperCaseFirstLetter ? capitalizeString(item[text]) : item[text]
    }));

    if (shouldSort) {
      return mappedItems.sort((a, b) => sortCallback(a.text, b.text));
    }
    return mappedItems;
  }

  const mappedStringItems = (items as string[]).map(item => ({
    value: item,
    text: upperCaseFirstLetter ? capitalizeString(item) : item
  }));

  if (shouldSort) {
    return mappedStringItems.sort((a, b) => sortCallback(a.text, b.text));
  }
  return mappedStringItems;
};

export const parseClipboardData = (data: string) => {
  return data.split(/\r\n|\n|\r/).map(row => row.split('\t'));
};

export const capitalizeString = (data: string) =>
  data.charAt(0).toUpperCase() + data.slice(1).toLocaleLowerCase();
