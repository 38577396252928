import { useFormContext } from 'react-hook-form';
import { OperationalFarm } from '../../../types';
import { TotalsRow, TotalsCell, TotalLabel, EmptyRow } from '../style';
import { DailyTotalValues, HubDemandConfig, PlantingWeekDays, TotalValues } from '../types';
import { calculateTotals, grandTotals } from '../utils';

interface TableTotalsProps {
  dateColumns: string[];
  tableConfigs?: HubDemandConfig[];
  plantingWeekDays: PlantingWeekDays;
  isGrandTotal?: boolean;
  groupedConfigurations?: { [uuid: string]: HubDemandConfig[] };
}

export const TableTotals = (props: TableTotalsProps) => {
  const { tableConfigs, isGrandTotal, plantingWeekDays, dateColumns, groupedConfigurations } =
    props;
  const { getValues } = useFormContext();
  const { hubDetails } = getValues();
  const [farmConfiguration] = (hubDetails?.operationalFarms || []) as OperationalFarm[];

  const totalRows = Object.keys(TotalValues) as Array<keyof DailyTotalValues>;

  const totals =
    groupedConfigurations && isGrandTotal
      ? grandTotals(groupedConfigurations, farmConfiguration)
      : calculateTotals(tableConfigs!, farmConfiguration, !!isGrandTotal);

  return (
    <>
      {isGrandTotal && <EmptyRow />}
      {totalRows.map(row => {
        if (isGrandTotal && TotalValues[row] === TotalValues.extraTrays) {
          return null;
        }

        return (
          <TotalsRow isGrandTotal={isGrandTotal} key={row}>
            <TotalsCell />
            <TotalsCell>
              {isGrandTotal ? `total ${TotalValues[row]}` : TotalValues[row]}
              {TotalValues[row] === TotalValues.totalBenches && (
                <TotalLabel>Round up to full bench</TotalLabel>
              )}
            </TotalsCell>
            {dateColumns.map(weekday => {
              const value =
                TotalValues[row] === TotalValues.totalBenches
                  ? Math.ceil(totals[plantingWeekDays[weekday]]?.[row] || 0)
                  : totals[plantingWeekDays[weekday]]?.[row];
              return <TotalsCell key={weekday}>{value || 0}</TotalsCell>;
            })}
          </TotalsRow>
        );
      })}
    </>
  );
};
