import { FC } from 'react';
import { DsIcon } from '@infarm/design-system-react';
import AcreImage from '../../assets/acre.svg';
import MissingFarms from '../../assets/missing_farms.svg';
import InstoreV2Image from '../../assets/instore-v2_farm_front_icon.png';
import NurseryChamberImage from '../../assets/nursery_chamber.png';
import { farmSelectTypes } from './constants';

import {
  FarmRow,
  FarmRowInfo,
  FarmImg,
  FarmName,
  SmallInRow,
  StyledIconButton,
  FarmIdStyled,
  FarmIdMissingStyled
} from './styles';
import { FarmItem } from './types';
import { FarmModel } from '../../types';
import { hasPermission } from '../../utils/user';

const FarmID: FC<{ farm: FarmItem }> = ({ farm }) => {
  if (farm.model === FarmModel.GROWTH_CHAMBER) return null;
  if (farm.serialNumber) return <FarmIdStyled>ID {farm.serialNumber}</FarmIdStyled>;
  return <FarmIdMissingStyled>Farm ID missing</FarmIdMissingStyled>;
};

export const FarmListItem: FC<{
  onRemove: Function;
  onEdit: Function;
  farm: FarmItem;
}> = ({ onRemove, onEdit, farm }) => {
  const removeItem = () => onRemove(farm);
  const editItem = () => onEdit(farm);

  let iconImage;
  switch (farm.model) {
    case FarmModel.ACRE:
      iconImage = AcreImage;
      break;
    case FarmModel.INSTOREV2:
    case FarmModel.INSTOREV1:
      iconImage = InstoreV2Image;
      break;
    case FarmModel.GROWTH_CHAMBER:
      iconImage = NurseryChamberImage;
      break;
    default:
      iconImage = MissingFarms;
      break;
  }

  const farmType = farmSelectTypes.find(
    farmType => farmType.capacityInBenches === farm.capacityInBenches
  );

  const farmLabel =
    farm?.nurseryBenches && hasPermission(['super_admin'])
      ? `${farmType?.text} | ${farm?.nurseryBenches} nursery benches`
      : farm?.model === FarmModel.GROWTH_CHAMBER
      ? `${farmType?.text} | ${farm.darkGerminationBenches} DG${
          farm.lightGerminationBenches ? ` | ${farm.lightGerminationBenches} LG` : ''
        } benches`
      : farmType?.text;

  return (
    <FarmRow>
      <FarmImg src={iconImage} alt={farm.model.replace('_', ' ')}></FarmImg>
      <FarmRowInfo>
        <FarmName>{farm.name}</FarmName>
        <SmallInRow>{farmLabel}</SmallInRow>
      </FarmRowInfo>
      {farm.uuid ? (
        <>
          <FarmID farm={farm} />
          <StyledIconButton onClick={editItem}>
            <DsIcon>edit</DsIcon>
          </StyledIconButton>
        </>
      ) : (
        <StyledIconButton onClick={removeItem}>
          <DsIcon>close</DsIcon>
        </StyledIconButton>
      )}
    </FarmRow>
  );
};
