import React from 'react';
import { Controller } from 'react-hook-form';
import { DsTextField } from '.';

interface FormInputProps {
  name: string;
  control: any;
  label: string;
  setValue?: any;
  caption?: string;
}

export const FormInputText = ({ name, control, ...props }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DsTextField
          {...props}
          validationMessage={error?.message}
          onBlur={field.onBlur}
          onChange={field.onChange}
          value={field.value}
        />
      )}
    />
  );
};
