import { useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FarmModel, StageRecipe, DRFErrorResponse } from '../../types';
import { CREATE_PLANTING_SCHEDULE, TRIGGER_NURSERY_DEMAND_GENERATION } from './queries';
import { toIsoDate } from '../../utils/date-functions';
import {
  getStageRecipeForFarmCycle,
  getServerConfigInput,
  hasFarmMoreThanTwoTrays
} from '../../utils/planting-schedule';
import {
  FarmConfigTable,
  Modal,
  SubmitButtonWithValidation,
  ScheduleName,
  CommentInput,
  DateConfig,
  WarningPanel,
  BackDrop,
  GrowingCycle
} from './components';
import { WarningTypes, modalContent, DEFAULT_FORM_VALUES } from './constants';
import { FormProps, FormState, ModalTypes } from './types';
import { formSchema } from './validationSchema';
import { useNavigate, useParams } from 'react-router';
import { isPast, isToday } from 'date-fns';
interface Props {
  availableStageRecipes: StageRecipe[];
  defaultValues: FormProps;
  isSelfNurseryLocation: boolean;
}

export const PlantingScheduleForm = (props: Props) => {
  const { locationUuid, farmUuid, type } = useParams();
  const { isSelfNurseryLocation } = props;
  const dateConfigRef = useRef<null | HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.SUCCESS);
  const { availableStageRecipes, defaultValues } = props;
  const [warningList, setWarningList] = useState<WarningTypes[]>([]);
  const navigate = useNavigate();

  const formMethods = useForm<FormProps>({
    defaultValues,
    resolver: yupResolver(formSchema()),
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError'
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset
  } = formMethods;
  const { scheduleName, selectedFarm, configuration } = getValues();
  const isAcreFarm = selectedFarm!.model === FarmModel.ACRE;

  const [triggerNurseryDemand] = useMutation(TRIGGER_NURSERY_DEMAND_GENERATION, {
    variables: {
      locationUuid
    },
    onCompleted: () => {
      navigate(`/locations/${locationUuid}`, { state: { tabIndex: 1 } });
    },
    onError: () => {
      setModalType(ModalTypes.GENERATE_ERROR);
    }
  });

  const [createPlantingSchedule, { loading }] = useMutation(CREATE_PLANTING_SCHEDULE, {
    onCompleted: () => {
      if (isAcreFarm) {
        if (isSelfNurseryLocation) {
          setModalType(ModalTypes.GENERATE);
        } else {
          setModalType(ModalTypes.CONTINUE);
        }
      } else {
        setModalType(ModalTypes.SUCCESS);
      }
      setIsModalOpen(true);
    },
    onError: error => {
      let futureModalType = ModalTypes.ERROR;
      const drf_response: DRFErrorResponse = error.graphQLErrors[0].extensions
        .response as DRFErrorResponse;
      if (error.message.includes('429')) {
        futureModalType = ModalTypes.BUSY;
      } else if (
        drf_response.body.non_field_errors &&
        drf_response.body.non_field_errors.length &&
        drf_response.body.non_field_errors[0].includes('mix different plant groups')
      ) {
        futureModalType = ModalTypes.MIXED_PLANT_GROUPS;
      }
      setModalType(futureModalType);
      setIsModalOpen(true);
    }
  });

  const firstSeedingDate: Date | undefined = useWatch({
    name: 'firstSeedingDate',
    control
  });

  const growingCycle: number | undefined = useWatch({
    name: 'growingCycle',
    control
  });

  useEffect(() => {
    reset(defaultValues);
  }, [type, reset, defaultValues]);

  useEffect(() => {
    if (firstSeedingDate) {
      const isSeedingDateInPastOrToday = isPast(firstSeedingDate) || isToday(firstSeedingDate);
      const hasAnyVarietySelected = configuration.some(config => config.stageRecipeUuid !== '');
      const showDateWarning = isSeedingDateInPastOrToday && hasAnyVarietySelected;
      updateWarningList(showDateWarning);
    }
  }, [errors, firstSeedingDate, configuration]);

  const updateWarningList = (showWarning: boolean) => {
    if (showWarning) {
      setWarningList(list => {
        const hasDateWarning = list.some(warning => warning === WarningTypes.DATE);
        return hasDateWarning ? list : [...list, WarningTypes.DATE];
      });
    } else {
      setWarningList(list => {
        const newWarningList = list?.filter(w => w !== WarningTypes.DATE);
        return [...newWarningList];
      });
    }
  };

  const savePlantingSchedule = async (formData: FormProps) => {
    const { selectedFarm, firstPlantingDate, scheduleName, configuration, comment } = formData;

    createPlantingSchedule({
      variables: {
        input: {
          farm: selectedFarm!.uuid || '',
          firstPlantingDate: toIsoDate(firstPlantingDate!),
          name: scheduleName,
          configuration: getServerConfigInput(configuration),
          comment
        }
      }
    });
  };

  const onModalClosed = () => {
    setIsModalOpen(false);
  };

  const onApplySchedule = (formData: FormProps) => {
    const { configuration, hasDeficit } = formData;

    if (!isAcreFarm) {
      const hasMoreThanTwoTrays = hasFarmMoreThanTwoTrays(configuration);
      if (hasMoreThanTwoTrays) {
        setModalType(ModalTypes.MAX_TRAYS);
        setIsModalOpen(true);
      } else {
        savePlantingSchedule(formData);
      }
    } else {
      if (hasDeficit) {
        setModalType(ModalTypes.DEFICIT);
        setIsModalOpen(true);
      } else {
        savePlantingSchedule(formData);
      }
    }
  };

  const executeScroll = (type: WarningTypes) => {
    if (type === WarningTypes.DATE) {
      dateConfigRef?.current?.scrollIntoView();
    }
  };

  const dismissWarning = (type: WarningTypes) => {
    setWarningList(warningList.filter(w => w !== type));
  };

  const modalText =
    modalType === ModalTypes.SUCCESS
      ? `${scheduleName} has been successfully ${
          type === FormState.EDIT ? 'updated' : 'created'
        } and applied to ${selectedFarm?.name}`
      : modalContent[modalType].text;

  const modalSecondaryAction = () => {
    if (modalType === ModalTypes.MAX_TRAYS) {
      setModalType(ModalTypes.DISCARD);
    } else if (modalType === ModalTypes.CONTINUE || modalType === ModalTypes.GENERATE_ERROR) {
      navigate(`/locations/${locationUuid}/${farmUuid}/planting-schedules`);
    } else if (modalType === ModalTypes.GENERATE) {
      triggerNurseryDemand();
    } else {
      onModalClosed();
    }
  };

  const modalPrimaryAction = (nextAcreUuid?: string) => {
    const submittableTypes = [
      ModalTypes.ERROR,
      ModalTypes.DEFICIT,
      ModalTypes.SURPLUS,
      ModalTypes.BUSY,
      ModalTypes.MIXED_PLANT_GROUPS
    ];
    if (submittableTypes.includes(modalType)) {
      onModalClosed();
      handleSubmit(savePlantingSchedule)();
    } else if (modalType === ModalTypes.MAX_TRAYS) {
      onModalClosed();
    } else if (modalType === ModalTypes.CONTINUE || modalType === ModalTypes.GENERATE) {
      const isNextSameAcre = farmUuid === nextAcreUuid;
      navigate(`/locations/${locationUuid}/${nextAcreUuid}/planting-schedules/create`);
      if (isNextSameAcre) {
        reset({ ...DEFAULT_FORM_VALUES, selectedFarm, growingCycle });
        onModalClosed();
      }
    } else if (modalType === ModalTypes.GENERATE_ERROR) {
      triggerNurseryDemand();
    } else {
      navigate(`/locations/${locationUuid}/${farmUuid}/planting-schedules`);
    }
  };
  return (
    <>
      <Modal
        modalType={modalType}
        modalSecondaryAction={modalSecondaryAction}
        modalText={modalText || ''}
        heading={modalContent[modalType].heading}
        isModalOpen={isModalOpen}
        subHeading={modalContent[modalType].subHeading}
        onModalClosed={onModalClosed}
        modalPrimaryAction={modalPrimaryAction}
        primaryLabel={modalContent[modalType].primaryLabel}
        secondaryLabel={modalContent[modalType].secondaryLabel}
      />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onApplySchedule)}>
          {isAcreFarm && <GrowingCycle name="growingCycle" control={control} />}
          <ScheduleName name="scheduleName" control={control} />
          <div ref={dateConfigRef}>
            <DateConfig
              control={control}
              name={'firstPlantingDate'}
              availableStageRecipes={availableStageRecipes}
            />
          </div>
          <FarmConfigTable
            control={control}
            name={'configuration'}
            availableVarieties={getStageRecipeForFarmCycle(availableStageRecipes, growingCycle!)}
          />
          <CommentInput name="comment" control={control} />
          {warningList.length && type !== FormState.VIEW
            ? warningList.map((warning, warningIndex: number) => (
                <WarningPanel
                  key={warningIndex}
                  warningType={warning}
                  scrollToInput={executeScroll}
                  onDismiss={dismissWarning}
                />
              ))
            : null}
          <SubmitButtonWithValidation />
        </form>
      </FormProvider>
      <BackDrop loading={loading} />
    </>
  );
};
