import { FC } from 'react';
import { OperationalFarm, TaskType } from '../../../types';
import { UnitFilter, VarietyFilter } from '../constants';
import { ProductionTableLocationTotals } from './ProductionTableLocationTotals';
import { ProductionExpandableRowVariety } from './ProductionExpandableRowVariety';
import { LocationWithTaskGroup } from './types';
import { ProductionTablesByFarm } from './ProductionTablesByFarm';

interface Props {
  location: LocationWithTaskGroup;
  farms: OperationalFarm[];
  selectedUnitFilter: UnitFilter;
  selectedVarietyFilter: VarietyFilter;
  selectedTaskType: TaskType;
  weekDates: string[];
  isInhubLocation: boolean;
}

export const ProductionTableDetails: FC<Props> = ({
  location,
  farms,
  selectedUnitFilter,
  selectedVarietyFilter,
  selectedTaskType,
  weekDates,
  isInhubLocation
}) => {
  const stageRecipes = location.tasksForDate.byStageRecipe;
  const isViewByFarm = selectedVarietyFilter === VarietyFilter.FARM;

  return (
    <>
      <ProductionTableLocationTotals
        taskGroupsByDate={location.tasksForDate.byDate}
        selectedUnitFilter={selectedUnitFilter}
        weekDates={weekDates}
      />
      {isViewByFarm ? (
        <ProductionTablesByFarm
          individualFarms={farms.filter(farm => !farm.isEligibleForAutomation)}
          groupFarms={farms.filter(farm => farm.isEligibleForAutomation)}
          location={location}
          weekDates={weekDates}
          selectedUnitFilter={selectedUnitFilter}
          selectedTaskType={selectedTaskType}
          isInhubLocation={isInhubLocation}
        />
      ) : (
        stageRecipes.map(taskGroupByStageRecipe => (
          <ProductionExpandableRowVariety
            key={taskGroupByStageRecipe.stageRecipe.uuid}
            taskGroupByStageRecipe={taskGroupByStageRecipe}
            weekDates={weekDates}
            selectedUnitFilter={selectedUnitFilter}
          />
        ))
      )}
    </>
  );
};
