import { CircularProgress } from '@material-ui/core';
import { DsButton } from '@infarm/design-system-react';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { TRIGGER_NURSERY_DEMAND_GENERATION } from '../../../PlantingSchedule/queries';
import { StatuesWrapper, GeneratingStatus, StatusText } from '../../style';

export interface NurseryDemandStatuesProps {
  lastNurseryDemandUpdatedAt?: string;
  isGeneratingNurseryDemand: boolean;
}

export const NurseryDemandStatues = ({
  lastNurseryDemandUpdatedAt,
  isGeneratingNurseryDemand
}: NurseryDemandStatuesProps) => {
  const { locationUuid } = useParams();
  const [triggerNurseryDemand] = useMutation(TRIGGER_NURSERY_DEMAND_GENERATION, {
    variables: {
      locationUuid
    }
  });
  const nurseryDemandStatusText =
    lastNurseryDemandUpdatedAt &&
    format(new Date(lastNurseryDemandUpdatedAt), 'd LLL yyyy / HH:mm').replace('/', 'at');

  return (
    <StatuesWrapper>
      {nurseryDemandStatusText && !isGeneratingNurseryDemand && (
        <StatusText data-cy="lastUpdatedAt"> Last updated on {nurseryDemandStatusText}</StatusText>
      )}
      {isGeneratingNurseryDemand && (
        <GeneratingStatus data-cy="progressStatus">
          <StatusText>Nursery demand is being updated</StatusText>
          <CircularProgress color="inherit" size={20} />
        </GeneratingStatus>
      )}
      <DsButton
        label="Update"
        stretch
        primary
        data-cy="update"
        disabled={isGeneratingNurseryDemand}
        icon="cached"
        onClick={() => triggerNurseryDemand()}
      ></DsButton>
    </StatuesWrapper>
  );
};
