import styled from 'styled-components';
import {
  DsButton,
  DsModal,
  DsColumn,
  DsRow,
  DsSpacer,
  DsSnackbar
} from '@infarm/design-system-react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CANCEL_TASKS } from './queries';
import { Task } from '../../types';

const StyledButton = styled(DsButton).attrs(() => ({
  stretch: true,
  primary: false
}))`
  width: 120px;
`;

const Copy = styled.p`
  margin-block: 1em;
`;

interface ConfirmModalProps {
  task: Task;
}
export const ConfirmCancellationModal = ({ task }: ConfirmModalProps): JSX.Element => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [success, setSuccess] = useState(false);
  const isCancelled = task.cancelled;
  const label = isCancelled ? 'Cancelled' : 'Cancel Task';
  const taskUuids = [task.uuid];
  const [cancelTasks, { loading }] = useMutation(CANCEL_TASKS, {
    variables: { taskUuids },
    awaitRefetchQueries: true
  });
  const cancelCallback = async () => {
    setErrors(null);
    setSuccess(false);
    try {
      const result = await cancelTasks();
      if (result.errors) {
        throw result.errors;
      } else {
        setSuccess(true);
      }
    } catch (e: any) {
      const extractedErrors = e.networkError?.result?.errors ||
        (e.graphQLErrors?.length && e.graphQLErrors) ||
        (e.clientErrors?.length && e.clientErrors) || [e];
      setErrors(extractedErrors.map((err: Error) => err.message));
    }
    setPopupVisible(false);
  };
  const dismissPopup = () => {
    setPopupVisible(false);
  };
  return (
    <span>
      {errors && (
        <DsModal open hideCloseButton heading="Failed to cancel." onClosed={() => setErrors(null)}>
          <DsColumn>
            <Copy>The following error occured when trying to cancel the task:</Copy>
            {errors.map(message => (
              <p key={message}>{message}</p>
            ))}
            <DsRow>
              <DsSpacer size={10} />
              <DsButton primary label="Dismiss" onClick={() => setErrors(null)} />
            </DsRow>
          </DsColumn>
        </DsModal>
      )}
      {success && (
        <DsSnackbar open="true" labelText="Task was cancelled successfully."></DsSnackbar>
      )}
      {popupVisible && (
        <DsModal
          open
          hideCloseButton
          onClosed={dismissPopup}
          heading="Are you sure you want to cancel this task?"
        >
          <DsColumn>
            <Copy>You can not undo this action.</Copy>
            <DsRow>
              <DsSpacer size={10} />
              <DsButton secondary onClick={dismissPopup} label="Abort Cancellation" />
              <DsButton
                primary
                disabled={loading}
                label={loading ? 'Confirming…' : 'Confirm'}
                onClick={cancelCallback}
              />
            </DsRow>
          </DsColumn>
        </DsModal>
      )}
      <StyledButton
        disabled={loading || isCancelled}
        label={label}
        onClick={() => isCancelled || setPopupVisible(true)}
      />
    </span>
  );
};
export default ConfirmCancellationModal;
