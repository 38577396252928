import { ReactElement } from 'react';
import { Border, Content, Container } from './styles';

export const DividerWithContent = ({ children }: { children: ReactElement }) => {
  return (
    <Container>
      <Border />
      <Content>{children}</Content>
      <Border />
    </Container>
  );
};
