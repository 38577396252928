import { TableRow } from './TableRow';
import { Tr, Td, Tbody, NoPhotosTd } from '../style';
import { PhotosTableRow } from '../../../types';

interface TableBodyProps {
  loading: boolean;
  photos: PhotosTableRow[];
  onThumbnailClick: (photoIndex: number, date: string) => void;
}

export const TableBody = (props: TableBodyProps) => {
  const { loading, photos, onThumbnailClick } = props;

  if (loading)
    return (
      <Tbody>
        <Tr>
          <Td>Loading....</Td>
        </Tr>
      </Tbody>
    );
  if (!photos?.length)
    return (
      <Tbody>
        <Tr>
          <NoPhotosTd>No photos are available at the moment</NoPhotosTd>
        </Tr>
      </Tbody>
    );
  return (
    <>
      <Tbody>
        {photos.map((photo: PhotosTableRow) => (
          <TableRow key={photo.uuid} photo={photo} onThumbnailClick={onThumbnailClick} />
        ))}
      </Tbody>
    </>
  );
};
