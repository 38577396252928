import styled from 'styled-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsProgress } from '@infarm/design-system-react';
import {
  FarmConfigTotals,
  FarmModel,
  PlantingScheduleWeeklyConfiguration,
  StageRecipeQuantity,
  WeekDayFormat,
  WeekDays
} from '../../../types';
import { getWeeklyBenchCapacity } from '../../../utils/farm';
import { computeFarmFillingPercentage, generateTotals } from '../../../utils/planting-schedule';
import { Cell, EmptyRow, TextWrapper, TotalCount } from '../style';
import { FormProps } from '../types';
import { getWeekDays, benchCapacityTooltipsTexts, styleValues } from '../constants';
import { useLazyQuery } from '@apollo/client';
import { FARM_HARVESTED_PRODUCTS_TOTALS } from '../queries';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getLocaleWeekDay } from '../../../utils/date-functions';
import { IconTooltip } from '../../../components/IconTooltip';
import { getUnderOrOverProvisionedMessage, roundToFull } from '../utils';
import { FindByValues } from '../__tests/mock';

const TotalsRow = styled.tr<{ $prevConfig?: boolean }>`
  padding: 0 16px;
  height: ${({ $prevConfig }) => ($prevConfig ? '24px' : '56px')};
  background-color: ${({ $prevConfig }) => ($prevConfig ? '#F5F5F5' : '#FFFFFF')};
  font-weight: ${({ $prevConfig }) => ($prevConfig ? '400' : 'bold')};
  color: ${({ $prevConfig }) => ($prevConfig ? 'rgba(0, 0, 0, 0.4)' : 'inherit')};
  font-size: 16px;

  &:last-of-type {
    background-color: transparent;
    td:first-of-type {
      border-bottom-left-radius: 10px;
    }
    td:last-of-type {
      border-bottom-right-radius: 10px;
    }
  }
`;

const TotalsCell = styled.td<{ $error?: boolean; $warning?: boolean }>`
  padding: 0 16px;
  border-width: thin;
  border-color: ${({ $warning, $error }) =>
    $warning ? '#FA6400' : $error ? '#FF3B30' : '#e0e0e0'};
  border-style: ${({ $warning, $error }) => ($warning || $error ? 'double' : 'solid')};
`;

const ErrorCell = styled.td<{ error?: boolean; cellErrorColor?: string }>`
  border: thin solid #e0e0e0;
  padding: 0 16px;
  background: ${props => (props.error ? props.cellErrorColor : 'inherit')};
`;

const SubText = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-size: 90%;
`;

export const TableTotals = () => {
  const { farmUuid } = useParams();
  const [getPreviousTotals, { data }] = useLazyQuery(FARM_HARVESTED_PRODUCTS_TOTALS);

  const configuration: PlantingScheduleWeeklyConfiguration[] = useWatch({
    name: 'configuration'
  });

  const { getValues, setValue } = useFormContext<FormProps>();
  const { selectedFarm, firstPlantingDate, growingCycle } = getValues();
  const weekDays = getWeekDays();
  const isAcreFarm = selectedFarm!.model === FarmModel.ACRE;

  useEffect(() => {
    if (farmUuid && isAcreFarm && firstPlantingDate) {
      getPreviousTotals({ variables: { uuid: farmUuid, date: firstPlantingDate } });
    }
  }, [firstPlantingDate, farmUuid, getPreviousTotals, isAcreFarm]);

  const getTotals = (): FarmConfigTotals => {
    const { perDay } = generateTotals();

    weekDays.forEach(day => {
      const { capacityUnitsPerBench } = selectedFarm!;
      const totalTraysPerDay = configuration.reduce((a, config) => {
        return a + (config[day] || 0);
      }, 0);

      const totalUnitsPerDay = configuration.reduce((a, config) => {
        return a + (config.trayType?.surfaceUnits || 0) * (config[day] || 0);
      }, 0);

      const benches = parseFloat(
        Math.ceil(totalUnitsPerDay / (capacityUnitsPerBench || 1)).toString()
      );

      perDay[day] = {
        ...perDay[day],
        trays: totalTraysPerDay,
        benches,
        units: totalUnitsPerDay
      };
    });

    const trays = Object.values(perDay).reduce((acc, item) => acc + item.trays, 0);
    const benches = parseFloat(
      Object.values(perDay)
        .reduce((acc, item) => acc + item.benches, 0)
        .toFixed(2)
    );
    const units = Object.values(perDay).reduce((acc, item) => acc + item.units, 0);
    const fillingPercentage = computeFarmFillingPercentage(selectedFarm!, units, growingCycle!);

    return {
      trays,
      benches,
      fillingPercentage,
      perDay
    };
  };

  const totals = getTotals();
  const benchCapacity = getWeeklyBenchCapacity(selectedFarm!, growingCycle!);
  const isUnderOrOverProvisionedError =
    totals.fillingPercentage > 0 && totals.fillingPercentage !== 100;

  const dailyStageRecipeTotals = data?.operationalFarm?.stageRecipeTotals;
  const harvestedBenchesOnFirstPlantingDate = dailyStageRecipeTotals?.[0]?.benches || 0;
  const surplusOfFirstPlantingDate =
    data?.operationalFarm?.schedulableBenches -
      data?.operationalFarm?.occupiedStandardBenches -
      harvestedBenchesOnFirstPlantingDate || 0;

  // excute only when firstPlantingDate exists to have correct data.
  firstPlantingDate &&
    dailyStageRecipeTotals?.reduce(
      (previousSurplusOrDeficit: number, harvestedTotal: StageRecipeQuantity) => {
        const harvestedDate = harvestedTotal?.date;
        const harvestedDateWeekDay = getLocaleWeekDay(
          harvestedDate,
          WeekDayFormat.LONG
        ).toLowerCase() as WeekDays;
        const configuredTotalBenches = totals?.perDay[harvestedDateWeekDay]?.benches || 0;

        const surplusOrDeficit = roundToFull(
          previousSurplusOrDeficit + ((harvestedTotal?.benches || 0) - configuredTotalBenches)
        );
        totals.perDay[harvestedDateWeekDay].harvestedBenches = roundToFull(
          harvestedTotal.benches || 0
        );

        totals.perDay[harvestedDateWeekDay].surplusOrDeficit = surplusOrDeficit;
        return totals.perDay[harvestedDateWeekDay].surplusOrDeficit;
      },
      surplusOfFirstPlantingDate
    );

  const hasDeficit = weekDays.some(weekday => totals.perDay[weekday].surplusOrDeficit < 0);
  const hasSurplus = weekDays.some(weekday => totals.perDay[weekday].surplusOrDeficit > 0);

  const totalCapacityTooltipStyle =
    totals.fillingPercentage > 100 ? styleValues.error : styleValues.warning;

  const capacityErrorMsg = hasDeficit
    ? benchCapacityTooltipsTexts.deficit
    : benchCapacityTooltipsTexts.surplus;
  const capacityTooltipStyle = hasDeficit ? styleValues.error : styleValues.warning;

  useEffect(() => {
    setValue('hasDeficit', hasDeficit);
  }, [hasDeficit, setValue]);

  useEffect(() => {
    setValue('hasSurplus', hasSurplus);
  }, [hasSurplus, setValue]);

  return (
    <>
      <EmptyRow />
      <TotalsRow>
        <ErrorCell />
        <TotalsCell>
          <TextWrapper>
            <h4>Total Trays</h4>
            <p>
              <TotalCount role={FindByValues.TOTAL_CAPACITY}>{totals.trays}</TotalCount>
            </p>
          </TextWrapper>
        </TotalsCell>
        {weekDays.map(day => (
          <TotalsCell key={day}>{totals.perDay[day].trays}</TotalsCell>
        ))}
      </TotalsRow>
      {isAcreFarm && (
        <>
          <TotalsRow>
            <td></td>
            {/* <ErrorCell error={isBenchPartialNumber} cellErrorColor={styleValues.error.subColor}>
              {isBenchPartialNumber && (
                <div data-cy={FindByValues.PARTIAL_BENCH_TOOLTIP_TESTID}>
                  <IconTooltip
                    body={partialBenchErrorMessage}
                    icon={'error'}
                    styles={{
                      backgroundColor: '#FF3B30',
                      iconColor: '#FF3B30',
                      arrowColor: '#FF3B30'
                    }}
                  />
                </div>
              )}
            </ErrorCell> */}
            <TotalsCell>
              <TextWrapper vertical={true}>
                <h4>Required benches</h4>
                <SubText>(Round up to full bench)</SubText>
                <p>
                  <TotalCount role={FindByValues.TOTAL_BENCHES}>{totals.benches}</TotalCount>
                  {` / ${benchCapacity} max`}
                </p>
              </TextWrapper>
            </TotalsCell>
            {weekDays.map(day => (
              <TotalsCell key={day}>{Math.ceil(totals.perDay[day]?.benches)}</TotalsCell>
            ))}
          </TotalsRow>
          <TotalsRow $prevConfig>
            <ErrorCell />
            <TotalsCell>
              <TextWrapper>
                <p>Benches to be harvested</p>
              </TextWrapper>
            </TotalsCell>
            {weekDays.map(day => (
              <TotalsCell role={FindByValues.PREV_CONFIGURED_BENCHES} key={day}>
                {Math.ceil(totals.perDay[day].harvestedBenches)}
              </TotalsCell>
            ))}
          </TotalsRow>
          <TotalsRow>
            <ErrorCell
              error={hasDeficit || hasSurplus}
              cellErrorColor={capacityTooltipStyle.subColor}
            >
              {(hasDeficit || hasSurplus) && (
                <IconTooltip
                  body={capacityErrorMsg.text}
                  heading={capacityErrorMsg.heading}
                  icon={capacityTooltipStyle.icon}
                  styles={{
                    backgroundColor: capacityTooltipStyle.color,
                    iconColor: capacityTooltipStyle.color,
                    arrowColor: capacityTooltipStyle.color
                  }}
                />
              )}
            </ErrorCell>
            <TotalsCell>
              <TextWrapper>
                <h4>Daily bench surplus / deficit</h4>
                <IconTooltip body={benchCapacityTooltipsTexts.info} />
              </TextWrapper>
            </TotalsCell>
            {weekDays.map(day => {
              const fixedSurplusOrDeficit = totals.perDay[day].surplusOrDeficit;
              return (
                <TotalsCell
                  role={FindByValues.SURPLUS_OR_DEFICIT}
                  key={day}
                  $error={fixedSurplusOrDeficit > 0}
                  $warning={fixedSurplusOrDeficit < 0}
                >
                  {fixedSurplusOrDeficit > 0
                    ? '+' + Math.ceil(fixedSurplusOrDeficit)
                    : Math.floor(fixedSurplusOrDeficit)}
                </TotalsCell>
              );
            })}
          </TotalsRow>
        </>
      )}
      <TotalsRow>
        <ErrorCell
          error={isUnderOrOverProvisionedError}
          cellErrorColor={totalCapacityTooltipStyle.subColor}
        >
          {isUnderOrOverProvisionedError && (
            <div data-cy={FindByValues.UNDER_OVER_PROVISIONED_TOOLTIP_TESTID}>
              <IconTooltip
                body={getUnderOrOverProvisionedMessage(totals.fillingPercentage)}
                icon={totalCapacityTooltipStyle.icon}
                styles={{
                  backgroundColor: totalCapacityTooltipStyle.color,
                  arrowColor: totalCapacityTooltipStyle.color,
                  iconColor: totalCapacityTooltipStyle.color
                }}
              />
            </div>
          )}
        </ErrorCell>
        <TotalsCell>
          <TextWrapper>
            <h4>Capacity in %</h4>
            <p>
              <TotalCount data-cy={FindByValues.TOTAL_CAPACITY}>
                {totals.fillingPercentage}%
              </TotalCount>
              {` full`}
            </p>
          </TextWrapper>
        </TotalsCell>
        <Cell colSpan={7}>
          <DsProgress value={totals.fillingPercentage / 100} />
        </Cell>
      </TotalsRow>
    </>
  );
};
