import { THead, Table, Tr, Th, Tbody, Td } from '../OverviewTable/style';
import { TableHeaders } from '../types';

export const NiAOverviewTable = ({
  data
}: {
  data: {
    variety: string;
    amountOfTrays: number;
    from: string;
  }[];
}) => {
  return (
    <Table>
      <THead>
        <Tr>
          {Object.keys(TableHeaders).map(header => (
            <Th key={header}>{TableHeaders[header as keyof typeof TableHeaders]}</Th>
          ))}
        </Tr>
      </THead>
      <Tbody>
        {!!data.length ? (
          <>
            {data.map(task => (
              <Tr key={task.variety}>
                {Object.keys(TableHeaders).map(header => (
                  <Td bold={header === 'variety'} key={header}>
                    {task[header as keyof typeof TableHeaders]}
                  </Td>
                ))}
              </Tr>
            ))}
          </>
        ) : (
          <Tr>
            <Td colSpan={3}>No data is available at the moment</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
