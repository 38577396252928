import { FC } from 'react';
import { legendData } from '../constants';
import { LegendContainer, LegendDashedLine, LegendIcon, LegendLabel, LegendText } from '../style';

/**
 *The chart legend displays keys about the datasets that are appearing on left of the chart.
 */
export const NurseryChartLegend: FC = () => (
  <LegendContainer>
    {legendData.map(({ name, fill, line }) => (
      <LegendLabel key={name}>
        {line ? <LegendDashedLine /> : <LegendIcon color={fill} />}
        <LegendText>{name}</LegendText>
      </LegendLabel>
    ))}
  </LegendContainer>
);
