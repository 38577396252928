import { useState, FocusEvent } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { parseClipboardData } from '../../../utils';
import {
  PageSubTitle,
  SectionWrapper,
  SectionSubTitle,
  PasteButton,
  PasteIcon,
  Row
} from '../style';
import { ConfigTable } from '../components';
import { Modal } from '../../PlantingSchedule/components';
import { pasteModalContent, PasteModalTypes } from '../constants';
import { getLocaleWeekDays, offsetDateWithTimezone } from '../../../utils/date-functions';
import {
  DailyNumberOfValues,
  HubDemandConfig,
  PlantingWeekDays,
  ProductExcessDemand
} from '../types';
import { StageRecipe } from '../../../types';
import { Snackbar } from '@material-ui/core';
import { DsButton } from '@infarm/design-system-react';
import { ExcessDemands } from './ExcessDemands';
import { isPast, isToday, subDays } from 'date-fns';

export const ScheduleDetails = ({
  plantingWeekDays,
  excessDemands
}: {
  plantingWeekDays: PlantingWeekDays;
  excessDemands: ProductExcessDemand[];
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [modalType, setModalType] = useState<PasteModalTypes>(PasteModalTypes.REPLACE);
  const [copiedRows, setCopiedRows] = useState<string[][]>([]);
  const [invalidVarieties, setInvalidVarieties] = useState<string[]>([]);
  const [copiedVarietiesWithPastPlantingDate, setCopiedVarietiesWithPastPlantingDate] = useState<
    HubDemandConfig[]
  >([]);

  const { setValue } = useFormContext();
  const [configurations, startDate, doesRepeatWeekly, endDate, stageRecipes] = useWatch({
    name: ['configurations', 'startDate', 'doesRepeatWeekly', 'endDate', 'stageRecipes']
  });
  const { replace } = useFieldArray({
    name: 'configurations'
  });
  const adhocOrder = !doesRepeatWeekly && startDate && endDate;
  const dateColumns = adhocOrder ? Object.keys(plantingWeekDays) : getLocaleWeekDays();

  const onPasteFromClipboard = (event: React.ClipboardEvent) => {
    const copiedData = parseClipboardData(event.clipboardData.getData('text'));
    setCopiedRows(copiedData);

    const hasAnyNaN = copiedData.some(data => {
      //ignore the first element
      const [, ...trays] = data;
      return trays.some(tray => isNaN(+tray));
    });
    if (hasAnyNaN) {
      setModalType(PasteModalTypes.NON_NUMERIC);
      setIsModalOpen(true);
      return;
    }

    const hasInvalidColumnsLength = copiedData.some(data => {
      const requiredColumnsLength = dateColumns.length + 1;
      return data.length !== requiredColumnsLength;
    });
    if (hasInvalidColumnsLength) {
      setModalType(PasteModalTypes.COLUMNS_LENGTH);
      setIsModalOpen(true);
      return;
    }

    const hasAlreadyValue = configurations.length;
    if (hasAlreadyValue) {
      setModalType(PasteModalTypes.REPLACE);
      setIsModalOpen(true);
      return;
    }

    pasteDataToTable(copiedData);
  };

  const pasteDataToTable = (copiedData: string[][]) => {
    const newConfigs: HubDemandConfig[] = [];
    const invalidVarieties: string[] = [];

    copiedData.forEach((row: string[]) => {
      const [variety, ...trays] = row;
      const stageRecipe = stageRecipes.find(({ internalCode, name }: StageRecipe) => {
        return internalCode?.concat(' ', name) === variety;
      });
      if (stageRecipe) {
        const dailyConfig = Object.values(plantingWeekDays).reduce((acc, weekday, index) => {
          acc[weekday] = Number(trays[index]);
          return acc;
        }, {} as DailyNumberOfValues);
        const newConfig = {
          stageRecipe,
          dailyConfig
        };
        newConfigs.push(newConfig);
      } else {
        invalidVarieties.push(variety);
      }
    });
    const recipesWithPlantingDateInPast = newConfigs.filter(({ stageRecipe }) => {
      const { duration } = stageRecipe;

      const hasAnyPlantingDateInPast = Object.values(plantingWeekDays).some(date => {
        const harvestDate = offsetDateWithTimezone(date);
        const plantingDate = subDays(harvestDate, duration);

        return isPast(plantingDate) || isToday(plantingDate);
      });
      return hasAnyPlantingDateInPast;
    });

    if (recipesWithPlantingDateInPast.length) {
      setCopiedVarietiesWithPastPlantingDate(recipesWithPlantingDateInPast);
      setModalType(PasteModalTypes.PLANTING_DATE_PAST);
      setIsModalOpen(true);
      return;
    }

    if (invalidVarieties.length) {
      setInvalidVarieties(invalidVarieties);
      setModalType(PasteModalTypes.MISSING_VARIETY);
      setIsModalOpen(true);
    }

    replace(newConfigs);
    setShowOverlay(false);
    setShowSnackbar(true);
  };

  const onModalClosed = () => {
    setIsModalOpen(false);
  };

  const blurEvent = (e: FocusEvent<HTMLInputElement>) => {
    const targetId = e.relatedTarget?.id as string;

    if (showOverlay && targetId !== 'configTable') {
      setShowOverlay(false);
    }
  };

  const modalPrimaryAction = () => {
    if (modalType === PasteModalTypes.REPLACE) {
      pasteDataToTable(copiedRows);
    }
    onModalClosed();
  };

  const modalText = () => {
    if (modalType === PasteModalTypes.MISSING_VARIETY) {
      return pasteModalContent[PasteModalTypes.MISSING_VARIETY].text(invalidVarieties);
    }
    if (modalType === PasteModalTypes.COLUMNS_LENGTH) {
      return pasteModalContent[PasteModalTypes.COLUMNS_LENGTH].text(
        dateColumns.length + 1,
        copiedRows[0]?.length
      );
    }
    if (modalType === PasteModalTypes.PLANTING_DATE_PAST) {
      return pasteModalContent[PasteModalTypes.PLANTING_DATE_PAST].text(
        copiedVarietiesWithPastPlantingDate
      );
    }

    return pasteModalContent[modalType].text;
  };

  const showExcessDemands =
    excessDemands.some(({ excessDemands }) => excessDemands?.length) && !!configurations.length;

  return (
    <SectionWrapper onPaste={onPasteFromClipboard} tabIndex={0} onBlur={blurEvent}>
      <Modal
        isModalOpen={isModalOpen}
        onModalClosed={onModalClosed}
        modalText={modalText() || ''}
        heading={pasteModalContent[modalType].heading}
        modalSecondaryAction={onModalClosed}
        modalPrimaryAction={modalPrimaryAction}
        primaryLabel={pasteModalContent[modalType].primaryLabel}
        secondaryLabel={pasteModalContent[modalType].secondaryLabel}
      />
      <PageSubTitle>Schedule Details</PageSubTitle>
      <SectionSubTitle>
        Input in the table below, how many trays' output will you need per weekday.
      </SectionSubTitle>
      <Row>
        <PasteButton
          disabled={!startDate}
          onClick={() => {
            setShowOverlay(true);
          }}
        >
          <PasteIcon>content_paste</PasteIcon>
          Paste from spreadsheet
        </PasteButton>
        <DsButton onClick={() => setValue('configurations', [])} label="Clear table data" />
      </Row>
      {showExcessDemands && <ExcessDemands excessDemands={excessDemands} />}
      <ConfigTable
        showOverlay={showOverlay}
        dateColumns={dateColumns}
        plantingWeekDays={plantingWeekDays}
      />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message={'Data successfully pasted from clipboard '}
      />
    </SectionWrapper>
  );
};
