import SubmitButton from '../../../components/SubmitButton';
import { CancelButton, ErrorMessage, SectionWrapper } from '../style';
import { useParams, useNavigate } from 'react-router';
import { Modal } from './Modal';
import { useState } from 'react';
import { modalContent } from '../constants';
import { useFormContext } from 'react-hook-form';
import { DsIcon } from '@infarm/design-system-react';

export const ButtonsSection = ({ loading }: { loading: boolean }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { locationUuid } = useParams();
  const {
    formState: { errors },
    getValues,
    watch
  } = useFormContext();

  const configurations = getValues('configurations');
  const hubDetails = watch('hubDetails');

  const handleCancel = () => {
    setIsModalOpen(true);
  };

  const onPrimaryBtnClick = () => {
    navigate(`/locations/${locationUuid}`);
  };

  const onSecondaryBtnClick = () => {
    setIsModalOpen(false);
  };

  const operationalFarms = hubDetails?.operationalFarms || [];

  return (
    <SectionWrapper>
      <Modal
        onSecondaryBtnClick={onSecondaryBtnClick}
        onPrimaryBtnClick={onPrimaryBtnClick}
        isModalOpen={isModalOpen}
        secondaryLabel="No"
        primaryLabel="Yes"
        heading={modalContent.cancel.heading}
        text={modalContent.cancel.text}
        onModalClosed={() => {
          setIsModalOpen(false);
        }}
      />
      <CancelButton label={'Cancel'} onClick={handleCancel} />
      <SubmitButton
        type="submit"
        label="Validate Capacity"
        disabled={!configurations.length || !!errors?.configurations || !operationalFarms.length}
        loading={loading}
      />
      {!operationalFarms.length && (
        <ErrorMessage>
          <DsIcon>error_outline</DsIcon> There is no eligible farm to be configured.
        </ErrorMessage>
      )}
    </SectionWrapper>
  );
};
