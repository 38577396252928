import { DsTextfield } from '@infarm/design-system-react';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Roboto;
`;

export const THead = styled.thead``;

export const Tr = styled.tr<{ selectable?: boolean }>`
  &:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
  }
  cursor: ${props => (props.selectable ? 'pointer' : 'default')};
`;

export const Th = styled.th`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #616161;
  padding: 20px 24px;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25em;
  box-shadow: 0 0 0 1px #bdbdbd;
`;

export const Td = styled.td<{ bold?: boolean }>`
  font-size: 16px;
  font-style: normal;
  font-weight: ${props => (props.bold ? 500 : 400)};
  text-align: left;
  color: #25213b;
  padding: 20px 24px;
`;

export const Row = styled.div`
  margin: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Error = styled.span`
  color: #e00028;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-weight: 500;
`;

export const Search = styled(DsTextfield)`
  margin-top: 16px;
  width: 300px;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
