import { StageRecipe, Location, TrayType, OperationalFarm, Bench } from '../../types';

interface GroupEndNode {
  totalNumberOfTrays: number;
  statuses?: string[];
  tasks?: NurseryInAcreTasks[];
}

export interface TaskGroupByStageRecipe {
  stageRecipe: StageRecipe;
  taskGroup: GroupEndNode;
}

export interface TaskGroupByTrayType {
  trayType: TrayType;
  taskGroup: GroupEndNode;
}

export interface TasksForDate {
  totalNumberOfTrays: number;
  statuses?: string[];
  tasks?: NurseryInAcreTasks[];
  byStageRecipe: TaskGroupByStageRecipe[];
  byTrayType: TaskGroupByTrayType[];
}

interface LocationWithTasksForDate extends Location {
  tasksForDate: TasksForDate;
}

export interface SeedingOverviewQueryData {
  location: LocationWithTasksForDate;
}

export interface NurseryInAcreTasksForDate {
  operationalFarm: OperationalFarm;
  taskGroup: {
    byBench: {
      bench: Bench;
      taskGroup: {
        [key in TaskGroup]: NurseryInAcreTaskGroup;
      };
    }[];
  };
}

export enum TaskGroup {
  LOADING = 'loading',
  SPRAYING = 'spraying',
  UNLOADING = 'unloading'
}

export interface NurseryInAcreTaskGroup {
  tasks: NurseryInAcreTasks[];
}

export interface NurseryInAcreTasks {
  date: string;
  status: string;
  uuid: string;
  traysPerStageRecipe: number;
  stageRecipe: StageRecipe;
  sourceType: string;
  plots: {
    uuid: string;
    name: string;
  }[];
}

export enum TableHeaders {
  variety = 'VARIETY',
  amountOfTrays = 'AMOUNT OF TRAYS',
  from = 'FROM'
}
