import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  InfarmLabel,
  InputWrapper,
  Title,
  SmallCaption,
  ValidationError,
  Placeholder,
  WarningMessage
} from './styles';
import { timezones } from '../utils';
import { Timezone } from 'countries-and-timezones';
import { TextField } from '@material-ui/core';

const StyledAutocomplete = styled(Autocomplete)`
  .Mui-disabled {
    cursor: not-allowed !important;
  }
`;

export interface SelectItem {
  text: string;
  value: string;
  selected?: boolean;
}

export interface TimeZoneAutocompleteProps {
  label?: string;
  caption?: string;
  validationMessage?: string;
  fullWidth?: boolean;
  loading?: boolean;
  warningMessage?: string;
  handleChange?: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
}

export const TimeZoneAutocomplete: FunctionComponent<TimeZoneAutocompleteProps> = ({
  label,
  caption,
  validationMessage,
  fullWidth = true,
  loading = false,
  warningMessage = '',
  ...props
}) => (
  <InfarmLabel>
    {label && <Title>{label}</Title>}
    {caption && <SmallCaption>{caption}</SmallCaption>}
    <InputWrapper>
      {loading ? (
        <Placeholder />
      ) : (
        <StyledAutocomplete
          {...props}
          renderInput={params => <TextField {...params} variant="outlined" margin={'dense'} />}
          options={timezones}
          getOptionLabel={option => {
            if (typeof option === 'string') return option;
            const timezone = option as Timezone;
            return `(UTC ${timezone.utcOffsetStr}) ${timezone.name}`;
          }}
          getOptionSelected={(option, value) => (option as Timezone).name === value}
          onChange={(_, timezone) => {
            if (timezone && typeof timezone === 'object') {
              props.onChange((timezone as Timezone).name);
            }
          }}
        />
      )}
    </InputWrapper>
    {warningMessage && <WarningMessage>{warningMessage}</WarningMessage>}
    {validationMessage && <ValidationError>{validationMessage}</ValidationError>}
  </InfarmLabel>
);
