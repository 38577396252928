import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { DsPlaceholder, DsRow, DsTextfield } from '@infarm/design-system-react';
import { Button, Switch, withStyles } from '@material-ui/core';

export const H4 = styled.h4`
  margin-top: 24px;
`;

export const Small = styled.small`
  font-weight: 400;
  font-size: 16px;
  color: #828282;
`;

export const P = styled.p`
  font-size: 12px;
  color: #828282;
`;

export const FarmInfo = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  width: 450px;
  padding: 16px 14px;
  margin: 8px 0;
`;

export const FarmInfoSpan = styled.span`
  color: #828282;
  font-size: 12px;
`;

export const FarmList = styled.ul`
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  padding: 0;
`;

export const FarmRowInfo = styled.div`
  width: 100%;
`;

export const FarmRow = styled.li`
  align-items: center;
  display: flex;
  padding: 5px 14px;
  line-height: 0.95;
  width: 100%;
`;

export const SmallInRow = styled.small`
  line-height: 0.95;
  color: #757575;
  font-size: 12px;
`;

export const FarmName = styled.div`
  font-weight: 500;
`;

export const FarmImg = styled.img`
  margin-right: 10px;
  width: 35px;
  height: 35px;
`;

export const StyledIconButton = styled(IconButton)`
  background-color: red;
`;

export const StyledLocationForm = styled.form`
  width: 28.375em;
  ds-select {
    width: 100%;
    mwc-select {
      width: 100%;
    }
  }
`;

export const FormSectionHead = styled.h3`
  margin-top: 2em;
  font-weight: 700;
  font-size: 24px;
`;
export const FormValidationError = styled.h4`
  margin-top: 2em;
  font-weight: 700;
  font-size: 18px;
  color: #e00028;
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
  margin-top: 48px;
`;

export const ThinLine = styled.hr`
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  position: absolute;
  top: auto;
  height: 4px;
  width: 100vw;
  left: 0;
`;

export const InfarmLabel = styled.label`
  margin-top: 1.25em;
  display: block;
  width: 100%;
`;

export const SmallCaption = styled.small`
  text-align: left;
  display: block;
  font-size: 12px;
  color: #828282;
  width: 100%;
  letter-spacing: 0.02em;
  line-height: 1.4;
`;

export const InputWrapperSelect = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
`;
export const ValidationError = styled.div`
  font-size: 11px;
  color: #e00028;
`;

export const InputWrapper = styled(InputWrapperSelect)`
  position: relative;
`;

export const ModalButtonsRow = styled(DsRow)`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  .MuiButton-root {
    margin: 0;
  }
`;

export const Title = styled.span`
  font-weight: 500;
  color: #000000;
  margin-top: 21px;
`;

export const DesignTextField = styled(DsTextfield)`
  width: 100%;
  mwc-textfield {
    height: 42px;
    width: 100%;
  }
`;

export const Placeholder = styled(DsPlaceholder)`
  width: 100% !important;
  height: 42px;
`;

export const FarmIdStyled = styled.span`
  font-size: 12px;
  color: #767676;
  white-space: nowrap;
`;

export const FarmIdMissingStyled = styled(FarmIdStyled)`
  color: #fa6400;
`;

export const ModalSubtitle = styled.span`
  color: #767676;
`;

export const AnchorLink = styled.a`
  text-decoration: underline;
`;

export const Note = styled.p`
  color: #e00028;
`;

export const SwitchLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-right: 7px;
`;

export const HybridCaption = styled.span`
  text-align: left;
  font-size: 12px;
  color: #828282;
  letter-spacing: 0.02em;
  line-height: 1.4;
`;

export const HybridConfig = styled.div``;

export const HybridSwitch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FarmDetailModalSwitch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonWithIcon = styled(Button)`
  &.MuiButton-root {
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    margin-left: 8px;
  }

  &.MuiButton-outlined {
    border-color: #000000;
  }

  .MuiButton-startIcon {
    margin: 0;
  }
`;

export const NurseryBenchesConfig = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

export const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#fafafa'
    },
    '&$checked + $track': {
      backgroundColor: '#000000',
      opacity: 'inherit'
    }
  },
  checked: {},
  track: {}
})(Switch);

export const FarmDetailModalWrapper = styled.div`
  max-height: calc(100% - 32px);
  border-radius: 4px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 28px;
`;
