import { useCallback, useState } from 'react';
import { Options } from 'react-select';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DsButton, DsModal } from '@infarm/design-system-react';
import {
  Column,
  EditButton,
  MenuName,
  ModalButtonsRow,
  ModalContentWrapper,
  Row,
  SelectedGrowersWrapper,
  Text,
  TextWrapper,
  WarningText,
  Span,
  MoreChipsButton
} from './style';
import { USER_ASSIGNMENT } from '../queries';
import { Chip } from '../../../components/Chip';
import { Error } from '../../../components/Error';
import UserIcon from '../../../assets/user.svg';
import { SelectGrowers } from './SelectGrowers';

export interface Grower {
  uuid: string;
  name: string;
  role: string;
}
interface AssignGrowerProps {
  assignedUsers: Grower[];
  hasFarms: boolean;
}

export const AssignGrower = ({ assignedUsers, hasFarms }: AssignGrowerProps) => {
  const { locationUuid } = useParams();
  const [isModalOpen, setModalVisibility] = useState(false);
  const [showRemainingGrowers, setShowRemainingGrowers] = useState(false);
  const [assignedGrowers, setAssignedGrowers] = useState<Options<Grower>>(assignedUsers);
  const [selectedOptions, setSelectedOptions] = useState<Options<Grower>>(assignedGrowers);
  const shouldDisableAssignButton = assignedGrowers.length === 0 && selectedOptions.length === 0;

  const [assignGrowers, { loading, error }] = useMutation(USER_ASSIGNMENT, {
    onCompleted: data => {
      if (data) setModalVisibility(false);
    }
  });

  const saveAssignedGrower = () => {
    assignGrowers({
      variables: {
        input: {
          locationUuid,
          userUuids: selectedOptions.map(user => user.uuid)
        }
      }
    });
    setAssignedGrowers(selectedOptions);
  };

  const dismissModal = useCallback(() => {
    setModalVisibility(false);
    setSelectedOptions(assignedGrowers);
  }, [assignedGrowers]);

  const AssignedGrowers = () => {
    const options = assignedGrowers.slice(0, 2);
    const remainingOptions = assignedGrowers.slice(2);

    return (
      <SelectedGrowersWrapper>
        {options.length !== 0 &&
          options.map(option => {
            return (
              <Chip
                key={option.name}
                label={option.name}
                icon={<img src={UserIcon} alt="User Icon" />}
              />
            );
          })}
        {showRemainingGrowers &&
          remainingOptions.map(option => {
            return (
              <Chip
                key={option.name}
                label={option.name}
                icon={<img src={UserIcon} alt="User Icon" />}
              />
            );
          })}
        {remainingOptions.length !== 0 && (
          <MoreChipsButton onClick={() => setShowRemainingGrowers(!showRemainingGrowers)}>
            {showRemainingGrowers ? (
              <Chip label={'Show less'} />
            ) : (
              <Chip label={`+${remainingOptions.length} more`} />
            )}
          </MoreChipsButton>
        )}
      </SelectedGrowersWrapper>
    );
  };

  return (
    <>
      <Row>
        <Column>
          <TextWrapper>
            <Text>Assigned Grower</Text>
            <EditButton onClick={() => setModalVisibility(true)} disabled={!hasFarms}>
              {!assignedGrowers.length ? `Add` : `Edit`}
            </EditButton>
          </TextWrapper>
          {!assignedGrowers.length && hasFarms && <WarningText>No grower is assigned</WarningText>}
          {!hasFarms && (
            <Span>
              Grower assignment will be enabled as soon as a Gen 2 Farm gets added to this Location.
            </Span>
          )}

          <AssignedGrowers />
        </Column>
      </Row>

      <DsModal
        open={isModalOpen}
        heading="Assign Growers"
        hideCloseButton
        onClosed={dismissModal}
        subtitle=" Assign grower(s) to make this location visible for them in the Grower App"
      >
        <ModalContentWrapper>
          <MenuName>Growers</MenuName>
          <SelectGrowers
            selectedOptions={selectedOptions}
            setSelectedOptions={options => {
              setSelectedOptions(options);
            }}
          />
          {!!error && <Error title="Something went wrong. Please try again." error={error} />}
          <ModalButtonsRow>
            <DsButton
              disabled={loading}
              label={'Cancel'}
              onClick={dismissModal}
              data-cy="AssignGrower___cancelButton"
            ></DsButton>
            <DsButton
              disabled={loading || shouldDisableAssignButton}
              label={'Apply'}
              primary
              onClick={saveAssignedGrower}
              data-cy="AssignGrower___applyButton"
            ></DsButton>
          </ModalButtonsRow>
        </ModalContentWrapper>
      </DsModal>
    </>
  );
};
