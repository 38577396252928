import { Status } from '../../types';
import { StatusChip } from '../StatusChip';

const enum SeedingPlanState {
  COMMITTED = 'Committed',
  PARTIALLY_COMMITTED = 'Partially Committed',
  UNCOMMITTED = 'Not Committed'
}

export const SeedingPlanStatus = ({ statuses }: { statuses?: string[] }) => {
  if (!statuses || statuses.length === 0) return <></>;
  if (!statuses.includes('PENDING')) {
    return <StatusChip status={Status.SUCCESS} label={SeedingPlanState.COMMITTED} />;
  }
  if (statuses.length === 1) {
    return <StatusChip status={Status.ERROR} label={SeedingPlanState.UNCOMMITTED} />;
  }
  return <StatusChip status={Status.WARNING} label={SeedingPlanState.PARTIALLY_COMMITTED} />;
};
