import React from 'react';
import { TueToSatHeader } from './TueToSatHeader';
import { MondayHeader } from './MondayHeader';
import { SundayHeader } from './SundayHeader';
import { DateLike, getLocaleWeekDay } from '../../../../utils/date-functions';
import { WeekDays, WeekDayFormat } from '../../../../types';

interface DateIndicatorProps {
  firstPlantingDate: DateLike | undefined;
  dashness: boolean;
  isHubDemandForm?: boolean;
}

export const DateIndicatorTableHeader = (props: DateIndicatorProps) => {
  const { firstPlantingDate, dashness, isHubDemandForm } = props;

  if (!firstPlantingDate) {
    return <React.Fragment />;
  }
  const firstPlantingWeekDay = getLocaleWeekDay(
    firstPlantingDate,
    WeekDayFormat.LONG
  ).toLowerCase();

  switch (firstPlantingWeekDay) {
    case WeekDays.monday:
      return <MondayHeader dashness={dashness} isHubDemandForm={isHubDemandForm} />;
    case WeekDays.tuesday:
    case WeekDays.wednesday:
    case WeekDays.thursday:
    case WeekDays.friday:
    case WeekDays.saturday:
      return (
        <TueToSatHeader
          isHubDemandForm={isHubDemandForm}
          firstPlantingWeekDay={firstPlantingWeekDay}
          dashness={dashness}
        />
      );
    case WeekDays.sunday:
      return <SundayHeader dashness={dashness} isHubDemandForm={isHubDemandForm} />;
    default:
      return <React.Fragment />;
  }
};
