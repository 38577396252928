import { HubDemandConfig } from './types';

export enum ModalType {
  VALIDATING = 'validating',
  SUBMIT = 'submit',
  SUCCESS = 'success',
  ERROR = 'error',
  SUBMIT_ERROR = 'submitError'
}

export const modalContent = {
  cancel: {
    heading: 'Are you sure you want to cancel?',
    text: 'All entered data will be lost.'
  },
  switch: {
    heading: 'Are you sure you want to turn off weekly repetition?',
    text: 'All unsaved data in the schedule detail table will be lost.'
  },
  reset: {
    heading: 'Are you sure you want to update the date?',
    text: 'All unsaved data in the schedule detail table will be lost.'
  }
};

export const validationModalContent = {
  [ModalType.VALIDATING]: {
    heading: 'Capacity is being validated..',
    secondaryLabel: undefined,
    primaryLabel: undefined
  },
  [ModalType.SUBMIT]: {
    heading: 'Demand is feasible. Submit this demand?',
    secondaryLabel: 'Cancel',
    primaryLabel: 'Submit'
  },
  [ModalType.SUCCESS]: {
    heading: 'Demand successfully submitted!',
    secondaryLabel: undefined,
    primaryLabel: 'Back to Hub'
  },
  [ModalType.ERROR]: {
    heading: 'Something went wrong with capacity validation..',
    secondaryLabel: undefined,
    primaryLabel: 'Try again'
  },
  [ModalType.SUBMIT_ERROR]: {
    heading: 'Something went wrong with submitting..',
    secondaryLabel: undefined,
    primaryLabel: 'Try again'
  }
};
export const pasteModalContent = {
  REPLACE: {
    heading: 'A schedule already exists, do you want to replace it?',
    text: 'A schedule with its content already exists in the table. Replacing it will overwrite its current contents.',
    primaryLabel: 'Replace',
    secondaryLabel: 'Cancel'
  },
  COLUMNS_LENGTH: {
    heading: 'Please check the number of columns and try again.',
    text: (requiredColumns: number, pastedColumns: number): string => {
      return `You tried to paste in ${pastedColumns} columns where ${requiredColumns} columns are required.`;
    },
    primaryLabel: 'Try again',
    secondaryLabel: undefined
  },
  NON_NUMERIC: {
    heading: 'You tried to paste text into number fields',
    text: 'The schedule quantity fields can only contain numeric values, please check if you have copied the correct data.',
    primaryLabel: 'Try again',
    secondaryLabel: undefined
  },
  MISSING_VARIETY: {
    heading: `You tried to paste non existing varieties.`,
    text: (varieties: string[]) => {
      return `${varieties.join(', ')} couldn't be pasted.`;
    },
    primaryLabel: 'Confirm',
    secondaryLabel: undefined
  },
  PLANTING_DATE_PAST: {
    heading: 'Cannot add variety(-ies) with planting date in the past.',
    text: (configs: HubDemandConfig[]) => {
      return `${configs
        .map(({ stageRecipe }) => stageRecipe.name)
        .join(', ')} are with planting date in the past.`;
    },
    primaryLabel: 'Confirm',
    secondaryLabel: undefined
  }
};

export enum PasteModalTypes {
  REPLACE = 'REPLACE',
  COLUMNS_LENGTH = 'COLUMNS_LENGTH',
  NON_NUMERIC = 'NON_NUMERIC',
  MISSING_VARIETY = 'MISSING_VARIETY',
  PLANTING_DATE_PAST = 'PLANTING_DATE_PAST'
}
