import { ApolloError } from '@apollo/client';

export const getErrorMessage = (error: ApolloError) => {
  const { graphQLErrors } = error;
  if (graphQLErrors.length) {
    const errorCode = graphQLErrors[0]?.extensions?.code;
    switch (errorCode) {
      case 'FORBIDDEN':
        return 'You do not have permission to access this page';
      default:
        return error.graphQLErrors[0].message;
    }
  }

  return error.message;
};
