import { gql } from '@apollo/client';

export const LOCATION_DETAIL = gql`
  query Location($uuid: ID!, $farmModel: [FarmModel!], $farmStateNot: OperationalFarmState) {
    location(uuid: $uuid) {
      uuid
      name
      category
      isSelfNursery
      locationGroup {
        name
      }
      assignedUsers {
        name
        uuid
      }
      farms: operationalFarms(
        farmModel: $farmModel
        farmStateNot: $farmStateNot
        isEligibleForAutomation: false
      ) {
        uuid
        shortId
        name
        model
        traysPerBench
        capacityInBenches
        nurseryBenches
        lightGerminationBenches
        darkGerminationBenches
        state
        serialNumber
      }
      automationFarms: operationalFarms(
        farmModel: $farmModel
        farmStateNot: $farmStateNot
        isEligibleForAutomation: true
      ) {
        uuid
        shortId
        name
        model
        traysPerBench
        capacityInBenches
        nurseryBenches
        lightGerminationBenches
        darkGerminationBenches
        state
        serialNumber
      }
    }
  }
`;

export const GET_GROWERS = gql`
  query Growers($uuid: ID!) {
    location(uuid: $uuid) {
      uuid
      locationGroup {
        growers: members(roleName: "grower") {
          name
          uuid
        }
        growerSupervisors: members(roleName: "grower_supervisor") {
          name
          uuid
        }
      }
    }
  }
`;

export const USER_ASSIGNMENT = gql`
  mutation AssignUsers($input: LocationAssignmentInput!) {
    assignUsersToLocation(input: $input) {
      name
      uuid
    }
  }
`;

export const GET_PHOTOS = gql`
  query GetAllPhotos($filter: PhotoFilter) {
    allPhotos(filter: $filter) {
      uuid
      date
      photoUrl
      description
      uploadedBy {
        name
        uuid
      }
      createdAt
    }
  }
`;
