import { useEffect, useState } from 'react';
import { NurseryChart } from './NurseryChart';
import { NurseryMode } from '../../../constants';
import { formatNurseryChartData, getNurseryMaxCapacity } from './utils';
import { ChartData } from './types';
import { OperationalFarm } from '../../../../types';
import { LocationWaveByDate } from '../types';
import { EmptyList, SpacerVertical } from '../style';
import { NurseryDemandStatues } from './NurseryDemandStatues';
import { NurseryUtilisationHeaderWrapper } from '../../style';
import { NurseryContainer, Overlay, OverlayText } from '../NurserySystems/style';

export const NurseryUtilisation = ({
  date,
  farms,
  wavesByDate,
  selectedNurseryModes,
  lastNurseryDemandUpdatedAt,
  isGeneratingNurseryDemand
}: {
  date: Date;
  farms: OperationalFarm[];
  wavesByDate: LocationWaveByDate[];
  selectedNurseryModes: NurseryMode[];
  isGeneratingNurseryDemand: boolean;
  lastNurseryDemandUpdatedAt?: string;
}) => {
  const [chartData, setChartData] = useState<ChartData[]>();

  const [nurseryMaxCapacity, setNurseryMaxCapacity] = useState<{
    [NurseryMode.LIGHT]: number;
    [NurseryMode.DARK]: number;
  }>();

  useEffect(() => {
    if (nurseryMaxCapacity && wavesByDate) {
      setChartData(formatNurseryChartData(wavesByDate, nurseryMaxCapacity, date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesByDate, nurseryMaxCapacity]);

  useEffect(() => {
    if (farms) {
      setNurseryMaxCapacity(getNurseryMaxCapacity(farms));
    }
  }, [farms]);

  if (!nurseryMaxCapacity || !chartData)
    return (
      <>
        <h3>Nursery Utilisation</h3>
        <EmptyList lowOpacity rounded>
          There is no nursery utilisation
        </EmptyList>
      </>
    );

  return (
    <>
      <NurseryUtilisationHeaderWrapper>
        <h3>Nursery Utilisation</h3>
        <NurseryDemandStatues
          isGeneratingNurseryDemand={isGeneratingNurseryDemand}
          lastNurseryDemandUpdatedAt={lastNurseryDemandUpdatedAt}
        />
      </NurseryUtilisationHeaderWrapper>
      <SpacerVertical />
      <NurseryContainer>
        {isGeneratingNurseryDemand && (
          <Overlay>
            <OverlayText>Nursery demand is being generated. Please check it later.</OverlayText>
          </Overlay>
        )}
        <NurseryChart
          nurseryMaxCapacity={nurseryMaxCapacity}
          chartData={chartData}
          selectedNurseryModes={selectedNurseryModes}
        />
      </NurseryContainer>
    </>
  );
};
