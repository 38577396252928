import { forwardRef } from 'react';
import qrcode from 'qrcode-generator';
import {
  Task,
  Location,
  TaskType,
  OperationalFarm,
  Batch,
  User,
  BatchWithFarm
} from '../../../types';
import { formatFullDate, addDays } from '../../../utils/date-functions';
import InfarmLogo from '../../../assets/infarm-logo.svg';
import TrayImageUrl from '../../../assets/tray.svg';
import {
  StyledH1,
  FarmName,
  PlantingWrapper,
  StyledH2,
  PrintDetails,
  InfarmLogoImage,
  TrayColumnWrapper,
  CancelledColum,
  TrayColumnHeader,
  TrayImage,
  Page,
  InfoWrapper,
  InlineStyle,
  operationalBenchIdStyle,
  QRCodeImage
} from './style';
import { sortedBatch } from '../utils';

interface InfoColumnProps {
  batchName: string;
  batchID: string;
  taskDate: string;
  farmName: string;
  hubName: string;
  printDate: string;
  grower: string;
  batchType: TaskType;
  stageRecipeDuration: number;
  operationalBenchId: number;
}

const QRCode = (props: { value: string; style?: React.CSSProperties }) => {
  const { value, style } = props;
  const qrcodeData = qrcode(0, 'M');
  qrcodeData.addData(value, 'Alphanumeric');
  qrcodeData.make();
  return (
    <img
      src={`data:image/svg+xml,${qrcodeData.createSvgTag({
        cellSize: 4,
        margin: 0,
        scalable: true
      })}`}
      alt={value}
      style={style}
    />
  );
};

const InfoColumnTemplate = (props: InfoColumnProps) => {
  const {
    batchName,
    batchID,
    taskDate,
    farmName,
    hubName,
    printDate,
    grower,
    batchType,
    stageRecipeDuration,
    operationalBenchId
  } = props;

  return (
    <div style={InfoWrapper}>
      <div>
        <h1 style={StyledH1}>{batchName}</h1>
        <h2 style={FarmName}>
          {farmName}{' '}
          {operationalBenchId && (
            <span style={operationalBenchIdStyle}>Bench {operationalBenchId}</span>
          )}
        </h2>
        {batchType === TaskType.PLANTING ? (
          <div>
            <div style={PlantingWrapper}>
              <h1 style={StyledH1}>Planting</h1>
              <h2 style={StyledH2}>{taskDate}</h2>
            </div>
            <div style={PrintDetails}>
              <span style={{ fontWeight: '500' }}>Harvesting </span>
              <span>{formatFullDate(addDays(taskDate, stageRecipeDuration))}</span>
            </div>
          </div>
        ) : (
          [
            <h1 style={StyledH1} key="harvesting">
              Harvesting
            </h1>,
            <h2 style={StyledH2} key="taskDate">
              {taskDate}
            </h2>
          ]
        )}
      </div>
      <div>
        <QRCode value={batchID} style={QRCodeImage} />
        <p style={PrintDetails}>
          <strong>Full Batch ID</strong>
          <br />
          {batchID}
        </p>
        <p style={PrintDetails}>
          <strong>{hubName}</strong>
          <br />
          Printed at {printDate}
          <br />
          by {grower}
        </p>
        <img style={InfarmLogoImage} src={InfarmLogo} alt="infarm-logo" />
      </div>
    </div>
  );
};

const TraysColumn = ({ totalTrays, tasks }: { totalTrays: number; tasks: Array<Task> }) => {
  return (
    <div style={TrayColumnWrapper}>
      <div style={TrayColumnHeader}>
        <img src={TrayImageUrl} style={TrayImage} alt="Tray" />
        {totalTrays} Trays
      </div>
      <table cellSpacing="10">
        <thead>
          <tr>
            <th>Code</th>
            <th style={{ width: '40%' }}>Variety</th>
            <th>Plant Group</th>
            <th>Tray Type</th>
            <th>Plants</th>
            <th>Trays</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task: Task) => {
            return (
              <tr style={task.cancelled ? CancelledColum : {}} key={task.uuid}>
                <td>{task.stageRecipe?.internalCode}</td>
                <td>{task.stageRecipe?.name}</td>
                <td>{task.stageRecipe?.plantGroup}</td>
                <td>{task.trayType}</td>
                <td>{task.plantsOfStageRecipe}</td>
                <td>{task.traysPerStageRecipe}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

interface BatchLabelRendererProps {
  location: Location;
  user: User;
  date: Date;
  batchType: TaskType;
}

const BatchLabelRenderer = ({ location, user, date, batchType }: BatchLabelRendererProps) => {
  const printDate = new Date().toLocaleString('en-gb', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  const taskDate = formatFullDate(date);

  if (!location?.farms?.length) return null;

  return (
    <>
      {[...location.farms]
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
        .map((farm: OperationalFarm) => {
          if (!farm.batches?.length) return null;

          return farm.batches
            ?.map((batch: Batch) => {
              const batchWithFarm = { ...batch, farm: { ...farm } };
              return batchWithFarm;
            })
            .sort((a, b) => sortedBatch(a, b))
            .map((batch: BatchWithFarm) => {
              if (batch.cancelled) return null;
              const batchName = batch.batchId || '';
              const growerName = user.name;
              const duration = batch.tasks?.[0]?.stageRecipe?.duration || 0;
              return (
                <div style={Page} key={batch.batchId}>
                  <InfoColumnTemplate
                    batchName={batchName}
                    batchID={batch.fullBatchId || batchName}
                    batchType={batchType}
                    taskDate={taskDate}
                    farmName={farm.name}
                    hubName={location.name}
                    printDate={printDate}
                    grower={growerName}
                    stageRecipeDuration={duration}
                    operationalBenchId={batch.bench.operationalBenchId!}
                  />
                  <TraysColumn totalTrays={batch.totalTrays || 0} tasks={batch.tasks || []} />
                </div>
              );
            });
        })}
    </>
  );
};

export const BatchLabelPrinter = forwardRef(
  ({ location, user, date, batchType }: BatchLabelRendererProps, ref: any) => {
    return (
      <div ref={ref} style={{ display: 'none' }}>
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css2?family=Libre+Barcode+39&display=swap"
          rel="stylesheet"
        />
        <style dangerouslySetInnerHTML={{ __html: InlineStyle }} />
        {location && (
          <BatchLabelRenderer location={location} user={user} date={date} batchType={batchType} />
        )}
      </div>
    );
  }
);
