import { Bench } from '../../../types';
import { NurseryInAcreTaskGroup, TaskGroup } from '../types';
import {
  BenchName,
  BenchSummary,
  BenchNameWrapper,
  ChevronWrapper,
  CompletedTasks,
  CompletedTasksWrapper
} from './style';
import BenchIcon from '../../../assets/bench.svg';
import { DsButton } from '@infarm/design-system-react';
import { useState } from 'react';
import { TaskContainer } from './TaskContainer';
export const BenchContainer = ({
  bench,
  taskGroup
}: {
  bench: Bench;
  taskGroup: {
    [key in TaskGroup]: NurseryInAcreTaskGroup;
  };
}) => {
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpand = () => setExpanded(curr => !curr);

  const numberOfTasks = Object.values(TaskGroup).filter(
    task => taskGroup[task].tasks.length
  ).length;

  const numberOfCompletedTasks = Object.values(TaskGroup).filter(
    taskType =>
      taskGroup[taskType].tasks.length &&
      taskGroup[taskType].tasks.every(task => task.status && task.status === 'COMPLETED')
  ).length;

  return (
    <BenchSummary>
      <BenchNameWrapper>
        <div>
          <img src={BenchIcon} alt="Bench Icon" />
          <BenchName>NiA Bench {bench.name}</BenchName>
        </div>
        <CompletedTasksWrapper>
          <CompletedTasks>
            {numberOfCompletedTasks} / {numberOfTasks} Task(s)
          </CompletedTasks>
          <ChevronWrapper>
            <DsButton
              icon={isExpanded ? 'expand_less' : 'expand_more'}
              flat
              onClick={toggleExpand}
            />
          </ChevronWrapper>
        </CompletedTasksWrapper>
      </BenchNameWrapper>

      {isExpanded &&
        Object.values(TaskGroup).map(task => {
          if (taskGroup[task].tasks?.length) {
            return <TaskContainer task={task} taskGroup={taskGroup} key={task} />;
          }
          return null;
        })}
    </BenchSummary>
  );
};
