import { FarmModel, FarmState, OperationalFarm, StageRecipe } from '../../../types';
import { UnitFilter } from '../constants';
import {
  LocationTaskGroupByOperationalFarm,
  LocationWithTaskGroup,
  RowData,
  TaskGroupByDate,
  TaskGroupByDateAndStageRecipe
} from './types';

export const formatStageRecipeDetails = (recipe: StageRecipe): string => {
  const code = recipe.internalCode || '(internal code missing)';
  const weight = recipe.weight ? `${recipe.weight}g` : '(weight missing)';
  return `${code} · ${weight}`;
};

export const mergeFarmAndTaskGroup = (
  farm: OperationalFarm,
  taskGroupsByOperationalFarm: LocationTaskGroupByOperationalFarm[]
): LocationTaskGroupByOperationalFarm => {
  const taskGroupByOperationalFarm = taskGroupsByOperationalFarm.find(
    ({ operationalFarm }) => operationalFarm.uuid === farm.uuid
  );
  return {
    operationalFarm: farm,
    taskGroup: taskGroupByOperationalFarm?.taskGroup || ({} as TaskGroupByDateAndStageRecipe)
  };
};

export const formatRowData = (taskGroupsByDate: TaskGroupByDate[]): RowData => {
  const rowData: RowData = {};

  if (taskGroupsByDate?.length) {
    taskGroupsByDate.map(
      taskGroupByDate =>
        (rowData[taskGroupByDate.date] = {
          [UnitFilter.PLANTS]: taskGroupByDate.taskGroup.totalNumberOfPlants,
          [UnitFilter.TRAYS]: taskGroupByDate.taskGroup.totalNumberOfTrays,
          [UnitFilter.BENCH]: taskGroupByDate.taskGroup?.totalNumberOfBenches || 0
        })
    );
  }

  return rowData;
};

export const filterArchivedFarms = (
  farms: OperationalFarm[],
  location: LocationWithTaskGroup | undefined
) =>
  farms.filter(
    farm =>
      farm.model !== FarmModel.GROWTH_CHAMBER &&
      (farm.state !== FarmState.ARCHIVED ||
        !!location?.tasksForDate.byOperationalFarm.find(
          taskGroup => taskGroup.operationalFarm.uuid === farm.uuid
        ))
  );
