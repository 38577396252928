import { add } from 'date-fns';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { IconTooltip } from '../../../components/IconTooltip';
import { offsetDateWithTimezone, toIsoDate } from '../../../utils/date-functions';
import { StyledSwitch } from '../../LocationForm/styles';
import { modalContent } from '../constants';
import { RepeatConfig, RepeatSwitch, Label } from '../style';
import { Modal } from './Modal';

export const RepeatWeeklySwitch = () => {
  const { field } = useController({ name: 'doesRepeatWeekly' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setValue, getValues } = useFormContext();
  const { configurations, startDate } = getValues();
  const onPrimaryBtnClick = () => {
    setValue('configurations', []);
    field.onChange(!field.value);
    closeModal();
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (startDate) {
      if (field.value) {
        const defaultEndDate = toIsoDate(add(offsetDateWithTimezone(startDate), { months: 1 }));
        setValue('endDate', defaultEndDate);
      } else {
        const defaultEndDate = toIsoDate(add(offsetDateWithTimezone(startDate), { days: 6 }));
        setValue('endDate', defaultEndDate);
      }
    }
  }, [field.value, setValue, startDate]);

  return (
    <RepeatConfig>
      <Modal
        onSecondaryBtnClick={closeModal}
        onPrimaryBtnClick={onPrimaryBtnClick}
        isModalOpen={isModalOpen}
        secondaryLabel="No"
        primaryLabel="Yes"
        heading={modalContent.reset.heading}
        text={modalContent.reset.text}
        onModalClosed={() => {
          setIsModalOpen(false);
        }}
      />
      <IconTooltip
        styles={{
          backgroundColor: 'rgba(0, 0, 0, 0.7);'
        }}
        placement="top-start"
        body="When turn on, Mon-Sun pattern will
repeat during the selected period."
      >
        <RepeatSwitch>
          <Label>Repeat weekly</Label>
          <StyledSwitch
            checked={!!field.value}
            onInput={() => {
              if (configurations.length) {
                setIsModalOpen(true);
              } else {
                field.onChange(!field.value);
              }
            }}
          />
        </RepeatSwitch>
      </IconTooltip>
    </RepeatConfig>
  );
};
