import { underProvisionedMessage, overProvisionedMessage } from './constants';
import { FormState } from './types';

export const getUnderOrOverProvisionedMessage = (value: number) => {
  if (value <= 0) return '';
  if (value < 100) {
    return underProvisionedMessage;
  }
  if (value > 100) {
    return overProvisionedMessage;
  }
  return '';
};

export const getActionNameByFormState = (formState?: FormState) => {
  switch (formState) {
    case FormState.EDIT:
      return 'Edit';
    case FormState.DUPLICATE:
      return 'Duplicate';
    case FormState.VIEW:
      return 'View';
    // ? For create, we cannot use standart /:uuid/:type endpoint, because there is no schedule uuid yet, so we use just /create, which does not specify a type.
    // ? And this condition assumes that if type is not specified, the page should have been summoned by /create endpoint, meaning that the form type is actually "create".
    default:
      return 'Create';
  }
};

/** Rounds `value` to the next absolute integer.
 * `0.4` will become `1`, `-0.4` will become `-1`.
 */
export const roundToFull = (value: number): number => {
  return Math.sign(value) * Math.ceil(Math.abs(value));
};
