import { DsRow } from '@infarm/design-system-react';
import styled, { css } from 'styled-components';
import { components } from 'react-select';

export const Row = styled.div`
  margin: 24px 0;
`;

export const Column = styled.div``;

export const TextWrapper = styled.div`
  display: flex;
`;

export const Text = styled.h4`
  font-weight: 700;
`;

export const MenuName = styled.p`
  font-weight: 700;
  color: #000000;
  margin-top: 21px;
`;

export const EditButton = styled.button<{ disabled?: boolean }>`
  margin-left: 8px;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  ${({ disabled }) =>
    disabled &&
    css`
      text-decoration: none;
      cursor: not-allowed;
    `}
`;

export const WarningText = styled.span`
  color: #fa6400;
  font-size: 14px;
`;

export const ModalButtonsRow = styled(DsRow)`
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
`;

export const GrowerListMenu = styled(components.Menu)`
  position: relative !important;
  margin-top: 24px !important;
  padding-bottom: 24px !important;
  box-shadow: none !important;
`;

export const SelectedGrowersWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin: 10px 0;
`;

export const GrowerOption = styled(components.Option)`
  border-bottom: 1px solid #f5f5f5;
`;

export const OptionContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

export const OptionIcon = styled.img`
  width: 20px;
  margin-right: 10px;
  justify-content: center;
`;

export const DetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GrowerName = styled.span`
  color: #000000;
  font-weight: 400;
  line-height: 20px;
`;

export const Span = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #808080;
`;

export const ModalContentWrapper = styled.div`
  width: 450px;
`;

export const MoreChipsButton = styled.button`
  display: contents;
  cursor: pointer;
`;
