export interface FarmItem {
  name: string;
  model: string;
  uuid?: string;
  serialNumber?: string;
  capacityInBenches: number;
  nurseryBenches: number;
  isFullDark: boolean;
  lightGerminationBenches?: number;
  darkGerminationBenches?: number;
}

export enum FarmError {
  FARM_TYPE = 'FARM_TYPE',
  FARM_NAME = 'FARM_NAME'
}

export interface LocationGroupRecord {
  name: string;
  uuid: string;
}

export interface LocationInput extends LocationFormInput {
  locationUuid?: string;
  farms: FarmInput[];
}

export interface FarmInput {
  name: string;
  model: string;
  uuid?: string;
  benches: number;
  schedulableBenches: number;
  isFullDark: boolean;
}

export interface FarmItemResponse extends FarmItem {
  __typename: string;
}

export type LocationResponse = {
  name: string;
  uuid: string;
  timezone: string;
  category: string;
  locationGroup: {
    uuid: string;
  };
  nursery?: {
    uuid: string;
  };
  customer: {
    uuid: string;
  };
  farms: FarmItemResponse[];
};

export interface Location {
  name: string;
  uuid: string;
}

export interface LocationFormInput {
  locationGroupUuid: string;
  category: string;
  name: string;
  customerUuid: string;
  timezone: string;
  nurseryUuid: string;
}

export interface IFormInputs extends LocationFormInput {
  farms: FarmItem[];
}

export interface ConfirmModal {
  modalHeading: string;
  modalBody: string | string[];
  cancelLabel: string;
  confirmLabel?: string;
}
