import { DsCard, DsIcon, DsRow } from '@infarm/design-system-react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const DateRow = styled(DsRow)`
  margin: 24px 0 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 33.33%;
`;

export const DateControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Month = styled.span`
  font-size: 20px;
`;

export const Week = styled(Month)`
  color: #616161;
  margin-left: 10px;
`;

export const Toggle = styled.div`
  font-size: 24px;
  border: 1px solid #757575;
  border-radius: 4px;
  height: 39px;
`;

export const ToggleButton = styled.button<{ primary: boolean }>`
  background: none;
  height: 37px;
  border: none;
  color: rgba(0, 0, 0, 0.4);
  padding: 0px 20px;
  cursor: pointer;
  font-size: 16px;
  &:not(:last-child) {
    border-right: solid 1px rgba(0, 0, 0, 0.4);
  }
  ${({ primary }) =>
    primary &&
    css`
      background-color: #000000;
      color: #ffffff;
    `}
`;

export const Row = styled(DsRow)<{
  rounded?: boolean;
  topRounded?: boolean;
  noTopBorder?: boolean;
  darkBg?: boolean;
  lowOpacity?: boolean;
}>`
  border: 1px solid #e0e0e0;
  position: relative;
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 6px;
    `}
  ${({ topRounded }) =>
    topRounded &&
    css`
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    `}
  ${({ noTopBorder }) =>
    noTopBorder &&
    css`
      border-top-width: 0px;
    `}
  ${({ darkBg }) =>
    darkBg &&
    css`
      background-color: #f5f5f5;
    `}
  ${({ lowOpacity }) =>
    lowOpacity &&
    css`
      opacity: 0.5;
    `}
`;

export const DataRow = styled(DsRow)`
  display: grid;
  grid-template-columns: repeat(7, 3fr) 1fr;
  justify-self: stretch;
  vertical-align: middle;
`;

export const PhotoDataRow = styled(DsRow)`
  display: flex;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 8px;
  display: flex;
  align-items: center;
`;
export const LeftBorderdSpan = styled.span`
  padding: 8px 24px 8px 16px;
  border-left: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
`;
export const StyledSpan = styled.span`
  padding: 8px 24px 8px 16px;
`;
export const Span = styled.span<{ bold?: boolean }>`
  color: #808080;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
      color: #000000;
    `}
`;
export const FarmContainer = styled.div`
  margin-bottom: 16px;
`;

export const NoFarmsWrapper = styled.div`
  border: 1px solid #e0e0e0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 730px;
  padding: 40px;
  flex-direction: column;
  text-align: center;
`;

export const RedirectLink = styled(Link)`
  text-decoration: underline;
  color: #0275ff !important;
`;

export const NoFarmImage = styled.img`
  margin: 10px;
  margin-bottom: 25px;
`;

export const CardContainer = styled(DsRow)`
  margin: 24px 0;
`;

export const FullDate = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #767676;
`;

export const Card = styled(DsCard)`
  text-align: center;
`;

export const CardLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const UpperButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkWrapper = styled.div`
  top: -20px;
  position: relative;
`;

export const AnchorTagUnderline = styled(Link)`
  text-decoration: underline;
`;

export const AnchorTag = styled(AnchorTagUnderline)`
  color: #767676;
  left: -5px;
  position: relative;
`;

export const Icon = styled(DsIcon)`
  position: relative;
  color: #767676;
  top: 5px;
  left: -5px;
`;

export const GreyText = styled.span`
  font-size: 12px;
  color: #767676;
`;

export const RedText = styled(GreyText)`
  color: #fa6400;
`;

export const FlexStyledSpan = styled(StyledSpan)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalPlantsRow = styled(DsRow)`
  background-color: #f5f5f5;
  margin-bottom: 20px;
`;

export const TotalPlantsRowTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  padding-top: 8px;
  padding-left: 25px;
  position: absolute;

  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100px;
  margin-left: 15px;
  .Mui-disabled {
    cursor: not-allowed !important;
  }
`;

export const ControlsRow = styled.div`
  display: flex;
  align-content: center;
  align-items: center;

  a:hover {
    text-decoration: none;
  }
`;

export const ViewByWrapper = styled(ControlsRow)`
  width: 33.33%;
  place-content: flex-end;
`;

export const Options = styled(MenuItem)`
  pointer-events: auto !important;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 33.33%;
`;

export const GreyP = styled.p`
  color: #616161;
`;

export const PhotoFieldsWrapper = styled.div`
  padding: 10px;
  margin-left: 15px;
  display: flex;
`;

export const PhotoDate = styled.div`
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;

export const PhotoUploadedBy = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

export const PhotoThumbnailsWrapper = styled.div`
  display: flex;
  width: 90px;
  height: 68px;

  img {
    object-fit: cover;
  }
`;

export const PhotoComment = styled.div`
  width: 350px;
`;

export const PhotosTableHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 10px;
  color: #00000099;
`;

export const BoldP = styled.p`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const Delimiter = styled.p`
  font-size: 20px;
  color: #616161;
  padding-left: 4px;
  padding-right: 4px;
  text-align: left;
`;

export const TimeLimitError = styled.p`
  color: red;
  font-size: 15px;
  white-space: nowrap;
  padding-left: 20px;
`;

export const DatesWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 428px;
`;

export const DateSelectionContainer = styled.div`
  padding-bottom: 35px;

  .react-datepicker-wrapper {
    width: auto;
  }
`;

export const PhotoThumbnail = styled.img<{ applyOpacity?: boolean }>`
  width: 90px;
  height: 100%;
  border: 1px solid #616161;
  margin-right: 10px;
  border-radius: 3px;
  cursor: pointer;
  ${({ applyOpacity }) => (applyOpacity ? 'opacity: 0.5;' : '')}
`;

export const PicturesHidedCount = styled.div`
  position: absolute;
  color: black;
  font-weight: bold;
  font-size: 25px;
  margin-top: 21px;
  margin-left: 27px;
`;

export const PhotoWithHiddenCount = styled.span``;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Roboto;
`;

export const THead = styled.thead``;

export const Tr = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
  }
`;

export const TRheader = styled.tr<{ selectable?: boolean }>`
  cursor: ${props => (props.selectable ? 'pointer' : 'default')};
`;

export const Th = styled.th`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #616161;
  padding: 20px 15px;
`;

export const DateTh = styled(Th)`
  width: 200px;
`;

export const UploadByTh = styled(Th)`
  width: 230px;
`;

export const PhotosTh = styled(Th)`
  width: 420px;
`;

export const Td = styled.td`
  font-size: 16px;
  font-style: normal;
  text-align: left;
  color: #25213b;
  padding: 20px 15px;
  vertical-align: top;
  word-break: break-all;
`;

export const NoPhotosTd = styled(Td)`
  width: 400px;
`;

export const Tbody = styled.tbody`
  border-radius: 0.25em;
  box-shadow: 0 0 0 1px #bdbdbd;
`;

export const SliderCounter = styled.span`
  margin-left: 25px;
  margin-top: 68px;
  height: 30px;
  width: 100px;
  display: flex;
  color: #000000;
`;

export const SlashCounter = styled.div`
  font-size: 25px;
`;

export const LeftSlide = styled.div`
  font-size: 25px;
  padding-right: 20px;
  cursor: pointer;
`;

export const SlideImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const RightSlide = styled.div`
  cursor: pointer;
  font-size: 25px;
  padding-right: 20px;
`;

export const SliderPhotosText = styled.div`
  padding-left: 5px;
  margin-top: 3px;
  font-size: 14px;
  font-weight: normal;
`;

export const SliderPhotoDetails = styled.div`
  margin-top: 413px;
  margin-left: -100px;
  color: #000000;
`;
export const SliderPhotoNumber = styled.div`
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 20px;
`;

export const SliderPhotoUploadedText = styled.div`
  font-size: 15px;
  font-weight: normal;
`;

export const SliderPhotoDateUpload = styled.div`
  font-size: 15px;
  font-weight: normal;
`;

export const SliderPhotoHourUpload = styled.div`
  font-size: 15px;
  font-weight: normal;
`;

export const SliderPhotoUploadedBy = styled.div`
  font-size: 15px;
  font-weight: normal;
  padding-top: 19px;
`;

export const CarouselContainer = styled.div`
  text-align: center;
  background-color: black;
  height: 600px;

  div {
    width: 800px;
    height: 600px;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  margin-left: -25px;
  margin-top: -20px;
  margin-bottom: -20px;
`;

export const NurserySystemsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatuesWrapper = styled(ControlsRow)``;

export const StatusText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 12px;
`;

export const GeneratingStatus = styled.div`
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
`;

export const NurseryUtilisationHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
