import { DsColumn, DsIcon } from '@infarm/design-system-react';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  InputSection,
  SectionTitleWrapper,
  SectionTitle,
  SectionSubTitle,
  ErrorMessage,
  StyledInput
} from '../style';
import { FormProps, FormState } from '../types';
import { FindByValues } from '../__tests/mock';

export const ScheduleName = (props: UseControllerProps<FormProps>) => {
  const { field } = useController(props);
  const { type } = useParams();
  const {
    formState: { errors }
  } = useFormContext<FormProps>();

  return (
    <InputSection>
      <SectionTitleWrapper>
        <SectionTitle>Schedule Name*</SectionTitle>
        <SectionSubTitle>
          Give your schedule a name for easy recognition, if other schedules get created
        </SectionSubTitle>
      </SectionTitleWrapper>
      <DsColumn>
        <StyledInput
          className="scheduleNameInput"
          placeholder="Enter Schedule Name"
          inputProps={{ 'data-cy': 'ScheduleName___input' }}
          type="text"
          variant="outlined"
          onInput={field.onChange}
          value={field.value as string}
          error={!!errors?.scheduleName}
          disabled={type === FormState.VIEW}
        />
      </DsColumn>
      {errors?.scheduleName && (
        <ErrorMessage role={FindByValues.NAME_ERROR}>
          <DsIcon>error_outline</DsIcon>
          {errors.scheduleName.message}
        </ErrorMessage>
      )}
    </InputSection>
  );
};
