export const InlineStyle = `
* {
  box-sizing: border-box;               
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  color: #000000;
  font-weight: 400;
  font-size: 14pt;
  line-height: 1.4;
}

@media print {
  html,
  body {
    padding: 0;
    margin: 0;
  }

  @page {
    margin: 0;
    size: A4 landscape;
  }
}
`;

export const InfoWrapper = {
  display: 'flex',
  flexDirection: 'column' as any,
  justifyContent: 'space-between',
  flex: '1 0 33%',
  paddingRight: '0.35cm',
  borderRight: '0.3pt solid black'
};

export const TrayColumnHeader = {
  display: 'block',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '1.4mm',
  padding: '0.35cm',
  fontWeight: '500',
  fontSize: '22pt',
  lineHeight: '33pt',
  marginBottom: '0.35cm'
};

export const TrayImage = {
  height: '33pt',
  width: 'auto',
  verticalAlign: 'bottom',
  marginInlineEnd: '0.5em'
};

export const Page = {
  padding: '1.7cm 1.4cm',
  pageBreakAfter: 'always' as any,
  display: 'flex',
  flexDirection: 'row' as any,
  height: '100vh',
  '-webkit-print-color-adjust': 'exact'
};

export const InfarmLogoImage = {
  height: 'auto',
  width: '4cm'
};

export const TrayColumnWrapper = {
  paddingLeft: '0.35cm',
  flex: '2 0 66%',
  display: 'flex',
  flexDirection: 'column' as any
};

export const CancelledColum = {
  'text-decoration': 'line-through',
  opacity: '0.3'
};

export const StyledH1 = {
  fontSize: '37pt',
  lineHeight: '37pt',
  fontWeight: '700',
  margin: '0'
};

export const StyledH2 = {
  fontSize: '22pt',
  lineHeight: '28pt',
  fontWeight: '400',
  margin: '0'
};

export const FarmName = {
  fontSize: '22pt',
  lineHeight: '28pt',
  fontWeight: '400',
  margin: '0',
  marginBottom: '16pt',
  display: 'flex',
  justifyContent: 'space-between',
  'flex-direction': 'row'
};

export const PlantingWrapper = {
  backgroundColor: 'black',
  color: 'white',
  padding: '0.35cm',
  marginBottom: '10pt'
};

export const PrintDetails = {
  fontSize: '11pt',
  margin: '16pt 0'
};

export const operationalBenchIdStyle = {
  fontSize: '14pt',
  margin: '0'
};

export const BatchIdLabel = {
  fontSize: '11pt',
  fontWeight: '700'
};

export const BatchId = {
  fontSize: '22pt',
  fontWeight: '700'
};

export const QRCodeImage = {
  width: '50%',
  maxWidth: '50%',
  height: 'auto'
};
