import { FC } from 'react';
import { NoFarmImage, NoFarmsWrapper, RedirectLink } from '../style';
import MissingFarmsImage from '../../../assets/missing_farms.svg';

export const NoFarms: FC = () => {
  return (
    <NoFarmsWrapper>
      <NoFarmImage src={MissingFarmsImage} alt="No farms" />
      <h2>Some farms might be missing...</h2>
      <p>
        We are going to move Gen1 Farms to this page very soon, in the meantime, you can see and
        configure Gen1 farms under the <RedirectLink to="/#/">Location Groups Tab</RedirectLink>.
      </p>
      <p>If you want to add a Gen 2 farm please do that under the Location Settings.</p>
    </NoFarmsWrapper>
  );
};
