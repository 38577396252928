import styled from 'styled-components';
import {
  DsButton,
  DsModal,
  DsColumn,
  DsRow,
  DsSpacer,
  DsSnackbar
} from '@infarm/design-system-react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { COMMIT_SEEDING } from './queries';

interface CommitButtonProps {
  statuses?: string[];
  taskUuids?: string[];
  commitAll?: boolean;
}

const StyledButton = styled(DsButton).attrs(() => ({
  stretch: true,
  primary: true
}))`
  width: 120px;
`;

const Copy = styled.p`
  margin-block: 1em;
`;

export const CommitButton = ({ statuses, taskUuids, commitAll }: CommitButtonProps) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);
  const [success, setSuccess] = useState(false);
  const isCommitted = !statuses?.includes('PENDING');
  const label = isCommitted ? 'Committed' : commitAll ? 'Commit ALL' : 'Commit';
  const [commitTasks, { loading }] = useMutation(COMMIT_SEEDING, {
    variables: { taskUuids },
    refetchQueries: ['SeedingState', 'SeedingOverviewForHub'],
    awaitRefetchQueries: true
  });
  const dismissPopup = () => {
    setPopupVisible(false);
  };
  const commitCallback = async () => {
    setErrors(null);
    setSuccess(false);
    try {
      const result = await commitTasks();
      if (result.errors) {
        throw result.errors;
      } else {
        setSuccess(true);
      }
    } catch (e: any) {
      const extractedErrors = e.networkError?.result?.errors ||
        (e.graphQLErrors?.length && e.graphQLErrors) ||
        (e.clientErrors?.length && e.clientErrors) || [e];
      setErrors(extractedErrors.map((err: Error) => err.message));
    }
    setPopupVisible(false);
  };

  if (!taskUuids?.length) {
    return <></>;
  }

  return (
    <span>
      {errors && (
        <DsModal open hideCloseButton heading="Failed to commit" onClosed={() => setErrors(null)}>
          <DsColumn>
            <Copy>The following error occured when trying to commit to the seeding varieties:</Copy>
            {errors.map(message => (
              <p key={message}>{message}</p>
            ))}
            <DsRow>
              <DsSpacer size={10} />
              <DsButton primary label="Dismiss" onClick={() => setErrors(null)} />
            </DsRow>
          </DsColumn>
        </DsModal>
      )}
      {success && (
        <DsSnackbar open="true" labelText="Seeding trays were committed successfully."></DsSnackbar>
      )}
      {popupVisible && (
        <DsModal
          width={600}
          open
          hideCloseButton
          onClosed={dismissPopup}
          heading="Are you sure you want to commit?"
        >
          <DsColumn>
            <Copy>
              Once you commit, nothing can be removed from the seeding plan.
              <br />
              New seeding can still be added and will require additional commitment.
            </Copy>
            <DsRow>
              <DsSpacer size={10} />
              <DsButton
                primary
                disabled={loading}
                label={loading ? 'Committing…' : 'Commit'}
                onClick={commitCallback}
              />
              <DsButton label="Cancel" onClick={dismissPopup} />
            </DsRow>
          </DsColumn>
        </DsModal>
      )}
      <StyledButton
        disabled={loading || isCommitted}
        label={label}
        onClick={() => isCommitted || setPopupVisible(true)}
      />
    </span>
  );
};
