import {
  isToday,
  formatFullDate,
  toIsoDate,
  offsetDateWithTimezone
} from '../../utils/date-functions';
import { HUB_BATCHES, PLANTING_PLAN } from './queries';
import { Location, OperationalFarm, TaskType } from '../../types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { DsButton } from '@infarm/design-system-react';
import { displayTaskType } from '../../utils/farm';
import { InhubKanbanBoard } from './InhubKanbanBoard';
import { BatchLabelPrinter } from './BatchLabelPrinter';
import { PageHeaderRow, HeaderDate, ErrorMessage, FullPageBg } from './style';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { isValid } from 'date-fns';

const POLLING_INTERVAL = 10_000;

export const InhubTasks = () => {
  const { locationUuid, date, taskType } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  const batchType = taskType!.toUpperCase() as TaskType;
  const currentDate = offsetDateWithTimezone(date || '');
  const [getBatches, { data }] = useLazyQuery(HUB_BATCHES, {
    pollInterval: POLLING_INTERVAL,
    fetchPolicy: 'no-cache'
  });
  const [getPlantingPlan] = useLazyQuery(PLANTING_PLAN, {
    onCompleted: data => {
      const { location } = data;
      const downloadLink = document.createElement('a');
      const fileName = `Planting Plan_${location.name} (${location.locationGroup.name})_${urlDate}.xlsx`;
      downloadLink.href = location.plantingPlan.file;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    onError: error => {
      setError(error);
    }
  });
  const batchLabelPrinter: any = useRef();

  useEffect(() => {
    const currDate = offsetDateWithTimezone(date || '');
    if (!locationUuid || !isValid(currDate) || !taskType) return;
    const variables = {
      uuid: locationUuid,
      date: toIsoDate(currDate),
      batchType: taskType.toUpperCase()
    };
    getBatches({ variables });
  }, [date, locationUuid, taskType, getBatches]);

  const downloadPlantingPlan = async (): Promise<void> => {
    const urlDate = toIsoDate(currentDate);
    getPlantingPlan({
      variables: {
        uuid: locationUuid,
        date: urlDate
      }
    });
  };

  const printBatchLabels = async (): Promise<void> => {
    const printFrame = globalThis.document.createElement('iframe') as HTMLIFrameElement;
    printFrame.srcdoc = batchLabelPrinter.current?.innerHTML;
    printFrame.style.height = '0';
    printFrame.style.border = 'none';
    globalThis.document.body.appendChild(printFrame);

    printFrame.contentWindow!.onload = async () => {
      const printDoc = printFrame.contentDocument as any;
      const labelName = batchType === TaskType.PLANTING ? 'Planting Labels' : 'Harvest labels';
      const docTitle = `${labelName} - ${toIsoDate(currentDate)} - ${location.name}`;
      // Workaround: Chrome uses the parent doc title instead of
      // the iframe doc title as the PDF filename
      printDoc.title = globalThis.parent.document.title = docTitle;
      await printDoc.fonts.ready;
      printFrame.contentWindow!.focus();
      printFrame.contentWindow!.print();
      globalThis.document.body.removeChild(printFrame);
    };
  };

  const reportShrinkage = (): void => {
    const urlDate = toIsoDate(currentDate);
    navigate(
      `/locations/${locationUuid}/daily/${urlDate}/workstations/product/${batchType.toLowerCase()}/shrinkage`
    );
  };
  const location = data?.location as Location;
  const fullDate = formatFullDate(currentDate);
  const farms = location?.farms || [];
  const batchCount =
    farms.reduce((sum: number, farm: OperationalFarm) => sum + (farm.batches?.length || 0), 0) || 0;
  const showPrintButton = batchType === TaskType.PLANTING || batchType === TaskType.HARVEST;
  const showShrinkageButton = batchType === TaskType.PACKING;
  const showPlantingPlanButton = batchType === TaskType.PLANTING;
  const urlDate = toIsoDate(currentDate);

  return (
    <>
      <Breadcrumbs
        elements={[
          {
            text: 'Locations',
            link: `/locations`,
            dataCy: 'InhubWorkstation__goBackThreeLevels'
          },
          {
            text: data?.location?.name,
            link: `/locations/${locationUuid}/`,
            dataCy: 'InhubWorkstation__goBackTwoLevel'
          },
          {
            text: 'Workstations',
            link: `/locations/${locationUuid}/daily/${urlDate}`,
            dataCy: 'InhubWorkstation__goBackOneLevel'
          },
          {
            text: batchType.toLowerCase()
          }
        ]}
      />
      <PageHeaderRow>
        <div className="span-8">
          <h1>{displayTaskType(batchType)}</h1>
          <HeaderDate today={isToday(currentDate)}>{fullDate}</HeaderDate>
        </div>
        {showPlantingPlanButton && (
          <DsButton
            label="Download Planting Plan"
            icon="download"
            onClick={downloadPlantingPlan}
            disabled={!batchCount}
          />
        )}
        {showPrintButton && (
          <DsButton
            label={
              batchType === TaskType.PLANTING ? 'Print Planting Labels' : 'Print Harvest Labels'
            }
            icon="print"
            onClick={printBatchLabels}
            disabled={!batchCount}
          />
        )}
        {showShrinkageButton && (
          <DsButton label="Report Shrinkage" onClick={reportShrinkage} disabled={!batchCount} />
        )}
      </PageHeaderRow>
      {error && (
        <ErrorMessage>
          <p>
            <strong>⚠️ Unable to connect</strong> - {error.message}
          </p>
          <p>Please check your internet connection.</p>
        </ErrorMessage>
      )}
      <FullPageBg>
        <div>
          <InhubKanbanBoard location={location} batchType={batchType as TaskType} />
        </div>
      </FullPageBg>
      <BatchLabelPrinter
        ref={batchLabelPrinter}
        location={location}
        user={data?.user}
        date={currentDate}
        batchType={batchType}
      />
    </>
  );
};
