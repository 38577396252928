import styled from 'styled-components';
import { TextField, FormControl } from '@material-ui/core';
import { DsButton, DsIcon } from '@infarm/design-system-react';
import WarningIcon from '@material-ui/icons/Warning';
import { Link } from 'react-router-dom';

export const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 60px;
`;

export const SectionSubTitle = styled.p`
  color: #999999;
  font-weight: 400;
  max-width: 963px;
`;
export const PageSubTitle = styled.h3``;
export const SectionWrapper = styled.div`
  margin: 32px 0;
`;

export const StyledDateInput = styled(TextField)`
  width: 318px;
`;

export const SectionDates = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RepeatConfig = styled.div`
  margin: 16px 0;
`;

export const RepeatSwitch = styled.div`
  max-width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const Label = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;

export const TableWrapper = styled.div`
  position: relative;
`;

export const DemandTable = styled.table`
  width: 100%;
  border-spacing: 0;
  outline: thin solid #e0e0e0;
  border-radius: 10px;
  table-layout: auto;
  margin-top: 24px;

  tr {
    padding: 0 16px;
  }
`;

export const ConfigRowWrapper = styled.tr`
  height: 75px;
`;
export const HeaderRow = styled.tr`
  height: 40px;
`;

export const HeaderCell = styled.th`
  padding: 12px;
  padding-left: 16px;
  text-align: left;
`;

export const Cell = styled.td`
  padding: 0 16px;
`;
export const VarietyCell = styled.td`
  width: 245px;
`;

export const ActionIcon = styled(DsIcon)<{ disabled?: boolean }>`
  color: rgba(0, 0, 0, 0.9);
  ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}
  ${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')}
`;

export const ActionCell = styled.td`
  width: 50px;
  text-align: center;
`;

export const GroupNameRow = styled.tr`
  height: 56px;
  background-color: #f5f5f5;
  border-top: 3px solid #e0e0e0;
`;

export const DateLabel = styled.label`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const GroupNameCell = styled.td`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const VarietyRow = styled.tr`
  padding: 0 16px;
`;

export const GroupCell = styled.td<{ warning?: boolean }>`
  border: thin solid #e0e0e0;
  padding: 0 16px;
  border-color: ${({ warning }) => (warning ? '#FA6400' : '')};
`;

export const RemoveIcon = styled(DsIcon)`
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
`;

export const StyledFormControl = styled(FormControl)`
  .MuiInputBase-root {
    min-width: 267px;
    min-height: 68px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
  }
  .MuiInput-underline:before {
    border-bottom: 0;
  }
`;

export const TotalsRow = styled.tr<{ isGrandTotal?: boolean }>`
  padding: 0 16px;
  height: 56px;
  background-color: ${({ isGrandTotal }) => (isGrandTotal ? '#F5F5F5' : '#FFFFFF')};

  &:last-of-type {
    td:first-of-type {
      border-bottom-left-radius: 10px;
    }
    td:last-of-type {
      border-bottom-right-radius: 10px;
    }
  }
`;

export const TotalsCell = styled.td`
  padding: 0 16px;
  border-width: thin;
  border-color: #e0e0e0;
  border-style: solid;
  font-weight: 700;
  font-size: 16px;

  &:first-letter {
    text-transform: capitalize;
  }
`;

export const TotalLabel = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: block;
  padding-left: 2px;
`;

export const CancelButton = styled(DsButton)`
  margin-right: 12px;
`;

export const ErrorMessage = styled.p`
  color: #ff3b30;
  display: flex;
  margin-top: 8px;
  width: 350px;

  ds-icon {
    margin-right: 10px;
  }
`;

export const EmptyRow = styled.tr`
  height: 32px;
`;

export const Row = styled.div`
  display: flex;
  margin: 12px 0;
`;

export const PasteButton = styled.div<{ disabled?: boolean }>`
  align-self: flex-start;
  border: 1px solid;
  border-radius: 4px;
  width: 210px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-right: 12px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  ${({ disabled }) => (disabled ? 'pointer-events: none;' : 'cursor: pointer;')}
  ${({ disabled }) => (disabled ? 'opacity: 0.5;' : '')}
`;

export const PasteIcon = styled(DsIcon)`
  margin-right: 4px;
  margin-top: 4px;
`;

export const Overlay = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const Chevron = styled.td`
  text-align: center;
`;

export const DayIndicatorWrapper = styled.div`
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
`;

export const DateConfigWrapper = styled.div`
  display: flex;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  color: #fa6400;
`;

export const AnchorLink = styled.a`
  text-decoration: underline;
  color: #007aff;
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #007aff;
`;
