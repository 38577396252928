import { Table, Th, THead, Tr, Error } from '../styles';
import { Location } from '../../../types';
import { ApolloError } from '@apollo/client';
import { getErrorMessage } from '../../../utils/errors';
import { TABLE_HEADER } from '../constants';
import { TableBody } from './TableBody';

export interface LocationTableProps {
  loading: boolean;
  error: ApolloError | undefined;
  locations: Location[];
  tab: string;
  fetchMoreError: undefined | string;
}

export const LocationTable = (props: LocationTableProps) => {
  if (props.error) return <Error>{getErrorMessage(props.error)}</Error>;
  return (
    <>
      <Table>
        <THead>
          <Tr>
            {TABLE_HEADER.map(name => (
              <Th key={name}>{name}</Th>
            ))}
          </Tr>
        </THead>
        <TableBody {...props} />
      </Table>
      {props.fetchMoreError && <Error>{props.fetchMoreError}</Error>}
    </>
  );
};
