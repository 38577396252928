import { DsRow, DsColumn } from '@infarm/design-system-react';
import { BatchWithFarm, Location, Task, TaskType } from '../../types';
import { getBatchColumns, getBatchColumnTitles } from './utils';
import {
  KanbanColumn,
  StyledPlaceholder,
  BatchCard,
  BatchTaskSection,
  CancelButton,
  BatchTaskExpandButton,
  BatchTaskExpandAllButton
} from './style';
import ConfirmCancellationModal from './InhubBatchTaskModal';
import { useState } from 'react';

interface KanbanBatchCardProps {
  batch: BatchWithFarm;
  cancellable: Boolean;
  isAllExpanded: boolean;
}

const KanbanBatchCard = ({ batch, cancellable, isAllExpanded }: KanbanBatchCardProps) => {
  const [isExpanded, setExpanded] = useState(isAllExpanded);
  const toggleExpand = () => setExpanded(curr => !(curr || isAllExpanded));

  return (
    <BatchCard className={batch.cancelled ? ' cancelled' : ''}>
      <h2>
        {batch.batchId}
        {batch.cancelled && <span className="cancelled-hint">Cancelled</span>}
        <BatchTaskExpandButton
          icon={isExpanded || isAllExpanded ? 'expand_less' : 'expand_more'}
          flat
          data-cy="BatchInfo___expandLessMore"
          onClick={toggleExpand}
        />{' '}
      </h2>
      <p className="ds-secondary">
        {batch.farm?.name}
        {batch.farm?.model !== 'ACRE' && (
          <span>
            &nbsp;&middot;&nbsp;
            {batch.plots?.map(plot => `Plot ${plot.name}`).join(', ')}
          </span>
        )}
      </p>
      {isExpanded || isAllExpanded
        ? batch.tasks?.map(task => <KanbanBatchTaskCard task={task} cancellable={cancellable} />)
        : ''}
    </BatchCard>
  );
};

interface InhubKanbanBoardProps {
  batchType: TaskType;
  location: Location;
}

export const InhubKanbanBoard = (props: InhubKanbanBoardProps) => {
  const { location, batchType } = props;
  const [todoTitle, doneTitle] = getBatchColumnTitles(batchType);
  const [todoBatches, doneBatches] = getBatchColumns(location);
  const todoBatchCount = todoBatches.filter(b => !b.cancelled).length;
  const doneBatchCount = doneBatches.filter(b => !b.cancelled).length;
  const loading = !location;
  const [isAllExpandedLeft, setAllExpandedLeft] = useState(false);
  const [isAllExpandedRight, setAllExpandedRight] = useState(false);
  const toggleExpandAllLeft = () => setAllExpandedLeft(curr => !curr);
  const toggleExpandAllRight = () => setAllExpandedRight(curr => !curr);
  return (
    <>
      <DsRow>
        <KanbanColumn>
          <p className="ds-lead">
            {todoTitle} {!loading && `(${todoBatchCount})`}
            <BatchTaskExpandAllButton
              flat
              data-cy="KanbanColumnInfo___expandAllLessMore"
              onClick={toggleExpandAllLeft}
            >
              {isAllExpandedLeft ? 'Collapse All' : 'Expand All'}
            </BatchTaskExpandAllButton>
          </p>
          {loading ? (
            <StyledPlaceholder block />
          ) : (
            todoBatches.map(batch => (
              <KanbanBatchCard
                batch={batch}
                key={batch.batchId}
                cancellable={batchType === TaskType.PLANTING}
                isAllExpanded={isAllExpandedLeft}
              />
            ))
          )}
        </KanbanColumn>
        <KanbanColumn>
          <p className="ds-lead">
            {doneTitle} {!loading && `(${doneBatchCount})`}
            <BatchTaskExpandAllButton
              flat
              data-cy="KanbanColumnInfo___expandAllLessMore"
              onClick={toggleExpandAllRight}
            >
              {isAllExpandedRight ? 'Collapse All' : 'Expand All'}
            </BatchTaskExpandAllButton>
          </p>
          {loading ? (
            <StyledPlaceholder block />
          ) : (
            doneBatches.map(batch => (
              <KanbanBatchCard
                batch={batch}
                key={batch.batchId}
                cancellable={false}
                isAllExpanded={isAllExpandedRight}
              />
            ))
          )}
        </KanbanColumn>
      </DsRow>
    </>
  );
};

interface KanbanBatchTaskCardProps {
  task: Task;
  cancellable: Boolean;
}

const KanbanBatchTaskCard = ({ task, cancellable }: KanbanBatchTaskCardProps) => {
  return (
    <BatchTaskSection>
      <DsColumn>
        <DsRow>
          <p>
            <span>{task.stageRecipe?.name}</span>
            <span className="number-of-plants">{task.plantsOfStageRecipe}</span>
          </p>
        </DsRow>
        {cancellable && (
          <CancelButton>
            <ConfirmCancellationModal task={task} />
          </CancelButton>
        )}
      </DsColumn>
    </BatchTaskSection>
  );
};
