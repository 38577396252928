import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  InfarmLabel,
  InputWrapper,
  Title,
  SmallCaption,
  ValidationError,
  Placeholder,
  WarningMessage
} from './styles';

const Options = styled(MenuItem)`
  pointer-events: auto !important;
`;

const StyledSelect = styled(Select)`
  .Mui-disabled {
    cursor: not-allowed !important;
  }
`;

export interface SelectItem {
  text: string;
  value: string;
  selected?: boolean;
  capacityInBenches?: number;
}

export interface DsSelectProps {
  items: Array<SelectItem>;
  label?: string;
  caption?: string;
  validationMessage?: string;
  fullWidth?: boolean;
  loading?: boolean;
  warningMessage?: string;
  handleChange?: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
}

export const DsSelect: FunctionComponent<DsSelectProps> = ({
  items,
  label,
  caption,
  validationMessage,
  fullWidth = true,
  loading = false,
  warningMessage = '',
  ...props
}) => {
  const inputTitle = label ? <Title>{label}</Title> : null;
  const inputCaption = caption ? <SmallCaption>{caption}</SmallCaption> : null;
  return (
    <InfarmLabel>
      {inputTitle}
      {inputCaption}
      <InputWrapper>
        {loading ? (
          <Placeholder />
        ) : (
          <StyledSelect
            {...props}
            variant={'outlined'}
            autoWidth={true}
            fullWidth={fullWidth}
            margin={'dense'}
            error={!!validationMessage}
          >
            {items.map((item: SelectItem) => {
              return (
                <Options
                  data-cy="PlantingSchedule___NextAcreOption"
                  key={item.value}
                  value={item.value}
                >
                  {item.text}
                </Options>
              );
            })}
          </StyledSelect>
        )}
      </InputWrapper>
      {warningMessage && <WarningMessage>{warningMessage}</WarningMessage>}
      {validationMessage && <ValidationError>{validationMessage}</ValidationError>}
    </InfarmLabel>
  );
};
