import { FarmModel, StageRecipe } from '../../../types';
import { toIsoDate } from '../../../utils/date-functions';
import { FormProps } from '../types';

export const stageRecipe: StageRecipe[] = [
  {
    uuid: 'product uuid 1',
    name: 'Crop X',
    internalCode: 'X',
    duration: 14,
    nurseryMaxPropagationTime: 12,
    trayType: {
      uuid: 'uuid 1',
      name: 'tray type name 1',
      surfaceUnits: 1,
      plantsPerTray: 1
    },
    weight: '15g'
  },
  {
    uuid: 'product uuid 2',
    name: 'Empty Tray',
    internalCode: 'E',
    duration: 14,
    nurseryMaxPropagationTime: 13,
    trayType: {
      uuid: 'uuid 2',
      name: 'tray type name 2',
      surfaceUnits: 1,
      plantsPerTray: 1
    },
    weight: '15g'
  },
  {
    uuid: 'product uuid 3',
    name: 'Chives_18cc',
    internalCode: 'Chv5',
    duration: 14,
    nurseryMaxPropagationTime: 14,
    trayType: {
      uuid: 'uuid 3',
      name: 'tray type name 3',
      surfaceUnits: 1,
      plantsPerTray: 1
    },
    weight: '15g'
  }
];

export const defaultValues: FormProps = {
  scheduleName: '',
  firstPlantingDate: undefined,
  firstSeedingDate: undefined,
  growingCycle: 14,
  selectedFarm: {
    name: 'Acre 1',
    uuid: 'farm-uuid',
    schedulableBenches: 100,
    model: FarmModel.ACRE,
    traysPerBench: 100,
    capacityUnitsPerBench: 1,
    farmCycle: 14,
    serialNumber: 'TestingNumber',
    assignedUsers: null
  },
  configuration: [
    {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      trayType: {
        name: '',
        uuid: '',
        surfaceUnits: 0,
        plantsPerTray: 0
      },
      stageRecipeUuid: ''
    }
  ],
  hasDeficit: false,
  hasSurplus: false
};

export const partialBenchDefaultValues: FormProps = {
  ...defaultValues,
  selectedFarm: {
    capacityUnitsPerBench: 1.5,
    name: 'Acre 2',
    uuid: 'farm-uuid',
    schedulableBenches: 100,
    model: FarmModel.ACRE,
    traysPerBench: 100,
    farmCycle: 14,
    serialNumber: 'TestingNumber 2',
    assignedUsers: null
  }
};

export const plantingScheduleValues: FormProps = {
  scheduleName: 'Planting schedule name',
  growingCycle: 14,
  selectedFarm: {
    name: 'Acre 1',
    uuid: 'farm-uuid',
    schedulableBenches: 32,
    capacityUnitsPerBench: 1,
    model: FarmModel.ACRE,
    traysPerBench: 20,
    farmCycle: 14,
    serialNumber: null,
    assignedUsers: null
  },
  firstPlantingDate: toIsoDate(new Date()),
  firstSeedingDate: new Date(),
  configuration: [
    {
      monday: 8,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      trayType: {
        uuid: 'tray-type-uuid1',
        name: 'T27/95cc',
        surfaceUnits: 1,
        plantsPerTray: 1
      },
      stageRecipeUuid: 'product uuid 1'
    },
    {
      monday: 8,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      trayType: {
        uuid: 'tray-type-uuid2',
        name: 'T27/95cc',
        surfaceUnits: 1,
        plantsPerTray: 1
      },
      stageRecipeUuid: 'product uuid 2'
    }
  ],
  comment: 'testing comment',
  hasDeficit: false,
  hasSurplus: false
};

export enum FindByValues {
  SUBMIT_BTN = 'Apply Schedule',
  PLANTING_DATE = 'plantingDate',
  VARIETY_SELECT = 'varietySelect',
  VARIETY_SELECT_INPUT = 'varietySelectInput',
  TRAY_NUMBER = 'trayNumber',
  NAME_ERROR = 'nameError',
  TOTAL_CAPACITY = 'totalCapacity',
  TOTAL_BENCHES = 'totalBenches',
  ADD_ROW = 'addRow',
  REMOVE_ROW = 'removeRow',
  NAME_INPUT = 'Enter Schedule Name',
  PLACEHOLDER = 'placeholder',
  UNDER_OVER_PROVISIONED_TOOLTIP_TESTID = 'underOverProvisionedErrorTooltip',
  PARTIAL_BENCH_TOOLTIP_TESTID = 'partialBenchErrorTooltip',
  SURPLUS_OR_DEFICIT = 'surplusOrDeficitCell',
  TOOLTIP_ICON = 'tooltipIcon',
  PREV_CONFIGURED_BENCHES = 'prevConfigBenches'
}
