import styled from 'styled-components';
import { DsPlaceholder } from '@infarm/design-system-react';

export const InputWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 4px;
`;

export const InfarmLabel = styled.label`
  margin-top: 1.25em;
  display: block;
  width: 100%;
`;

export const Title = styled.span`
  font-weight: 500;
  color: #000000;
  margin-top: 21px;
`;

export const SmallCaption = styled.small`
  text-align: left;
  display: block;
  font-size: 12px;
  color: #828282;
  width: 100%;
  letter-spacing: 0.02em;
  line-height: 1.4;
`;

export const ValidationError = styled.div`
  font-size: 11px;
  color: #e00028;
`;

export const WarningMessage = styled(ValidationError)`
  color: #fa6400;
`;

export const Placeholder = styled(DsPlaceholder)`
  width: 100% !important;
  height: 42px;
`;
