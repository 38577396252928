import { sortCallback } from '.';
import { getWeekDays } from '../pages/PlantingSchedule/constants';
import { FormState } from '../pages/PlantingSchedule/types';
import {
  OperationalFarm,
  PlantingScheduleWeeklyConfiguration,
  FarmConfigTotals,
  PlantingScheduleWeeklyConfigurationInput,
  PerDayConfiguration,
  WeeklyConfigurationFromServer,
  StageRecipe
} from '../types';
import { DateLike, formatDateWithMonth, offsetDateWithTimezone } from './date-functions';
import { getTotalUnitsCapacity } from './farm';

export const getStageRecipeForFarmCycle = (
  stageRecipes: StageRecipe[],
  farmCycle: number
): StageRecipe[] =>
  stageRecipes
    .filter(({ duration }): boolean => {
      return duration === farmCycle;
    })
    .sort((a: StageRecipe, b: StageRecipe) =>
      sortCallback(a.internalCode ?? ''.concat(a.name), b.internalCode ?? ''.concat(b.name))
    );

export const getPropagationTimeBoundary = (
  stageRecipes: StageRecipe[],
  boundaryFn: Function
): number => {
  const totalDurationsPerSeeding: number[] = stageRecipes.map(
    ({ nurseryMaxPropagationTime }: StageRecipe) => nurseryMaxPropagationTime
  );
  const maxTimeInDays = boundaryFn(totalDurationsPerSeeding);
  return maxTimeInDays || 0;
};

export const selectConfiguredVarieties = (
  stageRecipes: StageRecipe[],
  plantingConfig: PlantingScheduleWeeklyConfiguration[]
) => {
  const stageRecipeUuids = plantingConfig
    .filter(({ stageRecipeUuid, trayType, ...numberOfTrays }) => {
      const listOfNumberOfTrays = Object.values(numberOfTrays);
      const hasAnyValue = listOfNumberOfTrays.some(value => value !== 0);
      return hasAnyValue;
    })
    .map(({ stageRecipeUuid }) => stageRecipeUuid);

  const stageRecipesFromConfig = stageRecipes.filter(stageRecipe => {
    return stageRecipeUuids.indexOf(stageRecipe.uuid) !== -1;
  });
  return stageRecipesFromConfig.length ? stageRecipesFromConfig : stageRecipes;
};

export const generateTotals = (): FarmConfigTotals => {
  const dailyTotal = {
    trays: 0,
    benches: 0,
    units: 0,
    harvestedBenches: 0,
    surplusOrDeficit: 0
  };

  const perDay: PerDayConfiguration = {
    monday: dailyTotal,
    tuesday: dailyTotal,
    wednesday: dailyTotal,
    thursday: dailyTotal,
    friday: dailyTotal,
    saturday: dailyTotal,
    sunday: dailyTotal
  };

  return {
    trays: 0,
    benches: 0,
    fillingPercentage: 0,
    perDay
  };
};

export const generateEmptyRowConfig = (): PlantingScheduleWeeklyConfiguration => {
  const plantingScheduleConfiguration = {
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    trayType: {
      name: '',
      uuid: '',
      surfaceUnits: 0,
      plantsPerTray: 0
    },
    stageRecipeUuid: ''
  };
  return plantingScheduleConfiguration;
};

export const computeFarmFillingPercentage = (
  farm: OperationalFarm,
  totalUnitsPerFarm: number,
  growingCycle: number
): number =>
  parseFloat(((totalUnitsPerFarm * 100) / getTotalUnitsCapacity(farm, growingCycle)).toFixed(2));

export const getFirstHarvestDate = (plantingDate: DateLike, growingCycle: number): Date => {
  const date = offsetDateWithTimezone(plantingDate);
  date.setDate(date.getDate() + growingCycle);
  return date;
};

export const getFirstSeedingDate = (plantingDate: DateLike, propagationTime: number): Date => {
  const date = offsetDateWithTimezone(plantingDate);
  date.setDate(date.getDate() - propagationTime);
  return date;
};

export const getServerConfigInput = (configuration: PlantingScheduleWeeklyConfiguration[]) => {
  const formattedConfig: PlantingScheduleWeeklyConfigurationInput = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  };

  getWeekDays().forEach(day => {
    formattedConfig[day] = configuration.map(conf => {
      return {
        stageRecipeUuid: conf.stageRecipeUuid,
        numberOfTrays: conf[day]
      };
    });
  });
  return formattedConfig;
};

export const hasFarmMoreThanTwoTrays = (configuration: PlantingScheduleWeeklyConfiguration[]) => {
  const weekDays = getWeekDays();

  return configuration.some(config => {
    let count = 0;
    for (const day of weekDays) {
      if (config[day] && ++count > 2) {
        return true;
      }
    }
    return false;
  });
};

export const getFormatedConfigAndTotalTrays = (
  configuration: WeeklyConfigurationFromServer,
  type: FormState
) => {
  const isView = type === FormState.VIEW;
  const groupedByStageRecipeUuid: {
    [stageRecipeUuid: string]: PlantingScheduleWeeklyConfiguration;
  } = {};
  const weekDays = getWeekDays();
  weekDays.forEach(day => {
    configuration[day]?.forEach(conf => {
      const stageRecipe = isView
        ? conf.stageRecipe
        : conf.stageRecipe.currentStageRecipe || conf.stageRecipe;
      const hasCurrentStageRecipe = conf.stageRecipe.currentStageRecipe;

      if (groupedByStageRecipeUuid[stageRecipe.uuid]) {
        groupedByStageRecipeUuid[stageRecipe.uuid] = {
          ...groupedByStageRecipeUuid[stageRecipe.uuid],
          [day]: conf.numberOfTrays,
          stageRecipeUuid: hasCurrentStageRecipe ? stageRecipe.uuid : undefined
        };
      } else {
        const emptyConfig = generateEmptyRowConfig();
        emptyConfig.stageRecipeUuid = hasCurrentStageRecipe ? stageRecipe.uuid : undefined;
        emptyConfig.trayType = stageRecipe.trayType;
        emptyConfig[day] = conf.numberOfTrays;
        emptyConfig.stageRecipeDensity = stageRecipe.density;
        if (isView) emptyConfig.stageRecipeName = stageRecipe.name;
        groupedByStageRecipeUuid[stageRecipe.uuid] = emptyConfig;
      }
    });
  });
  return Object.values(groupedByStageRecipeUuid);
};

export const getPlantingDaysWeek = (date: DateLike | undefined) => {
  if (!date) return null;
  const result: { [key: string]: string } = {};

  for (var i = 0; i < 7; i++) {
    const plantingDate = offsetDateWithTimezone(date);
    const newDate = new Date(plantingDate.setDate(plantingDate.getDate() + i));
    const weekDay = newDate.toLocaleString('en-us', { weekday: 'short' }).toString();
    result[weekDay] = formatDateWithMonth(newDate);
  }

  return result;
};

export const getGrowingCycleFromConfig = (configuration: WeeklyConfigurationFromServer): number => {
  const weekDays = getWeekDays();
  const defaultCycleWhenNoValue = 14;

  for (let dayIndex = 0; dayIndex < weekDays.length; dayIndex++) {
    const dailyConfigs = configuration[weekDays[dayIndex]];

    for (let configIndex = 0; configIndex < dailyConfigs?.length; configIndex++) {
      const currentConfig = dailyConfigs[configIndex]?.stageRecipe?.duration;

      if (currentConfig) {
        return currentConfig;
      }
    }
  }
  return defaultCycleWhenNoValue;
};
