import { addDays, eachDayOfInterval } from 'date-fns';
import { OperationalFarm, StageRecipe, WeekDayFormat } from '../../types';
import { getLocaleWeekDay, offsetDateWithTimezone, toIsoDate } from '../../utils/date-functions';
import { sortFarms } from '../../utils/farm';
import { DailyNumberOfValues, HubDemandConfig, PlantingWeekDays, TotalsRequirement } from './types';

export const generateDefaultHubConfig = (weekdays: PlantingWeekDays): Partial<HubDemandConfig> => {
  const dailyConfig = Object.values(weekdays).reduce((acc, weekday) => {
    acc[weekday] = 0;
    return acc;
  }, {} as DailyNumberOfValues);
  return {
    stageRecipe: undefined,
    dailyConfig
  };
};

export const formatHubInfo = (hubName: string, farms: OperationalFarm[]) => {
  const sortedFarms = sortFarms(farms);
  return `${hubName} | ${sortedFarms.map(({ name }) => name).join(', ')}`;
};

export const generateProductDemandDates = (
  start: string | undefined,
  end: string | undefined,
  doesRepeatWeekly: boolean
): { [key: string]: string } => {
  if (!start || !end) return {};
  const result: { [key: string]: string } = {};

  if (doesRepeatWeekly) {
    for (var i = 0; i < 7; i++) {
      const startDate = offsetDateWithTimezone(start);
      const newDate = new Date(startDate.setDate(startDate.getDate() + i));
      result[getLocaleWeekDay(newDate, WeekDayFormat.SHORT)] = toIsoDate(newDate);
    }
  } else {
    const startDate = offsetDateWithTimezone(start);
    const endDate = offsetDateWithTimezone(end);
    if (startDate > endDate) {
      return {};
    }
    const selectedDates = eachDayOfInterval({
      start: startDate,
      end: endDate
    });
    selectedDates.forEach(date => {
      result[getLocaleWeekDay(date, WeekDayFormat.SHORT)] = toIsoDate(date);
    });
  }
  return result;
};

interface APIProductDemands {
  quantity: number;
  stageRecipes: StageRecipe[];
}

interface APILocationDemand {
  harvestDate: string;
  isInternal: boolean;
  state: string;
  uuid: string;
  productDemands: APIProductDemands[];
}

export const convertAPIResponseToHubDemandConfig = (
  locationDemands: APILocationDemand[],
  plantingWeekDays: PlantingWeekDays
): HubDemandConfig[] => {
  const hubDemandConfigObj: { [stageRecipeUuid: string]: HubDemandConfig } = {};
  locationDemands.forEach(locationDemand =>
    locationDemand.productDemands.forEach(productDemand =>
      productDemand.stageRecipes.forEach(stageRecipe => {
        if (Object.values(plantingWeekDays).includes(locationDemand.harvestDate)) {
          const numberOfTrays = Math.floor(
            productDemand.quantity /
              (((stageRecipe?.density || 100) * (stageRecipe?.trayType?.plantsPerTray || 0)) / 100)
          );

          if (hubDemandConfigObj[stageRecipe.uuid]) {
            hubDemandConfigObj[stageRecipe.uuid].dailyConfig[locationDemand.harvestDate] =
              numberOfTrays;
          } else {
            const defaultDailyConfig = generateDefaultHubConfig(plantingWeekDays).dailyConfig;
            hubDemandConfigObj[stageRecipe.uuid] = {
              stageRecipe,
              dailyConfig: {
                ...defaultDailyConfig,
                [locationDemand.harvestDate]: numberOfTrays
              }
            };
          }
        }
      })
    )
  );
  return Object.values(hubDemandConfigObj);
};

interface ProductDemand {
  operationalRecipeUuid: string;
  quantity: number;
}
export const convertConfigToApiInput = (configurations: HubDemandConfig[], endDate: string) => {
  const dateProductDemandObject: { [date: string]: ProductDemand[] } = {};
  configurations.forEach(({ dailyConfig, stageRecipe }) => {
    const datesWithValue = Object.keys(dailyConfig).filter(date => !!dailyConfig[date]);
    datesWithValue.forEach(date => {
      const plantsPerTray = Math.ceil(
        ((stageRecipe?.density || 100) * (stageRecipe?.trayType?.plantsPerTray || 0)) / 100
      );

      const productDemand = {
        operationalRecipeUuid: stageRecipe.operationalRecipeUuid || '',
        quantity: dailyConfig[date] * plantsPerTray
      };
      if (dateProductDemandObject[date]) {
        dateProductDemandObject[date].push(productDemand);
      } else {
        dateProductDemandObject[date] = [productDemand];
      }
    });
  });
  const dates = Object.keys(dateProductDemandObject);

  dates.forEach(date => {
    let multiplier = 1;
    let dateToBeAdded = toIsoDate(addDays(offsetDateWithTimezone(date), 7 * multiplier));

    while (dateToBeAdded <= toIsoDate(offsetDateWithTimezone(endDate))) {
      dateProductDemandObject[dateToBeAdded] = [...dateProductDemandObject[date]];
      multiplier += 1;
      dateToBeAdded = toIsoDate(addDays(offsetDateWithTimezone(date), 7 * multiplier));
    }
  });

  return Object.keys(dateProductDemandObject).map(date => {
    return {
      harvestDate: date,
      productDemands: dateProductDemandObject[date]
    };
  });
};

export const calculateTotals = (
  tableConfigs: HubDemandConfig[],
  farmConfiguration: OperationalFarm,
  isGrandTotal: boolean
) => {
  return tableConfigs.reduce((total, config) => {
    const traysPerBench =
      (farmConfiguration?.capacityUnitsPerBench || 0) /
      (config.stageRecipe.trayType?.surfaceUnits || 0);

    Object.keys(config.dailyConfig).forEach(date => {
      if (!total[date]) {
        total[date] = {
          totalBenches: 0,
          totalTrays: 0,
          ...(!isGrandTotal && { extraTrays: 0 })
        };
      }
      total[date].totalTrays += config.dailyConfig[date];
      total[date].totalBenches += config.dailyConfig[date] / traysPerBench;

      if (!isGrandTotal) {
        total[date].extraTrays =
          Math.ceil(total[date].totalBenches) * traysPerBench - total[date].totalTrays;
      }
    });
    return total;
  }, {} as TotalsRequirement);
};

export const grandTotals = (
  groupedConfigurations: { [uuid: string]: HubDemandConfig[] },
  farmConfiguration: OperationalFarm
) => {
  const arrayOfTotals = Object.keys(groupedConfigurations).map(group => {
    return calculateTotals(groupedConfigurations[group], farmConfiguration, true);
  });
  const grandTotal = arrayOfTotals.reduce((grandTotal, total) => {
    Object.keys(total).forEach(date => {
      if (!grandTotal[date]) {
        grandTotal[date] = {
          totalBenches: 0,
          totalTrays: 0
        };
      }

      grandTotal[date].totalTrays += total[date].totalTrays;
      grandTotal[date].totalBenches += Math.ceil(total[date].totalBenches);
    });
    return grandTotal;
  }, {} as TotalsRequirement);

  return grandTotal;
};
