import { PlantingScheduleWeeklyConfiguration } from '../../../types';
import { generateEmptyRowConfig } from '../../../utils/planting-schedule';
import { ActionRowWrapper, ActionIcon, Cell, EmptyRow } from '../style';
import { FindByValues } from '../__tests/mock';

interface ActionRowProps {
  append: (value: PlantingScheduleWeeklyConfiguration) => void;
}

export const ActionRow = (props: ActionRowProps) => {
  const { append } = props;

  return (
    <>
      <ActionRowWrapper>
        <Cell>
          <ActionIcon
            role={FindByValues.ADD_ROW}
            data-cy="PlantingSchedule___addRow"
            onClick={() => {
              append(generateEmptyRowConfig());
            }}
          >
            add
          </ActionIcon>
        </Cell>
        {Array.from(Array(8), (_, x) => x).map(cell => {
          return <Cell key={cell} />;
        })}
      </ActionRowWrapper>
      <EmptyRow />
    </>
  );
};
