import styled from 'styled-components';
import { BreadcrumbsElement } from './BreadcrumbsElement';
import { BreadcrumbsElementType } from './types';

const BreadcrumbsStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

interface BreadcrumbsProps {
  elements: BreadcrumbsElementType[];
}

export const Breadcrumbs = ({ elements }: BreadcrumbsProps) => {
  return (
    <BreadcrumbsStyled>
      {elements.map((element, idx) => (
        <BreadcrumbsElement
          element={element}
          isLastElement={idx === elements.length - 1}
          key={idx}
        />
      ))}
    </BreadcrumbsStyled>
  );
};
