import { ExcessDemand, OperationalFarm, StageRecipe } from '../../types';

export interface FormProps {
  doesRepeatWeekly: boolean;
  startDate: string | undefined;
  endDate: string | undefined;
  configurations: HubDemandConfig[];
  hubDetails?: HubDetails;
  stageRecipes: StageRecipe[];
}

export interface HubDetails {
  uuid: string;
  name: string;
  operationalFarm: OperationalFarm[];
}

export type DailyNumberOfValues = {
  [date: string]: number;
};

export interface HubDemandConfig {
  stageRecipe: StageRecipe;
  dailyConfig: DailyNumberOfValues;
}

export type PlantingWeekDays = { [key: string]: string };

export interface TotalsRequirement {
  [date: string]: DailyTotalValues;
}

export enum TotalValues {
  totalTrays = 'required trays',
  totalBenches = 'required benches',
  extraTrays = 'Extra trays to utilise'
}

export type DailyTotalValues = {
  totalTrays: number;
  totalBenches: number;
  extraTrays?: number;
};

export enum PasteModalTypes {
  REPLACE = 'REPLACE',
  COLUMNS_LENGTH = 'COLUMNS_LENGTH',
  NON_NUMERIC = 'NON_NUMERIC',
  INVALID = 'INVALID'
}

export type LocationDemand = {
  state: string;
  uuid: string;
  harvestDate: string;
  name: string;
  description: string;
  isInternal: string;
  url: string;
  productDemands: [ProductDemand];
};

type ProductDemand = {
  uuid: string;
  stageRecipes: [StageRecipe];
  quantity: number;
};

export type ProductExcessDemand = {
  locationDemand: LocationDemand;
  excessDemands: ExcessDemand[];
};
