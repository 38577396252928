import { FormControl, MenuItem, Select, FormHelperText, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useController, UseFieldArrayUpdate } from 'react-hook-form';
import styled from 'styled-components';
import { PlantingScheduleWeeklyConfiguration, StageRecipe } from '../../../types';
import { FormProps, FormState } from '../types';
import { FindByValues } from '../__tests/mock';

export const StyledFormControl = styled(FormControl)`
  .MuiInputBase-root {
    min-width: 267px;
    min-height: 68px;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
  }
  .MuiInput-underline:before {
    border-bottom: 0;
  }
`;

interface VarietySelectProps {
  varietyOptions: StageRecipe[];
  name: string;
  productIndex: number;
  configuration: PlantingScheduleWeeklyConfiguration[];
  availableVarieties: StageRecipe[];
  update: UseFieldArrayUpdate<FormProps, 'configuration'>;
}

export const VarietySelect = (props: VarietySelectProps) => {
  const { varietyOptions, availableVarieties, productIndex, configuration, update } = props;
  const { type } = useParams();
  const { field } = useController(props);
  const stageRecipeDensity = configuration[productIndex].stageRecipeDensity || 100;
  const plantsPerTray = configuration[productIndex].trayType?.plantsPerTray || 0;
  const actualPlantsPerTray = Math.ceil((stageRecipeDensity / 100) * plantsPerTray);

  return (
    <StyledFormControl>
      {type === FormState.VIEW ? (
        <TextField
          role={FindByValues.VARIETY_SELECT}
          value={configuration[productIndex].stageRecipeName}
          disabled
        />
      ) : (
        <Select
          required
          fullWidth
          role={FindByValues.VARIETY_SELECT}
          disableUnderline={true}
          data-cy={FindByValues.VARIETY_SELECT}
          inputProps={{ 'data-cy': FindByValues.VARIETY_SELECT_INPUT }}
          onChange={event => {
            const stageRecipeUuid = event.target.value as string;
            const product = availableVarieties.find(variety => variety.uuid === stageRecipeUuid);
            const stageRecipeDensity = product?.density;
            const stageRecipeName = product?.name;

            update(productIndex, {
              ...configuration[productIndex],
              trayType: product?.trayType,
              stageRecipeUuid,
              stageRecipeDensity,
              stageRecipeName
            });
          }}
          value={field.value || 'placeholder'}
        >
          <MenuItem value="placeholder" disabled>
            Select Variety
          </MenuItem>
          {varietyOptions.map(variety => (
            <MenuItem
              value={variety.uuid}
              key={variety.uuid}
              data-cy={`VarietySelect___${variety.name}`}
            >
              {`${variety.internalCode ? variety.internalCode + '_' : ''}${variety.name}`}
            </MenuItem>
          ))}
        </Select>
      )}
      {
        <FormHelperText>
          {configuration[productIndex].trayType?.name}
          {!!actualPlantsPerTray && <span> {` ∙ ${actualPlantsPerTray} plants per tray`}</span>}
        </FormHelperText>
      }
    </StyledFormControl>
  );
};
