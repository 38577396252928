import { gql } from '@apollo/client';

export const HUB_DETAILS = gql`
  query getHubDetails($uuid: ID!) {
    location(uuid: $uuid) {
      uuid
      name
      operationalFarms(farmModel: [ACRE], isEligibleForAutomation: true) {
        uuid
        name
        traysPerBench
        capacityUnitsPerBench
      }
    }
    stageRecipes(
      operationalRecipeStatus: ["production", "research"]
      operationalRecipeTypes: ["production"]
      farmModel: ACRE
    ) {
      uuid
      duration
      plantGroup
      name
      density
      internalCode
      nurseryMaxPropagationTime
      trayType {
        name
        uuid
        surfaceUnits
        plantsPerTray
      }
      operationalRecipeUuid
    }
  }
`;

export const HUB_DEMAND_BY_DATE = gql`
  query getHubDemandsByDate($locationUuid: ID, $harvestStartDate: String, $harvestEndDate: String) {
    locationDemands(
      filter: {
        locationUuid: $locationUuid
        harvestDateStart: $harvestStartDate
        harvestDateEnd: $harvestEndDate
        isNursery: false
        states: [SEALED]
      }
    ) {
      uuid
      name
      state
      harvestDate
      description
      isInternal
      productDemands {
        quantity
        stageRecipes {
          uuid
          duration
          plantGroup
          name
          density
          internalCode
          trayType {
            name
            uuid
            surfaceUnits
            plantsPerTray
          }
          operationalRecipeUuid
        }
      }
    }
  }
`;

export const ADD_HUB_DEMAND = gql`
  mutation writeLocationDemands($input: writeLocationDemandsInput) {
    writeLocationDemands(input: $input) {
      locationDemand {
        uuid
        harvestDate
      }
      excessDemands {
        uuid
        totalNumberOfTrays
        stageRecipe {
          uuid
          plantGroup
          duration
          internalCode
        }
      }
    }
  }
`;

export const SEAL_HUB_DEMAND = gql`
  mutation sealLocationDemands($input: [String]) {
    sealLocationDemands(locationDemandUuids: $input)
  }
`;
